import { Pipe, PipeTransform } from '@angular/core';
import { ServerService } from '@pinacono/common';
import { Document } from './types';

@Pipe({
  name: 'refdoc'
})
export class RefdocPipe implements PipeTransform {

  constructor(
    protected server: ServerService
  ) {
  }

  public transform(code: string): Promise<string> {
    return new Promise<string>( (resolve) => {
      this.server.request('doclib.get.by_code', {code: code})
      .then( (doc: Document) => {
        let title = (doc.attr && doc.attr['prefix'] || '') + doc.title + (doc.attr && doc.attr['suffix'] || '');
        resolve(`${doc.doc_code} - ${title}`);
      });
    });
  }
}
