import {
  Component,
  AfterViewInit,
  OnInit
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@pinacono/common';

import moment from 'moment-timezone';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: 'maintenance.html',
  styleUrls: [ 'maintenance.scss' ]
})
export class MaintenancePageComponent implements OnInit, AfterViewInit {

  public until: number = Date.now();
  public hour: number = 0;
  public min: number  = 0;
  public sec: number  = 0;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
  ) {}

  public ngOnInit():void {
    const until = this.activatedRoute.snapshot.queryParamMap.get('until');
    if ( until !== null ) {
      this.until = moment(until, 'HH:mm:ss').unix() * 1000;
    }
  }

  public ngAfterViewInit(): void {
    this.nextSecond();
  }

  protected nextSecond() {
    setTimeout( () => {
      const diff = this.until - Date.now();
      this.hour = Math.floor( ( diff % (1000 * 60 * 60 * 24) ) / (1000 * 60 * 60) );
      this.min  = Math.floor( ( diff % (1000 * 60 * 60 ) ) / (1000 * 60) );
      this.sec  = Math.floor( ( diff % (1000 * 60) ) / 1000 );
      this.nextSecond();
    }, 1000);
  }
}
