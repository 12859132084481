<div *ngFor="let comment of content" class="comment panel panel-trans">
  <!--
  <pre>{{ comment | json }}</pre>
  -->
  <div class="pad-all">
    <div class="media user-info mar-btm">
      <avatar [user]="comment.user"></avatar>
      <div class="media-body">
        <p class="text-lg text-main text-semibold mar-no">{{ comment.user.fullname }} </p>
        <div class="text-sm user-info">
          <span title="E-mail"><i class="pli-mail icon-fw"></i>{{ comment.user.email || 'n/a' }}</span>
          <span title="Job Title"><i class="pli-business-man icon-fw"></i> {{ comment.user.profiles['job_title'] || 'n/a' }}</span>
          <span title="Post Time"><i class="fa fa-regular fa-clock icon-fw"></i> {{ comment.updated_at | datetime }}</span> <!-- @TODO - check if display current time when updated_at is null ? -->
          <span *ngIf="comment.user.deleted" class="badge badge-default">{{ 'Account Disabled' | translate }}</span>
          <ng-template *ngIf="comment.attr && templateRef"
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ comment: comment }"
          >
          </ng-template>
        </div>
        <p *ngIf="comment.attachments && comment.attachments.length > 0">
          <download *ngFor="let file of comment.attachments" [file]="file"></download>
        </p>
      </div>
    </div>
    <blockquote class="bq-sm bq-open bq-close" [innerHTML]="comment.comment | safe"></blockquote>
  </div>
</div>
<!-- new comment -->
<div *ngIf="!readonly" class="comment panel panel-trans pad-all">
  <div class="pad-all new-comment">
    <div class="media user-info mar-btm">
      <avatar [user]="session.currentUser"></avatar>
      <div class="media-body">
        <p class="text-lg text-main text-semibold mar-no"> {{ session.currentUser?.fullname || 'n/a' }} </p>
        <span><i class="pli-mail icon-fw"></i>{{ session.currentUser?.email || 'n/a' }}</span>
        <span><i class="pli-business-man icon-fw"></i> {{ session.currentUser && session.currentUser.profiles && session.currentUser.profiles['job_title'] || 'n/a' }}</span>
        <span><i class="fa fa-regular fa-clock icon-fw"></i> {{ 'Now' | translate }}</span>
        <ng-template *ngIf="templateRef"
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{ comment: new_comment }"
        >
        </ng-template>
      </div>
    </div>
    <textarea
      class="bq form-control"
      placeholder='{{ "Write some comment.." | translate }}'
      autosize  name="comment"
      style="resize: vertical;"
      [rows]="2"
      [minHeight]="67"
      [(ngModel)]="new_comment.comment"
    >
    </textarea>
    <div *ngIf="allow_attachment" class="mar-top attachment form-group">
      <input type="file" multiple #attachments>
    </div>
    <div class="text-right pad-top buttons">
      <button *ngFor="let btn of opl_buttons" class="btn btn-labeled {{ btn.btnClass }}" (click)="action(btn)"><i class="btn-label {{ btn.iconClass }}"></i> {{ btn.label | translate }}</button>
    </div>
  </div>
</div>