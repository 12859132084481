<div class="pad-all">
  <div *ngIf="!data" id="no-data">
    <span class="text-thin text-muted">{{ 'Competency information not found!' | translate}}</span>
  </div>
  <div *ngIf="!!data">

    <h3 class="text-semi-bold">
      {{ data.code }} - {{ data.name }}
    </h3>

    <div class="mar-btm competency-categories">
      <h5 class="text-bold">{{ 'Categories' | translate }}:</h5>
      <span> {{ data.categories }}</span>
      <!--
      <span *ngFor="let category of data.categories">
        {{ category.name }} ({{ category.description }})
      </span>
      -->
    </div>

    <div class="mar-btm competency-description">
      <h5 class="text-bold">{{ 'Description' | translate }}:</h5>
      <p class="text-thin markdown" [markdown]="data.description">
        {{data.description}}
      </p>
    </div>

    <!--
    <div *ngIf="show_attachments" class="mar-btm competency-attachments">
      <h5>Attachments</h5>
      <download *ngFor="let f of data.attachments" [file]="f"></download>
      <p *ngIf="data.attachments.length == 0"
        class="text-thin italic"
      >
        {{ 'Attachments not avaialble.' | translate }}
      </p>
    </div>
    -->

    <div *ngIf="show_levels" class="mar-btm competency-levels">
      <h5>{{ 'Levels' | translate }}:</h5>
      <div *ngFor="let level of data.levels"
        class="competency-level"
      >
        <div class="level">
          <h6 class="text-bold">{{ 'Level' | translate }} {{ level.level || 0 }}</h6>
          <div [markdown]="level.description">
            {{ level.description }}
          </div>
          <div *ngIf="level.jobs && level.jobs.length > 0" class="job">
            <p>
              <strong>{{ 'Target Job Titles' | translate }}</strong>
            </p>
            <ul>
              <li *ngFor="let job of level.jobs">
                <a (click)="selectJob(job)" class="clickable">{{ job.title }}</a>
              </li>
            </ul>
          </div>
          <div *ngIf="level.courses && level.courses.length > 0">
            <p>
              <strong>{{ 'Training Courses' | translate }}</strong>
            </p>
            <ul>
              <li *ngFor="let course of level.courses">
                <a (click)="selectCourse(course)" class="clickable">{{ course.code }} - {{ course.name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="show_dependencies" class="course-dependencies">
        <label class="text-bold">Courses:</label>
        <diagram #courseDiagram
          [(nodes)]="nodes"
          [(links)]="links"
          [readonly]="true"
          [scale]="0.8"
          [rootLayoutOptions]="rootLayoutOptions"
        >
          <ng-template
            let-node="node"
            let-selected="selected"
          >
            <div class="node-container"
              [ngClass]="{'selected': selected}"
              (click)="selectCourse(node.data)"
            >
              <div class="text-bold course-title">
                {{ node.data.code}}
              </div>
              <div class="course-details">
                <h5 class="text-bold">{{ node.data.name }}</h5>
                <p [markdown]="node.data.description">
                  {{ node.data.description }}
                </p>
              </div>
            </div>
          </ng-template>
        </diagram>
      </div>
    </div>

  </div>
</div>