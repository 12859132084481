import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  Renderer2,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';

import {
  NavigationService,
  StringUtilsService
} from '@pinacono/common';

import * as _ from 'lodash';

import { Menu, MenuBadgeSetting } from "../../types";
import { ThemeService } from "../../theme.service";

@Component({
  selector: 'ul[menu-items]',
  templateUrl: 'menu.html',
  styleUrls: ['menu.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements AfterViewInit, OnChanges {
  @Input('menu-items') items: Menu[] = [];
  @Input('parent') parent: Menu|null = null;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public theme: ThemeService,
    protected el: ElementRef,
    protected renderer: Renderer2,
    protected stringUtils: StringUtilsService
  ) {
  }

  public ngAfterViewInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'nifty-menu');

    if ( this.isSubMenu ) {
      this.renderer.addClass(this.el.nativeElement, 'collapse');
    }
    else {
      this.renderer.addClass(this.el.nativeElement, 'list-group');
    }

    if ( !! this.parent && this.isActive(this.parent) ) {
      this.renderer.addClass(this.el.nativeElement, 'show');
    }
    else {
      this.renderer.removeClass(this.el.nativeElement, 'show');
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ( changes['items'] ) {
      this.parseBadges(this.items);
    }
  }

  protected parseBadges(items: Menu[]) {
    items.forEach( m => {
      if ( !! m.badge ) {

        this.theme.setMenuBadge(
          m.badge.name,
          ( this.theme.badges[m.badge.name] && this.theme.badges[m.badge.name].text )    || m.badge.settings.text,
          ( this.theme.badges[m.badge.name] && this.theme.badges[m.badge.name].class )   || m.badge.settings.class,
          ( this.theme.badges[m.badge.name] && this.theme.badges[m.badge.name].tooltip ) || m.badge.settings.tooltip,
          ( this.theme.badges[m.badge.name] && this.theme.badges[m.badge.name].onClick ) || m.badge.settings.onClick
        );
      }
      m.children && this.parseBadges(m.children);
    });
  }

  // -- template API

  public getBadge(name: string): MenuBadgeSetting {
    return this.theme.badges[name]; // use callback to allow value updates
  }

  public goto(menu: Menu) {
    if ( menu.link ) {
      this.theme.activeMenu = menu;
      this.nav.setRoot(menu.link);
    }
  }

  public isActive(menu: Menu): boolean {
    if ( !! menu.type && menu.type != 'entry' ) {
      return false;
    }

    if ( ! menu.link ) {
      return false;
    }

    if ( this.theme.activeMenu && menu.id == this.theme.activeMenu.id ) {
      return true;
    }

    let menu_url    = this.stringUtils.trim(this.nav.NavigatableLinkToURLString(menu.link), '\\s\/');
    let current_url = this.stringUtils.trim(this.nav.getResolvedUrl(this.activatedRoute.snapshot), '\\s\/');

    if ( current_url == menu_url ) {
      return true;
    }

    // check if children active
    if ( menu.children ) {
      for ( let i in menu.children ) {
        if ( this.isActive(menu.children[i]) ) {
          return true;
        }
      }
    }

    return false;
  }

  public hasChildren(menu: Menu): boolean {
    return (!! menu.children && menu.children.length > 0);
  }

  // -- getter

  public get isSubMenu(): boolean {
    return !! this.parent;
  }

  public get parentIsActive(): boolean {
    return !! this.parent && this.isActive(this.parent)
  }
}