<full-page [title]="'Competencies'">
  <div page-content>
    <panel>
      <div panel-body>
        <extended-slickgrid *ngIf="gridOptions !== null" #grid
          class="row-selectable"
          [gridId]="'training-competencies-table'"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>
