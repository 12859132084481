import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { config } from '@pinacono/common';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const cfg = config('server', {});

  /*
  const headers = new HttpHeaders({
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "Origin, Accept, Authorization, Cache-Control, Content-Type, X-Requested-With, X-Auth-Token, X-Client-Locale",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELET",
    "Access-Control-Allow-Origin": cfg['base']
  });
  */

  return {
    link: httpLink.create({
      uri: '' + cfg['base'] + '/graphql',
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    }
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    }
  ],
  exports: [
    ApolloModule
  ]
})
export class ApolloGraphQLModule { }
