import {
  Component
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  SessionService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import { BasePageComponent } from "src/app/classes/base-page.component";

import { TrainingService } from '../training.service';

@Component({
  selector: 'competency-page',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ]
})
export class TrainingCompetencyViewPage extends BasePageComponent {

  public id: string|null = null;

  // -- lifecycle
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
  ) {
    super(router, activatedRoute)
  }

  public override loadData(): Promise<void> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    if ( ! id ) {
      this.back();
      return Promise.resolve();
    }

    this.id = id;
    return Promise.resolve();
  }

  // -- template API

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public selectCourse(course_id: number) {
    //this.nav.push(TrainingCoursePage, {id: course_id});
    this.nav.push([ '/training/courses/view', course_id ]);
  }

  public selectJob(job_id: number) {
    //this.nav.push(TrainingJobPage, {id: job_id});
    this.nav.push([ '/training/jobs/view', job_id ]);
  }
}