<minimal-page>
  <div class="cls-content-sm">
    <div page-head>
      <h1 class="error-code text-info">404</h1>
    </div>
    <div page-content>
      <div class="text-center">
        <p class="h4 text-uppercase text-bold">{{ 'Page Not Found' | translate}}!</p>
        <div class="pad-btm">
          Sorry, but the page you are looking for has not been found on our server.
        </div>
        <div class="row mar-ver">
          <form class="col-xs-12 col-sm-10 col-sm-offset-1" (keydown.enter)="search(keyword.value)">
            <div class="search-container collapse" id="nav-searchbox">
              <input type="text" #keyword class="form-control error-search" placeholder="{{ 'Type for Search' | translate }}...">
            </div>
          </form>
        </div>
        <hr class="new-section-sm bord-no">
        <div class="pad-top"><a class="btn btn-primary" [routerLink]="['/home']">{{ 'Return Home' | translate}}</a></div>
      </div>
    </div>
  </div>
</minimal-page>