import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { SearchResultEntry } from '../../types';


@Component({
  selector: 'search-page-component',
  templateUrl: 'search-page.component.html',
  styleUrls: [ 'search-page.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SearchPageComponent extends BasePageComponent implements OnInit{
  public keyword: string = '';

  public total: number = 0; // total result entries
  public pageno: number = 1;
  public perpage: number = 10;
  public elapsed: number = 0;
  public results: SearchResultEntry[] = [];

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    this.keyword = this.activatedRoute.snapshot.paramMap.get('keyword') || '';
  }

  // preprocess the keyword before searching
  public search(keyword: string | null) {
    if ( keyword === null ) return;

    this.keyword = keyword.trim();
    this.pageno = 1;
    this.doSearch(this.keyword, this.pageno, this.perpage);
  }

  // perform searching, override by derived class
  protected doSearch(keyword: string, pageno: number, perpage: number) {
    console.error('doSearch() need to be overriden by the extended class!');
  }

  // -- getters / setters
  set currentPage(page: number) {
    this.pageno = page;
    this.search(this.keyword);
  }

  get currentPage(): number {
    return this.pageno;
  }

}
