import {
  Component,
  Input,
  ViewChild, ElementRef,
  AfterViewInit,
  OnChanges, SimpleChanges,
  ChangeDetectorRef,
  ViewEncapsulation
} from '@angular/core';
import { Colors } from '../../types';

@Component({
  selector: 'panel',
  templateUrl: 'panel.component.html',
  styleUrls: [ 'panel.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent implements AfterViewInit, OnChanges {
  @Input() feature?: 'maximizable'|'dismissable'|'collapsible'|'collapsed'|null = null;
  @Input() height?: number|null = null;
  @Input() color?: Colors|null = null;
  @Input() colorful?: boolean = false;
  @Input() bordered?: Colors|boolean = false;

  public hasHeading: boolean = true;
  public hasFooter: boolean = true;
  public panelClasses: string = '';

  @ViewChild('panelControl') panelControl!: ElementRef;
  @ViewChild('panelHeading') panelHeading!: ElementRef;
  @ViewChild('panelFooter')  panelFooter!: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['color'] || changes['colorful'] || changes['bordered'] ) {
      let classes = [];

      if ( this.color ) {
        classes.push('panel-' + this.color);
      }
      if ( this.colorful ) {
        classes.push('panel-colorful');
      }
      if ( this.bordered ) {
        classes.push('panel-bordered' + ((this.bordered === true) ? '' : '-' + this.bordered));
      }

      this.panelClasses = classes.join(' ');
    }
  }

  public ngAfterViewInit() {
    this.hasHeading = this.panelControl.nativeElement.childElementCount > 0 || !! this.feature || this.panelHeading.nativeElement.childElementCount > 0;
    this.hasFooter  = this.panelFooter.nativeElement.childElementCount > 0;
    this.cdr.detectChanges();
  }
}
