export const admin = {
  "id":1,
  "email":"admin@pinacono.com",
  "fullname":"System Administrator",
  "profiles":[

  ],
  "preferences":{
    "lang":"en"
  },
  "last_login":"2022-12-01 08:26:45+07",
  "maximo_user":"escmngspd,escmngspw",
  "attr":{
    "agreements":[
      {
        "date":"2021-11-21 19:18:33+0700",
        "agreement":"\u0e1e.\u0e23.\u0e1a.\u0e04\u0e38\u0e49\u0e21\u0e04\u0e23\u0e2d\u0e07\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e38\u0e04\u0e04\u0e25 \u0e1e.\u0e28.2562 (PDPA)",
        "signature":"System Administrator (no staff id)"
      }
    ]
  },
  "level":255,
  "line_uid":null,
  "line_username":null,
  "avatar_id":1,
  "avatar_thumbUrl":"http:\/\/localhost:4200\/server\/files\/avatar\/5db\/ae4\/a11\/thumb_1_150x150_0_0_auto.png",
  "avatar_pathUrl":"http:\/\/localhost:4200\/server\/files\/avatar\/5db\/ae4\/a11\/5dbae4a118b0e732525282.png",
  "primary_domain":{
    "id":1,
    "name":"Domain",
    "description":"Domain",
    "parent_id":null,
    "nest_depth":0,
    "permissions":[
      "core_manage_group"
    ],
    "text":"Domain",
    "path":"Domain"
  },
  "deleted":false,
  "password_expired":false,
  "groups":[
    {
      "id":1,
      "name":"Domain",
      "description":"Domain",
      "parent_id":null,
      "nest_depth":0,
      "permissions":[
        "core_manage_group",
        "core_manage_group"
      ],
      "text":"Domain",
      "path":"Domain"
    },
    {
      "id":230,
      "name":"Competencies",
      "description":"Competencies",
      "parent_id":null,
      "nest_depth":0,
      "permissions":[
        "training_manage",
        "library_manage"
      ],
      "text":"Competencies",
      "path":"Competencies"
    }
  ],
  "effective_groups":[
    {
      "id":1,
      "name":"Domain",
      "description":"Domain",
      "parent_id":null,
      "nest_depth":0,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Domain",
      "path":"Domain"
    },
    {
      "id":118,
      "name":"\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":121,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":122,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "parent_id":121,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32"
    },
    {
      "id":123,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "parent_id":121,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07"
    },
    {
      "id":124,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25",
      "parent_id":121,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25"
    },
    {
      "id":149,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":150,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32",
      "parent_id":149,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32"
    },
    {
      "id":151,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e41\u0e25\u0e30\u0e23\u0e32\u0e22\u0e07\u0e32\u0e19",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e41\u0e25\u0e30\u0e23\u0e32\u0e22\u0e07\u0e32\u0e19",
      "parent_id":149,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e41\u0e25\u0e30\u0e23\u0e32\u0e22\u0e07\u0e32\u0e19",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e41\u0e25\u0e30\u0e23\u0e32\u0e22\u0e07\u0e32\u0e19"
    },
    {
      "id":152,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e01\u0e33\u0e01\u0e31\u0e1a\u0e14\u0e39\u0e41\u0e25\u0e01\u0e32\u0e23\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e01\u0e33\u0e01\u0e31\u0e1a\u0e14\u0e39\u0e41\u0e25\u0e01\u0e32\u0e23\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":149,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e01\u0e33\u0e01\u0e31\u0e1a\u0e14\u0e39\u0e41\u0e25\u0e01\u0e32\u0e23\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e2a\u0e48\u0e27\u0e19\u0e01\u0e33\u0e01\u0e31\u0e1a\u0e14\u0e39\u0e41\u0e25\u0e01\u0e32\u0e23\u0e1b\u0e0f\u0e34\u0e1a\u0e31\u0e15\u0e34\u0e07\u0e32\u0e19\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":153,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e2a\u0e21\u0e23\u0e23\u0e16\u0e19\u0e30\u0e1a\u0e38\u0e04\u0e25\u0e32\u0e01\u0e23\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e2a\u0e21\u0e23\u0e23\u0e16\u0e19\u0e30\u0e1a\u0e38\u0e04\u0e25\u0e32\u0e01\u0e23\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":149,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e2a\u0e21\u0e23\u0e23\u0e16\u0e19\u0e30\u0e1a\u0e38\u0e04\u0e25\u0e32\u0e01\u0e23\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e2a\u0e48\u0e27\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e2a\u0e21\u0e23\u0e23\u0e16\u0e19\u0e30\u0e1a\u0e38\u0e04\u0e25\u0e32\u0e01\u0e23\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":224,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32 Best Practice",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32 Best Practice",
      "parent_id":149,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32 Best Practice",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e1b\u0e23\u0e30\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e20\u0e32\u0e1e\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e1e\u0e31\u0e12\u0e19\u0e32 Best Practice"
    },
    {
      "id":955,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":956,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25",
      "parent_id":955,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25"
    },
    {
      "id":958,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "parent_id":956,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\/\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07"
    },
    {
      "id":957,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28",
      "parent_id":956,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\/\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28"
    },
    {
      "id":959,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01",
      "parent_id":956,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\/\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01"
    },
    {
      "id":960,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "parent_id":955,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32"
    },
    {
      "id":961,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23",
      "parent_id":960,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23"
    },
    {
      "id":962,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":960,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":963,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07",
      "parent_id":960,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07"
    },
    {
      "id":964,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32",
      "parent_id":955,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32"
    },
    {
      "id":965,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32",
      "parent_id":964,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32"
    },
    {
      "id":966,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
      "parent_id":964,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b"
    },
    {
      "id":967,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22",
      "parent_id":964,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22"
    },
    {
      "id":968,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":955,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":969,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38",
      "parent_id":968,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38"
    },
    {
      "id":970,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07",
      "parent_id":968,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07"
    },
    {
      "id":971,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":968,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":972,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19",
      "parent_id":968,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19"
    },
    {
      "id":973,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e2a\u0e31\u0e0d\u0e0d\u0e32\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e2a\u0e31\u0e0d\u0e0d\u0e32\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":955,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e2a\u0e31\u0e0d\u0e0d\u0e32\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32, \u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e2a\u0e31\u0e0d\u0e0d\u0e32\u0e14\u0e49\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":974,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22\u0e41\u0e25\u0e30\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22\u0e41\u0e25\u0e30\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22\u0e41\u0e25\u0e30\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22\u0e41\u0e25\u0e30\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c"
    },
    {
      "id":975,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22",
      "parent_id":974,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22\u0e41\u0e25\u0e30\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c\/\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22"
    },
    {
      "id":976,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":974,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22\u0e41\u0e25\u0e30\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c\/\u0e2a\u0e48\u0e27\u0e19\u0e0b\u0e2d\u0e1f\u0e15\u0e4c\u0e41\u0e27\u0e23\u0e4c\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":1756,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23"
    },
    {
      "id":1757,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":1756,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":1758,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "parent_id":1757,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e1a\u0e1a\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21"
    },
    {
      "id":1759,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a",
      "parent_id":1756,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a"
    },
    {
      "id":1760,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 1",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 1",
      "parent_id":1759,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 1",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 1"
    },
    {
      "id":1761,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 2",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 2",
      "parent_id":1759,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 2",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 2"
    },
    {
      "id":1762,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 3",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 3",
      "parent_id":1759,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 3",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\/\u0e1d\u0e48\u0e32\u0e22\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e41\u0e25\u0e30\u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a 3"
    },
    {
      "id":1763,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d \u0e41\u0e25\u0e30\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d \u0e41\u0e25\u0e30\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "parent_id":1756,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d \u0e41\u0e25\u0e30\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e01\u0e48\u0e2d\u0e2a\u0e23\u0e49\u0e32\u0e07 \u0e1b\u0e23\u0e30\u0e2a\u0e32\u0e19\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e41\u0e25\u0e30\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\/\u0e1d\u0e48\u0e32\u0e22\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d \u0e41\u0e25\u0e30\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e07\u0e1a\u0e1b\u0e23\u0e30\u0e21\u0e32\u0e13\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23"
    },
    {
      "id":978,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21"
    },
    {
      "id":979,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "parent_id":978,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32"
    },
    {
      "id":980,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23",
      "parent_id":979,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e41\u0e25\u0e30\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e32\u0e23"
    },
    {
      "id":981,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":979,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e31\u0e1a\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2b\u0e25\u0e31\u0e01\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":982,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":979,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e44\u0e1f\u0e1f\u0e49\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07\u0e41\u0e25\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":983,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "parent_id":979,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e01\u0e25\u0e41\u0e25\u0e30\u0e44\u0e1f\u0e1f\u0e49\u0e32\/\u0e2a\u0e48\u0e27\u0e19\u0e2a\u0e38\u0e02\u0e32\u0e20\u0e34\u0e1a\u0e32\u0e25\u0e41\u0e25\u0e30\u0e14\u0e31\u0e1a\u0e40\u0e1e\u0e25\u0e34\u0e07"
    },
    {
      "id":984,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22",
      "parent_id":978,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e2d\u0e02\u0e48\u0e32\u0e22"
    },
    {
      "id":985,
      "name":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":978,
      "nest_depth":3,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":986,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e04\u0e27\u0e1a\u0e04\u0e38\u0e21\u0e04\u0e27\u0e32\u0e21\u0e1b\u0e25\u0e2d\u0e14\u0e20\u0e31\u0e22\u0e41\u0e25\u0e30\u0e2a\u0e38\u0e02\u0e2d\u0e19\u0e32\u0e21\u0e31\u0e22"
    },
    {
      "id":987,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e08\u0e31\u0e14\u0e0b\u0e37\u0e49\u0e2d\u0e41\u0e25\u0e30\u0e04\u0e25\u0e31\u0e07\u0e1e\u0e31\u0e2a\u0e14\u0e38"
    },
    {
      "id":988,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e0a\u0e48\u0e32"
    },
    {
      "id":989,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e1a\u0e33\u0e23\u0e38\u0e07\u0e23\u0e31\u0e01\u0e29\u0e32\u0e2d\u0e32\u0e04\u0e32\u0e23\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b"
    },
    {
      "id":990,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e1b\u0e23\u0e30\u0e2b\u0e22\u0e31\u0e14\u0e1e\u0e25\u0e31\u0e07\u0e07\u0e32\u0e19"
    },
    {
      "id":991,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23"
    },
    {
      "id":992,
      "name":"\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07",
      "description":"\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07",
      "parent_id":985,
      "nest_depth":4,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e44\u0e2d\u0e04\u0e2d\u0e19\u0e2a\u0e22\u0e32\u0e21\/\u0e1d\u0e48\u0e32\u0e22\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\u0e42\u0e22\u0e18\u0e32\u0e41\u0e25\u0e30\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23\/\u0e2a\u0e48\u0e27\u0e19\u0e41\u0e2a\u0e07\u0e2a\u0e35\u0e40\u0e2a\u0e35\u0e22\u0e07"
    },
    {
      "id":1754,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e1a\u0e23\u0e34\u0e2b\u0e32\u0e23\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23"
    },
    {
      "id":1755,
      "name":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\u0e1e\u0e34\u0e40\u0e28\u0e29",
      "description":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\u0e1e\u0e34\u0e40\u0e28\u0e29",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\u0e1e\u0e34\u0e40\u0e28\u0e29",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19\u0e2d\u0e2d\u0e01\u0e41\u0e1a\u0e1a\u0e42\u0e04\u0e23\u0e07\u0e01\u0e32\u0e23\u0e1e\u0e34\u0e40\u0e28\u0e29"
    },
    {
      "id":1658,
      "name":"\u0e17\u0e35\u0e48\u0e1b\u0e23\u0e36\u0e01\u0e29\u0e32",
      "description":"\u0e17\u0e35\u0e48\u0e1b\u0e23\u0e36\u0e01\u0e29\u0e32",
      "parent_id":118,
      "nest_depth":2,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"\u0e17\u0e35\u0e48\u0e1b\u0e23\u0e36\u0e01\u0e29\u0e32",
      "path":"Domain\/\u0e2a\u0e32\u0e22\u0e07\u0e32\u0e19\u0e27\u0e34\u0e28\u0e27\u0e01\u0e23\u0e23\u0e21\/\u0e17\u0e35\u0e48\u0e1b\u0e23\u0e36\u0e01\u0e29\u0e32"
    },
    {
      "id":225,
      "name":"AIS",
      "description":"AIS",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"AIS",
      "path":"Domain\/AIS"
    },
    {
      "id":229,
      "name":"Maximo",
      "description":"Maximo",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Maximo",
      "path":"Domain\/Maximo"
    },
    {
      "id":977,
      "name":"Outsource",
      "description":"Outsource",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Outsource",
      "path":"Domain\/Outsource"
    },
    {
      "id":995,
      "name":"Pinacono",
      "description":"Pinacono",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Pinacono",
      "path":"Domain\/Pinacono"
    },
    {
      "id":230,
      "name":"Competencies",
      "description":"Competencies",
      "parent_id":null,
      "nest_depth":0,
      "permissions":[
        "training_manage",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Competencies",
      "path":"Competencies"
    }
  ],
  "acting_managable_groups":[
    {
      "id":1,
      "name":"Domain",
      "description":"Domain",
      "parent_id":null,
      "nest_depth":0,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Domain",
      "path":"Domain"
    },
    {
      "id":225,
      "name":"AIS",
      "description":"AIS",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"AIS",
      "path":"Domain\/AIS"
    },
    {
      "id":229,
      "name":"Maximo",
      "description":"Maximo",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Maximo",
      "path":"Domain\/Maximo"
    },
    {
      "id":977,
      "name":"Outsource",
      "description":"Outsource",
      "parent_id":1,
      "nest_depth":1,
      "permissions":[
        "core_manage_group",
        "library_manage",
        "training_mentor",
        "doc_manage",
        "core_admin"
      ],
      "text":"Outsource",
      "path":"Domain\/Outsource"
    }
  ],
  "permissions":[
    "library_manage",
    "training_mentor",
    "doc_manage",
    "core_admin"
  ],
  active: true
};