/**
 * routes for 'admin' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { AdminDashboardComponent } from 'src/app/modules/admin/dashboard/dashboard.component';
import { AdminGroupsComponent } from 'src/app/modules/admin/groups/groups';
import { AdminImportComponent } from 'src/app/modules/admin/imports/import.component.';
import { AdminReportsComponent } from 'src/app/modules/admin/reports/reports.component';
import { AdminTaxonomiesComponent } from 'src/app/modules/admin/taxonomies/taxonomies';
import { AdminUsersComponent } from 'src/app/modules/admin/users/users';
import { AdminNotifyComponent } from './notify/notify.component';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  admin_dashboard: {
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  },

  admin_groups: {
    path: 'admin/groups',
    component: AdminGroupsComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  },

  admin_taxonomies: {
    path: 'admin/taxonomies',
    component: AdminTaxonomiesComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  },

  admin_reports: {
    path: 'admin/reports',
    component: AdminReportsComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  },

  admin_notify: {
    path: 'admin/notify',
    component: AdminNotifyComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  },

  admin_imports: {
    path: 'admin/imports',
    component: AdminImportComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  },

  admin_users: {
    path: 'admin/users',
    component: AdminUsersComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: ['core_admin']
        }
      }
    }
  }
}
