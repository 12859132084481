<minimal-page>
  <div class="cls-content-sm panel">
    <div class="panel-body">
      <h1 class="h3">{{ 'Reset password' | translate }}</h1>
      <p class="pad-btm">{{'Since you have requested to reset your password. Please enter your new password' | translate}}.</p>
      <form #f="ngForm" autocomplete="off">
        <div class="form-group">
          <input type="email" name="email" class="form-control" placeholder="{{ 'E-mail' | translate }}"
            required
            [(ngModel)]="email"
            #input_email="ngModel"
          >
          <div *ngIf="input_email.invalid && (input_email.dirty || input_email.touched)" class="alert alert-danger">
            <div class="text-sm text-thin" *ngIf="input_email.errors && input_email.errors['required']">{{ 'Your e-mail is required' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <input type="password" name="password" class="form-control" placeholder="{{ 'New password' | translate }}"
            autocomplete="new-password"
            confirmPassword="conf-password"
            (keyup)="validate($event)"
            [(ngModel)]="new_passwd"
            #input_new_password="ngModel"
          >
          <div *ngIf="pwd_suggestions.length" id="password-suggestions" class="alert alert-danger">
            <div class="text-sm text-thin" *ngIf="input_new_password.errors && input_new_password.errors['required']">{{ 'New password is required' | translate }}</div>
            <div *ngFor="let s of pwd_suggestions" class="text-sm text-thin suggestion"><i class="fa-solid fa-times fa-fw"></i> {{ s }}</div>
          </div>
        </div>
        <div class="form-group">
          <input type="password" name="confirm" class="form-control" placeholder="{{ 'New password again' | translate }}"
            [(ngModel)]="cnf_passwd"
            #input_cnf_passwprd="ngModel"
          >
          <div class="alert alert-danger" *ngIf="input_new_password.invalid && (input_cnf_passwprd.dirty || input_cnf_passwprd.touched)">
            <div class="text-sm text-thin" *ngIf="input_cnf_passwprd.errors && input_cnf_passwprd.errors['required']">{{ 'New password is required' | translate }}</div>
            <div class="text-sm text-thin" *ngIf="input_new_password.errors && input_new_password.errors['confirmPassword']">{{ 'Password confirmation is mismatched' | translate }}</div>
          </div>
        </div>
        <div class="form-group text-right">
          <button class="btn btn-info btn-lg btn-block" type="submit" [disabled]="! f.form.valid || ! new_passwd || !! pwd_suggestions.length" (click)="reset()">{{ 'Change Password' | translate }}</button>
        </div>
      </form>
      <div class="pad-top">
        <a [routerLink]="['/login']" class="btn-link text-thin text-main mar-rgt">{{ 'Back to Login' | translate }}</a>
      </div>
    </div>
  </div>
</minimal-page>