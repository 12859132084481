<minimal-page>

  <div class="cls-content-sm panel">
    <div class="panel-body">
      <h1 class="h3">{{ 'Forgot password' | translate }}</h1>
      <form *ngIf="!request_sent" #f="ngForm" (ngSubmit)="reset(f)">
        <p class="pad-btm">{{'Enter your staff id or e-mail to recover your password' | translate}}.</p>
        <div class="form-group">
          <input type="email" name="email" ngModel class="form-control" placeholder="{{ 'Staff ID or E-mail' | translate }}">
        </div>
        <div class="form-group text-right">
          <button class="btn btn-danger btn-lg btn-block" type="submit">{{ 'Reset Password' | translate }}</button>
        </div>
      </form>
      <div *ngIf="request_sent">
        <p class="pad-btm">{{'The reset link has been sent to your e-mail. Please check your mailbox.' | translate}}.</p>
      </div>
      <div class="pad-top">
        <a [routerLink]="['/login']" class="btn-link text-thin text-main mar-rgt">{{ 'Back to Login' | translate }}</a>
        <a [routerLink]="['/contact']" class="btn-link text-thin text-main mar-lft">{{ 'Contact Administrator' | translate }}</a>
      </div>
    </div>
  </div>

</minimal-page>