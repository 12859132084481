import { ExternalContact } from './../app/modules/projects/types';
import { domain } from "src/moc-data/taxonomies";
import { admin } from "src/moc-data/users";

import {
  Project,
  ProjectDocument,
  ProjectDrawing
} from "../app/modules/projects/types";

const external_departments = [
  'ฝ่ายผู้เช่าสัมพันธ์',
  'ฝ่ายกฎหมาย'
];

const external_contacts: ExternalContact[] = [
  {
    id: 1,
    fullname: 'จรัสศรี สุขใจ',
    email: 'charatsri.s@siampiwat.com',
    tel: '090-198-4172',
    department: external_departments[0],
    job_title: undefined,
    company: 'SPW',
    attr: {}
  },
  {
    id: 2,
    fullname: 'สมชาย มีสุข',
    email: 'somchaim@bgrimmgroup.com',
    tel: '090-111-3242',
    department: undefined,
    job_title: undefined,
    company: 'บริษัท บี.กริม เพาวเวอร์ จำกัด (มหาชน)',
    attr: {}
  }
]

const proj_docs: ProjectDocument[] = [];

const proj_drawings: ProjectDrawing[] = [];

const proj_roles = {
  internal: {
    'Project Leader'      : 'Project Leader',
    'Project Coordinator' : 'Project Coordinator',
    'Project Team Member' : 'Project Team Member',
    'Project Design Leader'      : 'Project Design Leader',
    'Project Design Coordinator' : 'Project Design Coordinator',
    'Project Design Team Member' : 'Project Design Team Member',
    'Project Construction Leader'      : 'Project Construction Leader',
    'Project Construction Coordinator' : 'Project Construction Coordinator',
    'Project Construction Team Member' : 'Project Construction Team Member'
  },
  external: {},
  vendor: {
    'Project Manager'          : 'Project Manager / ทีมบริหารโครงการ',
    'Construction Management'  : 'Construction Management / ทีมควบคุมงานก่อสร้าง',
    'Designer'                 : 'Designer / ผู้ออกแบบ',
    'QS'                       : 'ผู้สำรวจและประเมินราคา (QS, Quantity Surveyor & Cost Control)',
    'EIA Consultants'          :'ผู้ประเมินผลกระทบสิ่งแวดล้อม (EIA Consulatants)',
    'LEED Consultants'         :  'ผู้ประเมินมาตรฐานด้านพลังงานอาคาร (LEED Consultants ,Green Building Consultants)',
    'Surveyers'                :'ผู้สำรวจงานต่างๆ เฉพาะทาง',
    'Construction Contractors' : 'ผู้รับเหมาก่อสร้าง (Construction Contractors)',
  }
};

const projects: Project[] = [
  {
    id: 1,
    documentable: {
      id: 1000,
      code: {
        a: 'ENG',
        b: 'SPW',
        c: 'ENG',
        d: 'N',
        e: 'I',
        f: 'PRJ',
        g: '0',
        id: '0005'
      },
      doc_code: 'ENG-SPW-CV-NI-PRJ-00005',
      status: 'draft', // 'draft'|'reviewed'|'published'|'deleted'|'cancelled'
      title: 'อะไรซักอย่าง',
      content: '',
      document_date: '',
      type: 'Project',
      domain_id: 1,
      domain: domain.children[0],
      files: [],
      categories: [],
      schedules: [],
      comments: [],
      uploader_id: 1,
      is_memo: false,
      expiration: null,
      next_review: null,
      content_docs: [],
      attr: {},
      mandatory_permitted_users: [],
      optional_permitted_users: []
    },
    title_prefix: 'โครงการก่อสร้าง',
    title: 'อะไรซักอย่าง',
    title_suffix: 'ปีที่แล้ว',

    masters: [],

    //is_handing_over: false,
    project_status: 'open', // 'draft'|'open'|'pending'|'cancelled'|'closed'
    project_type: 'renovate',
    project_scope: '-- project scope description --',
    locations: [ 'SPD '],
    schedule_start: '2023-06-01',
    schedule_finish: '2023-12-31',

    uploader_id: 1,
    uploader: admin,
    attr: {
    },
    internal_project_roles: [
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Leader'],
        team_name: 'Project Core Team',
        is_leader: true,
        is_coordinator: false
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Coordinator'],
        team_name: 'Project Core Team',
        is_leader: false,
        is_coordinator: true
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Team Member'],
        team_name: 'Project Core Team',
        is_leader: false,
        is_coordinator: false
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Design Leader'],
        team_name: 'Project Design Team',
        is_leader: true,
        is_coordinator: false
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Design Coordinator'],
        team_name: 'Project Design Team',
        is_leader: false,
        is_coordinator: true
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Design Team Member'],
        team_name: 'Project Design Team',
        is_leader: false,
        is_coordinator: false
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Construction Leader'],
        team_name: 'Project Construction Team',
        is_leader: true,
        is_coordinator: false
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Construction Coordinator'],
        team_name: 'Project Construction Team',
        is_leader: false,
        is_coordinator: false
      },
      {
        id: 1,
        project_id: 1,
        user_id: 1,
        user: admin,
        project_role: proj_roles.internal['Project Construction Team Member'],
        team_name: 'Project Construction Team',
        is_leader: false,
        is_coordinator: false
      }
    ],
    // External and vendors are overlay on the same table.
    // Let the companyname as null if it's SPW's staff
    external_project_roles: [
      {
        id: 1,
        project_id: 1,
        contact_id: 1,
        contact: external_contacts[0]
      }
    ],
    vendor_project_roles: [
      {
        id: 1,
        project_id: 1,
        project_role: proj_roles.vendor['Construction Contractors'],
        contact_id: 2,
        contact: external_contacts[1]
      }
    ],
    comments: [],
    documents: proj_docs,
    drawings: proj_drawings,
  }
];

export const TEST_DATA = {
  projlib: {
    register: {
      id: 1,
      code: "ENG-SPW-ENG-NI-PRJ-00005",
      next_id: "5"
    },
    browse: {
      projects: {
        projects: {
          data: projects,
          paginatorInfo: {
            count: 1,
            currentPage: 1,
            firstItem: 1,
            hasMorePage: false,
            lastItem: 0,
            lastPage: 1,
            perPage: 20,
            total: 0
          }
        }
      },
      docs: {
        project_documents: {
          data: proj_docs,
          paginatorInfo: {
            count: proj_docs.length,
            currentPage: 1,
            firstItem: 0,
            hasMorePage: false,
            lastItem: 0,
            lastPage: 1,
            perPage: 20,
            total: 0
          }
        }
      },
      drawings: {
        project_drawings: {
          data: proj_drawings,
          paginatorInfo: {
            count: proj_drawings.length,
            currentPage: 1,
            firstItem: 1,
            hasMorePage: false,
            lastItem: 0,
            lastPage: 1,
            perPage: 20,
            total: 0
          }
        }
      }
    },
    show: projects[0],
    proj_drawing: {
      show: {}
    }
  }
};
