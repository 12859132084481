import {
  Component,
  OnInit
} from '@angular/core';

import {
  Router,
} from '@angular/router';

import {
  NavigationService,
  object_t,
  PagesQueueService,
  ServerService,
  SessionService
} from '@pinacono/common';

const AGREEMENT_NAME = 'พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 (PDPA)';

@Component({
  selector: 'app-privacy',
  templateUrl: 'privacy.component.html',
  styleUrls: [ 'privacy.component.scss' ]
})
export class PrivacyComponent implements OnInit {
  public signature: string = '';

  constructor(
    protected router: Router,
    protected nav: NavigationService,
    protected session: SessionService,
    protected server: ServerService,
    protected queue: PagesQueueService
  ) {
    if ( session.currentUser !== null ) {
      this.signature = session.currentUser.fullname + ' (' + (session.currentUser.profiles['staff_id'] || 'no staff id') + ')';
    }
  }

  public ngOnInit(): void {
    if (
      this.session.currentUser?.attr['agreements'] &&
      this.session.currentUser.attr['agreements'].find( (agreement: object_t) => agreement['agreement'] == AGREEMENT_NAME)
    ) {
      //console.debug('User already signed the privacy agreement, advance to next page.');
      this.queue.next();
      return;
    }
    //console.debug('User has never been signed the privacy agreement, Entering the privacy page.');
  }

  public next(): void {
    this.server.request({ method: 'POST', url: '/spw/sign'}, null, {
      agreement: AGREEMENT_NAME,
      signature: this.signature
    })
    .then( () => {
      this.session.refresh();
      console.debug('Signing completed - proceed to next page.');
      this.queue.next();
    });
  }

  public cancel(): void {
    this.nav.goto('login');
  }

}
