import {
  Component,
  ViewChild
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  Attachment,
  config,
  NavigationService,
  object_t,
  PaginatedResults,
  ServerService,
  SessionService
} from "@pinacono/common";

import {
  AttachmentsComponent,
  ModalComponent,
  UIService
} from "@pinacono/ui";

import { ExamChoice } from "src/app/common/types";
import { BasePageComponent } from "src/app/classes/base-page.component";

import {
  CompetencyLevel,
  ExamAnswerSheet,
  TrainingObjective,
  TrainingRecord
} from '../types';

import { TrainingService } from '../training.service';

interface ReferenceDocType {
  value: string;
  label: string;
  url: string;
}

@Component({
  selector: 'training-record-view-page',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ]
})
export class TrainingRecordViewPage extends BasePageComponent {

  @ViewChild('attachments') attachments!: AttachmentsComponent;
  @ViewChild('examAnswerSheet') examAnswerSheet!: ModalComponent;
  @ViewChild('examDialog') examDialog!: ModalComponent;

  public record: TrainingRecord|null = null;
  public examinations: ExamAnswerSheet[] = [];
  public answer_sheet: ExamAnswerSheet|null = null;
  public current_question: number = 0;
  public show_exam: boolean = false;
  public next_exam: number = 0;

  public user_is_owner: boolean = false;
  public user_is_mentor: boolean = false;

  public statusCssClasses: object_t = {
    pending:   'info',
    progress:  'mint',
    cancelled: 'dark',
    passed:    'success',
    failed:    'danger'
  }

  public refTypes: ReferenceDocType[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    protected server: ServerService,
    protected api: TrainingService
  ) {
    super(router, activatedRoute);
    this.refTypes = this.api.config['ref_doc_options'] || [];
  }

  // -- lifecycle
  public override loadData(): Promise<void> {
    const associated = [
      'user',
      'plan',
      'batch', 'batch.trainers', 'batch.course', 'batch.course.objectives', 'batch.course.levels', 'batch.course.levels.competency',
      'comments', 'comments.user',
      'objectives', 'objectives.comments', 'objectives.comments.user',
      'logs'
    ];

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( ! id ) {
      this.back();
      return Promise.resolve();
    }

    return this.server.show('training/records', parseInt(id), {
      with: associated.join(',')
    })
    .then( (data: object_t) => {
      this.record = this.api.createTrainingRecord(data);
      this.user_is_owner  = this.record.user_id == this.session.currentUser!.id;
      this.user_is_mentor = !! this.record.batch!.trainers.find( u => u.id == this.session.currentUser!.id ) || this.session.hasPermission(['core_admin']);
      return this.server.request('training.exams.list', null, {
        filters: {
          candidate_id: this.record.user!.id,
          course_id: this.record.batch!.course.id
        }
      })
    })
    .then( (res: PaginatedResults<ExamAnswerSheet>) => {
      this.examinations = res.data.map( data => this.api.createExamAnswerSheet(data) );
      this.examinations.forEach( (answer_sheet: ExamAnswerSheet) => {
        answer_sheet.result = this.examResult(answer_sheet.score, answer_sheet.max_score);
      });
      let silent_period = this.session.hasPermission(['core_admin']) ? 0 : config('client.exam.exam_silent_period', 24 * 60 * 60) * 1000;
      if  ( this.examinations.length > 0 ) {
        let sorted = this.examinations
                      .map( (d) => Date.parse(d.created_at||'') )
                      .sort( (a, b) => b - a);
        this.next_exam = Math.max( 0, silent_period - ( Date.now() - sorted[0] ) );
      }
    });
  }

  // -- template API
  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public save() {
    if ( ! this.record ) return;
    this.ui.confirm("Save Changes?", undefined, () => {
      let data = {
        id: this.record!.id,
        batch_id: this.record!.batch_id,
        objectives: this.record!.objectives,
        attachments: this.record!.attachments,
        attr: this.record!.attr
      };
      this.server.update('training/records', this.record!.id!, data)
      .then( () => {
        this.refresh();
      });
    });
  }

  // examiniations
  public createExam() {
    if ( ! this.record ) return;
    this.ui.confirm("Take examination for '{{ name }}' ?", this.record.batch!.course,
      () => {
        // ref #895 - redirect to course page
        //this.nav.setRoot(TrainingCoursePage, { id: this.record.batch.course.id } );
        this.nav.setRoot([ '/training/courses/view', this.record!.batch!.course.id ]);
        /*
        this.show_exam = true;
        setTimeout( () => {
          this.examDialog.show();
        });
        */
      }
    );
  }

  public onExamSaved(score: number) {
    this.dismissExam(true);
  }

  public dismissExam(refresh: boolean = false) {
    this.examDialog.hide();
    this.show_exam = false;
    if ( refresh) {
      this.refresh();
    }
  }

  public examResult(score: number, max_score: number, objective: TrainingObjective|null = null): 'passed'|'failed'|'n/a' {
    if ( ! this.record ) {
      console.warn('Training record is not loaded!, return "n/a" by default');
      return 'n/a';
    }

    if ( objective == null ) {
      objective = this.record.objectives.find( o => o.min_score && o.min_score > 0 ) || null;
    }
    return objective && objective.min_score && ( objective.min_score <= (score/max_score) ? 'passed' : 'failed' ) || 'n/a';
  }

  public is_correct(choices: ExamChoice[], selected: number) {
    let corrects: number[] = choices.filter( c => c.is_correct ).map( c => c.id );
    return corrects.indexOf(selected) >= 0;
  }

  public viewExam(sheet: ExamAnswerSheet) {
    if ( this.session.hasPermission(['training_admin']) ) {
      this.current_question = 0;
      this.answer_sheet = sheet;
      this.examAnswerSheet.show();
    }
  }

  public prevQuestion() {
    this.current_question = Math.max(this.current_question - 1, 0);
  }

  public nextQuestion(max: number) {
    this.current_question = Math.min(this.current_question + 1, max);
  }

  public getAttachmentByObjId(attachments: Attachment[], id: number): Attachment[] {
    return attachments.filter( a => a.meta && a.meta['obj_id'] == id );
  }

  public setRefDoc(obj: TrainingObjective, list: any[]) {
    //https://engportal-qas.siampiwat.in.th/#/training/records/view/5395
    // @todo - check, if obj is a reference?
    obj.attr['ref_doc'] = list.map( r => !! r.type ? r : { id: r, type: 'others' } );
  }

  public getRefType(value: string): ReferenceDocType|undefined {
    return this.refTypes.find( r => r.value == value );
  }

  public openLink(item: { type: string, id: string }) {
    let type = this.getRefType(item.type);
    if ( ! type ) {
      return;
    }

    let url: string;
    if ( ! type.url ) {
      try {
        let _url = new URL(item.id);
        url = _url.href;
      }
      catch ( err ) {
        // invalid URL
        return;
      }
    }
    else {
      url = type.url.replace('{id}', item.id);
    }
    //console.log("Navigate to ", url);
    //this.nav.push(url);
    window.open(url, '_blank');
  }

  public onCompetencyClick(level: CompetencyLevel) {
    //this.nav.push(TrainingCompetencyPage, { id: level.competency_id });
    this.nav.push([ '/training/competencies/view', level.competency_id ]);
  }
}