import {
  Component,
  ViewChild,
  OnInit
} from '@angular/core';

import {
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  ServerService,
  SessionService,
  Taxonomy,
  TreeUtilsService,
  UserService
} from '@pinacono/common';

import {
  Tree,
  TreeComponent,
  UIService
} from '@pinacono/ui';

/**
 * Taxonomies Administration
 */
@Component({
  selector: 'app-admin-taxonomies-page',
  templateUrl: 'taxonomies.html',
  styleUrls: ['./taxonomies.scss']
})
export class AdminTaxonomiesComponent implements OnInit {
  @ViewChild('taxonomyTree') taxonomyTree!: TreeComponent;

  // template variables
  public vocabs: Taxonomy[] = [];
  public root: Taxonomy[] = [];

  public selected_vid: number|null = null;
  public contextMenu: Tree.ContextMenu = {
    create: {
      label: 'Create New Node',
    },
    edit: {
      label: 'Rename Node',
    },
    delete: {
      label: 'Delete Node',
    }
  };

  public treeOptions = {
    core: {
      multiple: false
    },
    plugins: ['dnd']
  };

  // -- constructor
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected session: SessionService,
    protected treeUtils: TreeUtilsService,
    protected ui: UIService,
    protected server: ServerService,
    protected userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.server.index('vocabularies').then( (v) => {
      this.vocabs = v;
      this.selected_vid = v[0].id;
    });
  }

  // -- template API
  public selectVocab(vid: number) {
    if ( ! vid ) {
      return;
    }

    this.server.show('terms', vid)
    .then( (terms: Taxonomy[]) => {
      //self.contextMenu = ( terms[0].name == config('core.primary_domain', 'Domain') ) ? false : CONTEXT_MENU;
      this.root = terms;
      this.selected_vid = vid;
      this.taxonomyTree.reload();
      this.taxonomyTree.expandAll();
    });
  }

  public selected_nid: number|null = null;
  public treeEvent(data: Tree.TreeEvent) {
    if ( data.name == 'ready' && this.selected_nid ) {
      this.taxonomyTree.select([this.selected_nid]);
      this.selected_nid = null;
    }
  }

  public createNode(data: Tree.CreateEvent) {
    this.selected_nid = data.node.id || null;
    this.selected_nid && this.server.request('groups.add', null, {
      name: data.newName,
      description: data.newName,
      new_parent_id: data.node.id
    })
    .then( () => {
      this.selected_vid && this.selectVocab(this.selected_vid)
    });
  }

  public editNode(data: Tree.EditEvent) {
    this.selected_nid = data.node.id || null;
    this.selected_nid && this.server.request('groups.edit', { id: data.node.id }, {
      name: data.newName,
    })
    .then( (res: any) => {
      this.selected_vid && this.selectVocab(this.selected_vid)
    });
  }

  public deleteNode(data: Tree.DeleteEvent) {
    let parent: Taxonomy = this.treeUtils.find(this.root, (node: Taxonomy): boolean => { return node.id == data.node.parent_id; } )
    this.selected_nid = parent && parent.id || null;
    this.selected_nid && this.server.request('groups.delete', { id: data.node.id })
    .then( (res: any) => {
      this.selected_vid && this.selectVocab(this.selected_vid)
    });
  }

  public async moveNode(data: Tree.MoveEvent) {
    // @todo - select to move or merge
    this.ui.prompt('Do you want to move current node to under this node or merge it to this node?',
      null,
      async (action: string) => {
        //console.debug('moveNode prompt', action);
        if ( action == 'move' ) {
          const res: any = await this.server.request('groups.edit', { id: data.node.id }, {
            new_parent_id: data.new_parent && data.new_parent.id,
            new_left_id: data.new_left && data.new_left.id || null,
            new_right_id: data.new_right && data.new_right.id || null
          })
          this.selected_vid && this.selectVocab(this.selected_vid)
        }
        else if ( action == 'merge' ) {
          const res: any = await this.server.request('groups.merge', { id: data.node.id }, {
            dst_id: data.new_parent && data.new_parent.id
          })
          this.selected_vid && this.selectVocab(this.selected_vid)
        }
      },
      null,
      'select',
      {
        inputOptions: [
          { text: 'Move',  value: 'move', default: true },
          { text: 'Merge', value: 'merge'}
        ],
      }
    );
  }

  public onSelectedNodeChange(selected: Tree.Node[]) {
    console.debug('selected nodes', selected);
  }
}
