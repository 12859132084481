<full-page [title]="'Competency'">
  <div page-content>
    <panel>
      <div panel-body>
        <p *ngIf="! id" class="text-center text-thin">
          {{ 'Description not available.' | translate }}
        </p>

        <competency *ngIf="!! id"
          [id]="id"
          [show_attachments]="true"
          [show_levels]="true"
          [show_dependencies]="true"
          (onCourseSelected)="selectCourse($event)"
          (onJobSelected)="selectJob($event)"
        >
        </competency>
      </div>
    </panel>
  </div>
</full-page>