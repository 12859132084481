import {
  Component,
  OnInit,
  OnDestroy,
  Input, ViewChild,
  forwardRef, Renderer2,
  Output, EventEmitter,
  ElementRef
} from "@angular/core";

import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from "@angular/forms";

import {
  ESResult,
  PaginatedESResult,
  ServerService
} from "@pinacono/common";

import {
  LookupEvent,
  LookupItem
} from "@pinacono/ui";

import { Document } from "src/app/modules/documents/types";
import { DocLibService } from "src/app/modules/documents/doclib.service";

const noop = () => {};

@Component({
  selector: 'related-documents',
  templateUrl: 'related-documents.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef( () => RelatedDocumentsComponent ),
      multi: true
    }
  ],
})
export class RelatedDocumentsComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input('min-char') minChar: number = 5;
  @Input('max-items') maxItems: number = 5;

  @Output('change') change = new EventEmitter<string>();

  @ViewChild('input') input!: HTMLInputElement;
  @ViewChild('editor') editor!: HTMLTextAreaElement;

  public docs: string[] = [];

  public keyword: string = '';
  public items: LookupItem<string>[] = [];

  // -- initialization

  constructor(
    protected element: ElementRef,
    protected server: ServerService,
    protected api: DocLibService,
    protected renderer: Renderer2
  ) {
  }

  public ngOnInit() {
    this.element.nativeElement.addEventListener('blur', this.blur.bind(this));
  }

  public ngOnDestroy() {
    this.element.nativeElement.removeEventListener('blur', this.blur);
  }

  // -- events
  private touchedHandler: () => void = noop;
  private changeHandler: (v: string) => void = noop;

  private blur() {
    this.touchedHandler();
  }

  // -- ControlValueAccessor interface
  public get value(): string {
    return this.docs.join("\n");
  }

  public set value(docs: string) {
    this.writeValue(docs);
  }

  public writeValue(docs: string) {
    if ( docs ) {
      this.docs = docs.split("\n").filter( (s: string) => s.length > 0 );
    }
    else {
      this.docs = [];
    }
  }

  public registerOnChange(fn: (v: string) => void) {
    this.changeHandler = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.touchedHandler = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if ( ! this.input ) return;
    let v: string = isDisabled ? '1' : '';
    this.renderer.setAttribute(this.input, 'disabled', v);
    this.renderer.setAttribute(this.editor, 'disabled', v);
  }

  // -- template interface
  public onLookup(keyword: string) {
    this.server.search('docs', `${keyword} AND NOT _exists_:attributes.template`, {
      status: 'published',
      doc_type: 'doc'
    }, { perpage: this.maxItems })
    .then( (res: PaginatedESResult<Document>) => {
      this.items = res.data.map( (d: ESResult<Document>) => {
        let doc  = this.api.createDocument(d.doc);
        let code = this.api.getDocCode(d.doc);
        return {
          label: code + ' : ' + doc.title,
          value: code
        };
      })
    });
  }

  public onSelect(item: LookupEvent<string>) {
    if ( item.value.value === null ) return;
    const values = this.docs.slice()
    values.push(item.value.value);
    this.writeValue(values.join("\n"));
    this.changeHandler(this.value);
    this.change.emit(this.value);
  }

  /*
  public onChange(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.writeValue(e.target['value']);
    this.changeHandler(this.value);
    this.change.emit(this.value);
  }
  */
  public onChange(value: string[]) {
    this.docs = value;
    this.changeHandler(this.value);
    this.change.emit(this.value);
  }
}