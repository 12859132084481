import {
  Component,
  Input,
  OnInit,
  ViewChild, ElementRef,
  Output, EventEmitter,
  ContentChild,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

import {
  FileUtilsService,
  ServerService,
  SessionService,
  UserService
} from '@pinacono/common';

import { AppCommonService } from '../../app-common.service';

import { Comment, CommentButton } from '../../types';

@Component({
  selector: 'comments',
  templateUrl: 'comments.html',
  styleUrls: [ 'comments.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class CommentsComponent implements OnInit {
  @Input() content: Comment[] = [];
  @Input() content_type!: string;       // content type to associate with
  @Input() content_id!: number|null;    // content id to associate with
  @Input() readonly?: boolean = false; // disable new comment or not
  @Input() allow_attachment: boolean = false;
  @Input() buttons: CommentButton[] = [];
  @Input() attributes?: object|null = null;
  @Output() attributesChange = new EventEmitter<object>();

  @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;
  @ViewChild('attachments') attachments!: ElementRef;

  public new_comment!: Comment;

  constructor(
    public session: SessionService,
    protected userService: UserService,
    protected server: ServerService,
    protected fileUtils: FileUtilsService,
    protected appCommonService: AppCommonService
  ) {
    this.initializeNewComment();
  }

  public opl_buttons: CommentButton[] = [];

  public ngOnInit() {
    this.initializeNewComment();
    this.opl_buttons = [
      ...this.buttons,
      {
        btnClass: 'btn-default',
        iconClass: 'fa fa-regular fa-comments',
        label: 'Add Comment',
        process: null
      }
    ];
  }

  protected initializeNewComment() {
    this.new_comment = this.appCommonService.createComment({
      user: this.session.currentUser,
      comment: '',
      attr: this.attributes
    });
  }

  // -- template API
  public action( btn:CommentButton ): void {
    let res: Comment | null | false = false;

    if ( !! btn.process ) {
      res = btn.process(this.new_comment);
      if ( res === null ) {
        return;
      }
      this.new_comment = res;
    }

    if ( this.new_comment.comment.length <= 0 ) {
      return;
    }

    let data: Comment = this.appCommonService.createComment({
      content_id: this.content_id,
      content_type: this.content_type,
      comment: this.new_comment.comment,
      attr: this.attributes
    });

    let promises: Promise<any>[] = [];

    if ( ! this.allow_attachment || this.attachments.nativeElement.files.length == 0 ) {
      promises.push(Promise.resolve());
    }
    else {
      for ( let file of this.attachments.nativeElement.files ) {
        promises.push(
          this.fileUtils.dataURL(file)
          .then( (base64: string) => {
            data.attachments && data.attachments.push(this.server.createAttachment({
              file_name: file.name,
              content_type: file.type,
              data_url: base64
            }));
          })
        );
      }
    }

    Promise.all(promises)
    .then( () => {
      this.server.request('comment.create', null, data)
      .then( (comment: Comment) => {
        this.content.push(comment);
        this.initializeNewComment();
      });
    })
  }
}