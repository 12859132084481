import {
  Component,
  OnInit
} from "@angular/core";

import { Service } from './types';

@Component({
  selector: 'admin-import-page',
  templateUrl: 'import.html',
  styleUrls: [ 'import.scss' ]
})
export class AdminImportComponent implements OnInit {

  public services: Service[] = [
    {
      label: 'Public Calendar',
      url: '/spreadsheet/holiday'
    },
    {
      label: 'User Information from HR',
      url: '/spreadsheet/HR'
    },
    {
      label: 'Organization Mapping and Permissions',
      url: '/spreadsheet/permissions'
    },
    {
      label: 'Job Description',
      url: '/spreadsheet/job'
    },
    {
      label: 'Competencies',
      url: '/spreadsheet/competency'
    }
  ];

  // -- constructor
  constructor() {}

  public ngOnInit(): void {}
}