<div *ngIf="!! course" class="exam panel">

  <div panel-heading>
    {{ 'New Examinations' | translate }} - {{ course.name }} ({{ course.code }})
  </div>

  <div panel-body id="new-exam" class="answer-sheet">
    <div class="exam-status text-right">
      <span *ngIf="scoring?.timer_mode == examTimerMode.TIME_LIMIT"
        class="exam-status-item exam-timer"
        [ngClass]="{
          'text-thin text-muted': (exam_remaining > 30),
          'text-bold text-warning': ( exam_remaining <= 30 && exam_remaining > 15 ),
          'text-bold text-danger': exam_remaining <= 15
        }"
      >
        <i class="pli-clock-back icon-fw icon-lg"></i>
        <span class="text-semi-bold">Time Remain</span>: {{ exam_remaining | interval:'mm:ss' }}
      </span>

      <span class="exam-status-item exam-timer" >
        <i class="pli-clock-forward icon-fw icon-lg"></i>
        <span class="text-semi-bold">Time Spent</span>: {{ exam_elapsed | interval:'mm:ss' }}
      </span>

      <span class="exam-status-item question-counter">
        <i class="pli-folder-archive icon-fw icon-lg"></i>
        {{ current_question + 1 }} / {{ new_exam.length }}
      </span>
    </div>

    <div *ngIf="!! new_exam[current_question]" class="question pad-all mar-all">
      <div class="question-group mar-btm">
        <p class="question-content text-lg text-semibold">{{ current_question + 1 }}. {{ new_exam[current_question].content }}</p>
        <gallery *ngIf="new_exam[current_question].attachments!.length > 0"
          class="img-responsive"
          [options]="{ tiles_col_width: 400 }"
          [items]="new_exam[current_question].attachments || []"
          [thumbnail]="{ width: 400, height: 400, mode: 'auto' }"
        ></gallery>
      </div>
      <div class="choices-group row">
        <div class="col-12" *ngFor="let choice of new_exam[current_question].choices">
          <div class="radio">
            <label>
              <input [(ngModel)]="new_answer_sheet[current_question].answer.id" type="radio" name="choice-{{choice.id}}" value="{{ choice.id }}">
              <span>{{ choice.content }}</span>
            </label>
            <gallery *ngIf="choice.attachments && choice.attachments.length > 0"
              class="img-responsive"
              [options]="{ tiles_col_width: 350 }"
              [items]="choice.attachments"
              [thumbnail]="{ width: 350, height: 350, mode: 'auto' }"
            ></gallery>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div pane-footer class="text-right pad-all">
    <button *ngIf="current_question > 0" stop-propagation (click)="prevQuestion()" title="{{ 'Previous' | translate }}" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa fas fa-arrow-left"></i> {{ 'Previous' | translate }}
    </button>
    <button *ngIf="current_question < new_exam.length - 1" stop-propagation (click)="nextQuestion(new_exam.length - 1)" title="{{ 'Next' | translate }}" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa fas fa-arrow-right"></i> {{ 'Next' | translate }}
    </button>
    <button stop-propagation (click)="saveExam()" title="{{ 'Submit this examination' | translate }}" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa far fa-save"></i> {{ 'Submit' | translate }}
    </button>
  </div>
</div>
