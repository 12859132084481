import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PinaconoUIModule } from '@pinacono/ui';
import { SlickgridExtensionModule } from '@pinacono/slickgrid-extension';

import {
  object_t,
  PinaconoCommonModule,
  ServerService,
  SessionService,
  UtilsService
} from '@pinacono/common';

import { AppUser } from 'src/app/types';
import { ThemesModule } from 'src/app/themes/module';

import { ENDPOINTS } from './endpoints';
import { LibraryService } from './library.service';
import { LibraryStatus} from './types';

// components
import { AssetsComponent } from './components/assets.component';
import { GridBookComponent } from './components/grid-book.component';

// pages
import { LibraryAdminPage } from './admin/admin';
import { LibraryAuditPage } from './admin/audit';

import { LibraryBooksBrowsePage } from './browse/browse';
import { LibraryBookViewPage } from './browse/view';
import { ApolloGraphQLModule } from 'src/app/apollo-graph-ql/apollo-graph-ql.module';
import { HttpLink } from 'apollo-angular/http';
import { LibraryActivitiesPage } from './browse/activities';
import { LibraryReportsPage } from './admin/reports';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,

    TranslateModule,
    PinaconoCommonModule,
    PinaconoUIModule,
    SlickgridExtensionModule,

    ApolloGraphQLModule,
    ThemesModule
  ],
  declarations: [
    // components
    GridBookComponent,
    AssetsComponent,
    /*
    RequesterComponent,
    */

    // pages
    LibraryActivitiesPage,
    LibraryAdminPage,
    LibraryAuditPage,
    LibraryReportsPage,
    LibraryBooksBrowsePage,
    LibraryBookViewPage,
    /*
    LibraryHome,
    LibraryBooksBrowse,
    LibraryMemberPage,

    LibraryBooksReservation,
    LibraryBooksReservationHistory,
    LibraryAssetsBrowse,
    LibraryAssetsAudit
    */
  ],
  providers: [
    LibraryService,
    HttpLink
  ],
  exports: [
    //TranslateModule,
    LibraryActivitiesPage,
    LibraryAdminPage,
    LibraryAuditPage,
    LibraryReportsPage,
    LibraryBooksBrowsePage,
    LibraryBookViewPage
  ]
})
export class LibraryModule {
  constructor(
    server: ServerService,
    session: SessionService,
    utils: UtilsService,
    library: LibraryService
  ) {

    server.registerEndpoints(ENDPOINTS); // register Library Service Endpoints

    session.LOGGEDIN.subscribe( (u: AppUser|null) => {
      // -- Library Module login
      /*
      if ( !! u ) {
        // monitor library status
        events.subscribe('server.system.library_status', (data: EventMessage) => {
          library.updateLibraryStatus(data.data as LibraryStatus)
        }, 'library-module');
      }
      else {
        events.unsubscribe('library-module');
      }
      */

      // get library configurations
      server.getconfig('library')
      .then( (cfg: object_t) => {
        library.rules = cfg['rules'];
        library.home_url = utils.sanitize(cfg['home_url'], 'resourceURL');
        //library.libraries_locations = cfg['locations'].sort( (a: string, b: string) => ( a > b) ? 1 : ( a < b) ? -1 : 0 );
        library.libraries_locations = Object.getOwnPropertyNames(cfg['locations']);
        library.libraries_branches  = cfg['branches'].sort( (a: string, b: string) => ( a > b) ? 1 : ( a < b) ? -1 : 0 );
      });

      // get library status
      server.request('library.status.get')
      .then( (statuses: LibraryStatus[]) => {
        statuses.forEach( (s) => library.updateLibraryStatus(s) );
      });

    });

    session.LOGGEDOUT.subscribe( () => {
      //events.unsubscribe('library-module');
    });
  }
}