/**
 * routes for 'opl' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { DocLibBrowsePage } from './browse/browse';
import { DocLibSearchPage } from './search/search';
import { DocLibViewPage } from './view/view';
import { DocLibEditPage } from './edit/edit';
import { DocLibReservePage } from './reserve/reserve';
import { DocLibReportsPage } from './reports/reports';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  /*
  doclib_dashboard: {
    path: 'doc',
    component: DocBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  */
  doclib_browse: {
    path: 'doc/browse/:name',
    component: DocLibBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  doclib_search: {
    path: 'doc/search/:keyword',
    component: DocLibSearchPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  doclib_view: {
    path: 'doc/view/:id',
    component: DocLibViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  doclib_reserve: {
    path: 'doc/reserve',
    component: DocLibReservePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  doclib_edit: {
    path: 'doc/edit/:id',
    component: DocLibEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  doclib_reports: {
    path: 'doc/reports',
    component: DocLibReportsPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
};