<full-page [title]="'Competency Gaps Simulator'">
  <div page-content>
    <panel>
      <div panel-body>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <label class="control-label text-bold">{{ 'Select Job' | translate }}:</label>
            <!--
            <input #searchForm type="text" placeholder="{{ 'Enter job title here...' | translate }}..." name="search-form" class="form-control" [(ngModel)]="job_title">
            -->
            <lookup-input
              [placeholder]="'Enter job title here...'"
              [minChar]="3"
              [autoClose]="false"
              [items]="jobs_list"
              (onLookup)="lookupJobTitle($event)"
              (onSelect)="selectJobTitle($event)"
              class="lookup"
            >
            </lookup-input>
          </div>
        </div>
        <gaps-analyzer *ngIf="candidate && job_description"
          [job]="job_description"
          [candidates]="[candidate]"
          [target]="'show'"
          (onJobChange)="refresh()"
          (onCompetencyClick)="onCompetencyClick($event)"
        >
        </gaps-analyzer>
      </div>
      <div panel-footer>
        <div class="text-right">
          <button type="button" class="btn btn-labeled btn-dark" data-dismiss="modal" (click)="back()"><i class="btn-label fa far fa-chevron-circle-left"></i>{{ 'Back' | translate }}</button>
        </div>
      </div>
    </panel>
  </div>
</full-page>