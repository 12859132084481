/**
 * routes for 'opl' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { LibraryBooksBrowsePage } from './browse/browse';
import { LibraryAdminPage } from './admin/admin';
import { LibraryAuditPage } from './admin/audit';
import { LibraryReportsPage } from './admin/reports';

import { LibraryBookViewPage } from './browse/view';
import { LibraryActivitiesPage } from './browse/activities';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  library_dashboard: {
    path: 'library/home',
    component: LibraryBooksBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  library_view_activities: {
    path: 'library/user',
    component: LibraryActivitiesPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },


  library_view_book: {
    path: 'library/book/:id',
    component: LibraryBookViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  library_manage: {
    path: 'library/admin',
    component: LibraryAdminPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: [ 'library_manage' ]
        }
      }
    }
  },
  library_manage_audit: {
    path: 'library/admin/audit',
    component: LibraryAuditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: [ 'library_manage' ]
        }
      }
    }
  },
  library_manage_report: {
    path: 'library/admin/reports',
    component: LibraryReportsPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: [ 'library_manage' ]
        }
      }
    }
  },
};