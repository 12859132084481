/**
 * routes for 'opl' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { OplBrowsePage } from './browse/browse';
import { OplViewPage } from './view/view';
import { OplEditPage } from './edit/edit';
import { OplReportsPage } from './reports/reports';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  opl_dashboard: {
    path: 'opl',
    component: OplBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  opl_browse_default: {
    path: 'opl/browse',
    component: OplBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  opl_browse: {
    path: 'opl/browse/:name',
    component: OplBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  opl_view: {
    path: 'opl/view/:id',
    component: OplViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  opl_edit: {
    path: 'opl/edit/:id',
    component: OplEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    },
  },
  opl_report: {
    path: 'opl/reports',
    component: OplReportsPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  }
};