<minimal-page>
  <div class="cls-content-lg panel animated fadeIn slow">
    <h1 id="page-icon" class="error-code text-muted"><i class="pli-pen"></i></h1>
    <div id="page-content" class="pad-btm">
      <content-frame id="privacy-content" [url]="'server/contents/privacy.html'"></content-frame>
      <div class="input-group mar-top mar-btm">
        <input type="text" placeholder="{{ 'Please enter your full name to sign the agreement' | translate }}.." class="form-control" name="signagure" [(ngModel)]="signature">
        <span class="input-group-btn">
          <button [attr.disabled]="!!signature ? null : ''" (click)="next()"   class="btn btn-labeled btn-mint"   type="button"><i class="btn-label fa fa-regular fa-thumbs-up"></i>{{ 'Accept' | translate }}</button>
          <button (click)="cancel()" class="btn btn-labeled btn-danger" type="button"><i class="btn-label fa fa-regular fa-thumbs-down"></i>{{ 'Not Accept' | translate }}</button>
        </span>
      </div>
    </div>
  </div>
</minimal-page>