import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild
} from "@angular/core";

import {
  config,
  ServerService,
  object_t
} from "@pinacono/common";

import {
  ModalComponent,
  InterpolatbleErrorMessage,
  UIService
} from "@pinacono/ui";

import { IIPViewerComponent } from "@pinacono/iip-viewer";

import { CustomPropertyConfig } from 'src/app/common/types';
import { Drawing } from '../../types';
import { ProjectLibService } from '../../projects.service';

@Component({
  selector: 'master-dwg-page',
  templateUrl: 'master.html',
  styleUrls: [ 'master.scss' ]
})
export class DrawingMasterPage implements OnInit, AfterViewInit {

  @ViewChild('masterEditor') masterEditor!: ModalComponent;
  @ViewChild('viewer') viewer!: IIPViewerComponent;

  public search_input = {
    title: '',
    building: '',
    floor: ''
  };

  public drawing: Drawing;
  public iip_base_path: string;

  public buildings_config: CustomPropertyConfig = {
    name: 'building',
    type: 'editable-list',
    label: 'Building',
    icon: 'fa-regular fa-building',
    options: []
  };

  public floors_config: CustomPropertyConfig = {
    name: 'floor',
    type: 'editable-list',
    label: 'Floor',
    icon: 'fa-regular fa-building',
    options: []
  }

  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[]
  } = {};

  public drawingsList: Drawing[] = [];

  // -- lifecycle

  constructor(
    public api: ProjectLibService,
    protected server: ServerService,
    protected ui: UIService
  ) {
    this.iip_base_path = config('client.drawing.iip_base_path');
    this.drawing = this.api.createDrawing();
  }

  public ngOnInit(): void {
    this.buildings_config.options = this.api.getBuildings();
  }

  public ngAfterViewInit(): void {
  }

  // -- template API

  public onBuildingChange(value: string): void {
    this.search_input.floor = '';
    this.floors_config.options = this.api.getFloors(value);
  }

  public onFilesChange(): void {
    if ( this.drawing.attachments.length == 0 ) {
      this.errors = {};
    }
    else {
      this.drawing.title = this.drawing.attachments[0].file_name;
    }
  }

  // create new master drawing

  public upload(): void {
    this.drawing = this.api.createDrawing({
      title:    this.search_input.title.trim(),
      building: this.search_input.building.trim(),
      floor:    this.search_input.floor.trim()
    });
    this.masterEditor.show();
  }

  // save master drawing

  public save(): void {

    this.errors = {};

    if ( this.drawing.attachments.length == 0 ) {
      this.errors['attachments'] = 'Drawing file is required';
    }

    if ( this.drawing.title.trim().length == 0 ) {
      this.errors['title'] = 'Drawing title is required';
    }

    //if ( this.drawing.attr['building'].trim().length == 0 ) {
    if ( ! this.drawing.attr['building'] || this.drawing.attr['building'].trim().length == 0 ) {
      this.errors['building'] = 'Building is required';
    }

    //if ( this.drawing.attr['floor'].trim().length == 0 ) {
    if ( ! this.drawing.attr['floor'] || this.drawing.attr['floor'].trim().length == 0 ) {
      this.errors['floor'] = 'Floor is required';
    }

    if ( Object.keys(this.errors).length > 0 ) {
      return;
    }

    // prepare data for saving
    const data = {
      id: this.drawing.id,
      title: this.drawing.title,
      //building: this.drawing.attr['building'],
      //floor: this.drawing.attr['floor'],
      is_master: true,
      attr: this.drawing.attr,
      attachment_defer_key: this.drawing.attachment_defer_key,
    }

    if ( data.id ) {
      this.ui.confirm('Update this drawing?', undefined, () => {
        this.server.update('drawings', data.id!, data)
        .then( (res: object_t) => {
          this.drawing = this.api.createDrawing(res);
          this.masterEditor.hide();
          this.search();
        });
      });
    }
    else {
      this.ui.confirm('Create this drawing?', undefined, () => {
        this.server.create('drawings', data)
        .then( (res: object_t) => {
          this.drawing = this.api.createDrawing(res);
          this.masterEditor.hide();
          this.search();
        });
      });
    }
    return;
  }

  public delete(): void {
    if ( ! this.drawing.id ) return;
    this.ui.confirm('Delete this drawing?', undefined, () => {
      this.server.destroy('drawings', this.drawing.id as number)
      .then( () => {
        this.drawing = this.api.createDrawing();
        this.masterEditor.hide();
        this.search();
      });
    });
  }

  // search for master drawings

  public search(): void {
    const data = {
      title:    this.search_input.title.trim(),
      building: this.search_input.building.trim(),
      floor:    this.search_input.floor.trim()
    }
    this.server.request('drawings.masters.search', null, data)
    .then( (res: object_t[]) => {
      this.drawingsList = res.map( r => this.api.createDrawing(r) );
    });
    return;
  }

  public select(dwg: Drawing): void {
    this.drawing = dwg;
    this.masterEditor.show();
  }
}