import { Component } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-faq',
  templateUrl: 'faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  constructor() { }

  public loaded() {
  }
}