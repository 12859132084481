import {
  Component,
  OnInit
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  Filters,
  FieldType,
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlPaginatedResult
} from "@slickgrid-universal/graphql";

import {
  AngularGridInstance,
  Column,
  GridOption
} from "angular-slickgrid";

import {
  object_t,
  NavigationService,
  ServerService,
  SessionService,
  User,
  UserService
} from "@pinacono/common";

import {
  UIService
} from '@pinacono/ui';

import {
  ExtendedFormatters,
  GraphQLServerService,
  LighthouseService
} from "@pinacono/slickgrid-extension";

import { BasePageComponent } from "src/app/classes/base-page.component";

import {
  Candidate,
  JobDescription,
  TrainingRecord
} from '../types';

import { TrainingService } from '../training.service';

@Component({
  selector: 'page-training-team-view',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ]
})
export class TrainingGapsViewPage extends BasePageComponent implements OnInit {

  // grid
  protected gridComponent: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  public records: TrainingRecord[] = [];

  // -- configurations
  protected maxSelectedGaps = 5;

  // -- models
  protected staff_id: number|null = null;
  public staff: User|null = null;
  public job_description: JobDescription|null = null;
  public candidate: Candidate|null = null;

  // -- initialization
  constructor(
    public override router: Router,
    public activatedRout: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected userService: UserService,
    protected server: ServerService,
    protected graphServer: GraphQLServerService
  ) {
    super(router, activatedRout);
  }

  // -- lifecycle
  public override ngOnInit() {
    let id = this.activatedRout.snapshot.paramMap.get('id');

    if ( ! id )  {

      if ( this.nav.canGoBack() ){
        this.nav.pop();
      }
      else {
        //this.nav.setRoot(TrainingTeamPage);
        this.nav.setRoot('/trainings/team')
      }
      return;
    }

    this.staff_id = parseInt(id);
    this.initGrid();
    super.ngOnInit();
  }

  protected silent: boolean = true;
  public override async loadData(): Promise<void> {

    this.job_description = null;

    const u: User = await this.server.show('users', this.staff_id!)
    this.staff = this.userService.create(u);

    const c: object_t[] = await this.server.get('training.profile.list', { uid: this.staff_id });
    this.candidate = {
      staff: this.staff!,
      profiles: c.map( (p : object) => this.api.createCompetencyProfile(p) )
    };

    const j: object_t = await this.server.get('training.job.user', {id: this.staff_id});
    this.job_description = this.api.createJobDescription(j);

    if ( !! this.gridComponent ) {
      this.silent = false;
      this.gridComponent.extensionService.refreshBackendDataset();
    }
  }

  // -- grid interfaces

  protected async processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    try {
      const res: object_t = await this.graphServer.sendQuery({query: query})
      const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
      this.records = re.data['trainingRecords'].nodes.map( (r: TrainingRecord) => this.api.createTrainingRecord(r) );
      return re;
    }
    catch (error: any) {
      this.ui.alert(error.message, undefined, 'Error!');
      console.log('GraphQL Error:', error);
    }
    return LighthouseService.parseResponse({}); // will create another error?
  }

  protected initGrid() {
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'trainingRecords',
          //filteringOptions: [
          persistenceFilteringOptions: [
            { field: 'uid', operator: 'EQ', value: this.staff_id!.toString() }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },
      enableSorting: true,
      rowHeight: 80,
      //autoHeight: true,
      forceFitColumns: true,
      enableAutoResize: false,
      autoResize: {
        container: '#main-table',
        bottomPadding: 85,
        minHeight: 600
      },
      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };

    this.columnDefinitions = [
      {
        id: 'course', name: 'Course',
        field: 'batch.course.name',
        fields: ['batch.course.name', 'batch.course.levels.level', 'batch.course.levels.competency.code', 'batch.course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 120,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          let course = dataContext.batch.course;
          return `<span title="${course.name}">${course.name}</span>`;
        }.bind(this)
      },

      {
        id: 'batch_name', name: 'Batch Name',
        field: 'batch.name',
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 70,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          let batch = dataContext.batch;
          return `<span title="${batch.name}">${batch.name}</span>`;
        }.bind(this)
      },

      {
        id: 'competency', name: 'Competency',
        field: 'batch.course',
        fields: ['batch.course.name', 'batch.course.levels.level', 'batch.course.levels.competency.code', 'batch.course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 70,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          // @TODO - handle multiple 'levels' info
          if ( dataContext.batch.course.levels.length == 0 ) {
            return `<span class="error" title="Competency not found!">- Competency Not Found! -</span>`;
          }
          let competency = dataContext.batch.course.levels[0].competency;
          return `<span title="${competency.name}">${competency.code} - ${competency.name}</span>`;
        }.bind(this)
      },

      {
        id: 'level', name: 'Level',
        field: 'batch.course.levels.level',
        fields: ['batch.course.name', 'batch.course.levels.level', 'batch.course.levels.competency.code', 'batch.course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-center', width: 30,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid) {
          // @TODO - handle multiple 'levels' info
          // @TODO - graphQL error here!
          let course = dataContext.batch.course;
          if ( course.levels.length == 0 ) {
            return `<span class="error" title="Competency not found!">?</span>`;
          }
          return `<span>${course.levels[0].level - 1}</span> <i class="fas fa-arrow-right"></i> <span>${course.levels[0].level}</span>`;
        }.bind(this),
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: '',  label: 'Not Filter' },
            { value: '0', label: 'Level 0 to 1' },
            { value: '1', label: 'Level 1 to 2' },
            { value: '2', label: 'Level 2 to 3' },
            { value: '3', label: 'Level 3 to 4' }
          ]
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-center', width: 30,
        formatter: this.renderStatus.bind(this),
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: '',            label: 'Not Filter',  css: 'default' },
            { value: 'pending',     label: 'Pending',     css: 'info'    }, // user is requesting to attend the course and waiting for approval
            { value: 'in progress', label: 'In Progress', css: 'mint'    }, // course is started and in progress
            { value: 'cancelled',   label: 'Cancelled',   css: 'dark'    }, // course is cancelled - end
            { value: 'passed',      label: 'Passed',      css: 'success' }, // user passed the course - end
            { value: 'failed',      label: 'Failed',      css: 'danger'  }  // user not passed (failed) - end
          ]
        }
      },

      {
        id: 'updated_at', name: 'Last Update',
        field: 'updated_at',
        fields: [ 'updated_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 40,
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];
  }

  public onGridReady(event: Event) {
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    let record = this.records[(event as CustomEvent).detail.args['row']];
    //this.nav.push('training-record-page', {id: record.id}, null, this.navDone);
    this.nav.push([ '/training/records/view', record.id]);
  }

  // -- internal API
  protected renderStatus(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
    const labels: { [name: string]: { label: string, css: string} } = {
      pending:    { label: 'Pending',     css: 'info'    },
      progress:   { label: 'In Progress', css: 'mint'    },
      cancelled:  { label: 'Cancelled',   css: 'dark'    },
      passed:     { label: 'Passed',      css: 'success' },
      failed:     { label: 'Failed',      css: 'danger'  }
    }
    let status = dataContext.status.toLowerCase();
    return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
  }

  // -- template API
  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public onCompetencySelected(id: number) {
    //this.nav.push(TrainingCompetencyPage, { id: id } );
    this.nav.push([ '/training/competencies/view', id ]);
  }
}