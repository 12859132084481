import { Component, OnInit } from '@angular/core';
import {
  NavigationService,
  PagesQueueService,
  SessionService
} from '@pinacono/common';

@Component({
  selector: 'app-campaign',
  templateUrl: 'campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {

  protected campage_id: string = 'campaign'

  constructor(
    protected nav: NavigationService,
    protected queue: PagesQueueService,
    session: SessionService
  ) {
    session.LOGGEDOUT.subscribe( () => {
      //console.debug('Clear campaign visiting flag');
      sessionStorage.removeItem(this.campage_id);
    });
  }

  public ngOnInit(): void {
    // see - https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
    if ( sessionStorage.getItem(this.campage_id) ) {
      //console.debug('Campaign page is already visited. Advance to next page.');
      this.queue.next();
    }
    //console.debug('Campaign has not been visited. Mark and proceed');
    sessionStorage.setItem(this.campage_id, 'yes');
  }

  public onContentLoadError(response: Response) {
    if ( response.status == 404 ) {
      this.next();
    }
  }

  public next(): Promise<boolean> {
    return this.queue.next() as Promise<boolean>;
  }
}
