/**
 * document browser configuration profiles
 */

import {
  GraphqlFilteringOption,
  GraphqlSortingOption
} from '@slickgrid-universal/graphql';

export interface BrowseOptions {
  title: string;
  enableSearch: boolean;
  trashed?: 'WITH'|'WITHOUT'|'ONLY';
  filters: GraphqlFilteringOption[];
  sorters: GraphqlSortingOption[];
  hiddenColumns: string[];
  avaiableButtons: string[];
}

// list of avaialble fields in browse mode
export const Fields = [
  'project_code',
  'project_name',
  'related_bldgs',
  'year',
  'status'
];

export const BrowserProfiles: { [name: string]: BrowseOptions } = {

  /** default profile */
  default: {
    title: 'Projects',
    enableSearch: true,
    filters: [
      //{ field: 'status', operator: 'EQ', value: 'opened' }
    ],
    sorters: [
      // field is the database column name
      //{ field: 'documentable.code', direction: 'ASC' }
      { field: 'projects.updated_at', direction: 'DESC' }
    ],
    hiddenColumns: ['id'],
    avaiableButtons: [
      //'edit',
      //'delete',
      //'restore',
      //'trash'
    ]
  },

  /** list all docs with author is me */
  mine: {
    title: 'My Projects',
    enableSearch: false,
    trashed: 'WITH',
    filters: [
      { field: 'menu', operator: 'EQ', value: 'mine' }
    ],
    sorters: [
      { field: 'projects.updated_at', direction: 'DESC' }
    ],
    hiddenColumns: [ 'id', 'uploader' ],
    avaiableButtons: [
      'edit',
      'delete',
      'restore',
      'trash'
    ]
  },

  /** for administrator */
  admin: {
    title: 'All Projects',
    enableSearch: true,
    filters: [
      { field: 'menu', operator: 'EQ', value: 'admin' }
    ],
    sorters: [],
    hiddenColumns: ['id'],
    avaiableButtons: [
      'edit',
      'delete',
      'restore',
      'trash'
    ]
  }
}
