<div class="pad-all">
  <div *ngIf="!data" id="no-data">
    <span class="text-thin text-muted">{{ 'Job Description information not found!' | translate}}</span>
  </div>
  <div *ngIf="data !== null">
    <h3 class="text-semi-bold">
      {{ data.title }} ({{ 'Class' | translate }}: {{ data.job_level }})
    </h3>

    <div class="jd-description">
      <h5 class="text-bold">{{ 'Description' | translate }}:</h5>
      <div [markdown]="data.description">
      <!-- {{ data.description }} -->
      </div>
    </div>

    <div *ngIf="show_competencies" class="jd-competencies">
      <h5 class="text-bold">{{ 'Competencies' | translate }}:</h5>
      <ul *ngIf="data.competencies.length > 0">
        <li *ngFor="let competency of data.competencies" >
          <a class="btn-link competency" (click)="selectCompetency(competency)">
            {{ competency.competency.name }} (Level {{ competency.expectation }})
          </a>
        </li>
      </ul>
      <p *ngIf="data.competencies.length == 0" class="text-italic">{{ 'Competencies information not available' | translate }}</p>
    </div>

    <div *ngIf="show_attachments" class="mar-btm jd-attachments">
      <h5>Attachments</h5>
      <download *ngFor="let f of data.attachments" [file]="f"></download>
      <p *ngIf="data.attachments.length == 0"
        class="text-thin italic"
      >
        {{ 'Attachments not avaialble.' | translate }}
      </p>
    </div>

  </div>
</div>