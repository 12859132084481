/**
 * admin endpoints
 */

import { ServiceEndpointsMapping } from "@pinacono/common";

export const ENDPOINTS: ServiceEndpointsMapping = {
  library: {

    status: {
      get: '/library/branches',
      set: { method: 'POST', url: '/library/branches' }
    },

    reservation: {
      user: {
        all:    '/library/reservation/user/{uid}/all',    // get all user's reservations
        active: '/library/reservation/user/{uid}/active', // get user's active reservations
      },
      action:  '/library/reservation/book/{id}/action/{action}',
      approve: '/library/reservation/{id}/action/approve',
      reject:  '/library/reservation/{id}/action/reject',
      deliver: '/library/reservation/{id}/action/deliver',
      return:  '/library/reservation/{id}/action/return',
    },

    book: {
      lookup: { method: 'POST', url: '/library/book/lookup' }
    },

    assets: {
      validate: {
        unique: '/library/asset/code/{value}'
      }
    }
  }
};
