import { Component, Inject, ViewEncapsulation } from '@angular/core';

import {
  APP_INFO,
  AppInfo
} from "@pinacono/common";

@Component({
  selector: 'page-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  constructor(
    @Inject(APP_INFO) public app_info: AppInfo
  ) { }
}
