/**
 * admin endpoints
 */

import { ServiceEndpointsMapping } from "@pinacono/common";

export const ENDPOINTS: ServiceEndpointsMapping = {
  admin: {
    reports: {
      maximo: { method: 'POST', url: '/reports/maximo' },
      login:  { method: 'POST', url: '/reports/login'  },
      access: { method: 'POST', url: '/reports/access' },
      passwd_reset: { method: 'POST', url: '/reports/passwd_reset'},
      user_org: { method: 'POST', url: '/reports/user_org'}
    },
    line_notify: {
      list: '/spw/notify/list',
      subscribe: '/api/line/subscribe' ,
      unsubscribe: { method: 'DELETE', url: '/api/line/unsubscribe/{id}' },
    }
  }
};
