import { ExtraOptions } from '@angular/router';

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
//import { AppRoutingGuard } from 'src/app/app-routing.guard';

// module's routes
import { ROUTES_REGISTRY as EXP_ROUTES        } from './experiments/routing.config';
import { ROUTES_REGISTRY as COMMON_ROUTES     } from './pages/common/routing.config';
import { ROUTES_REGISTRY as SPW_ROUTES        } from './pages/spw/routing.config';
import { ROUTES_REGISTRY as ADMIN_ROUTES      } from './modules/admin/routing.config';
import { ROUTES_REGISTRY as REDIRECTOR_ROUTES } from './modules/redirector/routing.config';
import { ROUTES_REGISTRY as OPL_ROUTES        } from './modules/opl/routing.config';
import { ROUTES_REGISTRY as DOC_ROUTES        } from './modules/documents/routing.config';
import { ROUTES_REGISTRY as LIBRARY_ROUTES    } from './modules/library/routing.config';
import { ROUTES_REGISTRY as PROJ_ROUTES       } from './modules/projects/routing.config';
import { ROUTES_REGISTRY as TRAINING_ROUTES   } from './modules/training/routing.config';

// -- configurations

// Angular Router Module's extra options - https://angular.io/api/router/ExtraOptions

export const ROUTER_EXTRAS: ExtraOptions = {
  enableTracing: false,
  useHash: true
};

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = Object.assign({},
  EXP_ROUTES,
  COMMON_ROUTES,
  SPW_ROUTES,
  REDIRECTOR_ROUTES,
  OPL_ROUTES,
  DOC_ROUTES,
  LIBRARY_ROUTES,
  PROJ_ROUTES,
  TRAINING_ROUTES,
  ADMIN_ROUTES,
  {
    // -- defaults and error pages
    default: {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    },

    notfound: {
      path: '**',
      redirectTo: '/404'
    }
  }
);
