<full-page [title]="'Training Records List'">
  <div page-content>
    <panel>
      <div panel-control>
        <!--
        <button class="btn btn-success btn-icon" [modal]="'library-status'">
          <i class="pli-upload-to-cloud"></i> Update Library Status
        </button>
        -->
      </div>
      <!--
      <div panel-heading>
        {{ 'My Training Records' | translate }}
      </div>
      -->
      <div panel-body>
        <extended-slickgrid *ngIf="gridOptions"
          class="row-selectable"
          [gridId]="'training-records-table'"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>