import {
  Component,
  OnDestroy,
  ViewChild
} from "@angular/core";

import {
  ActivatedRoute,
  Router
} from "@angular/router";

import {
  NavigationService,
  ServerService,
  SessionService,
  StringUtilsService,
  Taxonomy,
  TreeUtilsService
} from "@pinacono/common";

import {
  AttachmentsComponent,
  LookupItem,
  ModalComponent,
  Tree,
  UIService
} from "@pinacono/ui";

import { BasePageComponent } from 'src/app/classes/base-page.component';
import { ExamChoice, ExamQuestion } from "src/app/common/types";

import { TrainingCourse } from '../../training/types';
import { TrainingService } from "../../training/training.service";

import { OPL, OPLQuestion } from "../types";
import { OplService } from "../opl.service";

declare let $: any;

@Component({
  selector: 'opl_edit',
  templateUrl: 'edit.html',
  styleUrls: [ 'edit.scss' ]
})
export class OplEditPage extends BasePageComponent implements OnDestroy {

  @ViewChild('attachments') attachments!: AttachmentsComponent;
  @ViewChild('revlog') revlog!: ModalComponent;
  @ViewChild('questionEditor') questionEditor!: ModalComponent;

  public domain_options: Tree.Options = {
    core: {
      themes: {
        name: 'proton',
        icons: false,
        dots: true
      },
      multiple: false
    },
    checkbox: {
      three_state: false,
      cascade: 'down'
    }
  };

  public categories_options: Tree.Options = {
    core: {
      themes: {
        name: 'proton',
        icons: false,
        dots: true
      },
      multiple: true
    },
    checkbox: {
      three_state: false,
      cascade: 'down'
    }
  };

  public opl: OPL = this.api.create(null);
  public quick_mode: boolean = false;
  public busy: boolean = false;

  public courses: TrainingCourse[] = [];
  public course_ids: number[] = [];

  protected editor: any; // jQuery object represent DOM of the summernote editor
  protected minimum_content_length: number = 0;

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: OplService,
    protected server: ServerService,
    protected treeUtils: TreeUtilsService,
    protected stringUtils: StringUtilsService,
    protected trainingAPI: TrainingService
  ) {
    super(router, activatedRoute);
  }

  // -- overriden

  protected override loadData(): Promise<any> {
    let promises: Promise<any>[] = [];
    let id = this.activatedRoute.snapshot.paramMap.get('id') || 'new';

    this.quick_mode = (!! this.activatedRoute.snapshot.paramMap.get('quick')) || false;

    if ( id == 'new' ) {
      /**
       * @TODO - considering this page is loaded as the first page, potentially, before
       * all 'master' data is loaded. The OPL properties will then empty. - Find solution
       */
      this.opl = this.api.create();

      //set up properties
      for ( let n in this.api.properties ) {
        let name = this.api.properties[n].name;
        if ( this.opl.properties && ! this.opl.properties.hasOwnProperty(name) ) {
          this.opl.properties[name] = ( this.api.properties[n].type == 'select' ) ? [] : '';
        }
      }

      this.opl.content = this.activatedRoute.snapshot.paramMap.get('content') || '';
      this.initialize();
    }
    else {
      promises.push(
        this.server.rejectOnError().show('opls', parseInt(id), { with: 'questions' })
        .then( (res: OPL) => {
          this.opl = this.api.create(res);

          for ( let n in this.api.properties ) {
            let name = this.api.properties[n].name;
            let type = this.api.properties[n].type;
            if ( type == 'select' && this.opl.properties && ! Array.isArray(this.opl.properties[name]) ) {
              this.opl.properties[name] = [this.opl.properties[name]];
            }
          }
          this.initialize();
        })
        .catch( (reason: any) => {
          if ( reason.error.code == 404 ) {
            this.ui.alert(reason.error.message, undefined, null, () => {
              this.quit();
            });
          }
          else {
            this.server.reThrow(reason);;
          }
        })
      );

      promises.push(
        this.server.rejectOnError().request('opls/{id}/courses', { id: id})
        .then( (courses: TrainingCourse[]) => {
          this.courses = courses.map( c => this.trainingAPI.createCourse(c) );
        })
      );
    }

    return Promise.all(promises);
  }

  public ngOnDestroy() {
    if ( !! this.opl ) {
      this.editor.summernote('destroy');
    }
  }

  // -- internal

  /**
   * initialize page's controls
   */
  protected initialize() {
    if ( ! this.editor ) {
      this.editor = $('#content-editor');

      this.editor.summernote({
        height: 300,
        placeholder: "write something...",
        focus: true,
        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'color', /* 'strikethrough', 'superscript', 'subscript', */ 'clear'] ],
          //['link',  ['link', 'table', 'hr'] ],
          ['para',  ['ul', 'ol', 'paragraph'] ]
        ],
        callbacks: {
          onPaste: function(e: ClipboardEvent) {
            // depend on Browser, we extract the clipboard content
            let text = (((e as any).originalEvent || e).clipboardData || (window as any).clipboardData ).getData('Text');
            //let text = e.clipboardData?.getData('Text');
            e.preventDefault();
            document.execCommand('insertText', false, text);
          }
        }
      });
    }
    // initialize editor content
    this.editor.summernote('code', this.opl.content);
  }

  /**
   * check, if can go back or redirect to Browse page
   */
  protected quit() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/opl/browse/draft');
    }
  }

  /**
   * scroll page to uploader, so user can see uploading progress
   */
  protected scrollToUploader() {
    let dom = document.getElementById('uploader-dropzone');
    dom && dom.scrollIntoView();
  }


  // -- template API

  public changeDomain(data: Taxonomy[]) {
    if ( data.length > 0 ) {
      // @TODO - check, if setTimeout is still required?
      let category = data && this.treeUtils.find(this.api.domains, (n: Taxonomy) => n.id == data[0].id) || null;
      this.opl.categories = !! category ? [category] : [];
    }
  }

  public get canSelectDomain(): boolean {
    return false; // do not allow domain selection, according to SPW's request
  }

  // Courses lookup
  public lookup_courses: LookupItem<TrainingCourse>[] = [];
  public lookupCourses(keyword: string) {
    this.server.lookup('training/courses', { keyword: keyword})
    .then( (res: object[]) => {
      this.lookup_courses = res.map( (c: object) => {
        let course = this.trainingAPI.createCourse(c)
        return {
          label: `${course.code}: ${course.name}`,
          value: course
        }
      } );
    });
  }

  public onCoursesChanged(list: TrainingCourse[]) {
    this.courses = list;
    this.course_ids = list.map( (item: TrainingCourse) => item.id! );
  }

  public get selectedDomain(): Taxonomy[] {
    return [ this.opl.domain ];
  }

  public set selectedDomain(domain: Taxonomy[]) {
    this.opl.domain = domain[0];
  }

  /**
   * submit for approval and return to browse
   *
   * IMPORTANT: any logic changes need to apply to the code in 'opl.component.ts' as well!
   */
  public log_msg: string = '';
  public submit() {
    this.scrollToUploader();
    if ( ! this.validate(true) ) {
      return;
    }

    if ( this.opl.revision_logs && this.opl.revision_logs.length >= 1) {
      this.revlog.show();
      return;
    }

    this.doSubmit();
  }

  public doSubmit() {
    if ( this.opl.revision_logs && this.opl.revision_logs.length >= 1 ) {
      this.revlog.hide();
      if ( this.log_msg.length < 10 ) {
        this.ui.alert('At least 10 characters of revision log message is required for submit');
        return;
      }
    }
    else {
      this.log_msg = 'Initial Version';
    }

    this.ui.confirm('Submit this OPL for approval?', undefined, () => {
      //console.log("Do submit", this.content);
      this.doSave().then( () => {
        this.server.request('opl.revlog', {id: this.opl.id}, {log: this.log_msg})
        .then( () => {
          return this.server.request('opl.submit', {id: this.opl.id})
        })
        .then( () => {
          this.quit();
        });
      });
    });
  }

  /**
   * save and return to browse
   */
  public save() {
    this.scrollToUploader();
    if ( ! this.validate() ) {
      return;
    }

    this.ui.confirm(this.quick_mode ? 'Save this OPL' : 'Save this OPL as draft?', undefined, () => {
      //this.opl.content = this.editor.summernote('code');
      this.doSave()
      .then( () => {
        // Back or Redirect to OPL Browse
        this.quit();
      })
      .catch( ( reason: any ) => {
        if ( typeof reason == 'string' ) {
          this.ui.alert(reason as string);
        }
        else {
          console.error('error saving OPL - ', reason);
        }
      });
    });
  }

  /**
   * validate content
   */
  protected validate(for_submit: boolean = false): boolean {
    this.opl.content = this.editor.summernote('code');
    if ( this.opl.content.length == 0 && ! for_submit ) {
      this.opl.content = '.';
    }
    return this.api.validate(this.opl, for_submit);
  }

  /**
   * perform saving tasks
   */
  protected doSave(): Promise<any> {
    this.busy = true;

    return new Promise( (resolve, reject) => {
      let promise: Promise<any>;

      // save OPL
      //this.opl.content = this.editor.summernote('code');

      if ( !! this.opl.id ) {
        if ( this.quick_mode ) {
          (this.opl as any).quick = true;
        }
        promise = this.server.update('opls', this.opl.id, this.opl);
      }
      else {
        promise = this.server.create('opls', this.opl)
                  .then( (res: OPL) => {
                    this.opl.id = res.id;
                  });
      }

      promise.then( () => {
        return this.server.post('/opls/{id}/courses', {
          id: this.opl.id
        },
        {
          course_ids: this.course_ids
        });
      })
      .then( () => {
        this.busy = false;
        resolve(null);
      })
      .catch( (reason: any) => {
        this.busy = false;
        reject(reason);
      });

      return promise;
    });
  }

  /**
   * cancel editing
   */
  public cancel() {
    this.ui.confirm('Cancel editing this OPL?', undefined, () => {
      this.quit();
    });
  }

  /**
   * quiz API
   */
  public question: ExamQuestion|null = null;
  protected loadQuestion(id: number): Promise<void> {
    if ( ! id ) {
      this.question = this.api.createOPLExamQuestion(null, this.opl);
      return Promise.resolve();
    }
    return this.server.show('exam/questions', id)
      .then( (q: object) => {
        this.question = this.api.createOPLExamQuestion(q, this.opl);
      });
  }

  public editQuestion(q: OPLQuestion|null) {
    this.loadQuestion(q && q.id || 0)
    .then( () => {
      this.questionEditor.show();
    });
  }

  public deleteChoice(choice: ExamChoice) {
    if ( choice && choice.id ) {
      this.server.destroy('exam/choices', choice.id);
    }
  }

  public saveQuestion() {
    let promise: Promise<ExamQuestion>;

    if ( ! this.question || ! this.question.id ) {
      this.opl.questionable_defer_key = this.opl.questionable_defer_key || this.stringUtils.random(16);
    }

    let question: OPLQuestion = this.api.createOPLExamQuestion(this.question, this.opl);
    question.defer_key = this.opl.questionable_defer_key;
    question.author_id = this.session.currentUser?.id;
    question.level = 0; // level is not use at the moment. just assign '0'

    if ( question.id ) {
      promise = this.server.update('exam/questions', question.id, question);
    }
    else {
      promise = this.server.create('exam/questions', question);
    }

    promise.then( (res: Object) => {
      this.questionEditor.hide();

      if ( ! this.opl.questions ) {
        return;
      }

      let saved: OPLQuestion = this.api.createOPLExamQuestion(res, this.opl);
      let i = this.opl.questions.findIndex( q => q.id == saved.id)
      if ( i < 0 ) {
        this.opl.questions.push(saved);
      }
      else {
        this.opl.questions[i] = saved;
      }
    });
  }

  public cancelQuestion() {
    this.questionEditor.hide();
  }
}