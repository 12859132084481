<full-page [title]="'Library'" class="library-books-browse">
  <div page-content>
    <panel *ngIf="!!book" class="pad-all">

      <div panel-heading class="pad-all">
        {{ book.title }}
      </div>

      <div panel-body class="pad-all">
        <div class="book d-flex">
        <div class="p-2">
          <pre style="display: none;">{{ book.covers | json }}</pre>
          <gallery class="img-responsive"
            [items]="book.covers"
            [layout]="'normal'"
            [defaultImage]="'/assets/images/default-cover-300x480.png'"
          ></gallery>
        </div>

        <div class="p-2 flex-grow-1 book-info">

          <div class="book-title">
            {{ book.title }}
            <span *ngIf="is_reservable && available == 0"     class="badge badge-default">{{ '0 book(s) available in library' | translate }}</span>
            <span *ngIf="is_reservable && available > 0"      class="badge badge-success">{{ available }} {{ 'book(s) available in library' | translate }}</span>
            <span *ngIf="is_reserved && requested.length > 0" class="badge badge-purple"  title="{{ 'You have requested for this book' | translate }}">{{ 'Requested' | translate }} ({{ requested.length }})</span>
            <span *ngIf="is_reserved && approved.length > 0"  class="badge badge-warning" title="{{ 'Your requests got approved' | translate }}">{{ 'Approved' | translate }} ({{ approved.length }})</span>
            <span *ngIf="is_reserved && with_you.length > 0"  class="badge badge-danger"  title="{{ 'Already with you' | translate }}">{{ 'With you' | translate }} ({{ with_you.length }})</span>
          </div>

          <div class="mt-3 book-attributes">
            <!--
            <span *ngIf="book.type == 'book'"     class="label label-info">Type: {{ 'Book' | translate }}</span>
            <span *ngIf="book.type == 'magazine'" class="label label-info">Type: {{ 'Magazine' | translate }}</span>
            <span *ngIf="book.type == 'drawing'"  class="label label-info">Type: {{ 'Drawing' | translate }}</span>
            -->
            <div class="row" *ngIf="!! book.attr['BOOK']">
              <div class="col-4">
                <label class="form-label">ISBN:</label>
                <span>{{ book.attr['BOOK']?.isbn || 'n/a' }}</span>
              </div>
              <div class="col-4">
                <label class="form-label">Publisher:</label>
                <span>{{ book.attr['BOOK']?.publishers || 'n/a' }}</span>
              </div>
              <div class="col-4">
                <label class="form-label">Publish Date:</label>
                <span>{{ book.attr['BOOK']?.publish_date || 'n/a' }}</span>
              </div>
              <!--
              <div class="col-4">
                <label class="form-abel">Genres:</label>
                <span>{{ book.attr['BOOK']?.genres || 'n/a' }}</span>
              </div>
              -->
              <div class="col-4">
                <label class="form-label">Author:</label>
                <span>{{ book.attr['BOOK']?.authors || 'n/a' }}</span>
              </div>
              <div class="col-4">
                <label class="form-label">Price:</label>
                <span *ngIf="!! book.attr['BOOK']?.price">{{ book.attr['BOOK']?.price | number:'1.2-2' }} THB.</span>
                <span *ngIf="! book.attr['BOOK']?.price">n/a</span>
              </div>
            </div>
          </div>

          <blockquote class="mt-3 bq-sm bq-open bq-close synopsis">
            <!-- {{ ( book.content || '...' ) | truncate:1000:true }} -->
            {{ book.content || '...' }}
          </blockquote>

          <div class="p-2 pt-4 buttons">
            <button class="btn btn-default btn-labeled" (click)="locations()" title="{{ 'Display book locations' | translate }}">
              <i class="btn-label icon-lg icon-fw pli-book"></i>{{ 'Locations' | translate }}
            </button>
            <button stop-propagation [attr.disabled]="can_reserve ? null : 1" (click)="reserve()" title="{{ can_reserve ? 'Request for this title' : 'Out of reserve quota' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa far fa-thumbs-up"></i> {{ 'Reserve' | translate }}
            </button>
            <button *ngIf="can_cancel_reservation" stop-propagation (click)="cancel()" title="{{ 'Cancel reservation(s) on this Title' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa far fa-thumbs-down"></i> {{ 'Cancel' | translate }}
            </button>
            <button *ngIf="can_view_history" stop-propagation (click)="history()" title="{{ 'Show reservation history of this book' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fas fa-history"></i> {{ 'History' | translate }}
            </button>
          </div>
        </div>
        </div>
      </div>

      <div panel-footer>
        <div class="text-right">
          <button type="button" class="btn btn-labeled btn-dark" data-bs-dismiss="modal" (click)="nav.back()">
            <i class="btn-label fa fa-solid fa-circle-chevron-left"></i>{{ 'Back' | translate }}
          </button>
        </div>
      </div>

    </panel>
  </div>
</full-page>

<!-- book instances information -->
<modal [template-id]="'modal-book-instance'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #modalBookInstances
>
  <div modal-title>
    {{ 'Book Instances' | translate }}
  </div>

  <div modal-body>

    <!-- instances location list -->
    <div id="instances">

      <table class="table">
        <thead>
          <tr>
            <th>{{ 'Code' | translate }}</th>
            <th>{{ 'Location' | translate }}</th>
            <th>{{ 'Status' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let instance of instances">
            <td>
              <barcode *ngIf="!! instance.code" class="inline"
                [value]="instance.code"
                [format]="'CODE128'"
                [width]="1"
                [height]="30"
              ></barcode>
              <div *ngIf="! instance.code" class="list-empty">- n/a -</div>
            </td>
            <td>
              {{ instance.location.branch }} ตู้ {{ instance.location.cabinet || 'n/a' }} ชั้น {{ instance.location.shelf || 'n/a'}}
            </td>
            <td>
              {{ InstanceStatusMapping[instance.status] || instance.status }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <!-- end locations table -->

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="modalBookInstances.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- book reservation history -->
<modal [template-id]="'modal-book-reservation-history'"
  [size]="'xlarge'"
  [fade]="true"
  [hideCloseButton]="true"
  #modalBookReservationHistory
>
  <div modal-title>
    {{ 'Book Reservations History' | translate }}
  </div>

  <div modal-body>

    <!-- reservations list -->
    <div id="reservations">
      <extended-slickgrid *ngIf="!! reservationGridOptions" gridId="history-table"
        [columnDefinitions]="reservationGridColumnDefinitions"
        [gridOptions]="reservationGridOptions"
        [dataset]="reservations"
        (onAngularGridCreated)="onReservationGridReady($event)"
        >
      </extended-slickgrid>
    </div>
    <!-- end locations table -->

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="closeHistory();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>