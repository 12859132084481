export const domain = {
  "id": 1,
  "name": "Domain",
  "description": "Domain",
  "parent_id": null,
  "nest_depth": 0,
  "text": "Domain",
  "path": "Domain",
  "children": [
      {
          "id": 118,
          "name": "สายงานวิศวกรรม",
          "description": "สายงานวิศวกรรม",
          "parent_id": 1,
          "nest_depth": 1,
          "text": "สายงานวิศวกรรม",
          "path": "Domain/สายงานวิศวกรรม",
          "children": [
              {
                  "id": 121,
                  "name": "กลุ่มงานออกแบบระบบวิศวกรรม",
                  "description": "กลุ่มงานออกแบบระบบวิศวกรรม",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานออกแบบระบบวิศวกรรม",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานออกแบบระบบวิศวกรรม",
                  "children": [
                      {
                          "id": 122,
                          "name": "ฝ่ายออกแบบระบบวิศวกรรมไฟฟ้า",
                          "description": "ฝ่ายออกแบบระบบวิศวกรรมไฟฟ้า",
                          "parent_id": 121,
                          "nest_depth": 3,
                          "text": "ฝ่ายออกแบบระบบวิศวกรรมไฟฟ้า",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานออกแบบระบบวิศวกรรม/ฝ่ายออกแบบระบบวิศวกรรมไฟฟ้า",
                          "children": []
                      },
                      {
                          "id": 123,
                          "name": "ฝ่ายออกแบบระบบสุขาภิบาลและดับเพลิง",
                          "description": "ฝ่ายออกแบบระบบสุขาภิบาลและดับเพลิง",
                          "parent_id": 121,
                          "nest_depth": 3,
                          "text": "ฝ่ายออกแบบระบบสุขาภิบาลและดับเพลิง",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานออกแบบระบบวิศวกรรม/ฝ่ายออกแบบระบบสุขาภิบาลและดับเพลิง",
                          "children": []
                      },
                      {
                          "id": 124,
                          "name": "ฝ่ายออกแบบระบบวิศวกรรมเครื่องกล",
                          "description": "ฝ่ายออกแบบระบบวิศวกรรมเครื่องกล",
                          "parent_id": 121,
                          "nest_depth": 3,
                          "text": "ฝ่ายออกแบบระบบวิศวกรรมเครื่องกล",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานออกแบบระบบวิศวกรรม/ฝ่ายออกแบบระบบวิศวกรรมเครื่องกล",
                          "children": []
                      }
                  ]
              },
              {
                  "id": 149,
                  "name": "กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม",
                  "description": "กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม",
                  "children": [
                      {
                          "id": 150,
                          "name": "ฝ่ายพัฒนา",
                          "description": "ฝ่ายพัฒนา",
                          "parent_id": 149,
                          "nest_depth": 3,
                          "text": "ฝ่ายพัฒนา",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม/ฝ่ายพัฒนา",
                          "children": []
                      },
                      {
                          "id": 151,
                          "name": "ฝ่ายควบคุมขั้นตอนปฏิบัติงานและรายงาน",
                          "description": "ฝ่ายควบคุมขั้นตอนปฏิบัติงานและรายงาน",
                          "parent_id": 149,
                          "nest_depth": 3,
                          "text": "ฝ่ายควบคุมขั้นตอนปฏิบัติงานและรายงาน",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม/ฝ่ายควบคุมขั้นตอนปฏิบัติงานและรายงาน",
                          "children": []
                      },
                      {
                          "id": 152,
                          "name": "ส่วนกำกับดูแลการปฏิบัติงานด้านวิศวกรรม",
                          "description": "ส่วนกำกับดูแลการปฏิบัติงานด้านวิศวกรรม",
                          "parent_id": 149,
                          "nest_depth": 3,
                          "text": "ส่วนกำกับดูแลการปฏิบัติงานด้านวิศวกรรม",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม/ส่วนกำกับดูแลการปฏิบัติงานด้านวิศวกรรม",
                          "children": []
                      },
                      {
                          "id": 153,
                          "name": "ส่วนพัฒนาสมรรถนะบุคลากรด้านวิศวกรรม",
                          "description": "ส่วนพัฒนาสมรรถนะบุคลากรด้านวิศวกรรม",
                          "parent_id": 149,
                          "nest_depth": 3,
                          "text": "ส่วนพัฒนาสมรรถนะบุคลากรด้านวิศวกรรม",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม/ส่วนพัฒนาสมรรถนะบุคลากรด้านวิศวกรรม",
                          "children": []
                      },
                      {
                          "id": 224,
                          "name": "ฝ่ายพัฒนา Best Practice",
                          "description": "ฝ่ายพัฒนา Best Practice",
                          "parent_id": 149,
                          "nest_depth": 3,
                          "text": "ฝ่ายพัฒนา Best Practice",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานพัฒนาประสิทธิภาพระบบวิศวกรรม/ฝ่ายพัฒนา Best Practice",
                          "children": []
                      }
                  ]
              },
              {
                  "id": 955,
                  "name": "กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ",
                  "description": "กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ",
                  "children": [
                      {
                          "id": 956,
                          "name": "ฝ่ายวิศวกรรมเครื่องกล",
                          "description": "ฝ่ายวิศวกรรมเครื่องกล",
                          "parent_id": 955,
                          "nest_depth": 3,
                          "text": "ฝ่ายวิศวกรรมเครื่องกล",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมเครื่องกล",
                          "children": [
                              {
                                  "id": 958,
                                  "name": "ส่วนสุขาภิบาลและดับเพลิง",
                                  "description": "ส่วนสุขาภิบาลและดับเพลิง",
                                  "parent_id": 956,
                                  "nest_depth": 4,
                                  "text": "ส่วนสุขาภิบาลและดับเพลิง",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมเครื่องกล/ส่วนสุขาภิบาลและดับเพลิง",
                                  "children": []
                              },
                              {
                                  "id": 957,
                                  "name": "ส่วนบริการปรับอากาศ",
                                  "description": "ส่วนบริการปรับอากาศ",
                                  "parent_id": 956,
                                  "nest_depth": 4,
                                  "text": "ส่วนบริการปรับอากาศ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมเครื่องกล/ส่วนบริการปรับอากาศ",
                                  "children": []
                              },
                              {
                                  "id": 959,
                                  "name": "ส่วนปรับอากาศหลัก",
                                  "description": "ส่วนปรับอากาศหลัก",
                                  "parent_id": 956,
                                  "nest_depth": 4,
                                  "text": "ส่วนปรับอากาศหลัก",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมเครื่องกล/ส่วนปรับอากาศหลัก",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 960,
                          "name": "ฝ่ายวิศวกรรมไฟฟ้า",
                          "description": "ฝ่ายวิศวกรรมไฟฟ้า",
                          "parent_id": 955,
                          "nest_depth": 3,
                          "text": "ฝ่ายวิศวกรรมไฟฟ้า",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมไฟฟ้า",
                          "children": [
                              {
                                  "id": 961,
                                  "name": "ส่วนควบคุมอาคารและสื่อสาร",
                                  "description": "ส่วนควบคุมอาคารและสื่อสาร",
                                  "parent_id": 960,
                                  "nest_depth": 4,
                                  "text": "ส่วนควบคุมอาคารและสื่อสาร",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมไฟฟ้า/ส่วนควบคุมอาคารและสื่อสาร",
                                  "children": []
                              },
                              {
                                  "id": 962,
                                  "name": "ส่วนไฟฟ้าบริการ",
                                  "description": "ส่วนไฟฟ้าบริการ",
                                  "parent_id": 960,
                                  "nest_depth": 4,
                                  "text": "ส่วนไฟฟ้าบริการ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมไฟฟ้า/ส่วนไฟฟ้าบริการ",
                                  "children": []
                              },
                              {
                                  "id": 963,
                                  "name": "ส่วนไฟฟ้ากำลัง",
                                  "description": "ส่วนไฟฟ้ากำลัง",
                                  "parent_id": 960,
                                  "nest_depth": 4,
                                  "text": "ส่วนไฟฟ้ากำลัง",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมไฟฟ้า/ส่วนไฟฟ้ากำลัง",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 964,
                          "name": "ฝ่ายวิศวกรรมโยธา",
                          "description": "ฝ่ายวิศวกรรมโยธา",
                          "parent_id": 955,
                          "nest_depth": 3,
                          "text": "ฝ่ายวิศวกรรมโยธา",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมโยธา",
                          "children": [
                              {
                                  "id": 965,
                                  "name": "ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "description": "ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "parent_id": 964,
                                  "nest_depth": 4,
                                  "text": "ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมโยธา/ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "children": []
                              },
                              {
                                  "id": 966,
                                  "name": "ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "description": "ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "parent_id": 964,
                                  "nest_depth": 4,
                                  "text": "ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมโยธา/ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "children": []
                              },
                              {
                                  "id": 967,
                                  "name": "ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "description": "ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "parent_id": 964,
                                  "nest_depth": 4,
                                  "text": "ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมโยธา/ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 968,
                          "name": "ฝ่ายวิศวกรรมสนับสนุนบริการ",
                          "description": "ฝ่ายวิศวกรรมสนับสนุนบริการ",
                          "parent_id": 955,
                          "nest_depth": 3,
                          "text": "ฝ่ายวิศวกรรมสนับสนุนบริการ",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมสนับสนุนบริการ",
                          "children": [
                              {
                                  "id": 969,
                                  "name": "ส่วนจัดซื้อและคลังพัสดุ",
                                  "description": "ส่วนจัดซื้อและคลังพัสดุ",
                                  "parent_id": 968,
                                  "nest_depth": 4,
                                  "text": "ส่วนจัดซื้อและคลังพัสดุ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมสนับสนุนบริการ/ส่วนจัดซื้อและคลังพัสดุ",
                                  "children": []
                              },
                              {
                                  "id": 970,
                                  "name": "ส่วนแสงสีเสียง",
                                  "description": "ส่วนแสงสีเสียง",
                                  "parent_id": 968,
                                  "nest_depth": 4,
                                  "text": "ส่วนแสงสีเสียง",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมสนับสนุนบริการ/ส่วนแสงสีเสียง",
                                  "children": []
                              },
                              {
                                  "id": 971,
                                  "name": "ส่วนวิศวกรรมบริการ",
                                  "description": "ส่วนวิศวกรรมบริการ",
                                  "parent_id": 968,
                                  "nest_depth": 4,
                                  "text": "ส่วนวิศวกรรมบริการ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมสนับสนุนบริการ/ส่วนวิศวกรรมบริการ",
                                  "children": []
                              },
                              {
                                  "id": 972,
                                  "name": "ส่วนประหยัดพลังงาน",
                                  "description": "ส่วนประหยัดพลังงาน",
                                  "parent_id": 968,
                                  "nest_depth": 4,
                                  "text": "ส่วนประหยัดพลังงาน",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ฝ่ายวิศวกรรมสนับสนุนบริการ/ส่วนประหยัดพลังงาน",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 973,
                          "name": "ส่วนควบคุมงบประมาณและบริหารสัญญาด้านวิศวกรรม",
                          "description": "ส่วนควบคุมงบประมาณและบริหารสัญญาด้านวิศวกรรม",
                          "parent_id": 955,
                          "nest_depth": 3,
                          "text": "ส่วนควบคุมงบประมาณและบริหารสัญญาด้านวิศวกรรม",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมบริการเครื่องกลและไฟฟ้า, โยธาและสนับสนุนบริการ/ส่วนควบคุมงบประมาณและบริหารสัญญาด้านวิศวกรรม",
                          "children": []
                      }
                  ]
              },
              {
                  "id": 974,
                  "name": "ฝ่ายวิศวกรรมระบบเครือข่ายและซอฟต์แวร์",
                  "description": "ฝ่ายวิศวกรรมระบบเครือข่ายและซอฟต์แวร์",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "ฝ่ายวิศวกรรมระบบเครือข่ายและซอฟต์แวร์",
                  "path": "Domain/สายงานวิศวกรรม/ฝ่ายวิศวกรรมระบบเครือข่ายและซอฟต์แวร์",
                  "children": [
                      {
                          "id": 975,
                          "name": "ส่วนวิศวกรรมระบบเครือข่าย",
                          "description": "ส่วนวิศวกรรมระบบเครือข่าย",
                          "parent_id": 974,
                          "nest_depth": 3,
                          "text": "ส่วนวิศวกรรมระบบเครือข่าย",
                          "path": "Domain/สายงานวิศวกรรม/ฝ่ายวิศวกรรมระบบเครือข่ายและซอฟต์แวร์/ส่วนวิศวกรรมระบบเครือข่าย",
                          "children": []
                      },
                      {
                          "id": 976,
                          "name": "ส่วนซอฟต์แวร์สนับสนุนระบบวิศวกรรม",
                          "description": "ส่วนซอฟต์แวร์สนับสนุนระบบวิศวกรรม",
                          "parent_id": 974,
                          "nest_depth": 3,
                          "text": "ส่วนซอฟต์แวร์สนับสนุนระบบวิศวกรรม",
                          "path": "Domain/สายงานวิศวกรรม/ฝ่ายวิศวกรรมระบบเครือข่ายและซอฟต์แวร์/ส่วนซอฟต์แวร์สนับสนุนระบบวิศวกรรม",
                          "children": []
                      }
                  ]
              },
              {
                  "id": 978,
                  "name": "กลุ่มงานวิศวกรรมไอคอนสยาม",
                  "description": "กลุ่มงานวิศวกรรมไอคอนสยาม",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานวิศวกรรมไอคอนสยาม",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม",
                  "children": [
                      {
                          "id": 979,
                          "name": "ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า",
                          "description": "ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า",
                          "parent_id": 978,
                          "nest_depth": 3,
                          "text": "ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า",
                          "children": [
                              {
                                  "id": 980,
                                  "name": "ส่วนควบคุมอาคารและสื่อสาร",
                                  "description": "ส่วนควบคุมอาคารและสื่อสาร",
                                  "parent_id": 979,
                                  "nest_depth": 4,
                                  "text": "ส่วนควบคุมอาคารและสื่อสาร",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า/ส่วนควบคุมอาคารและสื่อสาร",
                                  "children": []
                              },
                              {
                                  "id": 981,
                                  "name": "ส่วนปรับอากาศหลักและบริการ",
                                  "description": "ส่วนปรับอากาศหลักและบริการ",
                                  "parent_id": 979,
                                  "nest_depth": 4,
                                  "text": "ส่วนปรับอากาศหลักและบริการ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า/ส่วนปรับอากาศหลักและบริการ",
                                  "children": []
                              },
                              {
                                  "id": 982,
                                  "name": "ส่วนไฟฟ้ากำลังและบริการ",
                                  "description": "ส่วนไฟฟ้ากำลังและบริการ",
                                  "parent_id": 979,
                                  "nest_depth": 4,
                                  "text": "ส่วนไฟฟ้ากำลังและบริการ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า/ส่วนไฟฟ้ากำลังและบริการ",
                                  "children": []
                              },
                              {
                                  "id": 983,
                                  "name": "ส่วนสุขาภิบาลและดับเพลิง",
                                  "description": "ส่วนสุขาภิบาลและดับเพลิง",
                                  "parent_id": 979,
                                  "nest_depth": 4,
                                  "text": "ส่วนสุขาภิบาลและดับเพลิง",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมเครื่องกลและไฟฟ้า/ส่วนสุขาภิบาลและดับเพลิง",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 984,
                          "name": "ส่วนวิศวกรรมระบบเครือข่าย",
                          "description": "ส่วนวิศวกรรมระบบเครือข่าย",
                          "parent_id": 978,
                          "nest_depth": 3,
                          "text": "ส่วนวิศวกรรมระบบเครือข่าย",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ส่วนวิศวกรรมระบบเครือข่าย",
                          "children": []
                      },
                      {
                          "id": 985,
                          "name": "ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ",
                          "description": "ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ",
                          "parent_id": 978,
                          "nest_depth": 3,
                          "text": "ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ",
                          "children": [
                              {
                                  "id": 986,
                                  "name": "ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "description": "ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนควบคุมความปลอดภัยและสุขอนามัย",
                                  "children": []
                              },
                              {
                                  "id": 987,
                                  "name": "ส่วนจัดซื้อและคลังพัสดุ",
                                  "description": "ส่วนจัดซื้อและคลังพัสดุ",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนจัดซื้อและคลังพัสดุ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนจัดซื้อและคลังพัสดุ",
                                  "children": []
                              },
                              {
                                  "id": 988,
                                  "name": "ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "description": "ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนบำรุงรักษาพื้นที่เช่า",
                                  "children": []
                              },
                              {
                                  "id": 989,
                                  "name": "ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "description": "ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนบำรุงรักษาอาคารทั่วไป",
                                  "children": []
                              },
                              {
                                  "id": 990,
                                  "name": "ส่วนประหยัดพลังงาน",
                                  "description": "ส่วนประหยัดพลังงาน",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนประหยัดพลังงาน",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนประหยัดพลังงาน",
                                  "children": []
                              },
                              {
                                  "id": 991,
                                  "name": "ส่วนวิศวกรรมบริการ",
                                  "description": "ส่วนวิศวกรรมบริการ",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนวิศวกรรมบริการ",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนวิศวกรรมบริการ",
                                  "children": []
                              },
                              {
                                  "id": 992,
                                  "name": "ส่วนแสงสีเสียง",
                                  "description": "ส่วนแสงสีเสียง",
                                  "parent_id": 985,
                                  "nest_depth": 4,
                                  "text": "ส่วนแสงสีเสียง",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานวิศวกรรมไอคอนสยาม/ฝ่ายวิศวกรรมโยธาและสนับสนุนบริการ/ส่วนแสงสีเสียง",
                                  "children": []
                              }
                          ]
                      }
                  ]
              },
              {
                  "id": 1658,
                  "name": "ที่ปรึกษา",
                  "description": "ที่ปรึกษา",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "ที่ปรึกษา",
                  "path": "Domain/สายงานวิศวกรรม/ที่ปรึกษา",
                  "children": []
              },
              {
                  "id": 1763,
                  "name": "กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ",
                  "description": "กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ",
                  "children": [
                      {
                          "id": 1764,
                          "name": "ฝ่ายควบคุมงานก่อสร้างระบบวิศวกรรม",
                          "description": "ฝ่ายควบคุมงานก่อสร้างระบบวิศวกรรม",
                          "parent_id": 1763,
                          "nest_depth": 3,
                          "text": "ฝ่ายควบคุมงานก่อสร้างระบบวิศวกรรม",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายควบคุมงานก่อสร้างระบบวิศวกรรม",
                          "children": [
                              {
                                  "id": 1765,
                                  "name": "ส่วนควบคุมงานก่อสร้างระบบวิศวกรรม",
                                  "description": "ส่วนควบคุมงานก่อสร้างระบบวิศวกรรม",
                                  "parent_id": 1764,
                                  "nest_depth": 4,
                                  "text": "ส่วนควบคุมงานก่อสร้างระบบวิศวกรรม",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายควบคุมงานก่อสร้างระบบวิศวกรรม/ส่วนควบคุมงานก่อสร้างระบบวิศวกรรม",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 1766,
                          "name": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ",
                          "description": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ",
                          "parent_id": 1763,
                          "nest_depth": 3,
                          "text": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ",
                          "children": [
                              {
                                  "id": 1767,
                                  "name": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 1",
                                  "description": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 1",
                                  "parent_id": 1766,
                                  "nest_depth": 4,
                                  "text": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 1",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 1",
                                  "children": []
                              },
                              {
                                  "id": 1768,
                                  "name": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 2",
                                  "description": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 2",
                                  "parent_id": 1766,
                                  "nest_depth": 4,
                                  "text": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 2",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 2",
                                  "children": []
                              },
                              {
                                  "id": 1769,
                                  "name": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 3",
                                  "description": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 3",
                                  "parent_id": 1766,
                                  "nest_depth": 4,
                                  "text": "ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 3",
                                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ/ฝ่ายควบคุมงานก่อสร้าง และประสานงานออกแบบ 3",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "id": 1770,
                          "name": "ฝ่ายจัดซื้อ และควบคุมงบประมาณโครงการ",
                          "description": "ฝ่ายจัดซื้อ และควบคุมงบประมาณโครงการ",
                          "parent_id": 1763,
                          "nest_depth": 3,
                          "text": "ฝ่ายจัดซื้อ และควบคุมงบประมาณโครงการ",
                          "path": "Domain/สายงานวิศวกรรม/กลุ่มงานก่อสร้าง ประสานงานออกแบบและจัดซื้อโครงการ/ฝ่ายจัดซื้อ และควบคุมงบประมาณโครงการ",
                          "children": []
                      }
                  ]
              },
              {
                  "id": 1786,
                  "name": "กลุ่มงานบริหารโครงการ",
                  "description": "กลุ่มงานบริหารโครงการ",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานบริหารโครงการ",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานบริหารโครงการ",
                  "children": []
              },
              {
                  "id": 1787,
                  "name": "กลุ่มงานออกแบบโครงการพิเศษ",
                  "description": "กลุ่มงานออกแบบโครงการพิเศษ",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "กลุ่มงานออกแบบโครงการพิเศษ",
                  "path": "Domain/สายงานวิศวกรรม/กลุ่มงานออกแบบโครงการพิเศษ",
                  "children": []
              },
              {
                  "id": 1788,
                  "name": "วิศวกรรมบริการ",
                  "description": "วิศวกรรมบริการ",
                  "parent_id": 118,
                  "nest_depth": 2,
                  "text": "วิศวกรรมบริการ",
                  "path": "Domain/สายงานวิศวกรรม/วิศวกรรมบริการ",
                  "children": []
              }
          ]
      },
      {
          "id": 225,
          "name": "AIS",
          "description": "AIS",
          "parent_id": 1,
          "nest_depth": 1,
          "text": "AIS",
          "path": "Domain/AIS",
          "children": []
      },
      {
          "id": 229,
          "name": "Maximo",
          "description": "Maximo",
          "parent_id": 1,
          "nest_depth": 1,
          "text": "Maximo",
          "path": "Domain/Maximo",
          "children": []
      },
      {
          "id": 977,
          "name": "Outsource",
          "description": "Outsource",
          "parent_id": 1,
          "nest_depth": 1,
          "text": "Outsource",
          "path": "Domain/Outsource",
          "children": []
      },
      {
          "id": 995,
          "name": "Pinacono",
          "description": "Pinacono",
          "parent_id": 1,
          "nest_depth": 1,
          "text": "Pinacono",
          "path": "Domain/Pinacono",
          "children": []
      }
  ]
};