import { Component } from '@angular/core';
import { NavigationService } from '@pinacono/common';

@Component({
  selector: 'app-e403-page',
  templateUrl: 'error403.component.html',
  styleUrls: ['./error403.component.scss']
})
export class Error403Component {

  constructor(
    public nav: NavigationService
  ) { }

  public search(keyword: string) {
    this.nav.naviateByUrl(`/search/${keyword}`);
  }
}
