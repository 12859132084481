<full-page [title]="'Competency Gaps Comparator'">
  <div page-content>
    <panel>
      <div panel-heading>
      </div>
      <div panel-body>
        <gaps-analyzer *ngIf="job_description && candidates.length == candidates_count"
          [job]="job_description"
          [candidates]="candidates"
          [target]="'show'"
          (onJobChange)="refresh()"
          (onCompetencyClick)="onCompetencyClick($event)"
        >
        </gaps-analyzer>
      </div>
      <div panel-footer>
        <div class="text-right">
          <button type="button" class="btn btn-labeled btn-dark" data-dismiss="modal" (click)="back()"><i class="btn-label fa far fa-chevron-circle-left"></i>{{ 'Back' | translate }}</button>
        </div>
      </div>
    </panel>
  </div>
</full-page>