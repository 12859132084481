<full-page title="'Reports'">
  <div page-content>
    <form id="report-form" ngForm="reportForm">
      <div class="pad-all">
        <div class="row">

          <!-- maximo log report -->
          <panel class="col-xs-12 col-col-sm-6 col-md-6 col-lg-4">
            <div panel-heading>Maximo Log</div>
            <div panel-body>
              <p class="text-sm text-muted">Extract Maximo redirecting logs for the selected date range.</p>
              <div class="row pad-top form-group">
                <label class="col-xs-12 form-label">{{ 'Date Range' | translate }}</label>
                <div class="col-xs-12 col-sm-4">
                  <input type="date" name="from" class="form-control" required
                    [daterange]="'from'" [date-ref]="maximoTo"
                    [(ngModel)]="maximo.from"
                    #maximoFrom="ngModel"
                  >
                </div>
                <div class="col-xs-12 col-sm-4">
                  <input type="date" name="to" class="form-control" required
                    [daterange]="'to'" [date-ref]="maximoFrom"
                    [(ngModel)]="maximo.to"
                    #maximoTo="ngModel"
                  >
                </div>
                <div class="col-xs-12 col-sm-4">
                  <button class="btn btn-info labeled-btn pull-right"
                    (click)="run('maximo', maximo)"
                    [disabled]="!maximoFrom.valid"
                  >
                    <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
                  </button>
                </div>
              </div>
              <div class="alert alert-danger" *ngIf="( maximoFrom.invalid || maximoTo.invalid ) && ( maximoFrom.dirty || maximoFrom.touched || maximoTo.dirty || maximoTo.touched )">
                <div *ngIf="maximoFrom.errors && maximoFrom.errors['daterange']">{{ '"From" must be before "To"' | translate }}</div>
                <div *ngIf="maximoTo.errors && maximoTo.errors['daterange']">{{ '"To" must be after "From"' | translate }}</div>
              </div>
            </div>
          </panel>

          <!-- system access log -->
          <panel class="col-xs-12 col-col-sm-6 col-md-6 col-lg-4">
            <div panel-heading>System Access Log</div>
            <div panel-body>
              <p class="text-sm text-muted">Log of the user's system accessess for the selected date range.</p>
              <div class="row pad-top form-group">
                <label class="col-xs-12 form-label">{{ 'Date Range' | translate }}</label>
                <div class="col-xs-12 col-sm-4">
                  <input type="date" name="from" class="form-control" required
                    [daterange]="'from'" [date-ref]="accessTo"
                    [(ngModel)]="access.from"
                    #accessFrom="ngModel"
                  >
                </div>
                <div class="col-xs-12 col-sm-4">
                  <input type="date" name="to" class="form-control" required
                    [daterange]="'to'" [date-ref]="accessFrom"
                    [(ngModel)]="access.to"
                    #accessTo="ngModel"
                  >
                </div>
                <div class="col-xs-12 col-sm-4">
                  <button class="btn btn-info labeled-btn pull-right"
                    (click)="run('access', access)"
                    [disabled]="!accessFrom.valid || !accessTo.valid"
                  >
                    <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
                  </button>
                </div>
              </div>
              <div class="alert alert-danger" *ngIf="( accessFrom.invalid || accessTo.invalid ) && ( accessFrom.dirty || accessFrom.touched || accessTo.dirty || accessTo.touched )">
                <div *ngIf="accessFrom.errors && accessFrom.errors['daterange']">{{ '"From" must be before "To"' | translate }}</div>
                <div *ngIf="accessTo.errors && accessTo.errors['daterange']">{{ '"To" must be after "From"' | translate }}</div>
              </div>
            </div>
          </panel>

          <!-- password reset request log -->
          <panel class="col-xs-12 col-col-sm-6 col-md-6 col-lg-4">
            <div panel-heading>Password Reset Requests Log</div>
            <div panel-body>
              <p class="text-sm text-muted">List of password reset requests for the selected date range.</p>
              <div class="row pad-top form-group">
                <label class="col-xs-12 form-label">{{ 'Date Range' | translate }}</label>
                <div class="col-xs-12 col-sm-4">
                  <input type="date" name="from" class="form-control" required
                    [daterange]="'from'" [date-ref]="passwdTo"
                    [(ngModel)]="passwd.from"
                    #passwdFrom="ngModel"
                  >
                </div>
                <div class="col-xs-12 col-sm-4">
                  <input type="date" name="to" class="form-control" required
                    [daterange]="'to'" [date-ref]="passwdFrom"
                    [(ngModel)]="passwd.to"
                    #passwdTo="ngModel"
                  >
                </div>
                <div class="col-xs-12 col-sm-4">
                  <button class="btn btn-info labeled-btn pull-right"
                    (click)="run('passwd_reset', passwd)"
                    [disabled]="!passwdFrom.valid || !passwdTo.valid"
                  >
                    <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
                  </button>
                </div>
              </div>
              <div class="alert alert-danger" *ngIf="( passwdFrom.invalid || passwdTo.invalid ) && ( passwdFrom.dirty || passwdFrom.touched || passwdTo.dirty || passwdTo.touched )">
                <div *ngIf="passwdFrom.errors && passwdFrom.errors['daterange']">{{ '"From" must be before "To"' | translate }}</div>
                <div *ngIf="passwdTo.errors && passwdTo.errors['daterange']">{{ '"To" must be after "From"' | translate }}</div>
              </div>
            </div>
          </panel>

          <!-- last login report -->
          <panel class="col-xs-12 col-col-sm-6 col-md-6 col-lg-4">
            <div panel-heading>User Last Login</div>
            <div panel-body>
              <p class="text-sm text-muted">Extract each user's last login information.</p>
              <div class="row pad-top form-group">
                <div class="col-xs-1 ">&nbsp;</div>
                <div class="col-xs-11 col-sm-11">
                  <button class="btn btn-info labeled-btn pull-right" (click)="run('login')">
                    <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </panel>

          <!-- user organization information report -->
          <panel class="col-xs-12 col-col-sm-6 col-md-6 col-lg-4">
            <div panel-heading>User Organization</div>
            <div panel-body>
              <p class="text-sm text-muted">Get all users' organization information.</p>
              <div class="row pad-top form-group">
                <div class="col-xs-1 ">&nbsp;</div>
                <div class="col-xs-11 col-sm-11">
                  <button class="btn btn-info labeled-btn pull-right" (click)="run('user_org')">
                    <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </panel>


        </div>
      </div>
    </form>
  </div>
</full-page>