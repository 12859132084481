import {
  Injectable,
} from "@angular/core";

import * as moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { SafeResourceUrl } from '@angular/platform-browser';

import {
  object_t,
  ServerService,
  SessionService,
  PaginatedResults,
  UtilsService,
  UserService,
  config,
} from '@pinacono/common';

import {
  BootboxInputOption,
  UIService
} from '@pinacono/ui';

import { ThemeService } from "src/app/themes/theme.service";

import { DocLibService } from 'src/app/modules/documents/doclib.service';
import {
  HardcopyStatus,
  Revision
} from 'src/app/modules/documents/types';

import {
  BROWSER_DT_FORMAT,
  LibraryStatus,
  Book, BookInstance, BookReservation,
  Equipment, EquipmentInstance, EquipmentReservation, EquipmentStatus,
  ReservationStatus
} from './types';

export interface LibraryRules {
  require_before: number;
  return_before: number;
  fine_per_day: number;
  quota: number;          // max number of occpuied book including reservation
}

export interface ISBNResult {
  title: string;
  authors?: string;
  publishers?: string;
  year?: string;
  genres?: string;
  revision?: string;
  pages?: string;
}

@Injectable()
export class LibraryService {

  public libraries_status: LibraryStatus[] = [];
  public libraries_locations: string[]     = [];
  public libraries_branches: string[]      = [];
  public home_url: SafeResourceUrl|null = null;
  public rules: LibraryRules = {
    require_before: 0,
    return_before: 0,
    fine_per_day: 0,
    quota: 5,
  };

  // -- initialization
  constructor(
    protected http: HttpClient,
    protected user: UserService,
    protected server: ServerService,
    protected session: SessionService,
    protected utils: UtilsService,
    protected ui: UIService,
    protected theme: ThemeService,
    protected docapi: DocLibService
  ) {
    this.rules = config('client.library', this.rules);
  }

  // -- API

  // ISBN search
  public async isbn(isbn: string): Promise<ISBNResult|null> {
    let self = this;
    return new Promise( (resolve) => {
      self.http.get(`https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&jscmd=details&format=json`)
      .subscribe( (res: object_t) => {

        let data = res[`ISBN:${isbn}`];
        if ( ! data || ! data['details'] ) {
          resolve(null);
        }

        let details = data['details'];

        let title: ISBNResult = {
          title:      details['title'] || null,
          genres:     (details['genres'] && details['genres'].join(', ')) || null,
          authors:    (details['authors'] && details['authors'].map((a: object_t) => a['name']).join(', ')) || null,
          revision:   details['revision']   || null,
          publishers: details['publishers'] && details['publishers'].join(', ') || null,
          pages:      details['number_of_pages'] || null,
          year:       details['publish_date']  || null
        };
        resolve(title);
      });
    });
  }

  // permission
  public is_librarian(): boolean {
    return this.session.hasPermission(['library_manage']);
  }

  // library status
  public updateLibraryStatus(status: LibraryStatus) {
    let index = this.libraries_status.findIndex( (s: LibraryStatus) => { return s.branch == status.branch; } );
    if ( index >= 0 ) {
      this.libraries_status[index] = status;
    }
    else {
      this.libraries_status.push(status);
    }
    this.theme.notify({
      class: status.is_opened ? 'success' : 'warning',
      icon: 'pli-library',
      message: 'Library at {{ branch }} is now {{ status }}',
    }, {
      branch: status.branch,
      status: status.is_opened ? 'open' : 'closed'
    });
    this.updateBadge();
  }

  protected updateBadge() {
    let title:string[] = [];

    for ( let s of this.libraries_status ) {
      if ( s.is_opened ) {
        title.push(s.branch);
      }
    }

    if ( title.length == 0 ) {
      //this.theme.setMenuBadge('library-status', 'Closed', 'danger', 'All libraries are closed.', this.setBranchStatus.bind(this));
      this.theme.setMenuBadge('library-status', 'Closed', 'danger', 'All libraries are closed.');
    }
    else {
      const css = ( title.length == this.libraries_status.length ) ? 'success' : 'warning';
      //this.theme.setMenuBadge('library-status', 'Open', css, title.join("\n"), this.setBranchStatus.bind(this));
      this.theme.setMenuBadge('library-status', 'Open', css, title.join("\n"));
    }
  }

  public isBranchOpened(branch: string): boolean {
    const b = this.libraries_status.find( s => s.branch == branch );
    return b?.is_opened || false;
  }

  public async setBranchStatus(branch: string, is_open: boolean) {
    const b = this.libraries_status.find( b => b.branch == branch );
    if ( !! b ) {
      const s = { branch: branch, is_opened: is_open };
      this.server.silent().request('library.status.set', null, s);
      this.updateLibraryStatus(s);
    }
  }

  /*
  public async setBranchStatus() {
    if ( ! this.is_librarian() ) return;

    const options: BootboxInputOption[] = [];
    const opened: string[] = [];
    this.libraries_branches.forEach( (branch: string) => {
      options.push({
        value: branch,
        text: branch
      });
      if ( this.libraries_status.findIndex( s => s.branch == branch && s.is_opened ) >= 0 ) {
        opened.push(branch);
      }
    });

    const opening = await this.ui.prompt('Set the library status:', null, undefined, { size: 'large' }, 'checkbox', { inputOptions: options }, opened);
    this.libraries_branches.forEach( (branch: string, index: number) => {
      if ( opening && opening.findIndex( (b: string) => b == branch ) >= 0 ) {
        const status = { branch: branch, is_opened: true };
        this.server.silent().request('library.status.set', null, status);
        this.updateLibraryStatus(status);
      }
      else {
        const status = { branch: branch, is_opened: false };
        this.server.silent().request('library.status.set', null, status);
        this.updateLibraryStatus(status);
      }
    });
  }
    */

  // -- getters

  public get locations(): string[] {
    return this.libraries_locations;
  }

  public get libraryStatus() : LibraryStatus[] {
    return this.libraries_status;
  }

  public get active_libraries(): string[] {
    return this.libraries_status.filter( s => s.is_opened ).map( s => s.branch );
  }

  public get equipmentStatuses() : string[] {
    return this.utils.getEnumValue(EquipmentStatus);
  }

  // -- loaders

  public async loadPaginatedBookInstances(book: Book, pageno: number = 1, perpage: number = 10) {
    const res: PaginatedResults<BookInstance> = await this.server.index('hardcopies', { doc_id: book.id },
      {
        pageno: pageno,
        perpage: perpage,
        sorting: {
          updated_at: 'DESC'
        }
      }
    )
    book.instances = res.data.map( (h) => this.createBookInstance(h) );
    book.instances_pagination = {
      total: res.total,
      perpage: res.perpage,
      pageno: res.pageno
    };
  }

  public async loadPaginatedRevisions(book: Book, pageno: number = 1, perpage = 10) {
    const res: PaginatedResults<Revision> = await this.server.index('revisions', { doc_id: book.id },
      {
        pageno: pageno,
        perpage: perpage,
        sorting: {
          updated_at: 'DESC'
        }
      }
    )
    //book.revisions = res.data.map( (r: Revision) => this.docapi.createRevision(r) );
    book.reservations_pagination = {
      total: res.total,
      perpage: res.perpage,
      pageno: res.pageno
    };
  }

  public async loadPaginatedReservations(book: Book, pageno: number = 1, perpage = 10) {
    const res: PaginatedResults<BookReservation> = await this.server.index('/library/reservation/book', { doc_id: book.id },
      {
        pageno: pageno,
        perpage: perpage,
        sorting: {
          updated_at: 'DESC'
        }
      }
    )
    book.reservations = res.data.map( (r: BookReservation) => this.createBookReservation(r) );
    book.reservations_pagination = {
      total: res.total,
      perpage: res.perpage,
      pageno: res.pageno
    };
  }

  // -- creators

  public createBook(data: object_t|null = null): Book {
    data = data || {};
    const book: Book = this.docapi.createDocument(data) as Book;

    book.covers = data['covers'] && data['covers'].map( (f: object_t) => this.server.createAttachment(f) ) ||
                  book.attachments && book.attachments.filter( f => f.meta && f.meta['is_cover'] ) ||
                  [{
                    id: 0,
                    file_name: 'default-cover.png',
                    content_type: 'image/png',
                    thumb_url: '/assets/imgs/default-cover-300x480.png',
                    path_url: '/assets/imgs/default-cover-300x480.png'
                  }];

    book.instances = data['instances'] && data['instances'].map( (h: object_t) => this.createBookInstance(h) ) || [];
    /*
    book.instances = data['instances'] && data['instances'].data.map( (h: object_t) => this.createBookInstance(h) ) || [];
    book.instances_pagination = data['instances'] && {
      pageno:   data['instances'].paginatorInfo.currentPage || 1,
      perpage:  data['instances'].paginatorInfo.perPage     || 10,
      total:    data['instances'].paginatorInfo.total       || 0
    } || null;
    */
    book.instances_available = data['instances_available'] || 0;

    book.reservations = data['reservations'] && data['reservations'].map( (r: object_t) => this.createBookReservation(r) ) || [];
    /*
    book.reservations = data['reservations'] && data['reservations'].data.map( (r: object_t) => this.createBookReservation(r) ) || [];
    book.reservations_pagination = data['reservations'] && {
      pageno:   data['reservations'].paginatorInfo.currentPage || 1,
      perpage:  data['reservations'].paginatorInfo.perPage     || 10,
      total:    data['reservations'].paginatorInfo.total       || 0
    } || null;
    */

    /*
    if ( load_related && data['revisions'] && data['revisions'].total > 0 && b.revisions && b.revisions.length == 0 ) {
      this.loadPaginatedRevisions(b, b.revisions_pagination?.pageno, b.revisions_pagination?.perpage || 10);
    }

    if ( load_related && data['instances'] && data['instances'].total > 0 && b.instances && b.instances.length == 0 ) {
      this.loadPaginatedBookInstances(b, b.instances_pagination?.pageno, b.instances_pagination?.perpage || 10);
    }

    if ( load_related && data['reservations'] && data['reservations'].total > 0 && b.reservations && b.reservations.length == 0 ) {
      this.loadPaginatedReservations(b, b.reservations_pagination?.pageno, b.reservations_pagination?.perpage || 10);
    }
    */

    return book;
  }

  public createBookInstance(data: object_t|null = null): BookInstance {
    data = data || {};
    return {
      id:       data['id']     || null,
      code:     data['code']   || '', // barcode
      status:   data['status'] || HardcopyStatus.reserved,
      location: this.docapi.createHardcopyLocation( data['location'] || null ),

      attr: Object.assign( {}, data['attr'] || {} ),
      book: data['book'] && this.createBook(data['book']) || null,

      reservations: data['reservations'] && data['reservations'].map( (r: object) => this.createBookReservation(r) ) || [],
      reservations_pagination: data['reservations'] && {
        pageno:  data['reservations'].paginatorInfo.currentPage,
        perpage: data['reservations'].paginatorInfo.perPage,
        total:   data['reservations'].paginatorInfo.total
      } || null,

      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null
    };
  }

  public createBookReservation(data: object_t|null = null): BookReservation {
    data = data || {};
    let r: BookReservation = {
      id:       data['id']     || null,

      book:     data['book'] && this.createBook(data['book']) || null,
      book_id:  data['book'] && data['book'].id || null,

      extended: data['extended'] || false,

      instance:    data['instance'] && this.createBookInstance(data['instance']) || null,
      instance_id: data['instance_id'] || (data['instance'] && data['instance'].id) || null,

      user:     data['user'] && this.user.create(data['user']) || null,
      status:   data['status'] || ReservationStatus.requesting,

      request_date:   data['request_date']   || this.utils.dateTime_utils.format(BROWSER_DT_FORMAT),
      require_before: data['require_before'] || this.utils.dateTime_utils.format(BROWSER_DT_FORMAT, moment().add(this.rules.require_before, 'days')),

      approve_date:   data['approve_date'] || null,
      approved_by:    data['approved_by'] && this.user.create(data['approved_by']) || null,

      deliver_date:   data['deliver_date'] || null,
      delivered_by:   data['delivered_by'] && this.user.create(data['delivered_by']) || null, // librarian who deliver the book to user

      instance_status: data['instance_status'] || null,
      return_before:  data['return_before'] || this.utils.dateTime_utils.format(BROWSER_DT_FORMAT, moment().add(this.rules.return_before, 'days')),
      return_date:    data['return_date']   || null,  // date of returning
      returned_to:    data['returned_to'] && this.user.create(data['returned_to']) || null, // librarian who recieve the returned book

      fine:           parseFloat(data['fine'] || null), // must be number
      note:           data['note'] || null,

      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null,
    };

    r.require_before = r.require_before || this.utils.dateTime_utils.format(BROWSER_DT_FORMAT, moment(r.request_date).add(this.rules.require_before, 'days'));
    return r;
  }

  public createEquipment(data: object_t|null = null): Equipment {
    data = data || {};
    let t: Equipment = {
      id:           data['id']    || null,
      title:        data['title'] || 'New Equipment',
      description:  data['description'] || null,

      instances:    data['instances']    && data['instances'].map( (i: object) => this.createEquipmentInstance(i) ) || [],
      reservations: data['reservations'] && data['reservations'].map( (r: object) => this.createEquipmentReservation(r) ) || [],

      attr:         data['attr'] || null,

      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null,
    };

    return t;
  }

  public createEquipmentInstance(data: object_t|null = null): EquipmentInstance {
    data = data || {};
    return {
      id:        data['id']     || null,
      equipment: data['equipment'] && this.createEquipment(data['equipment']) || null,
      code:      data['code']   || null,
      status:    data['status'] || EquipmentStatus.reserved,
      location:  data['location'] || null,
      attr:      data['attr']     || null,

      reservations: data['reservations'] && data['reservations'].map( (r: object) => this.createEquipmentReservation(r) ) || [],

      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null,
    };
  }

  public createEquipmentReservation(data: object_t|null = null): EquipmentReservation {
    data = data || {};
    let r: EquipmentReservation = {
      id:        data['id'] || null,
      equipment: this.createEquipment(data['equipment'] || null),
      instance:  this.createEquipmentInstance(data['instance'] || null),
      user:      data['user']     || null,
      status:    data['status']   || ReservationStatus.requesting,

      request_date:   data['request_date'] || this.utils.dateTime_utils.format(BROWSER_DT_FORMAT),
      require_before: data['require_before'] || null,

      approve_date:   data['approve_date'] || null,
      approve_by:     data['approve_by']   || null,

      deliver_date:   data['deliver_date'] || null,
      deliver_by:     data['deliver_by']   || null, // librarian who deliver the book to user

      return_before:  data['return_before'] || null,
      return_date:    data['return_date']   || null,  // date of returning
      return_to:      data['.return_to']    || null, // librarian who recieve the returned book

      fine:           data['fine'] || null,         // fine
      note:           data['note'] || null,

      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null,
    };

    r.require_before = r.require_before || this.utils.dateTime_utils.format(BROWSER_DT_FORMAT, moment(r.request_date).add(this.rules.require_before, 'days'));
    return r;
  }
}