import {
  Component,
  OnInit
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import {
  NavigationService,
  ObjectUtilsService,
  PaginatedESResult,
  ServerService
} from '@pinacono/common';

import { SearchResultEntry } from '../types';
import { CommonPagesService } from '../common-pages.service';

@Component({
  selector: 'app-search-page',
  templateUrl: 'search.component.html',
  styleUrls: [ 'search.component.scss' ]
})
export class SearchPageComponent implements OnInit {

  public keyword: string = '';

  public total: number = 0; // total result entries
  public pageno: number = 1;
  public perpage: number = 10;
  public elapsed: number = 0;
  public results: SearchResultEntry[] = [];

  // -- initialization
  constructor(
    public activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected server: ServerService,
    protected objectUtils: ObjectUtilsService,
    protected api: CommonPagesService
  ) {
    //super(router, activatedRoute);
  }

  public ngOnInit(): void {
    let keyword = this.activatedRoute.snapshot.paramMap.get('keyword');
    if ( keyword === null ) {
      this.nav.back();
      return;
    };

    this.pageno  = parseInt(this.activatedRoute.snapshot.queryParamMap.get('pageno') || '1');
    this.perpage = parseInt(this.activatedRoute.snapshot.queryParamMap.get('perpage') || '10');

    setTimeout( () => {
      this.search(keyword);
    });
  }

  // -- perform search
  protected prev_keyword: string = '';
  public async search(keyword: string|null, pageno: number = 0, perpage: number = 0) {
    if ( ! keyword ) {
      return;
    }

    this.keyword = keyword.trim();

    if ( this.keyword != this.prev_keyword ) {
      this.pageno = 1;
      this.prev_keyword = this.keyword;
    }
    this.pageno  = pageno || this.pageno;
    this.perpage = perpage || this.perpage;

    // exclude OPL from result
    const res: PaginatedESResult<any> = await this.server.request('search', null, { keyword: `(${keyword}) AND (NOT doc_type:opl)` }, {
      pageno: this.pageno,
      perpage: this.perpage
    });

    this.total   = res.total.value;
    this.pageno  = res.pageno;
    this.perpage = res.perpage;
    this.elapsed = res.header.took / 1000; // convert to sec
    this.results = [];

    for ( let row of res.data ) {
      // parsing content
      let content: string = row.doc.content;
      if ( row.doc.is_json ) {
        try {
          let o = JSON.parse(row.doc.content);
          content = JSON.stringify(o, null, 2);
        }
        catch ( error ) {
          console.log('Cannot parse search result content - ', error);
        }
      }

      let r: SearchResultEntry = {
        title: row.doc.title,
        doc_type: row.doc.doc_type,
        doc_id: row.doc.doc_id,
        teaser: content.replace(/\n\r/g, '').trim().substring(0, 150) + ( content.length > 150 ? '&hellip;' : '' ),
        teaser_is_json: row.doc.is_json || false,
        url: this.api.getLink(row.doc, '/#'),
        document: row.doc,
        highlight: this.objectUtils.toArray(row.highlight)
      };
      //r.highlight && r.highlight.forEach( h => h.value = h.value.map( (s: string) => s.replace(/\r\n/g, '').trim() ) );
      this.results.push(r);
    }
  }

  // -- getters / setters
  set currentPage(page: number) {
    this.pageno = page;
    this.search(this.keyword);
  }

  get currentPage(): number {
    return this.pageno;
  }
}