import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  object_t,
  ServerService,
  FileUtilsService
} from '@pinacono/common';

@Component({
  selector: 'page-opl-reports',
  templateUrl: 'reports.html',
  styleUrls: [ 'reports.scss' ]
})
export class OplReportsPage implements OnInit {

  // -- form parameters
  public params = {
  };

  // -- constructor
  constructor(
    protected server: ServerService,
    protected fileUtils: FileUtilsService
  ) {
  }

  public ngOnInit(): void {
  }

  public async run(name: string, form: NgForm) {
    const params = form.value;
    const res: object_t = await this.server.request(`opl.reports.${name}`, params);
    this.fileUtils.saveXLSX(name, res);
  }
}