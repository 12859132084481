import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import {
  PinaconoCommonModule,
  ServerService
} from '@pinacono/common';

import { PinaconoUIModule } from '@pinacono/ui';
import { PinaconoIIPViewerModule } from '@pinacono/iip-viewer';

import { AppCommonModule } from 'src/app/common/module';
import { ThemesModule } from 'src/app/themes/module';

// constants and interfaces
//import {} from './types';

// services
import { AdminServicesService } from './admin.service';
import { ENDPOINTS } from './endpoints';

// admin's pages
import { AdminDashboardComponent } from './dashboard/dashboard.component';
import { AdminGroupsComponent } from './groups/groups';
import { AdminImportComponent } from './imports/import.component.';
import { AdminTaxonomiesComponent } from './taxonomies/taxonomies';

import { ImporterComponent } from './imports/components/importer.component';
import { AdminReportsComponent } from './reports/reports.component';
import { AdminUsersComponent } from './users/users';
import { AdminNotifyComponent } from './notify/notify.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    TranslateModule.forChild(),

    PinaconoCommonModule,
    PinaconoUIModule,
    PinaconoIIPViewerModule,

    AppCommonModule,
    ThemesModule
  ],
  declarations: [
    // pages
    AdminDashboardComponent,
    AdminGroupsComponent,
    AdminImportComponent,
    AdminNotifyComponent,
    AdminReportsComponent,
    AdminTaxonomiesComponent,
    AdminUsersComponent,
    // components
    ImporterComponent
  ],
  providers: [
    AdminServicesService
  ],
  exports: [
    //TranslateModule,

    // pages
    AdminDashboardComponent,
    AdminGroupsComponent,
    AdminImportComponent,
    AdminNotifyComponent,
    AdminReportsComponent,
    AdminTaxonomiesComponent,
    AdminUsersComponent,
    // components
    ImporterComponent
  ]
})
export class AdminModule {
  constructor(server: ServerService) {
    server.registerEndpoints(ENDPOINTS);
  }
}
