<minimal-page class="landing-page">
  <div class="cls-content-sm panel">
    <div class="panel-body">
      <h1 class="h3">{{ 'Contact Administrator' | translate }}</h1>
      <p class="pad-btm">{{'Please fill the following form.' | translate}}.</p>
      <form #f="ngForm" (ngSubmit)="contact(f)">
        <!--
        <div class="form-group">
          <input type="text" required name="fullname" [(ngModel)]="model.fullname" class="form-control" tabindex="1" placeholder="{{ 'Full Name' | translate }}" #fullname="ngModel">
          <div class="alert alert-danger" *ngIf="fullname.invalid && (fullname.dirty || fullname.touched)">
            <div class="text-sm text-thin" *ngIf="fullname.errors.required">{{ 'Your full name is required' | translate }}</div>
          </div>
        </div>
        -->
        <div class="form-group">
          <input type="text" required name="staff_id" [(ngModel)]="staff_id" class="form-control" tabindex="1" placeholder="{{ 'Your e-mail or Staff ID' | translate }}" #staff_id_input="ngModel">
          <div class="alert alert-danger" *ngIf="staff_id_input.invalid && (staff_id_input.dirty || staff_id_input.touched)">
            <div class="text-sm text-thin" *ngIf="staff_id_input.errors && staff_id_input.errors['required']">{{ 'Your e-mail or staff ID is required' | translate }}</div>
          </div>
        </div>
        <div id="subject-container" class="form-group">
          <select name="subject" [chosen]="{ width: '100%' }" [(ngModel)]="subject" data-placeholder="Choose subject..." tabindex="2">
            <!--
            <option value="Cannot Login">I cannot login to the system.</option>
            <option value="Forget Login Credentials">I forget my password or email.</option>
            <option value="Request to Access System">I'd like to access the system.</option>
            <option value="Other request">Other request.</option>
            -->
            <option value="Need Help!">Need to contact admin.</option>
          </select>
        </div>
        <div id="details-container" class="form-group">
          <textarea required class="form-control" name="details" [(ngModel)]="details" placeholder="Details" rows="10" style="resize: none"></textarea>
        </div>
        <div class="form-group text-right">
          <button class="btn btn-success btn-lg btn-block" type="submit" [disabled]="!f.form.valid">{{ 'Send' | translate }}</button>
        </div>
      </form>
      <div class="pad-top">
        <a [routerLink]="['/login']" class="btn-link text-thin text-main">{{ 'Back to Login' | translate }}</a>
      </div>
    </div>
  </div>
</minimal-page>