import {
  Inject,
  Component,
  OnInit
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import {
  APP_INFO,
  AppInfo,
  NavigationService,
  object_t,
  PagesQueueService,
  SessionService,
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

/*
interface Credential {
  email: string;
  password: string;
  remember?: boolean;
}
*/

@Component({
  selector: 'app-login-page',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  /** enable/disable new account registration link */
  public allow_register: boolean = false;
  /** enable/disable remember me feature */
  public allow_remember: boolean = false;

  constructor(
    public nav: NavigationService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected session: SessionService,
    protected queue: PagesQueueService,
    protected ui: UIService,
    @Inject(APP_INFO) public app_info: AppInfo
  ) { }

  public ngOnInit(): void {
    if ( this.activatedRoute.snapshot.queryParamMap.get('logout') || this.activatedRoute.snapshot.paramMap.get('logout') ) {
      this.session.logout();
    }
    else if ( this.session.isLoggedIn ) {
      this.queue.next();
    }
  }

  public login(form: NgForm) {
    return this.session.login(
      form.controls['email'].value.toLowerCase(),
      form.controls['password'].value,
      form.controls['remember']?.value || true
    )
    .then(
      (user: object_t) => {
        this.queue.start(user['password_expired'] ? 'chpass' : null);
      },

      (reason: any) => {
        this.ui.alert('Invalid credentials. Please try again.', undefined, 'error');
        console.warn('Error! - Cannot log-in', reason);
      }
    );
  }
}
