import moment from 'moment';

import {
  Component,
  Renderer2,
  OnInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute,
  Event as AngularRouterEvent,
  RouterEvent
} from '@angular/router';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  INgxSelectOption
} from 'ngx-select-ex';

import {
  NavigationService,
  object_t,
  SessionService,
  ServerService
} from '@pinacono/common';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  ButtonsFormatter,
  ComponentFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GridUserComponent,
  GraphQLServerService,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { UIService } from '@pinacono/ui';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { DocLibService } from '../doclib.service';
import { Document } from '../types';

import { BrowserProfiles } from './profiles';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'page-doclib',
  templateUrl: 'browse.html',
  styleUrls: [ 'browse.scss' ],
  providers: [ AngularUtilService ]
})
export class DocLibBrowsePage extends BasePageComponent implements OnInit {

  // -- ------------------- --
  // -- page configurations --
  // -- ------------------- --

  /**
   * page title
   */
  public title: string = 'Document Library';

  /**
   * enable/disable search
   */
  public enableSearch: boolean = true;

  /**
   * graphQL filters configuration
   */
  protected filters: GraphqlFilteringOption[] = [
    { field: 'menu', operator: 'EQ', value: 'published' }
  ];

  /**
   * columns to be hidden by default
   */
  protected hiddenColumns: string[] = [ 'id', 'confidential', 'uploader', 'status', 'created_at' ];

  /**
   * list of button show in grid
   */
  protected avaiableButtons: string[] = [];

  /**
   * list of action buttons available for grid's action column
   */
  protected actionButtons: DataGridButton[] = [
    {
      name: 'edit',
      title: 'Edit this document',
      //css: 'btn-primary',
      css: 'btn-default',
      //icon: 'pli-pen',
      icon: 'fa-solid fa-pen',
      visible: this.editButtonVisible.bind(this),
      click: this.editButtonClick.bind(this)
    },

    /*
    {
      name: 'submit',
      title: 'Submit this document',
      css: 'btn-mint',
      icon: 'fa fa-solid fa-vote-yea',
      visible: this.submitButtonVisible.bind(this),
      click: this.submitButtonClick.bind(this)
    },

    {
      name: 'review',
      title: 'Mark this document as reviewed',
      css: 'btn-pink',
      icon: 'pli-magnifi-glass',
      visible: this.reviewButtonVisible.bind(this),
      click: this.reviewButtonClick.bind(this)
    },

    {
      name: 'publish',
      title: 'Publish this document',
      css: 'btn-success',
      icon: 'pli-megaphone',
      visible: this.publishButtonVisible.bind(this),
      click: this.publishButtonClick.bind(this)
    },

    {
      name: 'expire',
      title: 'Mark this document as expired',
      css: 'btn-dark',
      icon: 'fa fa-clock',
      visible: this.expireButtonVisible.bind(this),
      click: this.expireButtonClick.bind(this)
    },
    */

    {
      name: 'delete',
      title: '(Soft) Delete this document',
      //css: 'btn-warning',
      css: 'btn-default',
      //icon: 'pli-trash',
      icon: 'fa-solid fa-trash-can',
      visible: this.deleteButtonVisible.bind(this),
      click: this.deleteButtonClick.bind(this)
    },

    {
      name: 'restore',
      title: 'Restore this document',
      //css: 'btn-info',
      css: 'btn-default',
      //icon: 'pli-recycling-2',
      icon: 'fa-solid fa-recycle',
      visible: this.restoreButtonVisible.bind(this),
      click: this.restoreButtonClick.bind(this)
    },

    {
      name: 'trash',
      title: '(Hard) Delete this document',
      //css: 'btn-danger',
      css: 'btn-default',
      icon: 'fa-solid fa-bomb',
      visible: this.trashButtonVisible.bind(this),
      click: this.trashButtonClick.bind(this)
    },
  ];

  // -- --------------------- --
  // -- end of configurations --
  // -- --------------------- --

  // --

  // template
  public keyword: string|null = null;

  // grid
  protected gridComponent: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // data
  public docs: Document[] = [];

  // -- internal
  protected selectedRow: number = 0;

  // -- initialization

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: DocLibService,
    protected ngUtilService: AngularUtilService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected renderer: Renderer2,
    protected translate: TranslateService
  ) {
    super(router, activatedRoute);
    /*
    this.router.events.pipe(
      filter( (e: AngularRouterEvent): e is RouterEvent => e instanceof RouterEvent )
    )
    .subscribe( (e: RouterEvent) => {
      console.debug(`router event - ${e.url}`, e);
    });
    */
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    let name = this.activatedRoute.snapshot.paramMap.get('name') || 'default';
    let profile = BrowserProfiles[name];

    if ( ! profile ) {
      this.nav.goto('/404');
    }

    this.title           = profile.title;
    this.enableSearch    = profile.enableSearch;
    this.filters         = profile.filters.map( f => Object.assign({}, f) );
    this.hiddenColumns   = profile.hiddenColumns;
    this.avaiableButtons = profile.avaiableButtons;

    // require parsing filters - put document.categories into account
    this.filters.forEach( (o: GraphqlFilteringOption) => {
      if ( o.field == 'user_domain' && this.session.currentUser?.primary_domain?.id ) {
        o.value = (o.value as string).replace('{{user_domain}}', this.session.currentUser.primary_domain.id.toString());
      }
    })

    this.initGrid();
  }

  // -- overloading

  protected silent: boolean = true;
  protected override loadData(): Promise<any> {
    this.silent = false;
    this.gridComponent && this.gridComponent.extensionService.refreshBackendDataset();
    return Promise.resolve();
  }

  // -- grid interfaces
  protected async processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult|undefined> {
    try {
      const res: object_t = await this.graphqlServer.sendQuery({query: query})
      // parse response
      const re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
      this.docs = re.data['docs'].nodes.map( (d: Document) => {
        return this.api.createDocument(d);
      });
      return re;
    }
    catch (error: unknown) {
      this.ui.alert( (error as Error).message, undefined, 'Error!');
      console.error('GraphQL Error: ', error);
    }
    return;
  }

  protected initGrid() {
    this.columnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', width: 60,
        sortable: true,
        filterable: true,
      },

      {
        id: 'confidential', name: 'Confidential',
        field: 'confidential',
        type: FieldType.boolean,
        cssClass: 'text-center', width: 70,
        sortable: false,
        filterable: true,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: true,   label: 'Yes' },
            { value: false,  label: 'No'  },
          ]

        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return value ? '<i class="fa fa-lock"></i>' : '<i class="fa fa-unlock"></i>';
        },
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return value ? 'Yes' : 'No';
        },
      },

      {
        id: 'code', name: 'Document Code',
        field: 'doc_code',
        fields: [ 'code', 'doc_code', 'template_id', 'attr' ],
        type: FieldType.string,
        cssClass: 'doc-code text-left', minWidth: 100, maxWidth: 200,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Document, grid: SlickGrid): string  => {
          const badges = {
            default:      { label: null,           css: null     },
            confidential: { label: 'Confidential', css: 'danger' },
            //memo:         { label: this.translate.instant('memo'), css: 'info'   }
            memo:         { label: 'บันทึก', css: 'info'   }
          };

          let badge: object_t|null = null;
          if ( this.api.is_confidential(dataContext) ) {
            badge = badges.confidential;
          }
          else if ( !! dataContext['template_id'] ) {
            badge = badges.memo;
            value = '<span class="memo-no-code">N/A</span>'
          }

          return ( value as string ) + ( badge ? `<span class="badge badge-${badge['css']}">${badge['label']}</span>` : '');
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Title',
        field: 'title',
        fields: [ 'title', 'attr' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150 ,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string  => {
          let prefix = dataContext.attr.title?.prefix ? dataContext.attr.title.prefix + ' ' : '';
          let suffix = dataContext.attr.title?.suffix ? ' ' + dataContext.attr.title.suffix : '';
          return prefix + value + suffix;
        },
        sortable: true,
        filterable: true,
      },

      {
        id: 'uploader', name: 'Uploader',
        field: 'uploader',
        fields: [
          'uploader.id', 'uploader.avatar', 'uploader.avatar.thumb_url', 'uploader.email', 'uploader.fullname', 'uploader.profiles.staff_id',
          'mandatory_permitted_users.id', 'optional_permitted_users.id'
        ],
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 120 ,
        sortable: true,
        filterable: true,
        formatter: ComponentFormatter,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return `${value.fullname} (${value.email})`;
        },
        params: {
          //component: GridUserComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'uploader'
          }
        }
      },

      {
        id: 'doc_type', name: 'Type',
        field: 'type',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 70, maxWidth: 70,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          if ( dataContext['template_id'] ) {
            return `<span class="doc-type">บันทึก</span>`; // @TODO - also put this value in indexer
          }
          let doc_type = this.api.names['doc_code_formatter'].f.find( (o: INgxSelectOption) => o.value == dataContext.type);
          return `<span class="doc-type">${ doc_type && doc_type.label || '- unknown -' }</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [ ...this.api.names['doc_code_formatter'].f, { label:'บันทึก', value: '(FORM)' } ]
        }
      },

      /*
      {
        id: 'revisions', name: 'Revisions',
        field: 'total_revisions_count',
        fields: ['total_revisions_count', 'total_active_revisions_count'],
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 120, maxWidth: 120,
        sortable: false,
        filterable: false,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return `<span class="revisions">${ dataContext['total_active_revisions_count'] } / ${ dataContext['total_revisions_count'] }</span>`
        },
      },
      */

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',     css: 'default' },
            reserved:  { label: 'Reserved',  css: 'default' },
            submitted: { label: 'Submitted', css: 'mint'    },
            reviewed:  { label: 'Approved',  css: 'info'    },
            published: { label: 'Published', css: 'success' },
            cancelled: { label: 'Cancelled', css: 'dark'    },
            expired:   { label: 'Expired',   css: 'warning' },
            deleted:   { label: 'Deleted',   css: 'dark'    }
          }
          let status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            //{ value: 'draft',      label: 'Draft'     },
            { value: 'reserved',   label: 'Reserved'  },
            { value: 'submitted',  label: 'Submitted' },
            { value: 'reviewed',   label: 'Approved'  },
            { value: 'published',  label: 'Published' },
            //{ value: 'cancelled',  label: 'Cancelled' },
            { value: 'expired',    label: 'Expired'   },
            { value: 'deleted',    label: 'Deleted'   }
          ]
        }
      },

      {
        id: 'related_doc', name: 'Related To',
        field: 'references',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 100,
        filterable: true,
        formatter: (row: number, cell: number, value: string, columnDef: Column, dataContext: any, grid: SlickGrid): string  => {
          return value.split("\n").join(", ");
        },
      },

      {
        id: 'referred_by', name: 'Referred by',
        field: 'referred_by',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 100,
        filterable: true,
        formatter: (row: number, cell: number, value: string, columnDef: Column, dataContext: any, grid: SlickGrid): string  => {
          return value.split("\n").join(", ");
        },
      },

      {
        id: 'approve_date', name: 'Approve Date',
        field: 'attr',
        fields: [ 'reviewed_at', 'attr' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 80,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          //let s = DatetimeMomentFormatter(row, cell, dataContext.document_date || null, columnDef, dataContext, grid);
          if ( !! dataContext.attr['doc_date'] ) {
            const s = DatetimeMomentFormatter(row, cell, moment(dataContext.attr['doc_date'], 'YYYY-MM-DD') || null, columnDef, dataContext, grid);
            return s.toString();
          }
          return 'n/a';
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string  => {
          if ( !! dataContext.attr['doc_date'] ) {
            const s = DatetimeMomentFormatter(row, cell, moment(dataContext.attr['doc_date'], 'YYYY-MM-DD') || null, columnDef, dataContext, grid);
            return s.toString();
          }
          return 'n/a';
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'created_at', name: 'Reserved At',
        field: 'created_at',
        fields: [ 'created_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 80,
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'published_at', name: 'Publishing Date',
        field: 'published_at',
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 80,
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      }
    ];

    // extract avaialble buttons
    const buttons = this.actionButtons.filter( b => !! this.avaiableButtons.find( name => name == b.name ) );
    if ( buttons.length > 0 ) {
      this.columnDefinitions.push({
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 80, width: 80, maxWidth: 250,
        sortable: false,
        filterable: false,
        excludeFromExport: true,
        excludeFromHeaderMenu: true,
        formatter: ButtonsFormatter,
        params: {
          buttons: buttons
        }
      });
    }

    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'docs',
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Documents'
      },

      enableSorting: true,

      rowHeight: 100,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        //bottomPadding: 85,
        minHeight: 180,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        filters: [
          { columnId: 'confidential', operator: 'EQ', searchTerms: [false] }
        ],
        columns: this.columnDefinitions
                  .filter( col => ! this.hiddenColumns.find( id => id == col.id.toString() ) )
                  .map( col => {
                    return { columnId: col.id.toString() };
                  })
      }
    };
  }

  // -- grid interfaces
  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    this.selectedRow = (event as CustomEvent).detail.args['row'];
    const doc = this.docs[this.selectedRow];
    if ( this.api.is_confidential(doc) ) {
      const accssible = this.api.can_read_confidential(doc);
      if ( accssible === undefined ) {
        this.ui.alert('You do not have permission to access this document.');
        return;
      }
    }
    this.nav.push(['doc/view', doc.id ]);
  }

  // -- template API

  public search(keyword: string|null) {
    if ( keyword === null ) return;
    this.nav.push([ 'doc/search', keyword ]);
  }

  /*
  public upload() {
    this.nav.push('doclib-edit', {id: 'new'}, null, this.navDone);
  }
  */

  /*
  public view(doc: Document) {
    if ( this.api.is_confidential(doc) ) {
      //this.nifty.alert('You do not have permission to access this document.');
      this.ui.confirm('You do not have permission to access this document. Request for access?', undefined, () => {
        this.ui.prompt("Message to approver:", undefined, (reason: string) => {
          this.server.request('doclib.access.request',{ doc_id: doc.id}, { reason: reason})
          .then( () => {
            this.ui.alert("Request is sent. The access link will be sent to your mailbox, once it is approved.");
          });
        });
      });
    }
    else {
      this.nav.push([ 'doc/view', doc.id]);
    }
  }
  */

  /*
  public expired_in(date: string): number {
    return moment(date).diff(moment(), 'days');
  }

  public split(s: string): string[] {
    if ( ! s ) return [];
    return s.split("\n");
  }

  public getFileIcon(mime: string) {
    return MimeUtils.getFileIcon(mime);
  }
  */

  // -- permission
  protected isOwner(doc: Document): boolean {
    return doc.uploader?.id == this.session.currentUser?.id;
  }

  protected isAdmin(): boolean {
    return this.session.hasPermission(['doc_manage', 'doc_review']);
  }

  // -- actions
  public editButtonVisible(row: number, col: number, value: any, column: Column, dataContext: any): boolean {
    let doc: Document = value as Document;
    //return ( doc.status == 'draft' || doc.status == 'reserved' ) && ( this.isOwner(doc) || this.isAdmin() );
    return ( ( doc.status == 'draft' || doc.status == 'reserved' ) && ( this.isOwner(doc) ) ) || this.isAdmin();
  }

  public editButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    let doc: Document = value as Document;
    this.nav.push([ 'doc/edit', doc.id]);
  }

  /*
  public submitButtonVisible(row: number, col: number, value: any, column: Column, dataContext: any): boolean {
    let doc: Document = value as Document;
    return ( doc.status == 'reserved' ) && ( this.isOwner(doc) || this.isAdmin() );
  }

  public submitButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    console.log('submitButtonClick', value);
  }

  public reviewButtonVisible(row: number, col: number, value: any, column: Column, dataContext: any): boolean {
    let doc: Document = value as Document;
    return ( doc.status == 'submitted' ) && ( this.isAdmin() );
  }

  public reviewButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    console.log('reviewButtonClick', value);
  }

  public publishButtonVisible(row: number, col: number, value: any, column: Column, dataContext: SlickGrid): boolean {
    let doc: Document = value as Document;
    return ( doc.status == 'reviewed' ) && ( this.isAdmin() );
  }

  public publishButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    console.log('publishButtonClick', value);
  }

  public expireButtonVisible(row: number, col: number, value: any, column: Column, dataContext: any): boolean {
    let doc: Document = value as Document;
    return ( doc.status == 'published' ) && ( this.isAdmin() );
  }

  public expireButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    console.log('expireButtonClick', value);
  }
  */

  public deleteButtonVisible(row: number, col: number, value: any, column: Column, dataContext: SlickGrid): boolean {
    const doc: Document = value as Document;
    return ( doc.status == 'expired' ) && ( this.isAdmin() );
  }

  public async deleteButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    const doc: Document = value as Document;
    if ( !! await this.ui.confirm('Delete {{ title }} ({{ code }})?', { title: doc.title, code: doc.doc_code } ) ) {
      await this.server.destroy('docs', doc.id);
      this.refresh();
    }
  }

  public restoreButtonVisible(row: number, col: number, value: any, column: Column, dataContext: any): boolean {
    const doc: Document = value as Document;
    return ( doc.status == 'deleted' ) && ( this.isAdmin() );
  }

  public async restoreButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    const doc: Document = value as Document;
    if ( !! await this.ui.confirm('Restore {{ title }} ({{ code }})?', { title: doc.title, code: doc.doc_code }) ) {
      await this.server.restore('docs', doc.id)
      this.refresh();
    }
  }

  public trashButtonVisible(row: number, col: number, value: any, column: Column, dataContext: any): boolean {
    let doc: Document = value as Document;
    return ( doc.status == 'draft' || doc.status == 'reserved' || doc.status == 'deleted' ) && ( this.isOwner(doc) || this.isAdmin() );
  }

  public async trashButtonClick(row: number, col: number, value: object, config: DataGridButton, grid: SlickGrid) {
    let doc: Document = value as Document;

    if ( doc.status == 'reserved' ) {
      const reason = await this.ui.prompt('Please enter reason. Document code {{ code }} will be available for reservation again.', { code: doc.doc_code }, undefined, null, 'textarea');
      await this.server.request('doclib.code.release', { id: doc.id }, {
        reason: reason
      })
      this.refresh();
      return;
    }

    const confirmed = await this.ui.confirm('Purge (hard delete) "{{ title }}" ({{ code }}) from database? Document will permanently deleted from the system.', { title: doc.title, code: doc.doc_code });
    if ( confirmed ) {
      await this.server.purge('docs', doc.id);
      this.refresh();
    }
  }
}
