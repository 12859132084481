import {
  Component,
  OnInit
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  AngularGridInstance, GridOption,
  Column, FieldType,
  Filters
} from 'angular-slickgrid';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlPaginatedResult
} from "@slickgrid-universal/graphql";

import {
  NavigationService,
  SessionService,
  Taxonomy
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import {
  GraphQLServerService,
  LighthouseService
} from "@pinacono/slickgrid-extension";

import { BasePageComponent } from "src/app/classes/base-page.component";

import { Competency } from '../types';
import { TrainingService } from '../training.service';

@Component({
  selector: 'training-competencies-list-page',
  templateUrl: 'list.html',
  styleUrls: [ 'list.scss' ]
})
export class TrainingCompetenciesListPage extends BasePageComponent implements OnInit {
  // grid
  protected gridInstance: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  public competencies: Competency[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: GraphQLServerService
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  // -- overloading

  protected silent: boolean = true;
  protected override loadData(): Promise<any> {
    if ( !! this.gridInstance ) {
      this.silent = false;
      this.gridInstance.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces
  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.server.silent() : this.server;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.competencies = re.data['competencies'].nodes.map( (r: Competency) => {
            return this.api.createCompetency(r);
          });
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.log('GraphQL Error:', error);
        }
      );
    });
  }

  protected initGrid() {
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'competencies',
          /*
          //filteringOptions: [
          persistenceFilteringOptions: [
            //{ field: 'uid', operator: 'EQ', value: this.user.id.toString() }
          ],
          */
          paginationOptions: {
            first: 20
          }
        },

        process: this.processGraphQLQuery.bind(this),
      },
      enableSorting: true,
      //rowHeight: 80,
      forceFitColumns: true,
      enableAutoResize: true,
      autoResize: {
        calculateAvailableSizeBy: 'container',
        minHeight: 400,
      },
      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };

    this.columnDefinitions = [
      {
        id: 'code', name: 'Code',
        field: 'code',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150, maxWidth: 200,
        sortable: true,
        filterable: true
      },

      {
        id: 'name', name: 'Name',
        field: 'name',
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 300,
        sortable: true,
        filterable: true
      },

      {
        id: 'category', name: 'Category',
        //field: 'categories.name',
        field: 'categories',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 100, maxWidth: 100,
        sortable: true,
        filterable: true,
        filterSearchType: FieldType.string,
        filter: {
          model: Filters.multipleSelect,
          collection: [
            { value: 'E', label: 'Elective'   },
            { value: 'F', label: 'Functional' },
            { value: 'C', label: 'Common'     },
            { value: 'S', label: 'Soft Skill' }
          ]
        },
        /*
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          return dataContext["categories"].map( (c:Taxonomy) => c.name ).join(', ');
        }.bind(this)
        */
      }
    ];
  }

  // -- grid interfaces
  public onGridReady(event: Event) {
    this.gridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    let competency = this.competencies[(event as CustomEvent).detail.args['row']];
    //this.nav.push('training-competency-page', {id: competency.id}, null, this.navDone);
    this.nav.push(['/training/competencies/view', competency.id ]);
  }

  // -- internal API
}