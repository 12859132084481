<full-page [title]="opl.title" #page>
  <div page-content>
    <panel>
      <div panel-body class="pad-all">
        <opl *ngIf="!!opl" class="blog"
          [content]="opl"
          [format]="'full'"
          (onBrowse)="back()"
          (onQuickEdit)="qedit($event)"
          (onEdit)="edit($event)"
          (onTrash)="trash($event)"
          (onFlag)="flag($event)"
          (onUnFlag)="unflag($event)"
        >
        </opl>
      </div>
    </panel>
  </div>
</full-page>