/**
 * Redirector Page Module
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServerService } from '@pinacono/common';

import { ThemesModule } from 'src/app/themes/module';

import { ENDPOINTS } from './endpoints';

import { RedirectorService } from './redirector.service';
import { RedirectorComponent } from './redirector.component';

/**
 * module declaration
 */
 @NgModule({
  imports: [
    CommonModule,

    TranslateModule.forChild(),
    ThemesModule
  ],
  declarations: [
    RedirectorComponent
  ],
  providers: [
    RedirectorService
  ],
  exports: [
    //TranslateModule,
    RedirectorComponent
  ]
 })

 export class RedirectorModule {
   constructor(server: ServerService) {
     server.registerEndpoints(ENDPOINTS);
   }
 }
