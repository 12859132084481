import {
  Component
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { SearchPageComponent } from '../../../themes/components/search-page/search-page.component';
import {
  ObjectUtilsService,
  PaginatedESResult,
  ServerService
} from '@pinacono/common';

import { SearchResultEntry } from 'src/app/themes/types';

import { Document } from '../types';

@Component({
  selector: 'doc-search-page',
  templateUrl: '../../../themes/components/search-page/search-page.component.html',
  styleUrls: [ '../../../themes/components/search-page/search-page.component.scss' ]
})
export class DocLibSearchPage extends SearchPageComponent {

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected server: ServerService,
    protected objectUtils: ObjectUtilsService
  ) {
    super(router, activatedRoute);
  }

  protected override loadData(): Promise<any> {
    let keyword = this.activatedRoute.snapshot.paramMap.get('keyword');
    if ( keyword !== null ) {
      this.search(keyword);
    }
    return Promise.resolve();
  }

  // -- perform search
  protected override doSearch(keyword: string, pageno: number, perpage: number) {
    this.keyword = keyword;
    //this.server.request('search', null, {
    this.server.search('docs', this.keyword, {
        status: 'published'
      }, {
      pageno: pageno,
      perpage: perpage
    })
    .then(
      (res: PaginatedESResult<Document>) => {
        this.total   = res.total.value;
        this.pageno  = res.pageno;
        this.perpage = res.perpage;
        this.elapsed = res.header.took / 1000; // convert to sec
        this.results = [];

        for ( let row of res.data ) {
          let r: SearchResultEntry = {
            title: row.doc.title,
            teaser: row.doc.content,
            url: `/#/doc/view/${row.doc['id']}`,
            document: row.doc,
            highlight: this.objectUtils.toArray(row.highlight)
          };
          this.results.push(r);
        }
      },
      ( reason: any ) => {
        console.error('Search service return error', reason);
      });
  }
}
