import {
  Component,
  Input,
  Output, EventEmitter,
  ViewChild, ViewChildren, QueryList,
  OnChanges, SimpleChanges//, ViewEncapsulation
} from "@angular/core";

import {
  AttachmentsComponent,
  UIService
} from "@pinacono/ui";

import { AppCommonService } from "../../app-common.service";

import {
  ExamQuestion,
  ExamChoice
} from "../../types";


@Component({
  selector: 'quiz-editor',
  templateUrl: 'quiz-editor.html',
  styleUrls: [ 'quiz-editor.scss' ],
  //encapsulation: ViewEncapsulation.None
})
export class QuizEditorComponent implements OnChanges {

  @Input()  question: ExamQuestion;
  @Output() questionChange = new EventEmitter<ExamQuestion>();

  @Output() onChoiceDeleted = new EventEmitter<ExamChoice>();

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave   = new EventEmitter<ExamQuestion>();

  @ViewChild('questionMedia')  questionMedia!: AttachmentsComponent;
  @ViewChildren('choiceMedia') choiceMedia!: QueryList<AttachmentsComponent>;
  @ViewChild('newChoiceMedia') newChoiceMedia!: AttachmentsComponent;

  public newChoice: ExamChoice;

  public gallery_options = {
    tiles_col_width: 290
  }

  // -- lifecycle

  constructor(
    protected ui: UIService,
    protected api: AppCommonService
  ) {
    this.question = this.api.createExamQuestion();
    this.newChoice = this.api.createExamChoice();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['question'] ) {
      this.question  = this.api.createExamQuestion(this.question);
      this.newChoice = this.api.createExamChoice();
    }
  }

  // -- template API

  public onQuestionChange() {
    this.questionChange.emit(this.question);
  }

  public add() {
    /*
    if ( this.newChoice.content.length == 0 ) {
      this.nifty.alert("Content cannot be empty!");
      return;
    }
    */
    /*
    this.newChoice.attachments.forEach( a => {
      a.id = a.id || -1;
    });
    */
    this.question.choices.push(this.newChoice); // @TODO - newChoice should be saved before, otherwise, the deferred attachment will be messed up according to shared session
    this.newChoice = this.api.createExamChoice();
    this.questionChange.emit(this.question);
  }

  public remove(index: number) {
    this.ui.confirm("Remove the selected choice?", undefined, () => {
      let deletedChoices = this.question.choices.splice(index, 1);
      deletedChoices.forEach( c => {
        this.onChoiceDeleted.emit(c);
      });
      this.questionChange.emit(this.question);
    });
  }

  public cancel() {
    this.ui.confirm("Cancel editing this question?", undefined, () => {
      this.onCancel.emit();
    });
  }

  public save() {
    if ( this.question.content.trim().length == 0 ) {
      this.ui.alert('Question can not be empty!');
      return;
    }

    if ( this.newChoice.content.trim().length > 0 ) {
      this.add();
    }

    if ( this.question.choices.length < 4 ) {
      this.ui.alert('At least 4 choices are required!');
      return;
    }

    let correct: number = this.question.choices.map( c => (c.is_correct ? 1 : 0) as number )
                            .reduce( (count, correct) => count + correct, 0 );

    if ( correct <= 0 ) {
      this.ui.alert('At least 1 correct choice is required!');
      return;
    }

    if ( this.question.choices.length - correct < 3 ) {
      this.ui.alert('At least 3 incorrect choice is required!');
      return;
    }

    // trig saving process
    this.onSave.emit(this.question);
  }
}