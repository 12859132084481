<full-page [title]="'Training Record'">
  <div *ngIf="!!record" page-content>
    <panel class="pad-all">
      <div panel-control>
        <!--
        <button class="btn btn-success btn-icon">
          <i class="pli-upload-to-cloud"></i> Action
        </button>
        -->
      </div>
      <!--
      <div panel-heading>
        {{ 'My Training Records' | translate }}
      </div>
      -->
      <div panel-body>

        <div class="row">

          <div class="col-xs-12 col-sm-3 user-info">
            <user-profile *ngIf="record.user"
              [user]="record.user"
              [editable]="[]"
              [disable]="['extension', 'mobile', 'mobile2', 'join-date', 'last-login', 'agreements']">
            </user-profile>
          </div>

          <div class="col-xs-12 col-sm-9">

            <div class="row section">
              <!--
              <div class="col-xs-12 col-sm-2">
                <label>ID:</label>{{ record.id }}
              </div>
              -->
              <div class="col-xs-6 col-sm-3">
                <label>{{ 'Apply Date' | translate }}:</label>
                {{ (record.applied_at | datetime) || 'n/a' }}
              </div>
              <div class="col-xs-6 col-sm-3">
                <label>{{ 'Closed Date' | translate }}:</label>
                {{ (record.closed_at | datetime) || 'n/a' }}
              </div>
              <div class="col-xs-6 col-sm-3">
                <label>{{ 'Deadline' | translate }}:</label>
                {{ (record.plan?.dead_line | datetime) || 'n/a' }}
              </div>
              <div class="col-xs-5 col-sm-3">
                <label>{{ 'Status' | translate }}</label>
                <span class="badge badge-{{ statusCssClasses[record.status] }}">{{ record.status }}</span>
              </div>
            </div>

            <div class="row section">
              <div class="col-xs-12">
                <label>{{ 'Course' | translate }}:</label>
                <div class="record-info">
                  {{ (record.batch?.course?.code) || 'n/a' }} - {{ (record.batch?.course?.name) || 'n/a' }}
                </div>
              </div>
            </div>

            <div class="row section">
              <div class="col-xs-12">
                <label>{{ 'Competency' | translate }}</label>
                <ul>
                  <li *ngFor="let level of record.batch?.course?.levels">
                    <a class="btn-link" (click)="onCompetencyClick(level)">
                      {{ level.competency?.code || 'n/a' }} - {{ level.competency?.name || 'n/a' }} ({{ ( level.level - 1 ) || 0 }} <i class="pli-right"></i> {{ level.level || 0 }})
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row section">
              <div class="col-xs-12">
                <label>{{ 'Batch' | translate }}:</label>
                <div class="record-info">
                  {{ (record.batch?.name) || 'n/a' }}
                </div>
              </div>
            </div>

            <!-- objectives -->
            <div class="row section">
              <div class="col-xs-12 objectives">
                <div class="panel panel-primary">

                  <div class="panel-heading">
                    <div class="panel-control">
                      <ul class="nav nav-tabs">
                        <li *ngFor="let obj of record.objectives; let i = index" class="nav-item">
                          <button class="nav-link"
                            data-bs-toggle="tab"
                            [attr.data-bs-target]="'#obj-' + i"
                            [ngClass]="{ 'active': i == 0 }"
                          >
                            Objective #{{ i + 1 }}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <label class="panel-title">{{ 'Objectives' | translate }}</label>
                  </div>

                  <div class="panel-body">
                    <div class="tab-content">

                      <div *ngFor="let obj of record.objectives; let i = index"
                        id="obj-{{ i }}"
                        class="tab-pane obj-description"
                        [ngClass]="{
                          'active': i == 0,
                          'fade': i != 0
                        }"
                      >
                        <!-- objective description -->
                        <div class="row">
                          <div class="text-thin result col-xs-12 col-sm-3">
                            <label>{{ 'Status' | translate }}:</label>
                            <div>
                              <i class="text-{{ statusCssClasses[obj.result] }} fa fas"
                                [ngClass]="{
                                  'fa-check'    : obj.result == 'passed',
                                  'fa-times'    : obj.result == 'failed',
                                  'fa-clock'    : obj.result == 'pending',
                                  'fa-walking'  : obj.result == 'progress',
                                  'fa-trash-alt': obj.result == 'cancelled'
                                }"
                              ></i>
                              <span *ngIf="! user_is_mentor" title="{{ obj.result }}">{{ obj.result | titlecase }}</span>
                              <select *ngIf="user_is_mentor" class="select-picker" [(ngModel)]="record.objectives[i].result">
                                <option value="passed"   ><i class="fa fas fa-check"></i> Passed</option>
                                <option value="failed"   ><i class="fa fas fa-times"></i> Failed</option>
                                <option value="pending"  ><i class="fa fas fa-clock"></i> Pending</option>
                                <option value="progress" ><i class="fa fas fa-walking"></i> Progress</option>
                                <option value="cancelled"><i class="fa fas fa-trash-alt"></i> Cancelled</option>
                              </select>
                            </div>
                          </div>

                          <div class="text-thin evaluations col-xs-12 col-sm-3">
                            <label>{{ 'Evaluations' | translate }}:</label>
                            <ul class="obj-list">
                              <li *ngFor="let evaluation of obj.attr['evaluations']">
                                {{ evaluation }}
                              </li>
                            </ul>
                          </div>

                          <div class="text-thin evaluators col-xs-12 col-sm-3">
                            <label>{{ 'Evaluators' | translate }}:</label>
                            <ul *ngIf="record.batch && record.batch.trainers && record.batch.trainers.length > 0" class="obj-list">
                              <li *ngFor="let evaluator of record.batch.trainers">
                                {{ evaluator.fullname }} ({{ evaluator.email}})
                              </li>
                            </ul>
                            <ul *ngIf="! record.batch?.trainers || record.batch?.trainers?.length == 0">
                              <li *ngFor="let evaluator of obj.attr['evaluators']">
                                {{ evaluator }}
                              </li>
                            </ul>
                          </div>

                          <div class="text-thin evidences col-xs-12 col-sm-3">
                            <label>{{ 'Evidences' | translate }}:</label>
                            <ul class="obj-list">
                              <!-- exam score -->
                              <li *ngIf="obj.min_score && obj.min_score > 0" class="score">
                                <div>
                                  <div class="score-label">{{ 'Minimum Score' | translate }}</div>
                                  <div class="score-value min-score">{{ obj.min_score | percent }}</div>
                                </div>
                                <div>
                                  <div class="score-label">{{ 'User Score' | translate }}</div>
                                  <div *ngIf="record.attr['score']" class="score-value actual-score">{{ record.attr['score'] / record.attr['max_score'] * 100 | number:'1.2-2' }}%</div>
                                  <div *ngIf="!record.attr['score']" class="score-value actual-score"><i>- n/a -</i></div>
                                </div>
                              </li>
                              <li *ngFor="let evidence of obj.attr['evidences']">
                                {{ evidence }}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <!-- examinations list -->
                        <div *ngIf="obj.min_score && obj.min_score > 0" class="row exam-list">
                          <div class="col-xs-12">
                            <label>{{ 'Examinations' | translate }}</label>
                            <div class="table-responsive">
                              <table class="table table-striped table-vcenter">
                                <thead>
                                  <tr>
                                    <th class="text-right">{{'ID' | translate}}</th>
                                    <th class="text-right">{{'Examination Date' | translate}}</th>
                                    <th class="text-right">{{'Time Spent' | translate}}</th>
                                    <th class="text-center">{{'Score' | translate}}</th>
                                    <th class="text-center">{{'Result' | translate}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let exam of examinations" class="exam-entry" (click)="viewExam(exam)">
                                    <td class="text-right">{{ exam.id }}</td>
                                    <td class="text-right">{{ exam.updated_at | datetime }}</td>
                                    <td class="text-right">{{ exam.time_spent | interval:'mm:ss' }}</td>
                                    <td class="text-center">{{ exam.score }} / {{ exam.max_score }} = {{ exam.score / exam.max_score | percent }}</td>
                                    <td class="text-center text-bold" [ngClass]="{
                                      'text-success': exam.result == 'passed',
                                      'text-info':    exam.result == 'n/a',
                                      'text-danger':  exam.result == 'failed'
                                    }">
                                      {{ exam.result }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div *ngIf="user_is_owner" class="pad-all">
                              <button
                                class="pull-right btn btn-default btn-labeled"
                                title="{{ 'Take new Examination' | translate }}"
                                (click)="createExam()"
                                [attr.disabled]="next_exam > 0 ? 1 : null"
                              >
                                <i class="btn-label pli-diploma-2 icon-lg icon-fw"></i> {{ 'New Examination' | translate }}
                              </button>
                            </div>
                          </div>
                        </div>

                        <!-- reference documents -->
                        <div class="row">
                          <div class="text-thin col-xs-12 col-sm-6 ref-docs">
                            <!-- reference docs, e.g., work order number, etc-->
                            <label>{{ 'Reference Documents' | translate }}:</label>
                            <list-editor *ngIf="user_is_owner"
                              [(list)]="obj.attr['ref_doc']"
                              [name]="'ref-doc'"
                              [enableLookup]="false"
                              (listChange)="setRefDoc( obj, $event )"
                            >
                              <ng-template #itemTemplate
                                let-item="item"
                                let-index="index"
                              >
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-addon">
                                      <!--
                                      <select class="form-control" name="ref-type" [(ngModel)]="obj['attr'].ref_doc[index].type">
                                        <option *ngFor="let o of refTypes" value="{{ o.value }}">{{ o.label }}</option>
                                      </select>
                                      -->
                                      <ngx-select required
                                        name="reftype"
                                        [items]="refTypes"
                                        [optionValueField]="'value'"
                                        [optionTextField]="'label'"
                                        [(ngModel)]="obj['attr']['ref_doc'][index].type"
                                      >
                                      </ngx-select>
                                    </div>
                                    <input class="form-control" name="ref-id" type="text" placeholder="Reference ID" [(ngModel)]="obj['attr']['ref_doc'][index].id">
                                  </div>
                                </div>
                              </ng-template>

                              <ng-template #newItemTemplate
                                let-item="item"
                              >
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-addon">
                                      <!--
                                      <select class="form-control" name="ref-type" [(ngModel)]="item.type">
                                        <option *ngFor="let o of refTypes" value="{{ o.value }}">{{ o.label }}</option>
                                      </select>
                                      -->
                                      <ngx-select required
                                        name="reftype"
                                        [items]="refTypes"
                                        [optionValueField]="'value'"
                                        [optionTextField]="'label'"
                                        [(ngModel)]="item.type"
                                      >
                                      </ngx-select>
                                    </div>
                                    <input class="form-control" name="ref-id" type="text" placeholder="Reference ID" [(ngModel)]="item.id">
                                  </div>
                                </div>
                              </ng-template>

                            </list-editor>

                            <div *ngIf="! user_is_owner">
                              <ul>
                                <li *ngFor="let item of obj.attr['ref_doc']">
                                  <a class="clickable" (click)="openLink(item)">
                                    <span class="ref_type">{{ getRefType(item.type)?.label || 'unknown (' + item.type +')' }}</span> -
                                    <span class="ref_id">{{ item['id'] }}</span>
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <!--
                            <textarea *ngIf="user_is_owner"
                              class="form-control"
                              style="resize: none;"
                              placeholder="Enter reference work order numbers"
                              [(ngModel)]="record.attr['ref_doc']"
                              autosize
                            ></textarea>
                            <pre *ngIf="! user_is_owner">{{ record.attr['ref_doc'] }}</pre>
                            -->
                          </div>

                          <div class="text-thin col-xs-12 col-sm-6 attachments">
                            <!-- attachments -->
                            <label>{{ 'Attachments' | translate }}:</label>
                            <ng-container *ngIf="! user_is_owner && ! user_is_mentor">
                            <div *ngIf="obj.attachments.length > 0" class="d-flex flex-column">
                              <!--
                              <gallery class="img-responsive" [items]="obj.attachments" [layout]="'plain'"></gallery>
                              -->
                              <download *ngFor="let file of obj.attachments" [file]="file"></download>
                            </div>
                            <div *ngIf="obj.attachments.length == 0">
                              <div class="text-thin text-center">- No attachments avaialble -</div>
                            </div>
                            </ng-container>
                            <attachments *ngIf="user_is_owner || user_is_mentor" #attachments
                              [(files)]="obj.attachments"
                              [master-content-type]="'training_batch_objective'"
                              [master-id]="obj.id"
                              [defer-key]="obj.attachment_defer_key"
                            >
                            </attachments>
                          </div>
                        </div>

                        <!-- comments -->
                        <div class="row comments-container">
                          <div class="col-xs-12">
                            <label>{{ 'Comments' | translate }}</label>
                            <comments
                              [content]="obj.comments"
                              [content_id]="obj.id||null"
                              [content_type]="'training_batch_objective'"
                              [readonly]="false">
                            </comments>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- end of objectives -->

            <div class="row section">
              <div class="col-xs-12">
                <label>{{ 'Logs' | translate }}</label>
                <ul *ngIf="record.logs && record.logs.length > 0" class="notes">
                  <li *ngFor="let log of record.logs" class="note-entry">
                    <span class="timestamp">{{ log.created_at | datetime }}</span> - <span class="message">{{ log.message }}</span>
                  </li>
                </ul>
                <div *ngIf="! record.logs || record.logs.length == 0" class="text-thin text-italic notes">
                  - n/a -
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div panel-footer>
        <div class="row">

          <!-- left -->
          <div class="col-xs-12 col-sm-6 footer-items pad-btm">
          </div>

          <!-- right -->
          <div class="col-xs-12 col-sm-6 text-right footer-items">
            <button type="button" class="btn btn-labeled btn-dark" (click)="back()">
              <i class="btn-label fa fas fa-chevron-circle-left"></i>{{ 'Back' | translate }}
            </button>
            <button *ngIf="user_is_mentor || user_is_owner" type="button" class="btn btn-labeled btn-primary" (click)="save()">
              <i class="btn-label fa far fa-save"></i>{{ 'Save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</full-page>

<!-- examination answer sheet -->
<modal
  [hideCloseButton]="true"
  [size]="'large'"
  #examAnswerSheet
>
  <div modal-title>
    {{ 'Answer Sheet' | translate }} ({{ answer_sheet && answer_sheet.id || 'new' }})
  </div>
  <div modal-body>
    <div *ngIf="!! answer_sheet" class="answer-sheet">
      <div class="text-right text-thin text-muted">
        <div>
          <label>{{ 'Date' | translate }}:</label> <span class="text-thin">{{ answer_sheet.created_at | datetime }}</span>
        </div>
        <div>
          <label>{{ 'Time Spent' | translate }}:</label> <span class="text-thin">{{ answer_sheet.time_spent | interval:'mm:ss' }}</span>
        </div>
        <div>
          <label>{{ 'Score' | translate }}:</label> <span class="text-thin">{{ answer_sheet.score }} / {{ answer_sheet.max_score }}</span>
          <div class="text-thin text-sm">
            (
              <span class="score-explain">{{ 'Correct Answers' | translate }} = {{ answer_sheet.score_explanations.correct }} pts.</span>
              <span class="score-explain">{{ 'Incorrect Answers' | translate }} = {{ answer_sheet.score_explanations.incorrect }} pts.</span>
              <span class="score-explain">{{ 'No Answers' | translate }} = {{ answer_sheet.score_explanations.no_answer }} pts.</span>
              <span class="score-explain">{{ 'Time Limit Exceed' | translate }} = {{ answer_sheet.score_explanations.time_limit_exceed }} {{ 'minute(s)' | translate }}</span>
              <span class="score-explain">{{ 'Time Limit Penalty' | translate }} = {{ answer_sheet.score_explanations.time_limit_penalty }} pts.</span>
            )
          </div>
        </div>
      </div>
      <div *ngIf="!! answer_sheet.answers[current_question]" class="question pad-all mar-all">
        <div class="question-group mar-btm">
          <p class="question-content text-lg text-semibold">{{ current_question + 1 }}. {{ answer_sheet.answers[current_question].answer.content }}</p>
          <div class="text-center">
            <gallery *ngIf="answer_sheet.answers[current_question].answer.attachments!.length > 0"
                class="img-responsive text-center"
                [items]="answer_sheet.answers[current_question].answer.attachments!"></gallery>
          </div>
        </div>
        <div class="choices-group row">
          <div class="col-xs-12 col-sm-6" *ngFor="let choice of answer_sheet.answers[current_question].choices" [ngClass]="{ 'correct': choice.is_correct }">
            <div class="radio">
              <label>
                <i class="far {{ answer_sheet.answers[current_question].answer && choice.id == answer_sheet.answers[current_question].answer.id ? 'fa-times-circle selected' : 'fa-circle' }}"></i>
                <span class="choice-content">{{ choice.content }}</span>
              </label>
              <div class="text-center">
                <gallery *ngIf="choice.attachments && choice.attachments.length > 0" class="img-responsive" [items]="choice.attachments"></gallery>
              </div>
            </div>
          </div>
        </div>
        <div class="result">
          <i class="fa-5x {{ answer_sheet.answers[current_question].answer && is_correct(answer_sheet.answers[current_question].choices, answer_sheet.answers[current_question].answer.id) ? 'text-success fas fa-check' : 'text-danger fas fa-times' }}"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!! answer_sheet" modal-footer>
    <button *ngIf="current_question > 0" stop-propagation (click)="prevQuestion()" title="{{ 'Previous' | translate }}" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa fas fa-arrow-left"></i> {{ 'Previous' | translate }}
    </button>
    <button *ngIf="current_question < answer_sheet.answers.length - 1" stop-propagation (click)="nextQuestion(answer_sheet.answers.length - 1)" title="{{ 'Next' | translate }}" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa fas fa-arrow-right"></i> {{ 'Next' | translate }}
    </button>
    <button class="btn btn-default" type="button" (click)="examAnswerSheet.hide()">
      <i class="btn-label icon-lg icon-fw fa fas fa-times-circle"></i>{{ 'Close' | translate }}
    </button>
  </div>
</modal>

<modal *ngIf="show_exam"
  [size]="'large'"
  (onDismiss)="dismissExam()"
  #examDialog
>
  <div modal-body>
    <exam *ngIf="record && record.batch"
      [course]="record.batch.course"
      (saved)="onExamSaved($event)"
    ></exam>
  </div>
</modal>