<full-page [page-title]="'User Profile'">
  <div page-content>
    <panel [ngClass]="{ 'editable' : editable }">
      <div panel-heading>
        <h4>{{ 'User Profile' | translate }}</h4>
      </div>
      <div panel-body>
        <div *ngIf="!!account" class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 pad-ver">
            <user-profile [user]="account" [editable]="editable" (saved)="onSave($event)" [ngClass]="{ 'deactivated' : account.deleted }">
              <ng-container *ngIf="account.deleted">
                <i class="pli-lock-user icon-lg icon-fw"></i>
                <span>{{ 'This account has been deactivated' | translate }}</span>
              </ng-container>
            </user-profile>
          </div>
          <div id="treant-container" class="col-xs-12 col-sm-12 col-md-6" [ngClass]="{ 'full-screen' : maximized }">
            <button id="treant-toggle" class="btn btn-default btn-active-success" title="{{ 'Click to maximize/minimize view' | translate }}" (click)="toggleTreant()">
              <i class="pli-full-screen-2"></i>
            </button>
            <treant [mapping]="user2chart_mapper" [root]="team" (onClick)="clickUserProfile($event)"></treant>
          </div>
        </div>
      </div>
    </panel>

  </div>
</full-page>