import {
  Component,
  OnChanges, SimpleChanges,
  Input,
  Output, EventEmitter
} from '@angular/core';

import {
  ServerService,
  SessionService
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

import {
  JobDescription,
  JobCompetency
} from '../../types';
import { TrainingService } from '../../training.service';

@Component({
  selector: 'job-description',
  templateUrl: 'job-description.html',
  styleUrls: [ 'job-description.scss' ]
})
export class JobDescriptionComponent implements OnChanges {
  @Input() id!: number;
  @Input() show_attachments?: boolean = false;
  @Input() show_competencies?: boolean = false;

  @Output() onCompetencySelected = new EventEmitter<number>();

  public data: JobDescription|null = null

  // -- lifecycle
  constructor(
    protected server: ServerService,
    protected session: SessionService,
    protected ui: UIService,
    protected api: TrainingService
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['id'] && this.id ) {
      let associated: string[] = [
      ];

      this.server.show('training/jobs', this.id, { with: associated.join(',') })
      .then( (j: object) => {
        this.data = this.api.createJobDescription(j);
      });
    }
  }

  // -- template API

  public selectCompetency(competency: JobCompetency) {
    this.onCompetencySelected.emit(competency.competency.id);
  }
}