<full-page [title]="'Team'">
  <div page-content>
    <panel>
      <div panel-heading>
        <div class="row">
          <div class="col-xs-12 text-right">
            <button (click)="popupCompareDialog()" class="btn btn-primary btn-labeled" [title]="'Gaps Comparator'">
              <i class="btn-label pli-engineering"></i> {{ 'Compare Gaps' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div panel-body>
        <div id="teams">
          <label class="control-label text-bold">{{ 'Select Team' | translate }}</label>
          <tree class="bg-trans pad-btm"
            [options]="{
              core: {
                themes: {
                  name: 'proton',
                  icons: false,
                  dots: true
                },
                multiple: true
              },
              checkbox: {
                three_state: false,
                cascade: 'down'
              }
            }"
            [data]="teams"
            [expand]="true"
            [(selected)]="selectedTeams"
            (selectedChange)="selectTeam($event)"
          >
          </tree>
        </div>

        <div id="members">
          <div class="row">
            <div *ngFor="let user of users" class="col-xs-12 col-sm-6 col-md-4 user-info-card">
              <input type="checkbox" (change)="add(user, $event)" [attr.checked]="isChecked(user)">
              <user-card [user]="user" (profile)="view($event)">
                <div user-profile>
                  <p class="text-sm text-bold">{{ user.profiles['job_title'] }}</p>
                </div>
              </user-card>
              <!--
              <button class="btn btn-default btn-icon" (click)="view(user)">
                <i class="pli-magnifi-glass"></i>
              </button>
              -->
            </div>
          </div>
        </div>
      </div>
      <div panel-footer>
      </div>
    </panel>
  </div>
</full-page>

<!-- batch editor -->
<modal template-id="job-selector" [hideCloseButton]="true" [size]="'medium'" #jobSelectorModal>
  <div modal-body>
    <div class="full-width">
      <label class="control-label text-bold">{{ 'Candidates' | translate }}:</label>
      <ul>
        <li *ngFor="let candidate of candidates">{{ candidate.fullname }} ({{ candidate.profiles['job_title'] }})</li>
      </ul>
    </div>
    <div class="full-width">
      <label class="control-label text-bold">{{ 'Job' | translate }}:</label>
      <ul>
        <li class="text-thin">{{ ( job && job.title ) || '- not selected -' }}</li>
      </ul>
    </div>
    <div class="full-width form-inline">
      <label class="control-label text-bold">{{ 'Select Job' | translate }}:</label>
      <lookup-input class="lookup mt-2"
        [placeholder]="'Enter job title here...'"
        [minChar]="3"
        [autoClose]="false"
        [items]="jobs_list"
        (onLookup)="lookupJobTitle($event)"
        (onSelect)="selectJobTitle($event)"
      >
      </lookup-input>
    </div>
    <div class="full-width text-right pad-top mar-top">
      <button (click)="compare()" class="btn btn-primary btn-labeled" [title]="'Gaps Comparator'">
        <i class="btn-label pli-engineering"></i> {{ 'Compare Gaps' | translate }}
      </button>
    </div>
  </div>
</modal>