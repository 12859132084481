import {
  Component,
  OnInit
} from "@angular/core";
import {
  ActivatedRoute,
  Router
} from '@angular/router';

import {
  NavigationService,
  object_t,
  PaginatedResults,
  Pagination,
  ServerService,
  SessionService
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { OPL } from '../types';

import { OplService } from '../opl.service';

@Component({
  selector: 'opl-browse',
  templateUrl: 'browse.html',
  styleUrls: [ 'browse.scss' ]
})
export class OplBrowsePage extends BasePageComponent implements OnInit {

  public opls: OPL[] = [];
  public pagination: Pagination = {
    pageno: 0,
    perpage: 10,
    total: 0,
    sorting: undefined
  }

  public name: string | null = null;
  public keyword: string|null = null;

  protected filter: object_t|null = null;

  // for full view and editing
  public opl: OPL|null   = null;
  public new_opl: string = '';

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    protected ui: UIService,
    protected server: ServerService,
    protected session: SessionService,
    protected oplService: OplService
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    this.new_opl = '';
    this.keyword = this.activatedRoute.snapshot.queryParamMap.get('keyword') || '';
    super.ngOnInit();
  }

  // -- overloading

  protected override loadData(): Promise<any> {
    this.opls = [];

    this.name = this.activatedRoute.snapshot.paramMap.get('name');
    if ( ! this.name ) {
      this.name = 'home'
    }

    let filter: object_t|null = null;
    if ( !! this.filter ) {
      filter = this.filter;
    }
    else if ( !! this.keyword ) {
      filter = { keyword: this.keyword }
    }
    else {
      filter = { name: this.name }
    }
    return this.server.index('opls', filter, this.pagination)
    .then( (res: PaginatedResults<object_t[]>) => {
      this.filter = null; // reset filter
      this.pagination.pageno  = res.pageno;
      this.pagination.perpage = res.perpage;
      this.pagination.total   = res.total;
      this.opls = res.data.map( r => this.oplService.create(r) );
    });
  }

  // -- template API

  public search() {
    this.pagination.sorting = undefined;
    this.loadPage();
 }

  public loadPage( pageno: number|null = null ) {
    this.pagination.pageno = pageno || this.pagination.pageno;
    this.loadData()
  }

  public browse(filter: object_t|null = null) {
    this.filter = null;
    if ( !! filter ) {
      if ( !! filter['keyword'] ) {
        this.keyword = filter['keyword'];
      }
      else  if ( !! filter['name'] ) {
        this.name = filter['name'];
      }
      else {
        this.filter = filter;
      }
    }
    this.loadPage();
  }

  public open(opl: OPL) {
    this.nav.push({ commands: [ '/opl/view', opl.id ] });
  }

  public create() {
    this.nav.push({ commands: [ `/opl/edit/new`, { content: this.new_opl } ] });
  }

  public edit(opl: OPL) {
    if ( opl.status == 'approved' ) {
      this.ui.confirm('Editing approved OPL will required re-approval from manager. Proceed?', undefined, () => {
        this.nav.push({ commands: [ '/opl/edit', opl.id ] });
      });
    }
    else {
      this.nav.push({ commands: [ '/opl/edit', opl.id ] });
    }
  }

  public qedit(opl: OPL) {
    this.nav.push({ commands: [ '/opl/edit', opl.id, { quick: true } ] });
  }

  public trash(opl: OPL) {
    if ( ! opl.id ) {
      this.refresh();
      return;
    }
    this.server.destroy('opls', opl.id).then( () => {
      this.refresh();
    });
  }

  public flag(opl: OPL) {
    this.server.request('opl.flag', {
      id: opl.id,
      gid: this.session.currentUser?.primary_domain?.id || 0
    })
    .then( () => {
      this.refresh();
    });
  }

  public unflag(opl: OPL) {
    this.server.request('opl.unflag', {
      id: opl.id,
      gid: this.session.currentUser?.primary_domain?.id || 0
    })
    .then( () => {
      this.refresh();
    });
  }
}
