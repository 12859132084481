import {
  Component,
  Input,
  ViewChild, ElementRef, Renderer2,
  OnChanges, SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { config } from '@pinacono/common';

@Component({
  selector: 'kibana',
  template: '<iframe #frame [src]="safeUrl" (load)="frameReady()"></iframe>',
  styleUrls: ['./kibana.component.scss']
})
export class KibanaComponent implements OnChanges {

  @Input() name: string = '';
  @ViewChild('frame') frame!: ElementRef<HTMLIFrameElement>;

  public safeUrl!: SafeResourceUrl;

  public constructor(
    protected sanitizer: DomSanitizer,
    protected renderer: Renderer2
  ) {
  }

  public frameReady() {
    if ( ! this.frame || ! this.frame.nativeElement.contentWindow ) {
      console.warn('Frame is not ready. Auto-resize cannot be proceed.');
      return;
    }

    // resize iframe to fit it's content. keep observing the height
    // until it reach the maximum height.
    let maxHeight: number = 0;
    let observer = new MutationObserver( (list: MutationRecord[], observer: MutationObserver) => {
      let h: number = ( this.frame.nativeElement.contentWindow && this.frame.nativeElement.contentWindow.document.body.scrollHeight ) || 0;
      if ( h > maxHeight ) {
        this.frame.nativeElement.style.height = h + "px";
        maxHeight = h;
      }
      else {
        observer.disconnect();
      }
    });

    observer.observe(this.frame.nativeElement.contentWindow.document.body, {
      childList: true,
      subtree: false
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ( changes['name'] ) {
      this.getURL(this.name);
    }
  }

  protected getURL(name: string) {
    let url: string = config(`client.dashboard.kibana.urls.${name}`, 'about:blank');
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
