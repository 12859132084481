import {
  object_t,
  Attachment,
  Pagination,
  User
} from '@pinacono/common';

import {
  Document,
  Revision,
  HardcopyStatus,
  HardcopyLocation
} from 'src/app/modules/documents/types';

/**
 * types for Library module
 */

export const BROWSER_DT_FORMAT = 'yyyy-MM-dd';

export interface LibraryStatus {
  branch: string;
  is_opened: boolean;
}

export enum EquipmentStatus {
  available    = 'available',
  checked_out  = 'checked_out',
  in_progress  = 'in_progress',
  reserved     = 'reserved',
  in_repair    = 'in_repair',
  lost         = 'cancelled/lost',
  library_use_only = 'library_use_only',
  other_location   = 'other_glocation'
}

export enum ReservationStatus {
  requesting = 'requesting',
  approved   = 'approved',
  delivered  = 'delivered',
  returned   = 'returned',
  //extended   = 'extended',
  cancelled  = 'cancelled'
}

export const ReservationStatusActionMapping = {
  requesting: 'request',
  approved:   'approve',
  delivered:  'deliver',
  returned:   'return',
  lost:       'lost',
  cancelled:  'cancel'
}

export interface EquipmentLocation {
  branch: string;
  cabinet: string;
  shelf: string;
}

export interface Book extends Document {
  covers: Attachment[];

  instances?: BookInstance[];
  instances_pagination?: Pagination;
  instances_available: number;

  reservations?: BookReservation[];
  reservations_pagination?: Pagination;
}

// map HardcopyStatus to label
export const InstanceStatusMapping = {
  available: 'Available',
  checked_out: 'Checked Out',
  in_progress: 'In Progress',
  reserved: 'Reserved',
  in_repair: 'In Repair',
  cancelled: 'Cancelled',
  lost: 'Lost',
  library_use_only: 'Library use only',
  other_location: 'Other Location'
};

export interface BookInstance {
  id?: number;
  code: string;
  status: HardcopyStatus;
  location: HardcopyLocation;

  attr: object_t;

  book?: Book;

  reservations?: BookReservation[];
  reservations_pagination?: Pagination;

  created_at?: string; // ISO8601
  updated_at?: string; // ISO8601
  deleted_at?: string; // ISO8601
}

export interface BookReservation {
  id?: number;

  book: Book;
  book_id: number;

  instance?: BookInstance;
  instance_id?: number;

  extended: boolean;

  user: User;
  user_id?: string;
  status: ReservationStatus;

  request_date?: string;   // YYYY-MM-DD
  require_before?: string; // YYYY-MM-DD

  approve_date?: string;   // YYYY-MM-DD
  approved_by?: User;

  deliver_date?: string;   // YYYY-MM-DD
  delivered_by?: User;

  instance_status?: string; // OK, lost, damage, null
  return_before?: string;  // YYYY-MM-DD
  return_date?: string;    // YYYY-MM-DD
  returned_to?: User;

  fine?: number;
  note?: string;

  created_at?: string; // ISO8601
  updated_at?: string; // ISO8601
  deleted_at?: string; // ISO8601
}

export interface Equipment {
  id?: number;
  title: string;
  description: string;

  instances: EquipmentInstance[];
  reservations: EquipmentReservation[];

  attr?: object_t;

  created_at?: string; // ISO8601
  updated_at?: string; // ISO8601
  deleted_at?: string; // ISO8601
}

export interface EquipmentInstance {
  id?: number;
  equipment: Equipment;
  code: string; // barcode
  status: EquipmentStatus;
  location: EquipmentLocation;
  attr?: object_t;

  reservations?: EquipmentReservation[];

  created_at?: string; // ISO8601
  updated_at?: string; // ISO8601
  deleted_at?: string; // ISO8601
}

export interface EquipmentReservation {
  id?: number;
  equipment: Equipment;
  instance?: EquipmentInstance;
  user: User;
  status: ReservationStatus;

  request_date?: string;   // YYYY-MM-DD
  require_before?: string; // YYYY-MM-DD

  approve_date?: string;   // YYYY-MM-DD
  approve_by?: User;

  deliver_date?: string;   // YYYY-MM-DD
  deliver_by?: User;

  return_before?: string;  // YYYY-MM-DD
  return_date?: string;    // YYYY-MM-DD
  return_to?: User;

  fine?: number;
  note?: string;

  created_at?: string; // ISO8601
  updated_at?: string; // ISO8601
  deleted_at?: string; // ISO8601
}

export interface Visitor {
  id?: number;
  user?: User;
  user_id?: string;
  enter_at?: string; // ISO8601
  leave_at?: string; // ISO8601
}

export interface LibraryMember extends User {
  visits: Visitor[];
  reservations?: (BookReservation|EquipmentReservation)[];
}
