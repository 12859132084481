<full-page [title]="'Dashboard'">
  <div page-content>

    <!-- Gaps/Gaps Radar -->
    <div class="row">
      <div class="col-xs-12">
        <panel>
          <div panel-control>
            <button (click)="simulate()" class="btn btn-primary btn-labeled" [title]="'Job Simulator'">
              <i class="btn-label pli-chemical"></i> {{ 'Job Simulator' | translate }}
            </button>
          </div>
          <div panel-heading>{{ 'Competencies' | translate }}</div>
          <div panel-body>
            <div class="row">
              <div class="col-xs-12">
                <gaps-analyzer *ngIf="candidate && job_description"
                  [job]="job_description"
                  [candidates]="[candidate]"
                  [target]="'show'"
                  (onJobChange)="refresh()"
                  (onCompetencyClick)="onCompetencySelected($event)"
                >
                </gaps-analyzer>
              </div>
            </div>
          </div>
        </panel>
      </div>
    </div>

  </div>
</full-page>