import {
  Component,
  AfterViewInit,
  ViewEncapsulation
} from "@angular/core";

import { SessionService } from '@pinacono/common';
import { ThemeService } from "../../theme.service";

declare let $: any;

// -- minimal page container

@Component({
  selector: 'minimal-page',
  templateUrl: 'minimal-page.component.html',
  styleUrls: [ 'minimal-page.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class MinimalPageComponent implements AfterViewInit {
  constructor(
    public session: SessionService,
    public theme: ThemeService
  ) {
  }

  public ngAfterViewInit(): void {
    // unregister all animation events and re-initialize Nifty
    $(document).off('show.bs.dropdown', '.dropdown');
    $(document).off('hide.bs.dropdown', '.dropdown');
    $(document).trigger('nifty.ready');
  }
}