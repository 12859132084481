import {
  Component,
  OnInit,
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  NavigationService,
  object_t,
  SessionService
} from "@pinacono/common";

import {
  Column,
  FieldType,
  Filters,
  SlickGrid,
} from "@slickgrid-universal/common";

import {
  GraphqlPaginatedResult
} from "@slickgrid-universal/graphql";

import {
  AngularGridInstance,
  GridOption
} from "angular-slickgrid";

import {
  ExtendedFormatters,
  GraphQLServerService,
  LighthouseService
} from "@pinacono/slickgrid-extension";

import { UIService } from "@pinacono/ui";

import { BasePageComponent } from "src/app/classes/base-page.component";
import { TrainingService } from '../training.service';
import { TrainingRecord } from '../types';

@Component({
  selector: 'training-records-list-page',
  templateUrl: 'list.html',
  styleUrls: [ 'list.scss' ]
})
export class TrainingRecordsListPage extends BasePageComponent implements OnInit {
  // grid
  protected gridInstance: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  public records: TrainingRecord[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: GraphQLServerService
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  // -- overloading
  protected silent: boolean = true;
  protected override loadData(): Promise<any> {
    if ( !! this.gridInstance ) {
      this.silent = false;
      this.gridInstance.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces
  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.server.silent() : this.server;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.records = re.data['trainingRecords'].nodes.map( (r: TrainingRecord) => {
            return this.api.createTrainingRecord(r);
          });
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.log('GraphQL Error:', error);
        }
      );
    });
  }

  protected initGrid() {
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'trainingRecords',
          persistenceFilteringOptions: [
            { field: 'uid', operator: 'EQ', value: this.session.currentUser!.id.toString() }
          ],
          paginationOptions: {
            first: 20
          }
        },

        process: this.processGraphQLQuery.bind(this),
      },
      enableSorting: true,
      enableAutoResize: true,
      autoResize: {
        calculateAvailableSizeBy: 'container',
        //bottomPadding: 85,
        minHeight: 600
      },
      rowHeight: 70,
      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };

    this.columnDefinitions = [
      {
        id: 'course', name: 'Course',
        field: 'batch.course.name',
        fields: ['batch.course.id', 'batch.course.name', 'batch.course.code', 'batch.course.levels.level', 'batch.course.levels.competency.code', 'batch.course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 120,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          let course = dataContext.batch.course;
          return `<span title="${course.name}">${course.code} - ${course.name}</span>`;
        }.bind(this)
      },

      {
        id: 'batch_name', name: 'Batch Name',
        field: 'batch.name',
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 70,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          let batch = dataContext.batch;
          return `<span title="${batch.name}">${batch.name}</span>`;
        }.bind(this)
      },

      {
        id: 'competency', name: 'Competency',
        field: 'batch.course.levels.competency',
        fields: ['batch.course.id', 'batch.course.name', 'batch.course.code', 'batch.course.levels.level', 'batch.course.levels.competency.code', 'batch.course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 70,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          const competencies: string[] = [];
          dataContext.batch.course.levels.forEach( (l: any) => {
            competencies.push(`<span class="grid-cell-item" title="${l.competency.name}">${l.competency.code} - ${l.competency.name}</span>`);
          });
          if ( competencies.length > 0 ) {
            return competencies.join('');
          }
          return `<span class="text-muted text-thin">- no competency information -</span>`;
        }.bind(this)
      },

      {
        id: 'level', name: 'Level',
        field: 'batch.course.levels.level',
        fields: ['batch.course.id', 'batch.course.name', 'batch.course.code', 'batch.course.levels.level', 'batch.course.levels.competency.code', 'batch.course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-center', width: 30,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid) {
          // @TODO - graphQL error here!
          const course = dataContext.batch.course;
          const levels: string[] = [];
          course.levels.forEach( (l: any) => {
            levels.push(`<div class="grid-cell-item"><span>${l.level - 1}</span> <i class="fas fa-arrow-right"></i> <span>${l.level}</span></div>`);
          });
          if ( levels.length > 0 ) {
            return levels.join('');
          }
          return `<span class="text-muted text-thin">- no level information -</span>`;
        }.bind(this),
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: '',  label: 'Not Filter' },
            { value: '0', label: 'Level 0 to 1' },
            { value: '1', label: 'Level 1 to 2' },
            { value: '2', label: 'Level 2 to 3' },
            { value: '3', label: 'Level 3 to 4' }
          ]
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-center', width: 30,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
            const labels: { [name: string]: { label: string, css: string} } = {
              pending:    { label: 'Pending',     css: 'info'    },
              progress:   { label: 'In Progress', css: 'mint'    },
              cancelled:  { label: 'Cancelled',   css: 'dark'    },
              passed:     { label: 'Passed',      css: 'success' },
              failed:     { label: 'Failed',      css: 'danger'  }
            }
            let status = dataContext.status.toLowerCase();
            return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
          }.bind(this),
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: '',            label: 'Not Filter',  css: 'default' },
            { value: 'pending',     label: 'Pending',     css: 'info'    }, // user is requesting to attend the course and waiting for approval
            { value: 'in progress', label: 'In Progress', css: 'mint'    }, // course is started and in progress
            { value: 'cancelled',   label: 'Cancelled',   css: 'dark'    }, // course is cancelled - end
            { value: 'passed',      label: 'Passed',      css: 'success' }, // user passed the course - end
            { value: 'failed',      label: 'Failed',      css: 'danger'  }  // user not passed (failed) - end
          ]
        }
      },

      {
        id: 'updated_at', name: 'Last Update',
        field: 'updated_at',
        fields: [ 'updated_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 40,
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];
  }

  // -- grid interfaces
  public onGridReady(event: Event) {
    this.gridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    let record = this.records[(event as CustomEvent).detail.args['row']];
    //this.nav.push('training-record-page', {id: record.id}, null, this.navDone);
    this.nav.push(['/training/records/view/', record.id])
  }

  // -- internal API
}