<div class="container d-flex flex-row">
  <div class="groups">
    <!--
    <label class="control-label text-bold">{{ 'Select Team' | translate }}</label>
    -->
    <tree class="bg-trans pad-btm"
      [options]="{
        core: {
          themes: {
            name: 'proton',
            icons: false,
            dots: true
          },
          multiple: true
        },
        checkbox: {
          three_state: false,
          cascade: 'down'
        }
      }"
      [data]="groups"
      [expand]="true"
      [(selected)]="selectedGroups"
      (selectedChange)="selectGroup($event)"
    >
    </tree>
  </div>

  <div class="members flex-grow-1">
    <div class="toggle-select-all">
      <input type="checkbox" id="tree-select-all" class="form-check-input" [checked]="getAllMembersSelected()" (change)="toggleSelectAllUser(select_all)" #select_all>
      <label class="form-check-label" for="tree-select-all">{{ 'Check All' | translate }}</label>
    </div>

    <div class="row">
      <div *ngFor="let user of members" class="col">
        <user-card [user]="user">
          <div user-profile>
            <p class="text-sm text-bold">{{ user.profiles['job_title'] }}</p>
          </div>
        </user-card>
        <input type="checkbox" (change)="toggleSelected(user, checkbox)" [checked]="getChecked(user)" #checkbox>
      </div>
    </div>
  </div>
</div>