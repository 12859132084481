import {
  Component, OnInit,
  Input,
  OnChanges, SimpleChanges,
  Output, EventEmitter,
  ElementRef
} from "@angular/core";

import { object_t } from '@pinacono/common';

import { DocCode, GridFilterOption } from '../../types';
import { DocLibService } from "../../doclib.service";

import _ from 'lodash';

declare let $: any;

@Component({
  selector: 'doc-code-editor',
  templateUrl: 'doc-code-editor.html',
  styleUrls: [ 'doc-code-editor.scss' ]
})
export class DocCodeEditorComponent implements OnInit {
  @Input() excludes: string[] = ['OPL', 'DWG', 'PRJ']; // filter OPL, DWG and PRJ by default, since they should not be reserved via the doclib module.
  @Input() includes: string[] = [ '*' ];
  @Input() reservable_only?: boolean = true;

  @Input() hiddenComponents: string[] = [];

  @Input() model: DocCode = { a: 'ENG', b: 'SPW', c: '', d: '', e: '', f: '', g: '', id: undefined };
  @Output() modelChange = new EventEmitter<DocCode>();

  @Output() onChange = new EventEmitter<string>();

  public errors: string[] = [];
  @Output() hasError = new EventEmitter<boolean>();

  public doc_types = [];

  // -- lifecycle

  public constructor(
    public api: DocLibService,
    protected el: ElementRef
  ) {
  }

  public ngOnInit(): void {
    /** @todo - filter 'C' from segment 'd' if user do not have permission to reserve confidential doc */
    this.doc_types = this.api.names['doc_code_formatter'].f
                      .filter( (item: GridFilterOption) => this.includes.includes(item.value.toString()) || this.includes.includes('*') )
                      .filter( (item: GridFilterOption) => ! this.excludes.includes(item.value.toString()) || this.excludes.includes('*') );
  }

  // -- template API
  public docCodeChange(segment: string) {
    // change event raised before value in the model get updated
    // therefore, we validate it on the next cycle with setTimeout
    setTimeout( () => {
      this.onChange.emit(segment);

      // validate
      this.errors = [];
      const doc_code_fields = {
        a: 'สายงาน (A)',
        b: 'บริษัท (B)',
        c: 'หน่วยงาน (C)',
        d: 'ชั้นความลับ (D)',
        e: 'แหล่งเอกสาร (E)',
        f: 'ประเภทเอกสาร (F)',
        g: 'Flag (G) - 0-OMSE',
        id: 'หมายเลขเอกสาร (ID)'
      };

      for ( let n in doc_code_fields ) {
        if ( n != 'id' && ! (this.model as object_t)[n] ) {
          this.errors.push(`Code segment "${ (doc_code_fields as object_t)[n] }" is missing.`);
        }
      }
      this.hasError.emit( this.errors.length > 0 );
    });
  }
}