<full-page [title]="'Edit User'">
  <div page-content>
    <panel>
      <div panel-heading>
        {{ data.fullname || 'New User' }}
      </div>
      <div panel-body>
        <user-profile *ngIf="data" #editor
          [user]="data"
          [editable]="['*']"
          [disable]="['join-date', 'last-login', 'agreements']"
          (validated)="validateUser($event)"
        >
        </user-profile>
      </div>
    </panel>
  </div>
</full-page>