<full-page [title]="'Project Documents Library'" class="page-projlib-browse">
  <div page-content>

    <!-- Search bar -->
    <!--
    <search (keywordChange)="search($event)"></search>
    -->

    <panel>
      <div panel-heading>
        {{ title }}
      </div>
      <div panel-body>
        <extended-slickgrid *ngIf="!! gridOptions" id="container" gridId="main-table"
          class="row-selectable"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>

  </div>
</full-page>