/**
 * types for DocLib module
 */

import { INgxSelectOption } from 'ngx-select-ex';

import {
  Attachment,
  object_t,
  PaginatedResults,
  Tag,
  Taxonomy,
  TimeStampedModel,
  User
} from '@pinacono/common';

import { Comment } from 'src/app/common/types';

export interface GridFilterOption extends INgxSelectOption {
  label: string;
}

export interface DocCode {
  /*
  org: string,
  category: string,
  visibility: string,
  source: string,
  division: string,
  department: string,
  */
  a: string; // 'ENG'
  b: string; // company - SPW, SPD, SPMT, SEC
  c: string; // department
  d: string; // N/C - Non-confidential / Confidential
  e: string; // I/E - Internal / External
  f: string; // Document type
  g: string; // SOP/WI type (O, M, S, E) or 'X'
  id?: string;
}

export interface HardcopyLocation {
  branch: string;
  cabinet: string;
  shelf: string;
}

export enum HardcopyStatus {
  available    = 'available',
  checked_out  = 'checked_out',
  in_progress  = 'in_progress',
  reserved     = 'reserved',
  in_repair    = 'in_repair',
  cancelled    = 'cancelled',
  lost         = 'lost',
  library_use_only = 'library_use_only',
  other_location   = 'other_location'
}

export enum DocScheduleAction {
  review = 'review',
  expire = 'expire',
  delete = 'delete'
}

/**
 * Access Control List
 */
export interface AccessControlList extends TimeStampedModel {
  id?: number;

  accessible_type?: string;
  accessible_id?: number;

  accessor_type: 'user' | 'taxonomy' | 'default';
  accessor_id: number;
  accessor?: User| Taxonomy;
  list: boolean;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

/**
 * Document's Schedule
 */
export interface DocSchedule extends TimeStampedModel{
  id: number;
  doc_id: number;
  document?: Document;
  schedule: string; // ISO8601
  action: DocScheduleAction;
}

/**
 * Hardcopy model
 */
export interface Hardcopy extends TimeStampedModel {
  id?: number;

  location: HardcopyLocation;
  code: string; // barcode
  status: HardcopyStatus;
  attr?: object_t;

  revision_id?: number;
}

/**
 * softcopy is sub-set of hardcopy, it is used to represent data inside a zip file
 */
export interface Softcopy {
  id?: number;
  file_name: string;
  file_size: number;
  content_type: string;
  download_url: string;
}

/**
 * Document Revision Model
 */
export interface Revision extends TimeStampedModel {
  id?: number;

  revision: number;
  active: boolean;
  description?: string;

  file_id: number;
  file?: DocFile;

  //covers?: Attachment[];

  hardcopies: PaginatedResults<Hardcopy>;
  softcopies: Softcopy[];
  attachments: Attachment[];
  attachment_defer_key?: string;

  attr?: object_t;
}

export interface DocFile extends TimeStampedModel {
  id: number;
  title: string;
  description: string;
  acls: AccessControlList[];
  revisions: PaginatedResults<Revision>;
  active_revisions: Revision[];
  attr?: object_t;
}

export interface Document extends TimeStampedModel {
  id: number;
  title: string;

  content: string;
  status: string;
  document_date: string; // ISO 8601 string

  doc_code: string;
  code?: DocCode;
  type: string;

  template_id?: number;
  template_code?: string;

  domain_id: number;
  domain: Taxonomy;

  attachment_defer_key?: string;
  attachments?: Attachment[];

  attr: object_t;

  files: DocFile[];

  categories: Taxonomy[];
  tags?: Tag[];

  references?: string;
  referred_by?: string;

  schedules: DocSchedule[];

  comments: Comment[];

  uploader_id: number;
  uploader?: User;

  approver_id?: number;
  approver?: User;

  reviewer_id?: number;
  reviewer?: User;

  publisher_id?: number;
  publisher?: User;

  approved_at?: string;
  reviewed_at?: string;
  published_at?: string;

  // link to other associated doc type
  documentable_type?: string;
  documentable_id?: number;

  // calculated field
  is_memo: boolean;
  expiration: string|null;
  next_review: string|null;

  mandatory_permitted_users: User[];
  optional_permitted_users: User[];

  // spw specific
  registration_doc?: DocFile;
  content_docs: DocFile[];
}

export interface AccessRequest {
  id: number;
  doc_id: number;
  doc_code: string;
  doc_title: string;
  document: Document;
  reason: string;
  requester: User;
  approver?: User;
  access_code?: string;
  expiration?: string;
  attr?: object_t;
}

export interface ISBNResult {
  title: string;
  authors?: string;
  publishers?: string;
  year?: string;
  genres?: string;
  revision?: string;
  pages?: string;
}
