import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  NavigationService,
  ServerService
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

@Component({
  selector: 'app-contact',
  templateUrl: 'contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  public staff_id: string = '';
  public details: string  = '';
  //public fullname: string = '';
  public subject: string[]  = [];

  constructor(
    public nav: NavigationService,
    protected server: ServerService,
    protected ui: UIService
  ) { }

  public contact(form: NgForm) {
    this.server.request('contact', null, {
      staff_id: this.staff_id,
      subject: this.subject.length > 0 ? this.subject[0] : '',
      details: this.details
    })
    .then( () => {
      this.ui.alert('Message was successfully sent to the system administrator.', undefined, 'Message Sent!', () => {
        this.nav.setRoot('login');
      });
    });
  }

}
