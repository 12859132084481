import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import {
  PinaconoCommonModule,
  ServerService,
  SessionService,
  Taxonomy
} from '@pinacono/common';

import { PinaconoUIModule } from '@pinacono/ui';

/*
import {
  NotificationMessage,
  NotificationService,
  IrisNotification
} from '@pinacono/notifications';
*/

import { ThemesModule } from 'src/app/themes/module';
import { AppCommonModule } from 'src/app/common/module';

// constants and interfaces
import { AppUser } from 'src/app/types';
import { CustomPropertyConfig } from 'src/app/common/types';

// other modules
import { DocumentsModule } from '../documents/module';
import { TrainingModule } from '../training/module';

// services
import { ENDPOINTS } from './endpoints';
import { OplService } from './opl.service';

// components
import { OplComponent } from './components/opl.component';

// pages
import { OplBrowsePage } from './browse/browse';
import { OplViewPage } from './view/view';
import { OplEditPage } from './edit/edit';
import { OplReportsPage } from './reports/reports';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,

    TranslateModule.forChild(),
    PinaconoCommonModule,
    ThemesModule,
    PinaconoUIModule,
    AppCommonModule,

    DocumentsModule,
    TrainingModule
  ],
  declarations: [
    // components
    OplComponent,

    // pages
    OplBrowsePage,
    OplViewPage,
    OplEditPage,
    OplReportsPage,
  ],
  providers: [
    OplService
  ],
  exports: [
    //TranslateModule,

    // components
    OplComponent,

    // pages
    OplBrowsePage,
    OplViewPage,
    OplEditPage,
    OplReportsPage
  ]
})
export class OplModule {
  constructor(
    server: ServerService,
    session: SessionService,
    //notification: NotificationService,
    oplService: OplService
  ) {
    server.registerEndpoints(ENDPOINTS);

    session.LOGGEDIN.subscribe( (u: AppUser|null ) => {
      // -- Module login
      if ( u === null ) return;
      // loggedin - acquire protected master data after logged-in
      server.request('opl.domains')
      .then( (res: Taxonomy[]) => {
        oplService.domains = res;
      });

      server.request('opl.categories')
      .then( (res: Taxonomy[]) => {
        oplService.categories = res;
      });

      server.request('opl.properties')
      .then( (res: CustomPropertyConfig[]) => {
        oplService.properties = res;
      });

      oplService.count();
    });

    session.LOGGEDOUT.subscribe( () => {
      // -- Module logout
    });

    // notification message parser
    /*
    notification.registerParser('OPL', (n:IrisNotification): NotificationMessage => {
      return {
        group: 'opl',
        link: `/opls/view/${n.closure['opl_id']}`
      };
    });
    */

    /*
    // -- Module boot
    Promise.all([
      // load OPL configurations on BOOT
      server.getconfig('opl')
      .then( (cfg: OPLConfig) => {
        oplService.config = cfg;
      }),

      // add other bootstrap here
    ]);
    */
  }
}
