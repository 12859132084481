<full-page [title]="'Training Batches'">
  <div page-content>
    <panel>
      <div panel-control>
        <button class="btn btn-success btn-icon" (click)="newBatch()">
          <i class="pli-file"></i> {{ 'Create New Batch' | translate }}
        </button>
      </div>
      <div panel-heading>
        {{ 'Training Batches' | translate }}
      </div>
      <div panel-body>
        <extended-slickgrid *ngIf="gridOptions"
          class="row-selectable"
          [gridId]="'training-batches-table'"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>
