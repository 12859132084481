<full-page [title]="title | translate">
  <div page-content>

    <panel>
      <div panel-heading>
        {{ title | translate }}
      </div>
      <div panel-body>
        <extended-slickgrid *ngIf="!! gridOptions" id="container" gridId="main-table"
          class="row-selectable"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>

  </div>
</full-page>

<!-- modal template -->
<!--
  @TODO
  - Update template-id and local vairable name
  - review modal configurations
  - Update dialog title in modal-title
-->
<modal [template-id]="'release-info'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #releaseInfoModal
>
  <div modal-title>
    <div>
      Release of "<span class="text-bold">{{ selectedData?.project?.title_prefix + ' ' }}{{ selectedData?.project?.title }} {{ selectedData?.project?.title_suffix}}</span>"
    </div>
    <div class="text-sm">
      on <span class="text-thin">{{ selectedData?.created_at |  date }}</span>
    </div>
  </div>

  <div modal-body>
    <div class="info-section">
      <h6>{{ 'Recipients' | translate }}:</h6>
      <table class="table">
        <thead>
          <tr>
            <th>E-Mail</th>
            <th>Status</th>
            <th>Sent</th>
            <th>Expiration</th>
            <th>Downloaded</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let recipient of selectedData?.recipients">
            <td class="email">
              {{ recipient.email }}
              <span *ngIf="recipient.expired" class="badge badge-dark text-sm">Expired</span>
            </td>
            <td class="status">
              <span *ngIf="! recipient.sent_at" class="badge badge-default">Queued</span>
              <span *ngIf="!! recipient.sent_at && ! recipient.downloaded_at" class="badge badge-warning">Sent</span>
              <span *ngIf="!! recipient.sent_at && !! recipient.downloaded_at" class="badge badge-success">Downloaded</span>
            </td>
            <td class="sent">{{ ( recipient.sent_at | datetime ) || 'queued' }}</td>
            <td class="expiration">{{ (recipient.expired_at | date) || 'n/a' }}</td>
            <td class="downloaded">
              <ng-container *ngIf="! recipient.downloaded_at">
                n/a
              </ng-container>
              <ng-container *ngIf="!! recipient.downloaded_at">
                {{ recipient.downloaded_at | date }}, IP: {{ recipient.ip_addr || 'n/a' }}
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="info-section">
      <h6>{{ 'Mail Body' | translate }}:</h6>
      <div class="p-1">
        <span class="text-bold">{{ 'Subject' | translate }}: </span>
        <span class="text-thin">{{ selectedData?.subject }}</span>
      </div>
      <p class="m-1 p-2 border rounded border-default">
        {{ selectedData?.message }}
        <span *ngIf="! selectedData?.message" class="list empty">- no mail body -</span>
      </p>
    </div>

    <div class="info-section">
      <h6>{{ 'Attachments' | translate }}:</h6>
      <ol>
        <li *ngFor="let file of selectedData?.files">
          <a class="clickable" href="{{ file.download_url }}">{{ file.file_name }} ({{ file.file_size | memsize:'k' }} kBytes)</a>
        </li>
      </ol>
    </div>
  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="releaseInfoModal.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>