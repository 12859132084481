<full-page [title]="'Master Drawing Management'">
  <div page-content>
    <panel>
      <div panel-heading>
        <h4 class="text-bold">Manage Master Drawings</h4>
      </div>
      <div panel-body>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3 pb-2">
            <div class="form-group">
              <label class="form-label"><i class="icon fa fa-fw fa-building"></i>{{ 'Title' | translate }}:</label>
              <input type="text" class="form-control" id="title" name="title" [(ngModel)]="search_input.title">
            </div>
            <div>
              <custom-properties [config]="buildings_config" [(data)]="search_input.building" (dataChange)="onBuildingChange($event)"></custom-properties>
            </div>
            <div>
              <custom-properties [config]="floors_config" [(data)]="search_input.floor"></custom-properties>
            </div>
            <div class="mt-3 text-right">
              <button title="Upload new master drawing." class="btn btn-info btn-labeled" (click)="upload()" >
                <i class="btn-label icon-lg icon-fw pli-upload"></i> {{ 'Upload' | translate }}
              </button>
              <button title="Search for master drawing." class="btn btn-info btn-labeled" (click)="search()" >
                <i class="btn-label icon-lg icon-fw pli-magnifi-glass"></i> {{ 'Search' | translate }}
              </button>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-9 pad-no" id="search-result">
            <div class="row">
              <div *ngFor="let dwg of drawingsList" class="col m-2 drawing-entry">
                <div class="d-flex">
                  <div class="flex-shrink-0 download-button">
                    <download *ngFor="let f of dwg.attachments" [file]="f" #downloader>
                      <ng-template
                        let-file="file"
                        let-icon="icon"
                      >
                        <button class="btn btn-default btn-icon mar-all" title="click to download {{ file.file_name}}" (click)="downloader.download()">
                          <i class="btn-label icon-fw icon-3x fa fa-fw {{ icon }}"></i>
                        </button>
                      </ng-template>
                    </download>
                  </div>
                  <div class="flex-grow-1 details clickable" title="Click to edit {{ dwg.title }}" (click)="select(dwg)">
                    <div>
                      <span class="text-bold">{{ dwg.title || 'Untitled' }}</span>
                      <span class="badge" [ngClass]="{ 'badge-pink': !dwg.iip_name, 'badge-success': dwg.iip_name }" title="{{ dwg.iip_name ? 'Preview available' : 'Preview in progress' | translate }}">
                        <i [ngClass]="{ 'fa-regular fa-hourglass' : !dwg.iip_name, 'fa-solid fa-check': dwg.iip_name }"></i>
                      </span>
                    </div>
                    <div class="drawing-attributes">
                      อาคาร {{ dwg.attr['building'] }} ชั้น {{dwg.attr['floor']}}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="drawingsList.length == 0" class="col mt-5 text-center" id="no-drawing">
                <span class="text-muted text-thin text-lg">
                  {{ 'Sorry, no master drawing found, please try other criteria.' | translate }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </panel>
  </div>
</full-page>

<modal template-id="master-drawing-editor"
  size="xlarge"
  [hideCloseButton]="true"
  #masterEditor
>
  <div modal-body id="iip">

    <div class="row">

      <div [ngClass]="{ 'col-4': drawing.id , 'col': ! drawing.id }">
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="drawing.title">
          <validation-errors
            [errors]="errors['title']"
            [dirty]="true"
          ></validation-errors>
        </div>
        <div>
          <!--
          <custom-properties [config]="buildings_config" [(data)]="drawing.attr['building']" (dataChange)="onBuildingChange($event)" [errors]="errors['building']"></custom-properties>
          -->
          <custom-properties [config]="buildings_config" [(data)]="drawing.attr['building']" (dataChange)="onBuildingChange($event)" [errors]="errors['building']"></custom-properties>
        </div>
        <div>
          <!--
          <custom-properties [config]="floors_config" [(data)]="drawing.attr['floor']" [errors]="errors['floor']"></custom-properties>
          -->
          <custom-properties [config]="floors_config" [(data)]="drawing.attr['floor']" [errors]="errors['floor']"></custom-properties>
        </div>
        <attachments #attachments
          class="my-4"
          [(files)]="drawing.attachments"
          (filesChange)="onFilesChange()"
          [max-files]="1"
          [master-content-type]="'drawings'"
          [master-id]="drawing.id"
          [defer-key]="drawing.attachment_defer_key"
        ></attachments>
      </div>

      <div *ngIf="drawing.id" class="col-8" id="right-pane">
        <span *ngIf="drawing.id && ! drawing.iip_name" class="text-muted text-thin text-lg">
          {{ 'Sorry, master drawing preview is not avaialble at the moment.' | translate }}
        </span>

        <iip-viewer *ngIf="!! drawing && !! drawing.iip_name" #viewer
          [src]="drawing.iip_name"
          [path]="iip_base_path"
          [navButtons]="['reset', 'zoomIn', 'zoomOut']"
          [showNavigation]="false"
          [showCoords]="false"
          [showScale]="false"
          [drawingTools]="false"
        ></iip-viewer>
      </div>
    </div>
  </div>

  <div modal-footer>

    <button *ngIf="drawing.attachments.length > 0" title="Save" class="btn btn-success btn-labeled" (click)="save()">
      <i class="btn-label icon-lg icon-fw pli-disk"></i> {{ 'Save' | translate }}
    </button>

    <button *ngIf="!! drawing.id" title="Delete" class="btn btn-danger btn-labeled" (click)="delete()">
      <i class="btn-label icon-lg icon-fw pli-trash"></i> {{ 'Delete' | translate }}
    </button>

    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="masterEditor.hide()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
    </button>

  </div>

</modal>