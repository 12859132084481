import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Data
} from '@angular/router';

import {
  object_t,
  NavigationService,
  ServerService,
  SessionService,
  UserService
} from '@pinacono/common';

import {
  TreantNode,
  TreantNodeClickEvent,
  UIService
} from '@pinacono/ui';

import * as _ from 'lodash';

import { AppUser } from 'src/app/types';

@Component({
  selector: 'app-profile-page',
  templateUrl: 'profile.component.html',
  styleUrls: [ 'profile.component.scss' ]
})
export class ProfileComponent implements OnInit {

  public account!: AppUser;
  public team?: object_t;

  public editable: string[] = [];

  public user2chart_mapper: TreantNode = {
    text: {
      name:    '{fullname}',
      title:   '{profiles.job_title}',
      contact: {
        val: '{email}',
        href: 'mailto:{email}',
        target: '_blank'
      },
      data: {
        uid: '{id}'
      }
    },
    image: '{avatar_thumbUrl}',
    link: {
      href: '/#/profile/{id}',
    }
  }

  protected user_id!: number;
  protected redirect?: object_t;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected server: ServerService,
    protected user: UserService,
    protected session: SessionService,
    protected ui: UIService
  ) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id') || this.session.currentUser?.id || null;

    if ( ! id ) {
      this.nav.setRoot('/');
      return;
    }

    this.activatedRoute.data.subscribe( (data:Data) => {
      this.redirect = data['redirect'] as object_t;
    });

    this.user_id = id as number;
    this.load();
  }

  protected load(): Promise<void> {
    let promises: Promise<void>[] = [];
    promises.push(
      this.server.rejectOnError().show('users', this.user_id, { withtrashed: 1 })
      .then( (u: object_t) => {
        this.account = this.user.create(u);
      })
      .catch( async () => {
        await this.ui.alert(`Cannot load user id ${this.user_id}`);
        this.nav.canGoBack() && this.nav.back() || this.nav.setRoot('home');
      })
    );

    this.editable = (this.user_id == this.session.currentUser?.id) ? [
      'mobile2', 'password'
      //'*'
    ] : [];

    promises.push(
      this.server.request('group.team', {uid: this.user_id})
      .then( (team: object_t) => {
        this.team = team;
      })
    );

    return Promise.all(promises)
    .then( () => {
      this.setTreantSize();
    });
  }

  // -- internal
  static maximized: boolean = false;
  protected setTreantSize() {
    if ( ProfileComponent.maximized ) {
      $('#treant-toggle i').removeClass('pli-full-screen-2').addClass('pli-minimize');
    }
    else {
      $('#treant-toggle i').removeClass('pli-minimize').addClass('pli-full-screen-2')
    }
  }

  // -- template API

  public get maximized(): boolean {
    return ProfileComponent.maximized;
  }

  public toggleTreant() {
    ProfileComponent.maximized = ! ProfileComponent.maximized;
    this.setTreantSize();
  }

  public clickUserProfile(event: TreantNodeClickEvent) {
    const uid = (event.treant && event.treant.text && event.treant.text.data && event.treant.text.data['uid']) || null;
    if ( !! uid ) {
      if ( ! Number.isNaN(parseInt(uid)) ) {
        this.user_id = parseInt(uid);
        this.load();
      }
    }
  }

  public onSave(logout: boolean) {

    if ( logout ) {
      this.nav.setRoot(['login', {logout: true}]);
      return;
    }

    if ( !! this.redirect ) {
      this.nav.setRoot({
        path: this.redirect['page'],
        params: this.redirect['params']
      });
    }
    else if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

}
