<full-page [title]="'Project Contact List'">
  <div page-content>
    <panel>
      <div panel-control>
        <button class="btn btn-primary btn-labeled"
          [modal]="'edit-contact'"
          [title]="'Add New Contact'"
        >
          <i class="btn-label pli-add-user"></i> {{ 'Add Contact' | translate }}
        </button>
      </div>
      <div panel-heading>
        {{ 'Project External Contact' | translate }}
      </div>
      <div panel-body>
        <extended-slickgrid *ngIf="!! gridOptions" id="container" gridId="main-table"
          class="row-selectable"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>

<modal [template-id]="'edit-contact'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #modalEditContact>
  <div modal-title>
    {{ contact.id ? 'Edit Contact' : 'Add Contact' | translate }}
  </div>
  <div modal-body>
    <form #editorForm="ngForm">

      <div class="row">

        <div class="col">
          <label class="form-label">{{ 'Full Name' | translate }}</label>
          <input type="text" class="form-control"
            [(ngModel)]="contact.fullname" #fullname="ngModel"
            name="fullname"
            required
          >
          <validation-errors
            [models]="fullname"
            [messages]="{
              required: 'Contact full name is required.'
            }"
            [dirty]="true"
          ></validation-errors>
        </div>

      </div>

      <div class="row py-2">

        <div class="col">
          <label class="form-label">{{ 'E-Mail' | translate }}</label>
          <input type="email" class="form-control"
            [(ngModel)]="contact.email" #email="ngModel"
            name="email"
            required
          >
          <validation-errors
            [models]="email"
            [messages]="{
              required: 'Contact e-mail is required.'
            }"
            [dirty]="true"
          ></validation-errors>
        </div>

        <div class="col">
          <label class="form-label">{{ 'Tel' | translate }}</label>
          <input type="tel" class="form-control"
            [(ngModel)]="contact.tel" #tel="ngModel"
            name="tel"
            required
          >
          <validation-errors
            [models]="tel"
            [messages]="{
              required: 'Telephone number is required.'
            }"
            [dirty]="true"
          ></validation-errors>
        </div>

      </div>

      <div class="row py-2">

        <div class="col">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [attr.checked]="is_spw ? 1 : null" value="" id="is-spw" (change)="toggleSPW($event)">
            <label class="form-check-label" for="is-spw">
              หน่วยงานในเครือสยามพิวรรธน์/ไอคอนสยาม
            </label>
          </div>
        </div>

        <div class="col">
          <label class="form-label">{{ 'Department' | translate }}</label>
          <input type="text" class="form-control"
            [(ngModel)]="contact.department" #department="ngModel"
            [datalist]="spw_departments"
            name="department"
          >
          <validation-errors
            [models]="department"
            [messages]="{
            }"
            [dirty]="true"
          ></validation-errors>
        </div>

      </div>

      <div class="row py-2">

        <div *ngIf="!is_spw" class="col">
          <label class="form-label">{{ 'Company' | translate }}</label>
          <input type="text" class="form-control"
            [(ngModel)]="contact.company" #company="ngModel"
            [datalist]="companies"
            name="company"
            required
          >
          <validation-errors
            [models]="company"
            [messages]="{
              required: 'Company is required.'
            }"
            [dirty]="true"
          ></validation-errors>
        </div>

        <div *ngIf="!is_spw" class="col">
          <label class="form-label">{{ 'Job Title' | translate }}</label>
          <input type="text" class="form-control"
            [(ngModel)]="contact.job_title" #job_title="ngModel"
            name="job_title"
          >
          <validation-errors
            [models]="job_title"
            [messages]="{}"
            [dirty]="true"
          ></validation-errors>
        </div>

      </div>

    </form>
  </div>
  <div modal-footer>
    <!-- @TODO - delete button? -->
    <button class="btn btn-labeled btn-success" type="button" (click)="saveContact()">
      <i class="btn-label pli-save"></i> {{ 'Save' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="modalEditContact.hide();">
      <i class="btn-label pli-close"></i> {{ 'Cancel' | translate }}
    </button>
  </div>
</modal>