<!-- document code inputs -->
<div class="code-editor-container">
  <div class="code-editor-input">
    <ng-container *ngIf="! hiddenComponents.includes('a')">
      <ngx-select #doc_code_a="ngModel"
        [items]="api.names['doc_code_formatter'].a"
        required
        name="a"
        [(ngModel)]="model!.a"
        (selectionChanges)="docCodeChange('a')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
      -
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('b')">
      <ngx-select #doc_code_b="ngModel"
        [items]="api.names['doc_code_formatter'].b"
        required
        name="b"
        [(ngModel)]="model!.b"
        (selectionChanges)="docCodeChange('b')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
      -
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('c')">
      <ngx-select #doc_code_c="ngModel"
        [items]="api.names['doc_code_formatter'].c[model.b] || []"
        required name="c"
        [(ngModel)]="model!.c"
        (selectionChanges)="docCodeChange('c')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
      -
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('d')">
      <ngx-select #doc_code_d="ngModel"
        [items]="api.names['doc_code_formatter'].d"
        required
        name="d"
        [(ngModel)]="model!.d"
        (selectionChanges)="docCodeChange('d')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('e')">
      <ngx-select #doc_code_e="ngModel"
        [items]="api.names['doc_code_formatter'].e"
        required name="e"
        [(ngModel)]="model!.e"
        (selectionChanges)="docCodeChange('e')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('d') && ! hiddenComponents.includes('e')">
      -
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('f')">
      <ngx-select #doc_code_f="ngModel"
        [items]="doc_types"
        required name="f"
        [(ngModel)]="model!.f"
        (selectionChanges)="docCodeChange('f')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
      -
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('g')">
      <ngx-select #doc_code_g="ngModel"
        [items]="api.names['doc_code_formatter'].g[model!.f||'default']"
        required name="g"
        [(ngModel)]="model!.g"
        (selectionChanges)="docCodeChange('g')"
      >
        <ng-template ngx-select-option-selected let-option>
          {{ option.data.value }}
        </ng-template>
        <ng-template ngx-select-option let-option>
          {{ option.data.data.label }}
        </ng-template>
      </ngx-select>
    </ng-container>
    <ng-container *ngIf="! hiddenComponents.includes('id')">
      <input #doc_code_id="ngModel"
        class="form-control doc-id"
        readonly
        type="number"
        name="id"
        [(ngModel)]="model!.id"
      >
    </ng-container>
    <ng-content></ng-content>
  </div>
  <validation-errors [errors]="errors"></validation-errors>
</div>
