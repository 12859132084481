<minimal-page>
  <div class="cls-content-sm">
    <div page-head>
      <div class="text-center">
        <h1 class="error-code text-mint">000</h1>
      </div>
    </div>
    <div page-content>
      <div class="text-center">
        <p class="h4 text-uppercase text-bold">{{ 'We are under planned maintenance' | translate }}!</p>
        <div class="pb-3">
          and will be right back in:
        </div>
        <div class="mx-3" id="counter">
          <span id="hour">{{ ( hour | number:'1.0-0' ) || '' | leftpad:2:'0' }}</span> :
          <span id="min" >{{ ( min  | number:'1.0-0' ) || '' | leftpad:2:'0' }}</span> :
          <span id="sec" >{{ ( sec  | number:'1.0-0' ) || '' | leftpad:2:'0' }}</span>
        </div>
      </div>
    </div>
  </div>
</minimal-page>