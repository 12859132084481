import { NgModule, NgZone, InjectionToken } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { ROUTES_REGISTRY, ROUTER_EXTRAS } from 'src/app/app-routing.config';

declare let CLIENT_CONFIG: any;

@NgModule({
  imports: [
    RouterModule.forRoot(Object.values(ROUTES_REGISTRY), Object.assign({
      useHash: true,
      onSameNavigation: 'reload' // for reload when navigate to same URL
    }, ROUTER_EXTRAS))
  ],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    zone: NgZone,
    router: Router
  ) {
    zone.run(() => {
      // currently not working - need to find how to immedately route to offline page
      let config: any = null;
      try {
        config = CLIENT_CONFIG || null;
      }
      catch ( e ) {
        console.info(e);
      }
      if ( ! config ) {
        console.warn('Server is offline!');
        router.navigate(['/offline']);
      }
    });
  }
}
