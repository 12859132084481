import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  AfterViewInit,
  DoCheck,
  OnChanges, SimpleChanges,
  OnDestroy,
  ViewChild
} from "@angular/core";

import {
  ArrayUtilsService,
  object_t,
  SessionService,
  ServerService,
  Taxonomy,
  User
} from "@pinacono/common";

import {
  AttachmentsComponent,
  ModalComponent,
  UIService
} from "@pinacono/ui";

import {
  Comment, CommentButton,
  CustomPropertyConfig,
  ExamChoice
} from 'src/app/common/types';

import { TrainingCourse } from '../../training/types';
import { DocLibService } from '../../documents/doclib.service';

import { OplService } from "../opl.service";
import { OPL, OPLQuestion } from "../types";

declare let $: any; // use jQuery

@Component({
  selector: 'opl',
  templateUrl: 'opl.html',
  styleUrls: [ 'opl.scss' ]
})
export class OplComponent implements AfterViewInit, DoCheck, OnChanges, OnDestroy {
  @Input() content!: OPL;
  @Input() format?: 'full' | 'feed' = 'full';

  @Output()      onOpen = new EventEmitter<OPL>();
  @Output()    onBrowse = new EventEmitter<object_t|null>();
  @Output()      onEdit = new EventEmitter<OPL>();
  @Output() onQuickEdit = new EventEmitter<OPL>();
  @Output()     onTrash = new EventEmitter<OPL>();

  @Output()   onFlag = new EventEmitter<OPL>();
  @Output() onUnFlag = new EventEmitter<OPL>();

  @Output() onClose = new EventEmitter<null>();

  @ViewChild('attachments') attachments!: AttachmentsComponent;
  @ViewChild('revlog') revlog!: ModalComponent;

  public choices: string[] = [];

  public parsed = {
    content: '',
    teaser: ''
  };

  public feed_thumb: string|null = null;
  public readerComment: string|null = null;
  public remaining_read_time: number = 0;   // now - startRead in seconds
  public comment_buttons: CommentButton[] = [];

  public hide_quiz = false;

  // -- initialization

  /**
   * @TODO - integrate with competency and document library
   */
  constructor(
    public oplService: OplService,
    public docService: DocLibService,
    public session: SessionService,
    protected ui: UIService,
    protected server: ServerService,
    protected arrayUtils: ArrayUtilsService,
    protected el: ElementRef
  ) {
  }

  // -- lifecycle hooks

  public ngAfterViewInit() {
    // handle click over keyword
    $('.keyword', this.el.nativeElement).on('click', (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
      this.browse({keyword: $(event.target).html()});
    });

    /**
     * issue:
     * - list of data is not rendered yet
     * - the container is collapsed while rendering, therefore, got 0px height.
     */
    /*
    setTimeout( () => {
      $('[data-masonry]').masonry({
        itemSelector: '.col',
        columnWidth: 200,
        percentPosition : true
      })
    }, 15000);
    */
  }

  private startRead: Date|null = null; // timestamp when become visible
  private timer: number|null = null;
  public ngDoCheck() {
    if ( this.content.status != 'approved' || this.format != 'full' || this.oplService.config.read_prove_mode != 'timer' ) {
      return;
    }

    if ( this.el.nativeElement.offsetParent == null ) {
      if ( this.startRead ) {
        this.startRead = null;
        if ( this.timer ) {
          clearInterval(this.timer);
          this.timer = null;
          this.remaining_read_time = this.oplService.config.min_read_time;
        }
      }
      return;
    }

    if ( ! this.startRead ) {
      // become visible
      this.startRead = new Date();
      this.timer = window.setInterval( () => {
        // remainig time (in seconds)
        this.remaining_read_time =this.startRead && this.oplService.config.min_read_time - Math.round( ( (new Date()).getTime() - this.startRead.getTime() ) / 1000 ) || 0;
        if ( this.timer && this.remaining_read_time <= 0 ) {
          clearInterval(this.timer);
          this.timer = null;
          this.markRead();
        }
      }, 1000)
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['content'] ) {
      this.content = this.oplService.create(this.content);

      if ( this.content.audiences ) {
        for ( let i in this.content.audiences.unread ) {
          this.content.audiences.unread[i]['buttons'] = [];
          if ( this.is_manager ) {
            this.content.audiences.unread[i]['buttons'].push(
              { class: 'btn-default', icon: 'pli-bell', label: 'Remind', action: (user: User) => { this.remind(user); }}
            );
          }
        }
        for ( let i in this.content.audiences.read ) {
          this.content.audiences.read[i]['buttons'] = [];
          if ( this.is_manager ) {
            this.content.audiences.read[i]['buttons'].push(
              { class: 'btn-default', icon: 'pli-thumbs-down-smiley', label: 'Reject', action: (user: User) => { this.resetRead(user); }}
            );
          }
        }
      }

      this.parsed.content = this.parse(this.content.content);
      this.parsed.teaser  = this.parse(this.content.teaser || this.content.content);

      if ( this.content.attachments ) {
        this.feed_thumb = ( this.content.attachments.image   && this.content.attachments.image.length   > 0 ? this.content.attachments.image[0].path_url : null )  ||
                          ( this.content.attachments.video   && this.content.attachments.video.length   > 0 ? this.content.attachments.video[0].thumb_url : null ) ||
                          ( this.content.attachments.youtube && this.content.attachments.youtube.length > 0 ? `https://img.youtube.com/vi/${this.content.attachments.youtube[0].id}/hqdefault.jpg` : null )
      }
      else {
        this.feed_thumb = null;
      }

      if ( this.content.status == 'approved' ) {
        this.comment_buttons.push({
          btnClass: 'btn-warning',
          iconClass: 'fa fa-solid fa-hammer',
          label: 'Request to Update',
          process: this.request.bind(this)
        });
      }
      this.remaining_read_time = this.oplService.config.min_read_time;

      if ( this.content.status !== 'approved' ) {
        return;
      }

      if ( this.oplService.config.read_prove_mode == 'quiz') {
        this.randomQuestions();
      }
      else if ( this.oplService.config.read_prove_mode == 'none' ) {
        this.markRead();
      }
    }
  }

  public ngOnDestroy() {
    $('.keyword', this.el.nativeElement).off('click');
  }

  // -- internal

  protected parse(content: string): string {
    // make hash tag keyword a link
    let re = /#([^\s\.$<>]+)/ig;
    let s = content.replace(re, function(item) {
      let keyword = item.trim().replace(/\#/g,'').toString();
      return '<a class="hashtag btn-link keyword" href="#">'+ keyword +'</a>';
    });

    // make url a link
    re = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,32}\b(?:[-a-zA-Z0-9@:%_\+.~#?&;\/\/=]*))/g;
    s = s.replace(re, function(url) {
      return '<a class="btn-link" href="' + url + '" target="_blank">'+ url +'</a>';
    });

    return s;
  }

  // -- getter / setter
  public get has_media(): boolean {
    return !! this.content &&
           !! this.content.attachments &&
           !! this.content.attachments.unsorted &&
           !! this.content.attachments.document &&
           ( this.content.attachments.unsorted.length - this.content.attachments.document.length ) > 0;
  }

  public get is_read(): boolean {
    return !! this.content &&
           !! this.content.audiences &&
           ( this.content.audiences.read.findIndex((u: User) => { return u.id == this.session.currentUser?.id; }) >= 0 );
  }

  public get done_reading(): boolean {
    return this.oplService.config.read_prove_mode != 'timer' || this.remaining_read_time <= 0;
  }

  public get must_read(): boolean {
    return !! this.content.audiences &&
           ( this.content.audiences.unread.findIndex((u: User) => { return u.id == this.session.currentUser?.id; }) >= 0 );
  }

  public get is_author(): boolean {
    return !! this.content.author && this.content.author.id == this.session.currentUser?.id;
  }

  public get is_manager(): boolean {
    return this.session.canManage([this.content.domain]) &&
           !! this.session.currentUser &&
           !! this.session.currentUser.primary_domain &&
           (
             (this.session.currentUser.primary_domain.nest_depth || 0) <= 1 ||
             this.content.author?.id != this.session.currentUser.id
           );
    //return UserService.can(content.domain_id, ['group.content.moderate']);
  }

  public get is_owner(): boolean {
    return this.is_author || this.is_manager || this.session.hasPermission(['core_admin']);
  }

  public get can_approve(): boolean {
    return this.content.status == 'submitted' && ( ( this.is_author && this.session.hasPermission(['opl_self_approve']) ) || this.is_manager );
  }

  public get can_submit(): boolean {
    return this.is_author && this.content.status != 'submitted' && this.content.status != 'approved';
  }

  public get can_delete(): boolean {
    return this.is_manager;
  }

  public get can_quick_edit(): boolean {
    return this.content.status == 'approved' && this.is_manager;
  }

  public get can_edit(): boolean {
    return this.is_owner;
  }

  public get can_flag(): boolean {
    if ( this.content.status != 'approved' ) {
      return false;
    }

    if ( ! this.session.currentUser?.primary_domain ) {
      return false;
    }

    let cids = this.content.categories.map( (v: Taxonomy) => { return v.id; });
    if ( cids.indexOf(this.session.currentUser?.primary_domain.id) >= 0 ) {
      return false;
    }
    return this.session.canManage(this.session.currentUser.groups);
  }

  public get can_unflag(): boolean {
    if ( this.content.status != 'approved' ) {
      return false;
    }

    if ( ! this.session.currentUser?.primary_domain ) {
      return false;
    }

    if ( this.session.currentUser?.primary_domain.id == this.content.domain_id ) {
      return false;
    }

    let cids = this.content.categories.map( (v: Taxonomy) => { return v.id; });
    if ( cids.indexOf(this.session.currentUser.primary_domain.id) < 0 ) {
      return false;
    }

    return this.session.canManage(this.session.currentUser.groups);
  }

  public get related_courses(): TrainingCourse[] {
    // @TODO - list related course here
    return [];
  }

  // -- exam

  public correct_answers(q: OPLQuestion): string[] {
    //return this.content.correct_answers ? this.content.correct_answers.split("\n") : [];
    return q.choices ? q.choices.filter( c => c.is_correct ).map( c => c.content ) : [];
  }

  public incorrect_answers(q: OPLQuestion): string[] {
    //return this.content.correct_answers ? this.content.incorrect_answers.split("\n") : [];
    return q.choices ? q.choices.filter( c => ! c.is_correct).map( c => c.content ) : [];
  }

  // -- Template API
  public browse(filter: object_t|null = null) {
    this.onBrowse.next(filter);
  }

  public browseProperty(config: CustomPropertyConfig, value: any) {
    console.log('browseProperty', config, value);
    let filters: object_t = {};
    filters[config.name] = value;
    this.onBrowse.next({ properties: filters });
  }

  public open() {
    if ( this.format == 'full' ) {
      return;
    }
    if ( ! this.must_read && this.content.status == 'approved' ) {
      this.server.request('opl.set_read', {opl_id: this.content.id});
    }
    this.onOpen.next(this.content);
  }

  public approve() {
    this.ui.confirm('Approve this OPL?', undefined, () => {
      this.server.request('opl.approve', {id: this.content.id})
      .then( () => this.browse() );
    });
  }

  public reject() {
    this.ui.confirm('Reject this OPL?', undefined, () => {
      this.server.request('opl.reject', {id: this.content.id})
      .then( () => this.browse() );
    });
  }

  // -- submit
  // IMPORTANT: any logic changes need to apply to the code in 'edit.ts' as well!
  public log_msg: string|null = null;
  public submit() {
    if ( ! this.oplService.validate(this.content, true) ) {
      return;
    }
    if ( this.content.revision_logs && this.content.revision_logs.length >= 1) {
      this.revlog.show();
    }
    else {
      this.doSubmit();
    }
  }

  public doSubmit() {

    if ( this.content.revision_logs && this.content.revision_logs.length >= 1 ) {
      this.revlog.hide();
      if ( ! this.log_msg || this.log_msg.length < 10 ) {
        this.ui.alert('At least 10 characters of revision log message is required for submit');
        return;
      }
    }
    else {
      this.log_msg = 'Initial Version';
    }

    this.ui.confirm('Submit this OPL for approval?', undefined, () => {
      this.server.request('opl.revlog', {id: this.content.id}, {
        log: this.log_msg
      })
      .then( () => {
        return this.server.request('opl.submit', {id: this.content.id})
      })
      .then( () => {
        this.browse();
      });
    });

  }
  // -- end of submit

  public edit(quick: boolean) {
    if ( quick ) {
      this.onQuickEdit.next(this.content);
    }
    else {
      this.onEdit.next(this.content);
    }
  }

  public trash() {
    this.ui.confirm('Delete this OPL?', undefined, () => {
      this.onTrash.next(this.content);
    });
  }

  public remind(user: User) {
    //console.log('remind ' + user.fullname + ' to read OPL #' + this.content.id);
    this.ui.confirm('Send reminding to {{ fullname }} user?', { fullname: user.fullname }, () => {
      this.server.silent().request('opl.remind', {id: this.content.id, uid: user.id}, null, null, null);
    });
  }

  public request(comment: Comment): Comment {
    this.server.silent().request('opl.request', {id: this.content.id, action: 'update'}, null, null, null);
    comment.comment = "*** Request for action ***\n" + comment.comment;
    return comment;
  }

  public addComment() {
    if ( this.readerComment && this.readerComment.length < this.oplService.config.min_read_comment_length ) {
      this.ui.alert('At least {{ n }} characters of comment is required.', { n: this.oplService.config.min_read_comment_length });
      return;
    }
    //this.markRead(this.readerComment, true);
  }

  public markRead(comment: string|null = null, browse: boolean = false) {
    this.server.silent().request('opl.set_read', {opl_id: this.content.id}, { comment: comment })
    .then( () => {
      if ( browse) this.browse();
    });
  }

  public resetRead(user: User) {
    this.ui.confirm('Ask {{ fullname }} to read the OPL again?', { fullname: user.fullname }, () => {
      this.server.request('opl.reset_read', {opl_id: this.content.id})
      .then( () => {
        if ( this.content.audiences ) {
          let i = this.content.audiences.read.findIndex( u => u.id == user.id );
          this.content.audiences.read.splice(i, 1);
          this.content.audiences.unread.push(user);
        }
      });
    });
  }

  public flag() {
    this.ui.confirm('Flag this OPL to make it avaialble for your group?', undefined, () => {
      this.onFlag.next(this.content);
    });
  }

  public unflag() {
    this.ui.confirm('Un-Flag this OPL? It will be no longer available for your group.', undefined, () => {
      this.onUnFlag.next(this.content);
    });
  }

  // quiz
  public questions: OPLQuestion[] = [];

  protected fails: number = 0;
  protected passed: number = 0;
  protected answered: number = 0;

  protected randomQuestions() {
    this.passed = 0;
    this.answered = 0;
    this.questions = (this.content.questions || []).sort( () => 0.5 - Math.random()).slice(0, this.oplService.config.quiz_count); // make a copy
    this.questions.forEach( q => this.randomChoices(q) );
  }

  public randomChoices(quiz: OPLQuestion) {
    let choices: ExamChoice[] = [];

    choices = choices.concat(this.arrayUtils.random(quiz.choices.filter( c => c.is_correct )));
    choices = choices.concat(this.arrayUtils.random(quiz.choices.filter( c => ! c.is_correct ), 3));
    quiz.choices = this.arrayUtils.shuffle(choices);
  }

  public answer(quiz: OPLQuestion, ans: ExamChoice) {
    this.answered++;

    if ( quiz.choices.filter( c => c.is_correct ).map( c => c.id ).indexOf(ans.id) < 0 ) {
      this.fails++;
    }
    else {
      this.passed++;
    }

    if ( this.passed >= this.oplService.config.quiz_count ) {
      this.ui.alert("Congratulations! Your answer is correct!", undefined, 'Correct!', () => {
        this.server.request('opl.set_read', {opl_id: this.content.id}, { comment: this.readerComment, fails: this.fails / 2 })
        .then( () => {
          this.browse();
        });
      });
    }
    else if ( this.answered == this.oplService.config.quiz_count ) {
      this.hide_quiz = true;
      setTimeout(() => {
        this.hide_quiz = false;
      }, 30000);
      this.ui.alert("Sorry, your answer is incorrect. Please read the OPL carefully.");
      this.randomQuestions();
    }
  }
}