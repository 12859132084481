<div class="row mar-btm search">
  <form (ngSubmit)="search(keywordInput.value)" (keydown.enter)="search(keywordInput.value)" class="col-xs-12 col-sm-10 col-sm-offset-1">
    <div class="input-group mar-btm">
      <input #keywordInput [(ngModel)]="keyword" type="text" name="keyword" placeholder="{{ 'Search' | translate }}" class="form-control input-lg">
      <span class="input-group-btn">
        <button class="btn btn-success btn-lg" (click)="search(keywordInput.value)" type="button">{{ 'Search' | translate }}</button>
      </span>
    </div>
  </form>
</div>
