<h4>{{ title | translate }}</h4>
<div *ngIf="(items || []).length == 0" class="row">
  <p class="col-xs-12 text-center text-muted text-thin pad-all">{{ 'No such asset(s)!' | translate }}</p>
</div>
<div *ngIf="(items || []).length > 0" class="table-responsive-lg">
  <table class="table">
    <thead>
      <tr>
        <th>{{ 'Bar Code' | translate }}</th>
        <th>{{ 'Title' | translate }}</th>
        <th>{{ 'Location' | translate }}</th>
        <th>{{ 'Status' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of items">
        <td>{{ asset.code }}</td>
        <td>{{ asset.book?.title || '- no title -' }}</td>
        <td>{{ asset.location['branch'] }} / {{ asset.location['cabinet'] }} / {{ asset.location['shelf'] }}</td>
        <td>{{ asset.status }}</td>
      </tr>
    </tbody>
  </table>
</div>