<full-page [title]="'Courses List'">
  <div page-content>
    <!-- Search bar -->
    <search (keywordChange)="search($event)"></search>

    <div *ngIf="courses.length == 0" class="row" id="courses-list">
      <div class="col-xs-12 text-thin text-italic text-center">
        - {{ empty }} -
      </div>
    </div>

    <div class="row" id="courses-list">
      <div *ngFor="let course of courses" class="col-xs-12 card course">
        <div class="d-flex minimal-course spw-course-minimal">
          <div class="flex-shrink-0 cover">
            <!--
            <gallery *ngIf="course.covers.length > 0"
              class="img-fluid"
              [layout]="'plain'"
              [items]="course.covers"
              [options]="{ gallery_min_width: 81, tiles_col_width: 81, tile_exact_width: true, tiles_min_columns: 1 }"
              [thumbnail]="{ type: 'covers', width: 75, height: 75, mode: 'pad' }"
            ></gallery>
            <img *ngIf="course.covers.length == 0" class="img-fluid" src="/assets/images/75x75.png">
            -->
            <img class="img-fluid" [attr.src]=" course.covers.length > 0 ? course.covers[0].thumb_url : '/assets/images/75x75.png'">
          </div>

          <div class="flex-grow-1 details">
            <a (click)="selectCourse(course.id)" class="clickable">{{ course.name || 'Untitled' }} <sup *ngIf="course.auto_batch" class="badge badge-default"><i class="icon-sm pli-flash"></i></sup> </a>
            <a (click)="selectCourse(course.id)" class="clickable course-code">({{ course.code || 'code not available' }})</a>

            <div *ngIf="session.hasPermission(['training-admin'])" class="actions">
              <button (click)="edit(course.id)" class="btn btn-default btn-icon" [title]="'Edit' | translate ">
                <i class="btn-label pli-pencil icon-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="pagination-container">
      <div *ngIf="courses.length > 0" class="col-xs-12 text-center">
        <pagination [pageno]="pagination.pageno" [perpage]="pagination.perpage" [total]="pagination.total" (pagenoChange)="loadPage($event)"></pagination>
      </div>
    </div>
  </div>
</full-page>