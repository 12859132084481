<full-page [title]="opl.title || 'Edit OPL'">
  <div page-content id="editor" class="opl opl-edit">
    <panel>
      <div panel-heading>
        <h2><i *ngIf="!!opl.id" class="fa fa-hashtag"></i><span *ngIf="!!opl.id">{{ opl.id }} - </span>{{ ( opl.title || '(new OPL)') }}</h2>
      </div>
      <div panel-body>
        <div class="row">
          <!-- left -->
          <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 opl-edit-left">
            <attachments #attachments
              id="uploader-dropzone"
              class="pad-top pad-btm"
              [(files)]="opl.attachments!.unsorted!"
              [master-content-type]="'opls'"
              [master-id]="opl.id"
              [defer-key]="opl.attachment_defer_key"
            ></attachments>
          </div>

          <!-- right -->
          <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 opl-edit-right" >
            <div class="blog-content">
              <!-- header -->
              <div class="blog-title media-block">
                <div class="media-left">
                  <avatar [user]="session.currentUser"></avatar>
                </div>
                <div class="media-body">
                  <div class="form-group">
                    <label class="control-label text-bold">{{ 'Title' | translate }}:</label>
                    <input class="form-control full-width" type="text" name="title" [(ngModel)]="opl.title">
                  </div>
                </div>
              </div>

              <!-- body -->
              <div class="blog-body">
                <div class="card bord-none mb-1">

                  <!-- display of meta data -->
                  <div class="meta">
                    <div class="text-sm" [title]="'Approval Domain'" data-bs-toggle="collapse" data-bs-target="#domain" role="button" aria-expanded="false" aria-controls="domain">
                      <label class="form-label"><i class="icon fa fa-fw fa-flag"></i> {{ 'Domain' | translate }}</label>
                      <span *ngIf="!opl.domain" class="domain text-muted">&hellip; {{ 'select' | translate }} &hellip;</span>
                      <span class="domain">{{ ( opl.domain.name ) }}</span>
                      <a *ngIf="! session.currentUser!.primary_domain" class="btn btn-link btn-icon">
                        <i class="fa-fw fa-solid fa-chevron-down"></i>
                      </a>
                    </div>

                    <div class="text-sm" [title]="'Target Readers'" data-bs-toggle="collapse" data-bs-target="#categories" role="button" aria-expanded="false" aria-controls="categories">
                      <label class="form-label"><i class="icon fa fa-fw fa-users"></i> {{ 'Target Readers' | translate }}</label>
                      <span *ngIf="opl.categories.length <= 0" class="categories text-muted">&hellip; {{ 'select' | translate }} &hellip;</span>
                      <span *ngFor="let g of opl.categories" class="categories multiple-values">{{g.name}}</span>
                      <a class="btn btn-link btn-icon">
                        <i class="fa-fw fa-solid fa-chevron-down"></i>
                      </a>
                    </div>

                    <div *ngFor="let config of api.properties" class="text-sm" [title]="config.label" data-bs-toggle="collapse" [attr.data-bs-target]="'#prop-' + config.name" role="button" aria-expanded="false" aria-controls="properties">
                      <label class="form-label"><i class="icon fa fa-fw fa-{{ config.icon || 'wrench' }}"></i> {{ config.label }}</label>
                      <span *ngIf="! opl.properties![config.name]?.length" class="domain text-muted text-thin">&hellip; {{ 'select' | translate }} &hellip;</span>
                      <!-- <span *ngFor="let v of opl.properties[config.name]" class="properties multiple-values">{{ v }}</span> -->
                      <span *ngFor="let v of api.getProperties(config.name, opl.properties![config.name])" class="properties multiple-values"> {{ v }}</span>

                      <a class="btn btn-link btn-icon">
                        <i class="fa-fw fa-solid fa-chevron-down"></i>
                      </a>
                    </div>
                  </div>

                  <!-- value input control of meta data -->
                  <div id="attributes-group" class="accordian">
                    <div *ngIf="canSelectDomain" class="panel">
                      <div id="domain" class="collapse" data-bs-parent="#attributes-group">
                        <label class="control-label text-bold">{{ 'Domain' | translate }}</label>
                        <tree class="bg-trans pad-btm"
                          [options]="domain_options"
                          [data]="api.domains"
                          [expand]="true"
                          (selectedChange)="changeDomain($event)"
                          [(selected)]="selectedDomain"
                        >
                        </tree>
                      </div>
                    </div>
                    <div class="panel">
                      <div id="categories" class="collapse" data-bs-parent="#attributes-group">
                        <label class="control-label text-bold">{{ 'Target readers' | translate }}</label>
                        <tree class="bg-trans pad-btm"
                          [options]="categories_options"
                          [data]="api.categories"
                          [expand]="true"
                          [(selected)]="opl.categories"
                        >
                        </tree>
                      </div>
                    </div>
                    <div *ngFor="let config of api.properties" class="panel">
                      <div id="prop-{{ config.name }}" class="collapse" data-bs-parent="#attributes-group">
                        <custom-properties [config]="config" [(data)]="opl.properties![config.name]"></custom-properties>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- content edtor -->
                <div id="content-editor" class="content mar-top bord-all"></div>

                <!-- related documents -->
                <div class="card">
                  <div class="form-group">
                    <label class="control-label text-bold">{{ 'Related Documents' | translate }}</label>
                    <related-documents id="related-doc" name="related-docs"
                      [(ngModel)]="opl.references"
                      [min-char]="4" [max-items]="7"
                    >
                    </related-documents>
                  </div>
                </div>

                <!-- related training courses-->
                <div class="card">
                  <div class="form-group">
                    <label class="control-label text-bold">{{ 'Related Training Courses' | translate }}:</label>
                    <list-editor
                      [(list)]="courses"
                      [name]="'related-courses'"
                      [placeholder]="'Enter training course title or code'"
                      [lookupList]="lookup_courses"
                      (onLookup)="lookupCourses($event)"
                      (listChange)="onCoursesChanged($event)"
                    >
                      <ng-template #itemTemplate
                        let-item="item"
                        let-index="index"
                      >
                        <span class="code">{{ item.code }}</span>: <span class="title">{{ item.name }}</span>
                      </ng-template>
                    </list-editor>
                  </div>
                </div>

                <!-- quiz editor -->
                <div class="card quiz">
                  <div class="quiz">
                    <label class="control-label text-bold">{{ 'Quiz' | translate }}:</label>
                    <ul *ngIf="opl.questions!.length > 0">
                      <li *ngFor="let q of opl.questions">
                        <a class="btn-link" (click)="editQuestion(q)">{{ q.content }}</a>
                      </li>
                    </ul>
                    <div *ngIf="opl.questions!.length == 0" class="text-center text-sm text-italic text-thin">
                      - {{ 'No quiz avaialble for this course.' | translate }} -
                    </div>
                    <div class="text-right">
                      <button type="button" class="btn btn-labeled btn-primary" (click)="editQuestion(null)">
                        <i class="icon-lg fa fa-solid fa-plus"></i> {{ 'Add new question.' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <!--
                <div class="card">
                  <div class="quiz">
                    <div class="form-group">
                      <label class="control-label text-bold">
                        {{ 'Quiz' | translate }}
                      </label>
                      <panel *ngFor="let quiz of opl.questions; let i = index" class="pad-top pad-lft pad-rgt pad-btm bg-gray">
                        <div panel-control>
                          <button class="btn btn-warning text-right" [title]="'Remove'" (click)="quizRemove(i)"><i class="fa fa-times"></i></button>
                        </div>
                        <div panel-heading>
                        </div>
                        <div panel-body>
                          <div class="row">
                            <div class="col-xs-12">
                              <label class="control-label">{{ 'Question' | translate }}</label>
                              <textarea rows="2" autosize class="form-control mar-btm" style="resize: none;" [title]="'Type the question here.'" placeholder="{{ 'Type the question here...' | translate }}" name="quiz-question-{{ i }}" [(ngModel)]="opl.questions[i].question"></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12 col-sm-6">
                              <label class="control-label">{{ 'Correct Answers' | translate }}</label>
                              <textarea autosize rows="2" class="form-control mar-btm" style="resize: none;" [title]="'Correct answer here, one per line. At least 1 correct answer is required.'" placeholder="{{ 'Correct answer here, one per line..' | translate }}" name="quiz-correct-answer-{{ i }}" [(ngModel)]="opl.questions[i].correct_answers"></textarea>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                              <label class="control-label">{{ 'Incorrect Answers' | translate }}</label>
                              <textarea autosize rows="2" class="form-control mar-btm" style="resize: none;" [title]="'Incorrect answer here, one per line. At least 3 incorrect answers are required.'" placeholder="{{ 'Incorrect answer here, one per line..' | translate }}" name="quiz-incorrect-answer-{{ i }}" [(ngModel)]="opl.questions[i].incorrect_answers"></textarea>
                            </div>
                          </div>
                        </div>
                      </panel>
                      <div class="text-right">
                        <button type="button" class="btn btn-labeled btn-success" (click)="quizAdd()" [title]="'Add new question'"><i class="fa fa-plus-square btn-label"></i> {{ 'Add New Question' | translate }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                -->

                <!-- comment -->
                <div class="card">
                  <div class="comments">
                    <label class="control-label text-bold">
                      {{ 'Author Comments' | translate }}
                      <!--
                      <a class="btn btn-icon" data-bs-toggle="collapse" data-bs-target="#comments" role="button" aria-expanded="false" aria-controls="comments">
                        <i class="fa-fw fa-solid fa-chevron-down"></i>
                      </a>
                      -->
                    </label>

                    <div id="comments" class="comments collapse">
                      <!--
                      <comment *ngFor="let comment of opl.comments" [content]="comment" [format]="'full'"></comment>
                      -->
                      <comments [content]="opl.comments||[]" [readonly]="true"></comments>
                    </div>
                    <div class="board-top pad-btm new-comment">
                      <textarea rows="2" class="form-control" style="resize: none;" placeholder='{{ "You may add some comment here.." | translate }}' name="comment" [(ngModel)]="opl.comment"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- footer -->
        <div class="row mar-top pad-top">
          <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 footer-items">
            <!--
            <span *ngIf="is_read" class="mar-rgt label label-success" >
              <i class="fa fa-eye"></i>{{ "Read" | translate }}
            </span>
            -->
          </div>
          <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 text-right footer-items">
            <button (click)="save()" [attr.disabled]="busy ? '' : null" class="btn btn-icon btn-labeled btn-info"><i class="fa-fw fa-solid fa-save btn-label"></i>{{ 'Save' | translate }}</button>
            <button *ngIf="! quick_mode" (click)="submit()" class="btn btn-icon btn-labeled btn-success"><i class="fa-fw fa-solid fa-arrow-alt-circle-right btn-label"></i>{{ 'Submit' | translate }}</button>
            <button (click)="cancel()" [attr.disabled]="busy ? '' : null" class="btn btn-icon btn-labeled btn-default"><i class="fa-fw fa-solid fa-times-circle btn-label"></i>{{ 'Cancel' | translate }}</button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</full-page>

<modal template-id="rev-log-input" [size]="'small'" [hideCloseButton]="true" [fade]="true" #revlog>
  <div modal-title>
    {{ 'Revision Log Message' | translate }}
  </div>

  <div modal-body>
    <textarea
      name="log-msg"
      style="resize: none;"
      class="form-control"
      placeholder='{{ "Write revision log here.." | translate }}'
      autosize
      [rows]="2"
      [minHeight]="40"
      [(ngModel)]="log_msg">
    </textarea>
  </div>

  <div modal-footer>
    <button stop-propagation (click)="doSubmit()" [title]="'Submit'" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa fa-check-square"></i> {{ 'Submit' | translate }}
    </button>
  </div>
</modal>

<modal template-id="quiz-editor"
  size="large"
  [hideCloseButton]="true"
  #questionEditor
>
  <div modal-body>
    <quiz-editor
      [(question)]="question!"
      (onChoiceDeleted)="deleteChoice($event)"
      (onSave)="saveQuestion()"
      (onCancel)="cancelQuestion()"
    >
    </quiz-editor>
  </div>
</modal>