import {
  Component,
  OnInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy
} from '@angular/router';

import {
  Subject
} from 'rxjs';

import {
  object_t
} from '@pinacono/common';

/**
 *
 */
@Component({
  selector: 'base',
  template: '<div></div>'
})
export abstract class BasePageComponent implements OnInit, RouteReuseStrategy {

  // @TODO - considering should we use this pattern?
  //
  // ``` if ( (<BasePageComponent>this).signature === '???' ) { ... } ```
  // or just
  // ``` if ( this.refresh ) { ... } ```
  //
  // protected readonly signature: string = 'BasePageComponent';

  // route parameters
  protected url: string;
  protected params: object_t;

  // -- events

  protected afterRefresh: Subject<any> = new Subject();

  // -- lifecycle

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    this.url    = this.router.url;
    this.params = this.activatedRoute.snapshot.queryParams;
  }

  public ngOnInit(): void {
    this.refresh();
  }

  /**
   * internal framework - override with cares
   */
  protected dont_refresh: boolean = false; // @TODO - considering remove this feature?
  public refresh() {
    if ( this.dont_refresh ) {
      this.dont_refresh = false;
      this.afterRefresh.next(undefined);
    }
    else {
      // load data on next cycle to avoid
      // NG0100: ExpressionChangedAfterItHasBeenCheckedError
      setTimeout( () => {
        this.loadData()
        .then( (res: any) => {
          this.afterRefresh.next(res);
        });
      });
    }
  }

  // -- framework

  // ---- route strategies

  /**
   * Determines if a route should be reused
   *
   * @param next next route
   * @param current current route
   * @returns boolean - true = reuse current route
   */
   public shouldReuseRoute(next: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * Determines if this route (and its subtree) should be detached to be reused later
   *
   * @param route
   * @returns boolean
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * Stores the detached route.
   *
   * @param route
   * @param handle
   */
  public store(route: ActivatedRouteSnapshot, handle: {}): void {
  }

  /**
   * Determines if this route (and its subtree) should be reattached
   *
   * @param route
   * @returns boolean
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * Retrieves the previously stored route
   *
   * @param route
   */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
    return null;
  }

  /**
   * override to load data for the page
   */
  protected loadData(): Promise<any> {
    return Promise.resolve();
  };
}
