<full-page title="'Reports'">
  <div page-content>
    <form id="report-form" ngForm="reportForm">
      <div class="pad-all">
        <div class="row">
          <!-- Report template -->
          <panel class="col-md-4">
            <div panel-heading>
              All Documents
            </div>
            <div panel-body>
              <p class="description">
                List all documents with basic information.
              </p>
              <form class="row" #params="ngForm">
              </form>
              <div class="text-right pt-1">
                <button class="btn btn-default btn-labeled" (click)="run('all', params)">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

        </div>
      </div>
    </form>
  </div>
</full-page>