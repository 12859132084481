<!-- Redirector Page -->
<minimal-page>
  <div id="container">
    <div class="cls-content">
      <div class="cls-content-lg">
        <h1 class="text-muted"><i class="pli-cat icon-5x"></i></h1>
        <p class="h4 text-bold">{{ 'Redirecting ...' | translate }}...</p>
        <div class="pad-btm">
          <button *ngIf="url" class="btn btn-info text-large" (click)="onClick(url)">{{ 'Click to Redirect!' | translate }}</button>
          <div *ngIf="!url" class="sk-spinner sk-spinner-pulse"></div>
        </div>
      </div>
    </div>
  </div>
</minimal-page>
<!-- end of Redirecting Page -->