<full-page [title]="'Project Registration'">
  <div page-content>
    <panel class="doc-lib">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ project.title_prefix }} {{ project.title || 'New Project' }} {{ project.title_suffix }}
          </span>
          <span *ngIf="!! project.id" class="text-thin small"> - {{ project.documentable.doc_code }}</span>
        </h4>

        <form #mainForm="ngForm">

          <!-- upper block -->
          <div class="row">
            <!-- Right Panel -->
            <div class="col-xs-12 col-sm-12">

              <!-- section 1 (Reserve Mode): code reservation -->
              <div class="panel panel-default" id="doc-registration-form" aria-expanded="true">
                <div class="panel-body">

                  <!-- common registration attributes -->
                  <div class="pad-top row">
                    <!-- document code - locked on first save  -->
                    <div class="col">
                      <!-- document code display -->
                      <div class="form-group">
                        <label class="control-label text-bold">{{ 'Project Code' | translate }}:</label>
                        <span class="text-thin" #doccode>{{ docapi.getDocCode(project.documentable) }}</span>
                        <copy-button
                          [src]="doccode"
                          (copied)="onCodeCopied($event)"
                        ></copy-button>
                        <!-- document code inputs -->
                        <doc-code-editor *ngIf="project.documentable.code" #docCode
                          [(model)]="project.documentable.code"
                          [excludes]="[ '*' ]"
                          [includes]="[ 'PRJ' ]"
                          [hiddenComponents]="[ 'd', 'e' ]"
                          (onChange)="docCodeChange($event)"
                        >
                        </doc-code-editor>
                      </div>
                    </div>

                    <!-- domain -->
                    <!--
                    <div class="col form-group">
                      <label class="text-bold form-label">{{ 'Registered by' | translate }}:</label>
                      <ngx-select #domain="ngModel"
                        class="full-width"
                        [items]="docapi.names['doc_code_formatter'].c"
                        required name="domain"
                        [(ngModel)]="proj_domain"
                        (selectionChanges)="setDomain()"
                      >
                        <ng-template ngx-select-option-selected let-option>
                          {{ option.data.value }}
                        </ng-template>
                        <ng-template ngx-select-option let-option>
                          {{ option.data.data.label }}
                        </ng-template>
                      </ngx-select>
                      <validation-errors
                        [errors]="errors['domain']"
                      ></validation-errors>
                    </div>
                    -->

                  </div>

                  <!-- document title -->
                  <div class="pad-top row" id="doc-title">
                    <div class="col-xs-12 form-group">
                      <label class="control-label text-bold">{{ 'Project Title' | translate }}:</label>
                      <div class="row">

                        <!-- document prefix -->
                        <div class="col-3 ps-0">
                          <div>
                            <ngx-select #prefix="ngModel"
                              name="doc-project-prefix"
                              title="{{ 'Select project title prefix' | translate }}"
                              class="full-width"
                              required
                              [attr.disabled]="project.documentable.status == 'draft' ? null : 1"
                              [items]="docapi.names['doc_prefix']['PRJ'] | objectvalues"
                              (selectionChanges)="markDirty()"
                              [(ngModel)]="project.title_prefix"
                            >
                              <ng-template ngx-select-option-selected let-option>
                                {{ option.value }}
                              </ng-template>
                              <ng-template ngx-select-option let-option>
                                {{ option.value }}
                              </ng-template>
                            </ngx-select>
                            <validation-errors
                              [models]="prefix"
                              [errors]="errors['prefix']"
                              [messages]="{ required: 'Title prefix is required' }"
                            ></validation-errors>
                          </div>
                        </div>

                        <!-- document title -->
                        <div class="col-6">
                          <input #title="ngModel" type="text" class="form-control"
                            name="title"
                            required
                            [unique]="{
                              min: 5,
                              url: 'projlib.validation.title.unique',
                              params: getFullTitle()
                            }"
                            (change)="markDirty()"
                            [(ngModel)]="project.title"
                          >
                          <validation-errors
                            [models]="title"
                            [errors]="errors['title']"
                            [messages]="{
                              required: 'Project title is required',
                              unique:   'Title, prefix and suffix must be unique'
                            }"
                            ></validation-errors>
                        </div>

                        <!-- document suffix -->
                        <div class="col-3 pe-0" id="title-suffix">
                          <ngx-select #suffix="ngModel"
                            name="doc-project-suffix"
                            title="{{ 'Select project title suffix' | translate }}"
                            required
                            [attr.disabled]="project.documentable.status == 'draft' ? null : 1"
                            [items]="api.options['project_title_suffix'] | objectvalues"
                            (selectionChanges)="markDirty()"
                            [(ngModel)]="project.title_suffix"
                          >
                            <ng-template ngx-select-option-selected let-option>
                              {{ option.value }}
                            </ng-template>
                            <ng-template ngx-select-option let-option>
                              {{ option.value }}
                            </ng-template>
                          </ngx-select>
                          <validation-errors
                          [models]="suffix"
                          [errors]="errors['suffix']"
                          [messages]="{ required: 'Title prefix is required' }"
                        ></validation-errors>
                      </div>

                      </div>
                    </div>
                  </div>

                  <!-- action buttons -->
                  <div class="pad-top text-right">
                    <button *ngIf="can_reserve"
                      type="button"
                      class="ms-3 btn btn-primary"
                      (click)="lockID()"
                    >
                      {{ 'Reserve Code' | translate }}
                    </button>
                  </div>

                </div>
              </div>
              <!-- end of section 1 -->

            </div>
            <!-- end of right panel -->

          </div>
          <!-- end of upper block -->

        </form>

      </div>
    </panel>
  </div>
</full-page>
