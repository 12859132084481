import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NavigationService,
  ServerService
} from '@pinacono/common';

import {
  BootboxInputOption,
  UIService
} from '@pinacono/ui';

import { ThemeService } from 'src/app/themes/theme.service';

@Component({
  selector: 'app-maximo',
  templateUrl: 'maximo.component.html',
  styleUrls: ['./maximo.component.scss']
})
export class MaximoComponent implements OnInit, AfterViewInit, OnDestroy {

  public url: string|null = null;
  protected target: string = 'new-maximo-page'; // target windows

  // configuration
  protected showUrlParams: boolean = true;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected server: ServerService,
    protected ui: UIService,
    protected theme: ThemeService
  ) { }

  public ngOnInit(): void {
    this.target = (this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params['target']) || 'new-maximo-page';

    this.server.rejectOnError(true)
    .request('maximo.redirect')
    .then( (urls) => {
      if ( ! urls ) {
        // user do not have associated URL. Just go back
        this.ui.alert("Cannot find Maximo user account.");
        this.nav.back();
        return;
      }

      // each user may have more than one URL associated with them
      let names: string[] = Object.getOwnPropertyNames(urls);
      if ( names.length == 1 ) {
        this.go(urls[names[0]]);
        return;
      }
      else {
        let options: BootboxInputOption[] = [];
        for ( let i in names ) {
          options.push({ text: names[i], value: urls[names[i]] })
        }
        options[0].default = true;
        this.ui.prompt('Please select CMMS user.', undefined, (url: string) => {
          if ( !! url ) {
            this.go(url);
          }
          else {
            this.nav.back();
          }
        }, null, 'select', { inputOptions: options });
      }
    },

    (err) => {
      let message: string = err.status == 404 ? 'Cannot find your Maximo user account!' : err.error.message || 'Unknown error!';
      this.ui.alert(message, undefined, 'Error');
      this.nav.setRoot('home');
    });

  }

  public ngAfterViewInit(): void {
    //console.log('url params = ', this.activatedRoute.snapshot.queryParams);
    this.theme.showLoading(false); // disable loading overlay
  }

  public ngOnDestroy(): void {
    this.theme.showLoading(true); // re-enable loading overlay
  }

  public go(url: string) {
    // 'POST to new tab' to hide user id and password in URL
    let parts = url.split('?');

    let form = document.createElement('form');
    form.hidden = true;

    // extract additional query and set corresponding url
    if ( this.activatedRoute.snapshot.queryParamMap.keys.length == 0 ) {
      parts[0] += '/login';
    }
    else if (this.showUrlParams) {
      const query = this.activatedRoute.snapshot.queryParams;
      let params = [];
      for ( let name in query ) {
        params.push(`${name}=${query[name]}`);
      }
      parts[0] += '/maximo.jsp?' + params.join('&');
    }
    else {
      parts[0] += '/maximo.jsp';
    }


    if ( ! this.showUrlParams ) {
      let query = this.activatedRoute.snapshot.queryParams;
      for ( let name in query ) {
        let field = document.createElement('input');
        field.setAttribute('name', name);
        field.setAttribute('value', query[name]);
        form.appendChild(field);
      }
    }

    form.setAttribute('method', 'post');
    form.setAttribute('action', parts[0]);
    form.setAttribute('target', this.target);

    let params = new URLSearchParams(parts[1]);

    let field_login = document.createElement('input');
    field_login.setAttribute('name', 'login');
    field_login.setAttribute('value', params.get('login') || '');
    form.appendChild(field_login);

    let field_username = document.createElement('input');
    field_username.setAttribute('name', 'username');
    field_username.setAttribute('value', params.get('username') || '');
    form.appendChild(field_username);

    let field_password = document.createElement('input');
    field_password.setAttribute('name', 'password');
    field_password.setAttribute('value', params.get('password') || '');
    form.appendChild(field_password);

    document.body.appendChild(form);

    let win = window.open(undefined, this.target);
    setTimeout( () => {
      if ( win !== null ) {
        win.focus();
      }
      form.submit();
      if ( this.target != '_self' ) {
        this.nav.setRoot('home');
      }
    }, 100);
  }
}
