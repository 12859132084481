import { Component, Input } from "@angular/core";
import { BookInstance } from "../types";

@Component({
  selector: 'assets',
  templateUrl: 'assets.html'
})
export class AssetsComponent {
  @Input() title: string = 'Assets';
  @Input() items?: BookInstance[] = [];
}