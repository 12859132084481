import {
  Component, Inject,
  OnInit
} from '@angular/core';

import {
  APP_INFO,
  AppInfo
} from '@pinacono/common';

import * as packages from 'package-lock.json';

declare let CLIENT_CONFIG: any;

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit {

  public CLIENT_CONFIG = CLIENT_CONFIG;
  protected packages: any = packages;

  constructor(
    @Inject(APP_INFO) public app_info: AppInfo
  ) { }

  public client_packages: any[] = [];

  public ngOnInit(): void {
    const target_modules = [
      '@angular/core',
      '@fortawesome/fontawesome-free',
      '@ng-bootstrap/ng-bootstrap',
      '@ngx-translate/core',
      '@pinacono/common',
      '@pinacono/ui',
      '@pinacono/slickgrid-extension',
      '@pinacono/iip-viewer',
      '@pinacono/d3',
      '@pinacono/diagram',
      '@pinacono/notifications',
      'd3'
    ];

    for ( let module of target_modules ) {
      let name = `node_modules/${module}`;
      let version: string = 'Not Installed';

      if ( !! this.packages.packages[name] ) {
        if ( ! this.packages.packages[name].link ) {
          version = this.packages.packages[name].version as string;
        }
        else {
          version = this.packages.packages[this.packages.packages[name].resolved].version as string;
        }
      }
      this.client_packages.push({
        name: module,
        version: version
      });
    }
  }
}
