import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  NgForm,
  ValidationErrors
} from '@angular/forms';

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  Taxonomy
} from '@pinacono/common';

import {
  InterpolatbleErrorMessage,
  UIService
} from '@pinacono/ui';

import { DocLibService } from 'src/app/modules/documents/doclib.service';
import { DocCode } from 'src/app/modules/documents/types';

import {
  Project
} from '../types';

import {
  ProjectLibService
} from '../projects.service';
import { DocCodeEditorComponent } from '../../documents/components/doc-code/doc-code-editor.component';

@Component({
  selector: 'page-proflib-register',
  templateUrl: 'register.html',
  styleUrls: ['register.scss']
})
export class ProjectLibRegisterPage implements OnInit {

  @ViewChild('mainForm') mainForm!: NgForm;
  @ViewChild('docCode') docCode!: DocCodeEditorComponent;

  // -- configurations

  // enable/disable accordion layout
  public use_accordion: boolean = false;
  public locked_code: DocCode = {
    a: '',
    b: '',
    c: '',
    d: '',
    e: '',
    f: 'PRJ',
    g: ''
  }

  // -- template variables

  public project: Project;
  public proj_domain: string = '';
  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[];
  } | ValidationErrors = {};

  // ----------------------------------------------------
  // -- Lifecycle
  // ----------------------------------------------------

  constructor(
    public api: ProjectLibService,
    public docapi: DocLibService,
    protected nav: NavigationService,
    protected server: ServerService,
    protected session: SessionService,
    protected ui: UIService
  ) {
    this.project = api.createProject();
  }

  public ngOnInit(): void {
  }

  // ----------------------------------------------------
  // -- flags and control attributes
  // ----------------------------------------------------

  /**
   * if the doc is ready for code reservation
   */
  public get can_reserve(): boolean {
    return this.project.documentable.status == 'draft'// && this.validate();
  }

  /**
   * if user can manage this document (aka. DCC Manager)
   */
  public get can_manage(): boolean {
    return this.session.hasPermission(['doc_manage']);
  }

  /**
   * mark the document is 'dirty" (aka. being edited)
   * always return true to allow the template process
   * the next statement
   */
  public markDirty() {
    this.mainForm.form.markAsDirty();
    this.validate();
  }

  // ----------------------------------------------------
  // -- Template API
  // ----------------------------------------------------

  /**
   * validation logic based on action and document status
   * trig error message or pop error message
   */
  protected async validate(): Promise<boolean> {
    this.errors = await this.ui.validateForm(this.mainForm);
    // additional validations
    /*
    if ( ! this.project.title ) {
      this.errors['title'] = 'Project title is required';
    }
    */

    /*
    if ( ! this.project.documentable.domain_id ) {
      this.errors['domain'] = 'Approval Domain is required';
    }
    */

    return Promise.resolve( Object.getOwnPropertyNames(this.errors).length + this.docCode.errors.length == 0);
  }

  public getFullTitle(): () => object_t {
    // callback need to be wrapper or they will not bind 'this'
    return () => {
      return {
        prefix: this.project.title_prefix,
        title:  this.project.title,
        suffix: this.project.title_suffix
      }
    }
  }

  /*
  public setDomain() {
    // ngx-select bug? domain value not immediately updated
    setTimeout( () => {
      // @TODO - map domain code to domain id
      // lookup from 'categories'
      let domain = this.docapi.getDepartmentTerm(this.proj_domain);
      if ( !! domain ) {
        this.project.documentable.domain_id = domain.id || 0;
        this.project.documentable.domain = domain;
      }
      this.markDirty();
    });
  }
  */

  /**
   * lock and reserve document code
   */
   public async lockID() {
    if ( ! this.can_reserve ) {
      return;
    }

    if ( ! await this.validate() ) {
      this.ui.alert('Registration information is incompleted. Please check!');
      return;
    }

    let domain = this.docapi.getDepartmentTerm('ENG');

    this.ui.confirm('Once the project is registered, the project code will be no longer editable (locked). Register the project now?', undefined, () => {
      this.server.rejectOnError()
      //.simulate() // @TODO - remove
      .request('projlib.register', null, {
        prefix: this.project.title_prefix || null,
        title: this.project.title,
        suffix: this.project.title_suffix || null,
        status: 'draft',
        code: this.docapi.getDocCode(this.project.documentable),
        //domain_id: this.project.documentable.domain_id,
        domain_id: domain!.id,
        categories: this.project.documentable.categories.map( (c:Taxonomy) => c.id )
      })
      .then(
        (res: object_t) => {
          this.nav.goto(['/project/edit/', res['id']]);
        },
        (err: Error) => {
          this.ui.alert('Error lock document code - {{reason}}', { reason: err });
        }
      );
    });
  }

  /**
   * Reaction on document code copying
   */
   public onCodeCopied(text: string) {
    this.ui.alert('Document code: {{ code }} is successfully copied to clipboard', { code: text });
  }

  public docCodeChange(segment: string) {
    // change event raised before value in the model get updated
    // therefore, we validate it on the next cycle with setTimeout
    setTimeout( () => {
      this.project.documentable.type = this.project.documentable.code!.f;

      if ( segment == 'f' ) {
        return;
      }

      // adjust document allowed group according to c-segment
      /*
      if ( this.project.code && this.project.code.c && this.can_manage ) {
        let node = this.api.getDepartmentTerm(this.project.code.c);
        if ( node !== null ) {
          this.categoriesTreeComponent.unselectAll();
          this.categoriesTreeComponent.select([node.id!]);
        }
      }
      */

      this.markDirty();
    });
  }
}
