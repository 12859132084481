import { Component } from '@angular/core';
import { NavigationService } from '@pinacono/common';

@Component({
  selector: 'app-e500-page',
  templateUrl: 'error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component {

  constructor(
    public nav: NavigationService
  ) {}

  public search(keyword: string) {
    this.nav.naviateByUrl(`/search/${keyword}`);
  }
}
