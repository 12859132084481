<full-page [title]="'Library'" class="library-books-browse">
  <div page-content>

    <!-- Search bar -->
    <search (keywordChange)="search($event)"></search>

    <panel>
      <div panel-heading>
        {{ 'Library' | translate }}
      </div>
      <div panel-body>
        <!--
        <extended-slickgrid *ngIf="!! gridOptions" gridId="main-table"
          class="row-selectable"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
        -->
        <div class="books row">
          <div *ngFor="let book of data" class="book clickable col-3">
            <div class="p-2" (click)="selectBook(book)" #book_cover>
              <!-- check https://unitegallery.net/index.php?page=tilesgrid-options -->
              <gallery class="img-responsive"
                stop-propagation
                [options]="{
                  gallery_theme: 'tilesgrid',
                  gallery_width: book_cover.offsetWidth,
                  tile_width: book_cover.offsetWidth,
                  tile_height: book_cover.offsetWidth * 1.6,
                  tile_enable_border: false,
                  grid_padding: 5,
                  grid_num_rows: 1,
                }"
                [items]="book.covers"
                [layout]="'normal'"
                [defaultImage]="'/assets/images/default-cover-300x480.png'"
                #cover
              >
              </gallery>
              <div class="book-title">
                {{ book.attr['title']?.prefix || '' }} {{ book.title }} {{ book.attr['title']?.suffix || '' }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data.length > 0" class="full-width text-center">
          <pagination
            [pageno]="pagination.pageno"
            [perpage]="pagination.perpage"
            [total]="pagination.total"
            (pagenoChange)="loadPage($event)"
          ></pagination>
        </div>
      </div>
    </panel>
  </div>
</full-page>

<!-- book instances information -->
<!--
<modal [template-id]="'modal-book-instance'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #modalBookInstances
>
  <div modal-title>
    {{ 'Book Instances' | translate }}
  </div>

  <div modal-body>

    // instances location list
    <div id="instances">

      <table class="table">
        <thead>
          <tr>
            <th>{{ 'Code' | translate }}</th>
            <th>{{ 'Location' | translate }}</th>
            <th>{{ 'Status' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let instance of instances">
            <td>
              <barcode *ngIf="!! instance.code" class="inline"
                [value]="instance.code"
                [format]="'CODE128'"
                [width]="1"
                [height]="30"
              ></barcode>
              <div *ngIf="! instance.code" class="list-empty">- n/a -</div>
            </td>
            <td>
              {{ instance.location.branch }} ตู้ {{ instance.location.cabinet || 'n/a' }} ชั้น {{ instance.location.shelf || 'n/a'}}
            </td>
            <td>
              {{ InstanceStatusMapping[instance.status] || instance.status }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    // end locations table

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="modalBookInstances.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>
-->

<!-- book reservation history -->
<!--
<modal [template-id]="'modal-book-reservation-history'"
  [size]="'xlarge'"
  [fade]="true"
  [hideCloseButton]="true"
  #modalBookReservationHistory
>
  <div modal-title>
    {{ 'Book Reservations History' | translate }}
  </div>

  <div modal-body>

    // reservations list
    <div id="reservations">
      <extended-slickgrid *ngIf="!! reservationGridOptions" gridId="history-table"
        [columnDefinitions]="reservationGridColumnDefinitions"
        [gridOptions]="reservationGridOptions"
        [dataset]="reservations"
        (onAngularGridCreated)="onReservationGridReady($event)"
        >
      </extended-slickgrid>
    </div>
    // end locations table

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="closeHistory();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>
-->