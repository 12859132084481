/**
 * routes for 'opl' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { TrainingDashboardPage } from './dashboard/dashboard';

import { TrainingCoursesListPage } from './course/list';
import { TrainingCourseViewPage } from './course/view';
import { TrainingCourseEditPage } from './course/edit';

import { TrainingCompetenciesListPage } from './competency/list';
import { TrainingCompetencyViewPage } from './competency/view';

import { TrainingRecordsListPage } from './record/list';
import { TrainingRecordViewPage } from './record/view';
import { TrainingTeamPage } from './team/team';

import { TrainingGapsViewPage } from './gap/view';
import { TrainingGapsSimulatorPage } from './gap/simulator';
import { TrainingGapsComparatorPage } from './gap/comparator';

import { TrainingRequestsListPage } from './request/list';
import { TrainingBatchesListPage } from './batch/list';
import { TrainingBatchViewPage } from './batch/view';

import { TrainingReportsPage } from './report/reports';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {

  training_dashboard: {
    path: 'training/dashboard',
    component: TrainingDashboardPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  training_courses_browse: {
    path: 'training/courses/:uid',
    component: TrainingCoursesListPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_courses_view: {
    path: 'training/courses/view/:id',
    component: TrainingCourseViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_courses_edit: {
    path: 'training/courses/edit/:id',
    component: TrainingCourseEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          hasPermissions: [ 'training_admin' ]
        }
      }
    }
  },

  training_competencies_browse: {
    path: 'training/competencies',
    component: TrainingCompetenciesListPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_competency_view: {
    path: 'training/competencies/view/:id',
    component: TrainingCompetencyViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  training_records_list_mine: {
    path: 'training/records/mine',
    component: TrainingRecordsListPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_records_view: {
    path: 'training/records/view/:id',
    component: TrainingRecordViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  training_team: {
    path: 'training/team',
    component: TrainingTeamPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true,
          hasPermissions: [ 'core_manage_group' ]
        }
      }
    }
  },
  training_gap_view: {
    path: 'training/gaps/view/:id',
    component: TrainingGapsViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_gap_simulator: {
    path: 'training/gaps/simulator',
    component: TrainingGapsSimulatorPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_gap_comparator: {
    path: 'training/gaps/compare',
    component: TrainingGapsComparatorPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  training_requests_list: {
    path: 'training/requests',
    component: TrainingRequestsListPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true,
          hasPermissions: [ 'training_mentor' ]
        }
      }
    }
  },
  training_batches_list: {
    path: 'training/batches',
    component: TrainingBatchesListPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true,
          hasPermissions: [ 'training_mentor' ]
        }
      }
    }
  },
  training_batch_view: {
    path: 'training/batches/view/:id',
    component: TrainingBatchViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true,
          hasPermissions: [ 'training_mentor' ]
        }
      }
    }
  },
  training_reports: {
    path: 'training/reports',
    component: TrainingReportsPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true,
          hasPermissions: [ 'training_manage' ]
        }
      }
    }
  },
};