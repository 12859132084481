import { Injectable } from '@angular/core';

import moment from 'moment-timezone';

import {
  object_t, TimeStampedModel,
  StringUtilsService,
  ServerService,
  SessionService,
  NavigatableLink
} from '@pinacono/common';

import { Comment, ExamQuestion, ExamChoice } from './types';
import { Document } from '../modules/documents/types';

@Injectable({
  providedIn: 'root'
})
export class AppCommonService {
  constructor(
    protected server: ServerService,
    protected stringUtils: StringUtilsService,
    protected session: SessionService
  ) {
  }

  public createComment(data: object_t|null = null): Comment {
    return this.server.createTimeStampedModel({
      id:   data && data['id']   || null,
      user: data && data['user'] || this.session.currentUser || null,
      comment:      data && data['comment'] || null,
      content_id:   data && data['content_id'] || null,
      content_type: data && data['content_type'] || null, // error!!!
      attachments:  (data && data['attachments'] || []).map( (a: object_t) => this.server.createAttachment(a)) || [],
      attr:         data && data['attr'] || null,
    } as TimeStampedModel, data) as Comment
  }

  public createExamChoice(data: object_t|null = null) : ExamChoice {
    return this.server.createTimeStampedModel({
      id: ( data && data['id'] ) || null,

      content:     ( data && data['content'] ) || '',
      is_correct:  ( data && data['is_correct'] ) || false,

      attachments: ( data && data['attachments'] ) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      attr: ( data && data['attr'] ) || {},
    } as TimeStampedModel) as ExamChoice;
  }

  public createExamQuestion(data: object_t|null = null) : ExamQuestion {
    return this.server.createTimeStampedModel({
      id: ( data && data['id'] ) || null,

      content: ( data && data['content'] ) || '',
      choices: ( data && data['choices'] && data['choices'].map( (c: object) => this.createExamChoice(c) ) ) || [],

      author_id: ( data && data['author'] && data['author']['id'] )  || null,
      author:    ( data && data['author'] )  || null,

      defer_key: ( data && data['defer_key'] ) || this.stringUtils.random(16),
      level: 0,

      attachments: ( data && data['attachments'] ) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      attr: ( data && data['attr'] ) || {},
    } as TimeStampedModel) as ExamQuestion;
  }

  /** note - considering using 'documentable_type' and 'documentable_id' instead? */
  /*
  public generateLinkToModuleContent(rev: Revision, action: string = 'view'): NavigatableLink {
    if ( ! this.hasModuleContent(rev) ) {
      console.warn("This revision does not contains module's content! - redirect to Home");
      return '/home';
    }
    return `/${rev.attr!['type']}/${action}/${rev.attr!['link_id']}`;
  }

  public hasModuleContent(revision: Revision): boolean {
    return !! revision.attr && revision.attr['link_id'] && revision.attr['type'];
  }
  */

  public generateLinkToModuleContent(doc: Document, action: string = 'view'): NavigatableLink {
    if ( ! this.hasModuleContent(doc) ) {
      console.warn("This revision does not contains module's content! - redirect to Home");
      return '/home';
    }
    return `/${doc.documentable_type}/${action}/${doc.documentable_id}`;
  }

  public hasModuleContent(doc: Document): boolean {
    return !! doc.documentable_type && doc.documentable_type != 'doc' && !! doc.documentable_id;
  }

  /**
   * return current date/time
   */
  public now(): Date {
    return moment().toDate();
  }
}
