import {
  Component,
  OnInit,
  ViewChild
} from "@angular/core";

import {
  config,
  MIMEUtilsService,
  NavigationService,
  ServerService,
} from "@pinacono/common";

import {
  ModalComponent,
  UIService
} from "@pinacono/ui";

import { IIP } from '@pinacono/iip-viewer';

import {
  CustomPropertyConfig
} from 'src/app/common/types';

import {
  Drawing,
  IIPDrawingPrototypeShape,
  SupplementData,

  Project,
  ProjectDrawing
} from '../../types';

import { ProjectLibService } from '../../projects.service';

@Component({
  selector: 'search-dwg-page',
  templateUrl: 'search.html',
  styleUrls: [ 'search.scss' ]
})
export class DrawingSearchPage implements OnInit {

  @ViewChild('polygonEditor') polygonEditor!: ModalComponent;

  public search_input:Drawing;

  public drawing: Drawing;
  public iip_base_path: string;
  public enableDrawingTools: boolean = true;

  public buildings_config: CustomPropertyConfig = {
    name: 'building',
    type: 'editable-list',
    label: 'Building',
    icon: 'fa-regular fa-building',
    options: []
  };

  public floors_config: CustomPropertyConfig = {
    name: 'floor',
    type: 'editable-list',
    label: 'Floor',
    icon: 'fa-regular fa-building',
    options: []
  }

  public supplementsList: SupplementData[] = [];
  public masterDrawingsList: Drawing[] = [];
  public polygons: IIP.Polygon[] = []

  // -- lifecycle

  constructor(
    public api: ProjectLibService,
    public mimeUtils: MIMEUtilsService,
    protected server: ServerService,
    protected nav: NavigationService,
    protected ui: UIService
  ) {
    this.iip_base_path = config('client.drawing.iip_base_path')
    this.drawing = this.api.createDrawing();
    this.search_input = this.api.createDrawing();
  }

  public ngOnInit(): void {
    this.buildings_config.options = this.api.getBuildings();
  }

  public ngAfterViewInit(): void {
  }

  // -- template API

  public onBuildingChange(value: string): void {
    this.search_input['attr']['floor'] = '';
    this.floors_config.options = this.api.getFloors(value);
  }

  // search for master drawings
  public searchMaster(): void {
    this.polygonEditor.hide();
    this.supplementsList = [];
    this.enableDrawingTools = true;

    this.api.searchMasters(this.search_input)
    .then( (list: Drawing[]) => {
      this.masterDrawingsList = list;
      // reset
      this.drawing = this.api.createDrawing();
      this.polygons = [];
    });
  }

  //public polygonPrototype = IIPDrawingPrototypeShape;
  public polygonPrototype: IIP.Coordinate[] = Array.from(IIP.DefaultPrototypePolygon);
  public async onDrawingZoom(view: IIP.ViewPort) {
    this.polygonPrototype = IIP.DefaultPrototypePolygon.map( (v: IIP.Coordinate) => ({
      x: ( v.x / view.scale.w ) * 0.75,
      y: ( v.y / view.scale.h ) * 0.75
    }));
  }

  // search for all drawings
  public async search() {
    this.polygonEditor.hide();
    this.masterDrawingsList = [];

    this.supplementsList = await this.api.search(this.drawing, this.polygons);
    this.drawing = this.api.createDrawing();
    this.polygons = [];
  }

  public castToDrawing(sup: SupplementData): Drawing {
    return sup.document as Drawing;
  }

  public castToProject(sup: SupplementData): Project {
    return sup.document as Project;
  }

  public castToProjectDrawing(sup: SupplementData): ProjectDrawing {
    return sup.document as ProjectDrawing;
  }

  public selectMasterDrawing(dwg: Drawing): void {
    this.drawing = dwg;
    this.polygonEditor.show();
  }

  public selectSupplement(doc: SupplementData) : void {
    if ( doc.type == 'drawing' ) {
      this.drawing = this.api.createDrawing(doc.document);
      this.enableDrawingTools = false;
      this.polygonEditor.show();
    }

    if ( doc.type == 'project') {
      this.nav.push('/project/view/' + doc.document.id);
    }

    if ( doc.type == 'project_drawing' ) {
      this.drawing = this.api.createDrawing( (doc.document as ProjectDrawing).revisions[0] );
      this.enableDrawingTools = false;
      this.polygonEditor.show();
    }
  }
}
