import { User } from '@pinacono/common';
import { Menu } from "src/app/themes/types";
import { AppUser } from 'src/app/types';

export const MENUS: { [name: string]: Menu[] } = {

  // -- for guests
  LOGIN_MENU: [
    {
      id: 'login',
      type: 'entry',
      label: 'Login',
      description: 'Login to System.',
      link:'/login',
      icon: 'fa-rular fa-sign-in',
      visible_for: [
        { permission_name: 'Guest Only', gid: 0 }
      ]
    },
    {
      id: 'resetpass',
      type: 'entry',
      label: 'Forget Password',
      description: 'Reset password by sending password reset link to your e-mail.',
      link: '/forget',
      icon: 'pli-unlock-2',
      visible_for: [
        { permission_name: 'Guest Only', gid: 0 }
      ]
    },
  ],

  // -- for logged-in users
  USER_MENU: [
    {
      id: 'profile',
      type: 'entry',
      label: 'Profile',
      description: 'User Profile Setting.',
      link: '/me',
      icon: 'pli-profile',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ]
    },
    {
      id: 'logout',
      type: 'entry',
      label: 'Logout',
      description: 'Logout from System.',
      link: '/logout',
      icon: 'fa-solid fa-sign-out-alt',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ]
    }
  ]  ,

  // -- the main navigation
  MAIN_MENU: [
    {
      id: 'main.header',
      type: 'header',
      label: 'Main Menu'
    },

    {
      id: 'home',
      type: 'entry',
      label: 'Home',
      description: 'Home',
      link: '/home',
      icon: 'pli-globe',
    },

    // -- logged in users
    {
      id: 'module.header',
      type: 'header',
      label: 'Modules',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ]
    },

    // -- Maximo Module
    {
      id: 'maximo',
      //disabled: true,
      type: 'entry',
      label: 'Maximo',
      description: 'Redirect to Maximo',
      link: '/maximo',
      icon: 'pli-engineering',
      visible_for: (user: User|null) => !! (user && (user as AppUser).maximo_user)
    },

    // -- OPL Module
    {
      id: 'opl',
      //disabled: true,
      type: 'entry',
      label: 'OPL',
      description: 'One-Point Lesson',
      link: 'opl/browse/mine',
      badge: {
        name: 'opl-team',
        settings: {
          text: 'n/a',
          class: 'mint'
        }
      },
      icon: 'pli-teacher',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 },
        { permission_name: 'core_access_group', gid: 0 }
      ],
      children: [
        {
          id: 'opl-browse-team',
          type: 'entry',
          label: 'Team',
          description: 'List of my team\'s OPLs',
          link: '/opl/browse/team',
          badge: {
            name: 'opl-team',
            settings: {
              text: 'n/a',
              class: 'default'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-unread',
          type: 'entry',
          label: 'Unread',
          description: 'List of my "Unread" OPLs',
          link: '/opl/browse/unread',
          badge: {
            name: 'opl-unread',
            settings: {
              text: 'n/a',
              class: 'danger'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-read',
          type: 'entry',
          label: 'Read',
          description: 'List of my "Read" OPLs ',
          link: '/opl/browse/read',
          badge: {
            name: 'opl-read',
            settings: {
              text: 'n/a',
              class: 'default'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-all',
          type: 'entry',
          label: 'All',
          description: 'List of "All" OPLs',
          link: '/opl/browse/all',
          badge: {
            name: 'opl-all',
            settings: {
              text: 'n/a',
              class: 'default'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-edit',
          type: 'entry',
          label: 'Create',
          description: 'Creat new OPLs',
          link: '/opl/edit/new',
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-draft',
          type: 'entry',
          label: 'Drafts',
          description: 'List of my "Draft" OPLs',
          link: '/opl/browse/draft',
          badge: {
            name: 'opl-draft',
            settings: {
              text: 'n/a',
              class: 'default'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-submitted',
          type: 'entry',
          label: 'Submitted',
          description: 'List of my "Submitted" OPLs',
          link: '/opl/browse/submitted',
          badge: {
            name: 'opl-submitted',
            settings: {
              text: 'n/a',
              class: 'default'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-approved',
          type: 'entry',
          label: 'Approved',
          description: 'List of my "Approved" OPLs',
          link: '/opl/browse/approved',
          badge: {
            name: 'opl-approved',
            settings: {
              text: 'n/a',
              class: 'warning'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-rejected',
          type: 'entry',
          label: 'Rejected',
          description: 'List of my "Rejected" OPLs',
          link: '/opl/browse/rejected',
          badge: {
            name: 'opl-rejected',
            settings: {
              text: 'n/a',
              class: 'warning'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'opl-divider',
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-mgr-to-approve',
          type: 'entry',
          label: 'To Approve',
          description: 'List of OPLs wait for my approval',
          link: '/opl/browse/mgr-to-approve',
          badge: {
            name: 'opl-mgr-to-approve',
            settings: {
              text: 'n/a',
              class: 'success'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          id: 'opl-browse-mgr-rejected',
          type: 'entry',
          label: 'Rejected by Me',
          description: 'List of OPLs rejected by me',
          link: '/opl/browse/mgr-rejected',
          badge: {
            name: 'opl-mgr-rejected',
            settings: {
              text: 'n/a',
              class: 'pink'
            }
          },
          icon: 'pli-teacher',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          id: 'opl-divider2',
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'core_admin' }
          ]
        },
        {
          id: 'opl-report',
          type: 'entry',
          label: 'Reports',
          description: 'OPLs Reports',
          link: '/opl/reports',
          icon: 'pli-file-pie',
          visible_for: [
            { permission_name: 'core_admin' }
          ]
        },
      ]
    },

    // -- for candidate server
    /*
    {
      disabled: false,
      type: 'entry',
      label: 'Candidates',
      description: 'Candidates Competency Database',
      icon: 'pli-students',
      page: {
        component: TrainingCoursesListPage,
        params: {
          uid: 'self'
        }
      },
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ],
      children: [
        {
          disabled: false,
          type: 'entry',
          label: 'My Examinations',
          description: 'List of my examinations',
          page: {
            component: TrainingCoursesListPage,
            params: {
              uid: 'self'
            }
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: '???',
          type: 'divider',
          label: '',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 },
            { permission_name: 'training_mentor', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'Training Courses',
          description: 'List of training courses',
          page: {
            component: TrainingCoursesListPage,
            params: {
              uid: 'all'
            }
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'Competencies',
          description: 'List of competencies',
          page: {
            component: TrainingCompetenciesListPage,
            params: {
              uid: 'all'
            }
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'My Team',
          description: 'My Team\'s Training Records',
          page: {
            component: TrainingTeamPage
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'Manage Requests',
          description: 'Manage training requests',
          page: {
            component: TrainingRequestsListPage
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'training_mentor', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'Manage Batches',
          description: 'Manage training batches',
          page: {
            component: TrainingBatchesListPage
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'training_mentor', gid: 0 }
          ]
        },
        {
          id: '???',
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'Create New Course',
          description: 'Create New Course',
          page: {
            component: TrainingCourseEditPage,
            params: {
              id: 'new'
            }
          },
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'training_admin', gid: 0 }
          ]
        },
        {
          disabled: false,
          type: 'entry',
          label: 'Training Reports',
          description: 'Training Reports',
          page: {
            component: TrainingReportsPage
          },
          icon: 'pli-file-pie',
          visible_for: [
            { permission_name: 'training_admin', gid: 0 }
          ]
        }
      ]
    },
    */

    // -- training module
    {
      id: 'training-dashboard',
      disabled: false,
      type: 'entry',
      label: 'Competencies',
      description: 'Competencies Database',
      icon: 'pli-students',
      link: '/training/dashboard',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ],
      children: [
        {
          id: 'training-courses-browse',
          disabled: false,
          type: 'entry',
          label: 'Training Courses',
          description: 'List of all training courses',
          icon: 'pli-students',
          link: '/training/courses/all',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'training-competencies-browse',
          disabled: false,
          type: 'entry',
          label: 'Competencies',
          description: 'List of competencies',
          icon: 'pli-students',
          link: '/training/competencies',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'training-divider-1',
          disabled: false,
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'training-course-browse-mine',
          disabled: false,
          type: 'entry',
          label: 'My Courses',
          description: 'List of my planned courses',
          link: '/training/courses/mine',
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'training-records-browse-mine',
          disabled: false,
          type: 'entry',
          label: 'My Records',
          description: 'My Training Records',
          link: '/training/records/mine',
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'training-divider-2',
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 },
            { permission_name: 'training_mentor', gid: 0 }
          ]
        },
        {
          id: 'training-myteam',
          disabled: false,
          type: 'entry',
          label: 'My Team',
          description: 'My Team\'s Training Records',
          link: '/training/team',
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          id: 'training-requests-list',
          disabled: false,
          type: 'entry',
          label: 'Manage Requests',
          description: 'Manage training requests',
          link: '/training/requests',
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'training_mentor', gid: 0 }
          ]
        },
        {
          id: 'training-batches-list',
          disabled: false,
          type: 'entry',
          label: 'Manage Batches',
          description: 'Manage training batches',
          link: '/training/batches',
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'training_mentor', gid: 0 }
          ]
        },
        {
          id: 'training-divider-3',
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'core_manage_group', gid: 0 }
          ]
        },
        {
          id: 'training-create-course',
          disabled: false,
          type: 'entry',
          label: 'Create New Course',
          description: 'Create New Course',
          link: '/training/courses/edit/new',
          icon: 'pli-students',
          visible_for: [
            { permission_name: 'training_admin', gid: 0 }
          ]
        },
        {
          id: 'training-reports',
          disabled: false,
          type: 'entry',
          label: 'Training Reports',
          description: 'Training Reports',
          link: '/training/reports',
          icon: 'pli-file-pie',
          visible_for: [
            { permission_name: 'training_admin', gid: 0 }
          ]
        }
      ]
    },

    // -- Doc Lib
    {
      //disabled: true,
      id: 'doclib-browse-default',
      type: 'entry',
      label: 'Documents',
      description: 'Documents Lbrary',
      icon: 'pli-books',
      link: '/doc/browse/default',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ],
      children: [
        {
          id: 'doclib-reserve',
          type: 'entry',
          label: 'Reserve',
          description: 'Reserve document ID.',
          icon: 'pli-books',
          link: '/doc/reserve',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'doclib-browse-mine',
          type: 'entry',
          label: 'My Documents',
          description: 'List of my uploaded documents.',
          link: '/doc/browse/mine',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'doclib-browse-admin',
          type: 'entry',
          label: 'All Documents',
          description: 'List of all documents.',
          link: 'doc/browse/admin',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'doc_review' },
            { permission_name: 'doc_manage' }
          ]
        },
        {
          id: 'doclib-browse-reserved',
          type: 'entry',
          label: 'Reserved',
          description: 'List of reserved documents.',
          link: 'doc/browse/reserved',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'doc_review' },
            { permission_name: 'doc_manage' }
          ]
        },
        {
          id: 'doclib-browse-submitted',
          type: 'entry',
          label: 'Submitted',
          description: 'List of submitted documents.',
          link: 'doc/browse/submitted',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'doc_review' },
            { permission_name: 'doc_manage' }
          ]
        },
        {
          id: 'doclib-browse-reviewed',
          type: 'entry',
          label: 'Approved',
          description: 'List of approved documents.',
          link: 'doc/browse/reviewed',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'doc_manage' }
          ]
        },
        {
          id: 'doclib-browse-expired',
          type: 'entry',
          label: 'Expired',
          description: 'List of expiring documents',
          link: 'doc/browse/expired',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'doc_manage' },
            { permission_name: 'doc_review' }
          ]
        },
        {
          id: 'doclib-browse-deleted',
          type: 'entry',
          label: 'Deleted',
          description: 'List of deleted documents',
          link: 'doc/browse/deleted',
          icon: 'pli-books',
          visible_for: [
            { permission_name: 'doc_manage' },
            { permission_name: 'doc_review' }
          ]
        },
        {
          id: 'doclib-divider',
          label: '',
          type: 'divider',
          visible_for: [
            { permission_name: 'doc_manage' },
            { permission_name: 'doc_review' }
          ]
        },
        {
          id: 'doclib-reports',
          type: 'entry',
          label: 'Reports',
          description: 'Document Reports',
          link: 'doc/reports',
          icon: 'pli-file-pie',
          visible_for: [
            { permission_name: 'doc_manage' },
            { permission_name: 'doc_review' }
          ]
        }
      ]
    },

    // -- library
    {
      id: 'library-home',
      //disabled: true,
      type: 'entry',
      label: 'Library',
      description: 'Library Home',
      icon: 'pli-library',
      link: 'library/home',
      badge: {
        name: 'library-status',
        settings: {
          text: 'n/a',
          class: 'default'
        }
      },
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ],
      children: [
        {
          id: 'library-activities',
          type: 'entry',
          label: 'Activities',
          description: 'User Activities',
          icon: 'pli-user',
          link: 'library/user',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
      /*
        {
          //disabled: true,
          type: 'entry',
          label: 'My Library',
          description: 'Library Member Area',
          page: {
            component: LibraryMemberPage,
            params: {}
          },
          icon: 'pli-library',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        */
        {
          id: 'library-admin',
          type: 'entry',
          label: 'Librarian',
          description: 'Library Administration',
          link: 'library/admin',
          icon: 'pli-library',
          visible_for: [
            { permission_name: 'library_manage' }
          ],
          children: [
            /*
            {
              type: 'entry',
              label: 'Reservations',
              description: 'Manage Reservations',
              page: {
                component: LibraryBooksReservation,
                params: {}
              },
              icon: 'pli-library',
              visible_for: [
                { permission_name: 'library_manage' }
              ],
            },
            {
              disabled: true,
              type: 'entry',
              label: 'Equipments',
              description: 'Equipments Administration',
              page: {
                component: LibraryAssetsBrowse,
                params: {}
              },
              icon: 'pli-library',
              visible_for: [
                { permission_name: 'library_manage' }
              ],
            },
            {
              disabled: true,
              type: 'entry',
              label: 'Assets',
              description: 'Assets Administration',
              page: {
                component: LibraryAssetsBrowse,
                params: {}
              },
              icon: 'pli-library',
              visible_for: [
                { permission_name: 'library_manage' }
              ]
            },
            */
            {
              id: 'library-admin-audit',
              type: 'entry',
              label: 'Audit',
              description: 'Audit Library',
              link: 'library/admin/audit',
              icon: 'pli-library',
              visible_for: [
                { permission_name: 'library_manage' }
              ]
            },
            {
              id: 'library-admin-report',
              type: 'entry',
              label: 'Reports',
              description: 'Library Reports',
              link: 'library/admin/reports',
              icon: 'pli-library',
              visible_for: [
                { permission_name: 'library_manage' }
              ]
            }

          ]
        },
      ]
    },

    // -- project module
    {
      id: 'project-lib-dashboard',
      disabled: false,
      type: 'entry',
      label: 'Projects',
      description: 'Project Document Library',
      icon: 'pli-management',
      link: '/project/browse/default',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ],
      children: [
        {
          id: 'project-lib-register',
          disabled: false,
          type: 'entry',
          label: 'Register',
          description: 'Register new project',
          icon: 'pli-management',
          link: '/project/register',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ],
        },
        {
          id: 'project-lib-mine',
          disabled: false,
          type: 'entry',
          label: 'My Projects',
          description: 'Manage my projects',
          icon: 'pli-management',
          link: '/project/browse/mine',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'project-lib-release',
          disabled: false,
          type: 'entry',
          label: 'Releases',
          description: 'Releases Status',
          icon: 'pli-management',
          link: '/project/releases',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        },
        {
          id: 'project-lib-contact',
          disabled: false,
          type: 'entry',
          label: 'External Contacts',
          description: 'Manage external contacts',
          icon: 'pli-management',
          link: '/project/contact/browse/default',
          visible_for: [
            { permission_name: 'Logged In', gid: 0 }
          ]
        }
      ]
    },

    // -- drawing module
    {
      id: 'drawings-search',
      disabled: false,
      type: 'entry',
      label: 'Drawings',
      description: 'Drawings Library',
      icon: 'pli-map',
      link: '/drawings',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ],
      children: [
        {
          id: 'drawings-master',
          disabled: false,
          type: 'entry',
          label: 'Manage Masters',
          description: 'Master Drawings Management',
          icon: 'pli-map',
          link: '/drawings/masters',
          visible_for: [
            { permission_name: 'master_drawing_manage' }
          ]
        }
      ]
    },


    // -- forum
    {
      id: 'forum',
      //disabled: true,
      type: 'entry',
      label: 'Forum',
      description: 'SPW Forum',
      link: '/redirect?url=/forums', //url: 'http://opl.dev.pinacono.com/forums' // for dev
      icon: 'pli-smart',
      visible_for: [
        { permission_name: 'Logged In', gid: 0 }
      ]
    },

    // -- Administration
    {
      id: 'admin.header',
      //disabled: true,
      type: 'header',
      label: 'Administrations',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    {
      id: 'admin.dashboard',
      type: 'entry',
      label: 'Dashboard',
      description: 'Administration Dashboard',
      link: '/admin',
      icon: 'pli-bar-chart',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    {
      id: 'admin.groups',
      type: 'entry',
      label: 'Groups',
      description: 'Manage Group Members',
      link: 'admin/groups',
      icon: 'pli-mens',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    {
      id: 'admin.taxonomies',
      type: 'entry',
      label: 'Taxonomies',
      description: 'Manage Taxonomies',
      link: 'admin/taxonomies',
      icon: 'pli-tree-2',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    {
      id: 'admin.reports',
      type: 'entry',
      label: 'Reports',
      description: 'Administration Report',
      link: 'admin/reports',
      icon: 'pli-file-pie',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    {
      id: 'admin.notify',
      type: 'entry',
      label: 'LINE Notify',
      description: 'Register LINE Notify',
      link: 'admin/notify',
      icon: 'pli-bell',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    {
      id: 'admin.imports',
      type: 'entry',
      label: 'Import',
      description: 'Import Data',
      link: 'admin/imports',
      icon: 'pli-upload-2',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    },
    /**
     * @TODO - enable when finisih
    {
      id: 'admin.users',
      type: 'entry',
      label: 'Users',
      description: 'User Administration',
      link: 'admin/users',
      icon: 'pli-male-female',
      visible_for: [
        { permission_name: 'core_admin', gid: 0 }
      ]
    }
    */
  ]
}