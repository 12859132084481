<full-page [title]="'Training Batch'">
  <div *ngIf="!!batch" page-content>
    <panel class="pad-all">
      <div panel-control>
        <!--
        <button class="btn btn-success btn-icon">
          <i class="pli-upload-to-cloud"></i> Action
        </button>
        -->
      </div>
      <!--
      <div panel-heading>
        {{ 'My Training Records' | translate }}
      </div>
      -->
      <div panel-body>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3 form-group">

            <!-- attachments -->
            <label>{{ 'Attachments' | translate }}:</label>
            <div *ngIf="! user_is_mentor">
              <div *ngIf="batch.attachments && batch.attachments.length > 0">
                <gallery class="img-responsive" [items]="batch.attachments" [layout]="'plain'"></gallery>
                <download *ngFor="let file of batch.attachments" [file]="file"></download>
              </div>
              <div *ngIf="! batch.attachments || batch.attachments.length == 0">
                <div class="text-thin text-center">- No attachments avaialble -</div>
              </div>
            </div>
            <attachments *ngIf="batch.attachments && user_is_mentor && editable" #attachments
              [(files)]="batch.attachments"
              [master-content-type]="'training_batch'"
              [master-id]="batch.id"
              [defer-key]="batch.attachment_defer_key"
            >
            </attachments>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-9">

            <!-- course name -->
            <div class="form-group">
              <label>{{ 'Course' | translate }}:</label>
              <input *ngIf="! batch.id" class="form-control" type="text" [(ngModel)]="courseName">
              <a *ngIf="!! batch.id" class="btn btn-link" (click)="onCourseNameClick()">{{ courseName }}</a>
            </div>

            <div class="form-group">
              <label>{{ 'Competency' | translate }}:</label>
              <ul *ngIf="!! batch?.course?.levels">
                <li *ngFor="let level of batch.course.levels">
                  <a class="btn-link"
                    (click)="onCompetencyClick(level)"
                  >
                    {{ level.competency?.code || 'Unknown Competency Code' }} - {{ level.competency?.name || 'Unknown Competency Name' }} ({{ ( level.level - 1 ) || 0 }} <i class="pli-right"></i> {{ level.level || 0 }})
                  </a>
                </li>
              </ul>
            </div>

            <!-- batch name -->
            <div class="form-group">
              <label>{{ 'Batch Name' | translate }}:</label>
              <input class="form-control" type="text" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.name">
            </div>

            <!-- mentor -->
            <div class="form-group">
              <label>{{ 'Mentor' | translate }}:</label>
              <user-card *ngFor="let trainer of batch.trainers" [user]="trainer"></user-card>
            </div>

            <div class="row">
              <!-- capacity -->
              <div class="col-xs-6 form-group">
                <label>{{ 'Seats' | translate }}</label>
                <input class="form-control" type="number" min="1" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.capacity">
              </div>

              <!-- status -->
              <div class="col-xs-6 form-group">
                <label>{{ 'Status' | translate }}</label>
                <select class="select form-control" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.status">
                  <option [attr.selected]="batch.status == 'pending'     ? '' : null" value="pending">Pending</option>
                  <option [attr.selected]="batch.status == 'open'        ? '' : null" value="open">Open</option>
                  <option [attr.selected]="batch.status == 'full'        ? '' : null" value="full">Full</option>
                  <option [attr.selected]="batch.status == 'in_progress' ? '' : null" value="in_progress">In Progress</option>
                  <option [attr.selected]="batch.status == 'evaluation'  ? '' : null" value="evaluation">Evaluation</option>
                  <option [attr.selected]="batch.status == 'cancelled'   ? '' : null" value="cancelled">Cancalled</option>
                  <option [attr.selected]="batch.status == 'closed'      ? '' : null" value="closed">Closed</option>
                </select>
              </div>
            </div>

            <!-- schedule -->
            <div class="row">
              <div class="col-xs-12 col-sm-6 form-group">
                <label>{{ 'Start' | translate }}:</label>
                <input class="form-control" type="datetime-local" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.schedule_start_at">
              </div>
              <div class="col-xs-12 col-sm-6 form-group">
                <label>{{ 'Finish' | translate }}:</label>
                <input class="form-control" type="datetime-local" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.schedule_finish_at">
              </div>
            </div>

            <!-- venue -->
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label>{{ 'Venue' | translate }}:</label>
                  <input class="form-control" type="text" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.venue">
                </div>
              </div>
            </div>

            <!-- note -->
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label>{{ 'Note' | translate }}:</label>
                  <textarea class="form-control" autosize style="resize: none;" [attr.disabled]="editable ? null : 1" [(ngModel)]="batch.note">{{ batch.note }}</textarea>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div panel-footer>
        <div class="row">

          <!-- left -->
          <div class="col-xs-12 col-sm-6 footer-items pad-btm">
            <!-- keep as an example. to be removed
            <a class="btn-link" data-toggle="collapse" [attr.data-target]="'#comments-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseComment">
              <i class="pli-speech-bubble-5 icon-fw"></i>{{ content.comments.length }}
            </a>
            <a *ngIf="content.status == 'approved'" class="btn-link" data-toggle="collapse" [attr.data-target]="'#audiences-read-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseAudiences">
              <i class="fa far fa-eye icon-fw"></i>{{ content.audiences.read.length }}
            </a>
            -->
          </div>

          <!-- right -->
          <div class="col-xs-12 col-sm-6 text-right footer-items">
            <button type="button" class="btn btn-labeled btn-dark" (click)="back()">
              <i class="btn-label fa fas fa-chevron-circle-left"></i>{{ 'Back' | translate }}
            </button>
            <button *ngIf="editable && user_is_mentor && ( batch.status | not_in_array:['cancelled','closed'] ) " type="button" class="btn btn-labeled btn-danger" (click)="cancel()">
              <i class="btn-label fa far fa-times-circle"></i>{{ 'Cancel' | translate }}
            </button>
            <button *ngIf="editable && user_is_mentor" type="button" class="btn btn-labeled btn-primary" (click)="save()">
              <i class="btn-label fa far fa-save"></i>{{ 'Save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </panel>

    <panel *ngIf="!! batch" class="pad-all">
      <div panel-control>
        <button class="btn btn-success btn-icon"
          [attr.disabled]="editable && batch.attendees.length < batch.capacity ? null : 1"
          (click)="addAttendee()"
        >
          <i class="fa fas fa-user-plus"></i> {{ 'Add Attendee' | translate }}
        </button>
      </div>
      <div panel-heading>
        {{ 'Attendees Training Records' | translate }}
      </div>
      <div panel-body>
        <extended-slickgrid *ngIf="!!gridOptions"
          class="row-selectable"
          [gridId]="'training-batches-table'"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>

<modal #createBatchModal
  template-id="create-batch"
  size="large"
  [fade]="true"
  [hideCloseButton]="true"
  [allowEsc]="false"
>
  <div modal-title>
    <h5>{{ 'Create New Batch' | translate }}</h5>
  </div>
  <div modal-body>
    <lookup-input class="lookup"
      [placeholder]="'Enter course code or title'"
      [minChar]="3"
      [autoClose]="false"
      [items]="lookupCourseItems"
      [autoClose]="false"
      (onLookup)="lookupCourses($event)"
      (onSelect)="selectCourse($event)"
    >
    </lookup-input>
  </div>
  <div modal-footer>
    <button class="btn btn-danger btn-labelled" (click)="cancelCreateBatch()">
      {{ 'Cancel' | translate }}
    </button>
    <button class="btn btn-default btn-labelled" (click)="createNewBatch()">
      {{ 'Continue' | translate }}
    </button>
  </div>
</modal>

<modal #addAttendeeModal
  template-id="add-attendee"
  size="medium"
>
  <div modal-title>
    <h5>{{ 'Add Attendee' | translate }}</h5>
  </div>
  <div modal-body>
    <user-lookup
      [user]="newAttendee"
      [delay]="1000"
      (onSelect)="newAttendee = $event; addAttendeeModal.hide()"></user-lookup>
  </div>
</modal>