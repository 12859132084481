import {
  Component,
  OnInit
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  Column,
  FieldType,
  Filters,
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  GridOption
} from "angular-slickgrid";

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import {
  ExtendedFormatters,
  GraphQLServerService,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from "src/app/classes/base-page.component";

import {
  TrainingService
} from '../training.service';

import {
  TrainingBatch,
  TrainingBatchStatus
} from '../types';

@Component({
  selector: 'training-batches-list-page',
  templateUrl: 'list.html',
  styleUrls: [ 'list.scss' ]
})
export class TrainingBatchesListPage extends BasePageComponent implements OnInit {

  // grid
  protected gridInstance: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // models
  public batches: TrainingBatch[] = [];

  // -- initialization

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: ServerService,
    protected graphServer: GraphQLServerService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle

  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  protected silent: boolean = true;
  public override loadData(): Promise<void> {
    if ( !! this.gridInstance ) {
      this.silent = false;
      this.gridInstance.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.graphServer.silent() : this.graphServer;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.batches = re.data['trainingBatches'].nodes.map( (b: object) => this.api.createTrainingBatch(b) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.log('GraphQL Error:', error);
        }
      );
    });
  }

  protected initGrid() {
    let filters: GraphqlFilteringOption[] = [];

    if ( this.session.currentUser!.id != 1 ) {
      filters.push({
        field: 'trainer_id',
        operator: 'EQ',
        value: this.session.currentUser!.id.toString()
      });
    }

    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'trainingBatches',
          persistenceFilteringOptions: filters,
          //filteringOptions: filters,
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },
      enableSorting: true,
      //rowHeight: 80,
      autoHeight: true,
      forceFitColumns: true,
      enableAutoResize: true,
      autoResize: {
        calculateAvailableSizeBy: 'container',
        minHeight: 400
      },
      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };

    this.columnDefinitions = [
      {
        id: 'id', name: 'Batch ID',
        field: 'id',
        type: FieldType.number,
        cssClass: 'text-right', width: 20,
        sortable: true,
        filterable: true,
      },

      {
        id: 'course', name: 'Course',
        field: 'course.name',
        fields: ['course.name', 'course.levels.level', 'course.levels.competency.code', 'course.levels.competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 200,
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          let course = dataContext.course;
          return `<span title="${course.name}">${course.name}</span>`;
        }.bind(this)
      },

      {
        id: 'name', name: 'Batch Name',
        field: 'name',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 100 ,
        sortable: true,
        filterable: true
      },

      {
        id: 'schedule_start', name: 'Start At',
        field: 'schedule_start_at',
        type: FieldType.dateIso,
        cssClass: 'text-center',
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY HH:mm',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'schedule_finish', name: 'Finish At',
        field: 'schedule_finish_at',
        type: FieldType.dateIso,
        cssClass: 'text-center',
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY HH:mm',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'attendees', name: 'Attendees',
        field: 'attendees',
        fields: ['attendees.id', 'capacity'],
        type: FieldType.string,
        cssClass: 'text-left',
        sortable: true,
        filterable: true,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          return `${dataContext.attendees.length}/${dataContext.capacity}`;
        }.bind(this)
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-center',
        formatter: this.renderStatus.bind(this),
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: '',                             label: '- not filter -' },
            { value: TrainingBatchStatus.PENDING,    label: 'Pending'        },
            { value: TrainingBatchStatus.OPEN,       label: 'Open'           },
            { value: TrainingBatchStatus.FULL,       label: 'Full'           },
            { value: TrainingBatchStatus.PROGRESS,   label: 'In Progress'    },
            { value: TrainingBatchStatus.EVALUATION, label: 'Evaluation'     },
            { value: TrainingBatchStatus.CANCELLED,  label: 'Cancelled'      },
            { value: TrainingBatchStatus.CLOSED,     label: 'Closed'         }
          ]
        }
      },

      {
        id: 'updated_at', name: 'Last Update',
        field: 'updated_at',
        fields: [ 'updated_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center',
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];
  }

  public onGridReady(event: Event) {
    this.gridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    this.nav.push([ '/training/batches/view', this.batches[(event as CustomEvent).detail.args['row']].id ]);
  }

  protected renderStatus(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
    const labels: { [name: string]: { label: string, css: string} } = {
      pending:       { label: 'Pending',     css: 'info'    },
      open:          { label: 'Open',        css: 'success' },
      full:          { label: 'Full',        css: 'warning' },
      in_progress:   { label: 'In Progress', css: 'mint'    },
      evaluation:    { label: 'Evaluation',  css: 'primary' },
      cancelled:     { label: 'Cancelled',   css: 'dark'    },
      closed:        { label: 'closed',      css: 'default' }
    }
    let status = dataContext.status.toLowerCase();
    return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
  }

  // -- template API

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public newBatch() {
    this.ui.confirm('Create new batch?', undefined, () => {
      this.nav.push('/training/batches/view/new');
    });
  }
}