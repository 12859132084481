/**
 * routes for 'redirector' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { RedirectorComponent } from 'src/app/modules/redirector/redirector.component';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  redirector: {
    path: 'redirect',
    component: RedirectorComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  }
};