import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';

// types and constants
import {
  APP_INFO,
  AppInfo,
  NavigationService,
  Permission, PermissionChecker,
  SessionService,
} from '@pinacono/common';

// services
import { UIService } from '@pinacono/ui';

import * as _ from 'lodash';

// app's types and contants
import { THEME_CONFIG, MAIN_MENU, USER_MENU, LOGIN_MENU } from '../../tokens';
import { ThemeConfig, Menu, Breadcrumb } from '../../types';
import { ThemeService } from '../../theme.service';

/**
 * Page Regions
 * - <div page-head></div> vs [page-title]=""
 * - <div page-content></div>
 * - <div page-aside></div>
 */

@Component({
  selector: 'full-page',
  templateUrl: 'full-page.component.html',
  styleUrls: ['full-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FullPageComponent implements OnInit, AfterViewInit, OnDestroy {

  // -- configuration
  @Input('page-title') public page_title?: string;

  // -- template interfaces
  @ViewChild('aside_content') protected aside!: ElementRef;

  public breadcrumb: Breadcrumb[] = [];

  // -- lifecycle
  constructor(
    public session: SessionService,
    public nav: NavigationService,
    public ui: UIService,
    public theme: ThemeService,
    protected element: ElementRef,
    @Inject(APP_INFO) public APP_INFO: AppInfo,
    @Inject(THEME_CONFIG) public config: ThemeConfig,
    @Inject(MAIN_MENU) public main_menu: Menu[],
    @Inject(USER_MENU) public user_menu: Menu[],
    @Inject(LOGIN_MENU) public login_menu: Menu[]
  ) {
    this.main_menu  = this.filterMenus(this.main_menu, config.disabled_menus);
    this.user_menu  = this.filterMenus(this.user_menu, config.disabled_menus);
    this.login_menu = this.filterMenus(this.login_menu, config.disabled_menus);
  }

  public ngOnInit(): void {
    this.element.nativeElement.addEventListener('mousemove', this.resetTimer.bind(this), {passive: true});
    this.element.nativeElement.addEventListener('keypress', this.resetTimer.bind(this),  {passive: true});
    this.resetTimer();
    //console.log('Idle detector started!');
  }

  public ngAfterViewInit(): void {
    /*
    window.setTimeout(function() {
      // unregister all animation events and re-initialize Nifty
      $(document).off('show.bs.dropdown', '.dropdown');
      $(document).off('hide.bs.dropdown', '.dropdown');
      $(document).trigger('nifty.ready');
    }, 100);
    */
    // unregister all animation events and re-initialize Nifty
    $(document).off('show.bs.dropdown', '.dropdown');
    $(document).off('hide.bs.dropdown', '.dropdown');
    $(document).trigger('nifty.ready');
  }

  public ngOnDestroy(): void {
    if ( !! this.idleTimer ) {
      clearInterval(this.idleTimer);
      this.idleTimer = 0;
    }
    this.element.nativeElement.removeEventListener('keypress', this.resetTimer);
    this.element.nativeElement.removeEventListener('mousemove', this.resetTimer);
  }

  // --- getters

  // return true if 'aside' block contains any content
  public get has_aside(): boolean {
    return this.aside?.nativeElement.children.length > 0;
  }

  // idle detector
  protected idleTimer: number = 0;
  protected resetTimer() {
    let self = this;
    let timeout = this.config.inactivity_timeout * 60;
    let inactivity = 0;

    if ( !! this.idleTimer ) {
      clearInterval(this.idleTimer);
    }

    if ( timeout <= 0 ) {
      // do not use idle detection, if timeout is <= 0
      return;
    }

    this.idleTimer = window.setInterval( () => {
      inactivity++;
      let remaining = timeout - inactivity;
      //console.log("tick! - " + remaining);
      if ( remaining > 0 ) {
        // make alert 30 secs before
        if ( remaining == 30 ) {
          self.ui.alert("Inactivity logout in {{n}} seconds!!", { n: remaining }, undefined, () => {
            self.resetTimer();
          }, 30 * 1000);
        }
        return;
      }

      // no more remaining time, log-out!
      this.nav.setRoot('login');
      clearInterval(this.idleTimer);
      this.idleTimer = 0;
    }, 1000);
    return true;
  }

  // -- internal
  /**
   * Filtering the menus according to status (disabled?) and permission
   *
   * @param menus array of menu items
   */
   protected filterMenus(menus: Menu[], disabled_menus: string[] = []): Menu[] {
    let res: Menu[] = [];
    let _menus = _.cloneDeep(menus);

    _menus.forEach( (m: Menu) => {
      if ( ! m.disabled && disabled_menus.indexOf(m.label) < 0 ) {
        let type = !! m.type && m.type.toLowerCase() || 'entry';
        if ( type != 'header' && type != 'entry' && type != 'divider' ) {
          type = 'header';
        }

        // handle varius permissions setting
        let permissions: Permission[]|PermissionChecker = [];
        if ( m.visible_for ) {
          permissions = m.visible_for;
        }

        if (
          typeof permissions == 'function' && this.session.currentUser !== null && (permissions as PermissionChecker)(this.session.currentUser) ||
          permissions.length == 0 || this.session.isPermitted(permissions as Permission[])
        ) {
          m.type = type as 'header'|'entry'|'divider';
          if ( !! m.children && m.children.length > 0 ) {
            m.children = this.filterMenus(m.children);
          }
          res.push(m);
        }
      }
    });
    return res;
  }

  // -- Template API

  public search(keyword: string) {
    this.nav.naviateByUrl(`/search/${keyword}`);
  }

  /**
   * circulary return the value of array by index
   * @param arr set of data
   * @param index
   * @returns circulated value
   */
  /*
   public circular(arr: any[], index: number): any {
    return arr[index%arr.length];
  }
  */
}
