import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule, } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AngularSlickgridModule } from 'angular-slickgrid';
import { NgxSelectModule } from 'ngx-select-ex';

import {
  ServerService,
  SessionService,
  PinaconoCommonModule
} from '@pinacono/common';

import { PinaconoUIModule } from '@pinacono/ui';

/*
import {
  NotificationMessage,
  NotificationService,
  IrisNotification
} from '@pinacono/notifications';
*/

import { SlickgridExtensionModule } from '@pinacono/slickgrid-extension';

import { ThemesModule } from 'src/app/themes/module';
import { AppCommonModule } from 'src/app/common/module';

// constants and interfaces
import { AppUser } from 'src/app/types';

// services
import { ENDPOINTS } from './endpoints';
import { DocLibService } from './doclib.service';

// components, directives and pipes
import { DocCodeEditorComponent } from './components/doc-code/doc-code-editor.component';
import { RefdocPipe } from './docref.pipe';

// pages
import { DocLibBrowsePage } from './browse/browse';
import { DocLibSearchPage } from './search/search';
import { DocLibViewPage } from './view/view';
import { DocLibEditPage } from './edit/edit';
import { DocLibReservePage } from './reserve/reserve';
import { DocLibReportsPage } from './reports/reports';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,

    TranslateModule,

    AngularSlickgridModule.forRoot(),
    NgxSelectModule,
    PinaconoCommonModule,
    ThemesModule,
    PinaconoUIModule,
    SlickgridExtensionModule,
    AppCommonModule
  ],
  declarations: [
    // components, directives and pipes
    DocCodeEditorComponent,
    RefdocPipe,

    // pages
    DocLibBrowsePage,
    DocLibSearchPage,
    DocLibViewPage,
    DocLibEditPage,
    DocLibReservePage,
    DocLibReportsPage
  ],
  providers: [
    DocLibService
  ],
  exports: [
    //TranslateModule,

    // components, directives and pipes
    DocCodeEditorComponent,
    RefdocPipe,

    // pages
    DocLibBrowsePage,
    DocLibSearchPage,
    DocLibViewPage,
    DocLibEditPage,
    DocLibReservePage,
    DocLibReportsPage
  ]
})
export class DocumentsModule {
  constructor(
    server: ServerService,
    session: SessionService,
    //notification: NotificationService
  ) {
    server.registerEndpoints(ENDPOINTS);

    session.LOGGEDIN.subscribe( (u: AppUser|null) => {
      // -- Module login
      if ( u === null ) return;
      // loggedin - acquire protected master data after logged-in
      /*
      Promise.all([
        server.request('opl.domains')
        .then( (res: Taxonomy[]) => {
          oplService.domains = res;
        }),

        server.request('opl.categories')
        .then( (res: Taxonomy[]) => {
          oplService.categories = res;
        }),

        server.request('opl.properties')
        .then( (res: CustomPropertyConfig[]) => {
          oplService.properties = res;
        })
      ]);
      */
    });

    session.LOGGEDOUT.subscribe( () => {
      // -- Module logout
    });

    // notification message parser
    /*
    notification.registerParser('DOC', (n:IrisNotification): NotificationMessage => {
      return {
        group: 'doc',
        link: `/doc/view/${n.closure['doc_id']}`
      };
    });
    */

    /*
    // -- Module boot
    Promise.all([
      // load configurations on BOOT
      server.getconfig('opl')
      .then( (cfg: OPLConfig) => {
        oplService.config = cfg;
      }),

      // add other bootstrap here
    ]);
    */
  }
}
