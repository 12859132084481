import {
  Component
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  FileUtilsService,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  Taxonomy,
  User
} from "@pinacono/common";

import {
  LookupEvent,
  LookupItem,
  UIService
} from "@pinacono/ui";

import { BasePageComponent } from 'src/app/classes/base-page.component';
import { DocLibService } from "src/app/modules/documents/doclib.service";

import { TrainingService } from '../training.service';
import { Competency, JobDescription, TrainingCourse } from "../types";

// --

interface LookupCompetencyItems {
  report2: LookupItem<Competency>[];
  report3: LookupItem<Competency>[];
}

// --

@Component({
  selector: 'training-reports-page',
  templateUrl: 'reports.html',
  styleUrls: [ 'reports.scss' ]
})
export class TrainingReportsPage extends BasePageComponent {

  public root: Taxonomy[] = [];
  public selectedUsers: User[] = [];
  public from: string|null = null;
  public to: string|null = null;

  public course: TrainingCourse;;
  public skill: Competency;
  public job: JobDescription;

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    public docApi: DocLibService,
    protected server: ServerService,
    protected fileUtils: FileUtilsService
  ) {
    super(router, activatedRoute);

    this.course = api.createCourse();
    this.skill  = api.createCompetency();
    this.job    = api.createJobDescription();
  }

  public async run(name: string, params1: object_t, params2?: object_t) {
    const params = { ...params1, ...params2 };
    const res: any = await this.server.request(`training.reports.${name}`, null, params)
    this.fileUtils.saveXLSX(name, res);
  }

  // -- course name lookup

  public lookupCourseName: string = '';
  public lookupCourseItems: LookupItem<TrainingCourse>[] = [];
  public async lookupCourses(keyword: string) {
    const res: object_t[] = await this.server.lookup('training/courses', {
      keyword: keyword,
    }, 5);

    this.lookupCourseItems = res.map( c => {
      const course = this.api.createCourse(c);
      return {
        label: `${course.code}: ${course.name}`,
        value: course
      }
    });
  }

  public selectCourse(event: LookupEvent<TrainingCourse>, model: TrainingCourse) {
    if ( event.value.value === null ) {
      return;
    }
    model.id = event.value.value.id;
    this.lookupCourseName = event.value.value.name;
  }

  // -- competency name lookup

  //public lookupCompetencyName: string = '';
  public lookupCompetencyItems: LookupCompetencyItems  = {
    report2: [],
    report3: []
  };

  public lookupReport3CompetencyItems: LookupItem<Competency>[] = [];
  public async lookupCompetencies(keyword: string, name: keyof LookupCompetencyItems ) {
    const res: object_t[] = await this.server.lookup('training/competencies', {
      keyword: keyword,
    }, 5);

    this.lookupCompetencyItems[name] = res.map( c => {
      const skill = this.api.createCompetency(c);
      return {
        label: `${skill.code}: ${skill.name}`,
        value: skill
      }
    });
  }

  public selectCompetency(event: LookupEvent<Competency>, model: Competency) {
    if ( event.value.value === null ) {
      model.id = undefined;
      return;
    }
    model.id = event.value.value.id;
    //this.lookupCompetencyName = event.value.value.name;
  }

  // -- job title name lookup

  //public lookupJobName: string = '';
  public lookupJobItems: LookupItem<JobDescription>[] = [];
  public async lookupJobs(keyword: string) {
    const res: object_t[] = await this.server.lookup('training/jobs', {
      keyword: keyword,
    }, 5);

    this.lookupJobItems = res.map( c => {
      const job = this.api.createJobDescription(c);
      return {
        label: `${job.code}: ${job.title}`,
        value: job
      }
    });
  }

  public selectJob(event: LookupEvent<JobDescription>, model: JobDescription) {
    if ( event.value.value === null ) {
      model.id = undefined;
      return;
    }
    model['id'] = event.value.value.id;
    //this.lookupJobName = event.value.value.title;
  }

  // -- user selection

  public removeSelectedUser(user: User) {
    this.selectedUsers = this.selectedUsers.filter( (u: User) => { return u.id != user.id });
  }

  // -- report 1

  public parseReport1Params(): object_t {
    return {
      users: this.selectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      from: this.from,
      to: this.to
    };
  }

  // -- report 2

  public parseReport2Params(): object_t {
    return {
      users: this.selectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      from: this.from,
      to: this.to,
      skill: this.skill && this.skill.id
    };
  }

  // -- report 3

  public parseReport3Params(): object_t {
    return {
      users: this.selectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      skill: this.skill && this.skill.id
    };
  }
  // -- report 2

  public parseReport4Params(): object_t {
    return {
      users: this.selectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      job: this.job && this.job.id
    };
  }
}