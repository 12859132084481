import {
  Component,
} from "@angular/core";

import {
  ActivatedRoute,
  Router
} from '@angular/router';

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService
} from "@pinacono/common";

import { UIService } from "@pinacono/ui";

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { OPL } from "../types";

import { OplService } from "../opl.service";

@Component({
  selector: 'opl_view',
  templateUrl: 'view.html'
})
export class OplViewPage extends BasePageComponent {

  // for full view and editing
  public opl: OPL;

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public oplService: OplService,
    protected server: ServerService
  ) {
    super(router, activatedRoute);
    this.opl = oplService.create();
  }

  // -- overloading

  protected override loadData(): Promise<any> {

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( ! id ) {
      return this.back();
    }

    if ( id == '@' ) {
      let code = this.activatedRoute.snapshot.paramMap.get('doccode');
      if ( ! code ) {
        return this.back();
      }

      return this.server.request('opl.by_code', { doccode: code })
      .then( (res: object_t) => {
        if ( ! res['id'] ) {
          this.ui.alert('Cannot find OPL with document code {{ code }}', { code: code })
          .then( () => {
            this.back();
          })
        }
        else {
          this.opl = this.oplService.create(res);
        }
      });
    }

    return this.server.show('opls', parseInt(id))
    .then( (res: object_t) => {
      this.opl = this.oplService.create(res);
    });
  }

  // -- template API

  public back(): Promise<void> {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('opl');
    }
    return Promise.resolve();
  }

  public create() {
    this.nav.push([ 'opl/edit/new', { content: '' } ] );
  }

  public edit(opl: OPL) {
    if ( opl.status == 'approved' ) {
      this.ui.confirm('Editing approved OPL will required re-approval from manager. Proceed?', undefined, () => {
        this.nav.push([ 'opl/edit', opl.id] );
      });
    }
    else {
      this.nav.push([ 'opl/edit', opl.id ]);
    }
  }

  public qedit(opl: OPL) {
    this.nav.push([ 'opl/edit', opl.id, { quick: true } ]);
  }

  public trash(opl: OPL) {
    if ( ! opl.id ) {
      this.back();
      return;
    }
    this.server.destroy('opls', opl.id)
    .then( () => {
      this.back();
    });
  }

  public flag(opl: OPL) {
    if ( this.session.currentUser?.primary_domain ) {
      this.server.request('opl.flag', { id: opl.id, gid: this.session.currentUser.primary_domain.id})
      .then( () => {
        this.back();
      });
    }
  }

  public unflag(opl: OPL) {
    if ( this.session.currentUser?.primary_domain ) {
      this.server.request('opl.unflag', {id: opl.id, gid: this.session.currentUser.primary_domain.id})
      .then( () => {
        this.back();
      });
    }
  }
}
