import { Component, OnInit } from '@angular/core';
import { config } from '@pinacono/common';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  public kibana_url = config('client.dashboard.kibana.urls.admin');

  constructor() {
  }

  public ngOnInit(): void {
  }
}
