<full-page [title]="'Search: ' + keyword" class="search-page">
  <div page-content>

    <search [keyword]="keyword" (keywordChange)="search($event)"></search>

    <panel>
      <div panel-body>
        <div class="pad-hor mar-top">
          <h2 class="text-thin mar-no">{{ total | number:'1.0-0' }} {{ 'results found for' | translate }}: <i class="text-info text-normal">"{{ keyword }}"</i></h2>
          <small>{{ 'Request time' | translate }}  ({{ elapsed | number:'1.0-3' }} seconds)</small>
        </div>

        <ul class="list-group bord-no">

          <li class="list-group-item list-item-lg" *ngFor="let r of results" [ngClass]="{ 'media' : !! r.image_url }">

            <div *ngIf="r.image_url" class="pull-left">
              <img class="img-lg" alt="{{ r.image_caption }}" src="{{ r.image_url }}">
            </div>
            <div *ngIf="r.image_url" class="media-body">
              <div class="media-heading mar-no">
                <!-- <span class="label label-primary v-middle">New</span> -->
                <a class="btn-link text-lg text-semibold" href="{{ r.url }}">{{ r.title }}</a>
              </div>
              <p><a class="btn-link text-success" href="{{ r.url }}">{{ r.url }}</a></p>
              <p class="text-sm" [innerHTML]="r.teaser | safe"></p>
            </div>

            <div *ngIf="! r.image_url" class="media-heading mar-no">
              <!-- <span class="label label-primary v-middle">New</span> -->
              <a class="btn-link text-lg text-semibold" href="{{ r.url }}">{{ r.title }}</a>
            </div>

            <p *ngIf="! r.image_url"><a class="btn-link text-success" href="{{ r.url }}"><span class="text-bold">URL :</span> {{ r.url }}</a></p>
            <p *ngIf="! r.image_url" class="teaser text-lg" [innerHTML]="r.teaser | safe"></p>

            <div class="search-hit">
              <label>{{ 'Appears in' | translate }}:</label>
              <div *ngFor="let h of r.highlight" class="hit-entry">
                <span class="field">{{ h.key }}</span>: <span class="value" [innerHTML]="h.value | safe"></span>
              </div>
              <div *ngIf="r.highlight && r.highlight.length == 0" class="hit-entry">
                <span class="value">({{ 'Proximity Content' | translate }})</span>
              </div>
            </div>

            <div *ngIf="!r.document.tags" class="pad-btm">
              <small>Tags :</small>
              <a *ngFor="let t of r.document.tags" class="label label-mint" href="#">{{ t }}</a>
            </div>
          </li>

        </ul>

        <!--Pagination-->
        <div class="text-center">
          <pagination [total]="total" [perpage]="perpage" [(pageno)]="currentPage" [maxButtons]="5" [buttonSize]="'md'"></pagination>
        </div>
      </div>
    </panel>
  </div>
</full-page>
<!-- end of Nifty Search Page -->