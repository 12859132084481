import {
  Injectable
} from "@angular/core";

import {
  Attachment,
  config,
  object_t,
  ServerService,
  SessionService,
  StringUtilsService,
  TaxonomyService
} from "@pinacono/common";

import { AppCommonService } from "src/app/common/app-common.service";
import { ExamChoice } from "src/app/common/types";

import { DocLibService } from '../documents/doclib.service';

import {
  JobDescription, JobCompetency,
  Competency, CompetencyLevel,
  CompetencyProfile,
  TrainingRecordStatus, TrainingRecord, TrainingPlan,
  TrainingObjective, TrainingObjectiveResult,
  TrainingCourseRequest, TrainingCourseRequestStatus, TrainingBatch, TrainingBatchStatus,
  CourseObjective, CourseObjectiveDescription, TrainingCourse,
  TrainingExamQuestion, ExamAnswer, ExamAnswerSheet, ExamTimerMode, ExamScoreExplanations
} from './types';

@Injectable()
export class TrainingService {

  public config: object_t = {};

  // -------------------------------------------------------------------
  // -- initialization

  constructor (
    protected session: SessionService,
    protected taxonomy: TaxonomyService,
    protected server: ServerService,
    protected appCommonService: AppCommonService,
    protected stringUtils: StringUtilsService,
    protected docapi: DocLibService,
    //protected oplapi: OplService
  ) {
    this.config = config('client.training');
  }

  // -------------------------------------------------------------------
  // -- checking
  /*
  public canRequest(course: TrainingCourse, plans: TrainingPlan[], records: TrainingRecord[]): boolean {
    if ( course.levels.filter( l => l.level == 1 ) ) {
      return ! this.isPassed(course, records);
    }
    return this.isPlanned(course, plans);
  }

  public isPlanned(course: TrainingCourse, plans: TrainingPlan[]): boolean {
    for ( let plan of plans ) {
      for ( let level of course.levels ) {
        if ( level.competency_id == plan.competency_id && level.level == plan.target ) {
          return true;
        }
      }
    }
    return false;
  }

  public isPassed(course: TrainingCourse, records: TrainingRecord[]): boolean {
    return records.findIndex( r => r.batch && r.batch.course && r.batch.course.id == course.id && r.status == TrainingRecordStatus.PASSED ) >= 0 ;
  }
  */

  // -------------------------------------------------------------------
  // -- json to markdown

  public bulletize(str: string, prefix?: string, default_value?: string, trim?: string) : string[] {
    let list: string[] = [];

    trim = trim || " \n\r\t-*";
    if ( str ) {
      let m = str.split(/[\r\n]/)
      for ( let s of m ) {
        let r = new RegExp(trim);
        s = trim.length == 0 ? s.trim() : s.replace(r, '');
        if ( s.length == 0 ) {
          continue;
        }

        r = /(^[\d\-\*]+\.*\s*)(.+)/;
        let matches = r.exec(s);
        if ( matches && matches.length >= 2 ) {
          s = matches[2];
        }

        list.push( ( prefix ? `${prefix} `: '' ) + s );
      }
    }

    if ( list.length == 0 && !! default_value ) {
      list.push( ( prefix ? `${prefix} `: '' ) + default_value );
    }
    return list;
  }

  // generate markdown list
  public formatBullet(label: string, list: string[] = [], formatter?: (s?: string) => string, default_value?: string): string[] {
    // default formatter
    //formatter = formatter || ( (s?: string) => ( s && `- ${s}` || '' ) );
    formatter = formatter ||  ( (s?: string) => ( s && s.trim().replace(/(^[\s**]*)(.*)/, '* $2') ) || '' );
    let lines: string[] = [];
    if ( label ) {
      lines.push(`**${label}**`);
    }
    lines = lines.concat(list.map(formatter));
    if ( list.length == 0 ) {
      lines.push(formatter(default_value));
    }
    return lines;
  }

  /**
   * parsing JSON object to MD list
   */
  public parseCompetencyDescription(d: any): string {
    let lines: string[] = [];
    if ( d['attr'] ) {
      if ( d['attr']['function'] ) {
        lines = lines.concat(this.formatBullet('ขอบเขต', d['attr']['function']));
        lines.push('');
      }

      if ( d['attr']['related_doc'] ) {
        lines = lines.concat(this.formatBullet('เอกสารที่เกี่ยวข้อง', d['attr']['related_doc'], (s?: string) => {
          if ( ! s ) {
            return '- n/a -';
          }
          let ss = s.split(' ', 2);
          return `* [${s}](/#/doc/view/@?doccode=${ss[0]})`;
        }));
      }
    }
    return lines.join("\n");
  }

  public parseCompetencyLevelDescription(d: any): string {
    let lines: string[] = [];
    if ( d['attr'] ) {
      if ( d['attr']['desc'] ) {
        lines = lines.concat(this.formatBullet('คุณสมบัติ', d['attr']['desc']));
        lines.push('');
      }

      if ( d['attr']['job'] ) {
        lines = lines.concat(this.formatBullet('ระดับ', d['attr']['job'], undefined, '-- *n/a* --'));
      }
    }
    return lines.join("\n");
  }

  // -------------------------------------------------------------------
  // -- creators

  public createJobDescription(data: object_t|null = null): JobDescription {
    let competencies: JobCompetency[] = [];

    if ( data && data['competencies'] ) {
      for ( let c of data['competencies'] ) {
        competencies.push({
          competency: this.createCompetency( c['competency'] ),
          expectation: c['expectation']
        });
      }
    }

    let j: JobDescription = {
      id: ( data && data['id'] ) || undefined,

      title:       ( data && data['title'] ) || '',
      code:        ( data && data['code'] )  || null,
      description: ( data && data['description'] ) || '',
      job_level:   ( data && data['job_level'] )   || '',

      competencies: competencies,
      document: ( data && data['document'] && this.docapi.createDocument(data['document']) ) || null,
      users:    ( data && data['users'] ) || [],

      attachments: ( data && data['attachments'] ) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      attr: ( data && data['attr'] ) || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null,
    };
    return j;
  }

  public createCompetency(data: object_t|null = null): Competency {
    let levels: CompetencyLevel[] = [];

    if ( data && data['levels'] ) {
      for ( let l of data['levels'] ) {
        levels.push(this.createCompetencyLevel(l));
      }
    }

    let s: Competency = {
      id:   ( data && data['id'] )   || null,

      code: ( data && data['code'] ) || null,
      name: ( data && data['name'] ) || null,
      //description: ( data && data['description'] && this.parseCompetencyDescription(data['description']) ) || '',
      description: ( data && this.parseCompetencyDescription(data) ) || '',

      levels:   levels,

      //categories: ( data && data['categories'] && data['categories'].map( (c: object_t) => this.taxonomy.create(c) ) )|| null,
      categories:  ( data && data['categories'] )|| 'Uncategorized',
      attachments: ( data && data['attachments' ]) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      attr: ( data && data['attr'] ) || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null,
    };
    return s;
  }

  public createCompetencyLevel(data: object_t|null = null): CompetencyLevel {
    let l: CompetencyLevel = {
      id: ( data && data['id']) || null,

      competency_id: ( data && ( data['competency_id'] || ( data['competency'] && data['competency'].id ) ) ) || null,
      competency: ( data && data['competency'] && this.createCompetency(data['competency']) ) || null,

      job_ids: ( data && ( data['job_ids'] || ( data['jobs'] && data['jobs'].map( (j: object_t) => j['id'] ) ) ) ) || [],
      jobs:    ( data && data['jobs'] && data['jobs'].map( (j: object_t) => this.createJobDescription(j) ) ) || [],

      course_ids: ( data && ( data['course_ids'] || ( data['courses'] && data['courses'].map ( (c: object_t) => c['id'] ) ) ) ) || [],
      courses:    ( data && data['courses'] && data['courses'].map( (c: object_t) => this.createCourse(c) ) ) || [],

      level: ( data && data['level'] ) || 0,
      //description: ( data && data['description'] && this.parseCompetencyLevelDescription(data['description']) ) || '',
      description: ( data && this.parseCompetencyLevelDescription(data) ) || '',

      attr: ( data && data['attr'] ) || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null,
    };
    return l;
  }

  public getSoonestPlan(plans: TrainingPlan[]): TrainingPlan|null {
    let sorted_plans = plans
    .filter( p => p.status == 'planned' )
    .sort( (a, b) => Date.parse(a.dead_line) - Date.parse(b.dead_line) );

    if ( sorted_plans.length > 0 ) {
      return sorted_plans[0];
    }
    return null;
  }

  public getLatestPlan(plans: TrainingPlan[]): TrainingPlan|null {
    let sorted_plans = plans
    .filter( p => p.status == 'planned' )
    .sort( (a, b) => ( Date.parse(b.dead_line) - Date.parse(a.dead_line) ) || ( b.target - a.target ) );

    if ( sorted_plans.length > 0 ) {
      return sorted_plans[0];
    }
    return null;
  }

  public getActivePlanByLevel(profile: CompetencyProfile): TrainingPlan[] {
    let active_plans: TrainingPlan[] = [];
    for ( let level = 1; level <= 4; level ++ ) {
      let filtered: TrainingPlan[] = [];

      if ( profile.plans && profile.plans.length > 0 ) {
        filtered = profile.plans
          .filter( p => p.target == level )
          .sort( (a, b) => Date.parse(a.dead_line) - Date.parse(b.dead_line) );
      }

      if ( filtered.length > 0 ) {
        active_plans.push(filtered[0]);
      }
      else {
        active_plans.push(this.createTrainingPlan( {
          user_id: profile.user_id,
          competency_id: profile.competency_id,
          target: level
        }));
      }
    }
    return active_plans;
  }

  public createCompetencyProfile(data: object_t|null = null ) {
    data = data || {};

    let plans: TrainingPlan[] = ( data['plans'] && data['plans'].map( (p: object_t) => this.createTrainingPlan(p)) ) || [];

    /*
    let soonest_plan = this.getSoonestPlan(plans);
    let target: number = soonest_plan ? soonest_plan.target : 0;
    */

    let latest_plan = this.getLatestPlan(plans);
    //let target: number = latest_plan ? latest_plan.target : 0;

    let g: CompetencyProfile = {
      id: data['id'] || undefined,

      user_id: ( data['user_id'] || ( data['user'] && data['user'].id ) ) || this.session.currentUser!.id,
      user:    data['user'] || undefined,

      competency_id: data['competency_id'] || ( data['competency'] && data['competency'].id ),
      competency:    data['competency'] && this.createCompetency(data['competency']),

      actual:      data['actual'] || 0,
      expectation: data['expectation'] || 0,
      target:      latest_plan ? latest_plan.target : 0,

      plans:       plans,
      records:     data['records'] && data['records'].map( (r: TrainingRecord) => this.createTrainingRecord(r) ) || [],

      attr:       data['attr'] || {},
      created_at: data['created_at'] || undefined,
      updated_at: data['updated_at'] || undefined,
      deleted_at: data['deleted_at'] || undefined,
    }

    if ( plans.length < 4 ) {
      for ( let t = 1; t <= 4; t++ ) {
        if ( plans.findIndex( p => p.target == t ) < 0 ) {
          plans.push(this.createTrainingPlan({
            user_id: g.user_id,
            competency_id: g.competency_id,
            target: t
          }));
        }
      }
    }

    g.egap = Math.max(0, g.expectation - g.actual);
    g.tgap = Math.max(0, g.target - g.actual);
    g.active_plans = this.getActivePlanByLevel(g);

    return g;
  }

  public createTrainingRecord(data: object_t|null = null): TrainingRecord {
    let r: TrainingRecord = {
      id:     ( data && data['id'] ) || null,
      status: ( data && data['status'] ) || TrainingRecordStatus.PENDING,

      user_id: ( data && ( data['user_id'] || ( data['user'] && data['user.id'] ) ) ) || this.session.currentUser!.id,
      user:    ( data && data['user'] ) || null,

      plan_id: ( data && ( data['plan_id'] || ( data['plan'] && data['plan'].id ) ) ) || null,
      plan:    ( data && data['plan'] && this.createTrainingPlan(data['plan']) ) || null,

      batch_id: ( data && ( data['batch_id'] || ( data['batch'] && data['batch'].id ) ) ) || null,
      batch:    ( data && data['batch'] ) ? this.createTrainingBatch(data['batch']) : undefined,

      objectives: ( data && data['objectives'] && data['objectives'].map( (o: object_t) => this.createTrainingObjective(o) ) ) || [],

      examinations: ( data && data['examinations'] && data['examinations'].map( (o: object_t) => this.createExamAnswerSheet(o) ) ) || [],

      note: ( data && data['note'] ) || null,
      logs: (data && data['logs'] ) || [],

      comments:    ( data && data['comments'] )    || [],
      attachments: ( data && data['attachments' ]) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      applied_at: ( data && data['applied_at'] ) || null,
      closed_at:  ( data && data['closed_at'] ) || null,

      attr: ( data && data['attr'] ) || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null
    };

    return r;
  }

  public createTrainingPlan(data: object_t|null = null): TrainingPlan {
    let p: TrainingPlan = {
      id: ( data && data['id'] ) || null,

      user_id: ( data && ( data['user_id'] || ( data['user'] && data['user'].id ) ) ) || this.session.currentUser!.id,
      user:    ( data && data['user'] ) || null,

      competency_id: ( data && ( data['competency_id'] || ( data['competency'] && data['competency'].id ) ) ) || null,
      competency:    ( data && data['competency'] && this.createCompetency(data['competency']) ) || null,
      target:        ( data && data['target'] ) || 1,
      dead_line:     ( data && data['dead_line'] ) || null,
      status:        ( data && data['status'] ) || 'unplanned',

      records: ( data && data['records'] && data['records'].map( (r: object_t) => this.createTrainingRecord(r) ) ) || [],

      attr: ( data && data['attr'] )   || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null
    };

    return p;
  }

  public createTrainingObjective(data: object_t|null): TrainingObjective {
    data = data || {};

    const o: TrainingObjective = {
      id: data['id'] || null,
      description: data['description'] || null,

      record_id: data['record_id'] || data['record'] && data['record'].id  || null,
      record:    data['training_record'] && data['training_record'].map( (r: object_t) => this.createTrainingRecord(r) ) || [],

      trainer_id: data['trainer_id'] || ( data['trainer'] && data['trainer'].id ) || null,
      trainer:    data['trainer'] || null,

      min_score:    data['min_score'] || 0,
      answer_sheet: data['answer_sheet'] && data['answer_sheet'].map( (a: object_t) => this.createExamAnswerSheet(a) ) || null,

      result: data['result'] || TrainingObjectiveResult.PENDING,

      comments:    data['comments']    || [],
      attachments: data['attachments'] || [],
      attachment_defer_key: data['attachment_defer_key'] || this.stringUtils.random(16),

      attr: Object.assign( { ref_doc: [] }, data['attr'] || {} ),
      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null
    }

    o.attr!['evaluations'] = data['attr'] && this.castToArray(data['attr']['evaluations']) || [],
    o.attr!['evaluators']  = data['attr'] && this.castToArray(data['attr']['evaluators'])  || [],
    o.attr!['evidences']   = data['attr'] && this.castToArray(data['attr']['evidences'])   || []

    return o;
  }

  public createTrainingBatch(data: object_t|null = null): TrainingBatch {
    let b: TrainingBatch = {
      id:    ( data && data['id'] )    || null,
      name:  ( data && data['name'])   || null,
      venue: ( data && data['venue'] ) || null,
      schedule_start_at:  ( data && data['schedule_start_at'] && data['schedule_start_at'].slice(0, 16)  ) || null,
      schedule_finish_at: ( data && data['schedule_finish_at'] && data['schedule_finish_at'].slice(0, 16) ) || null,
      capacity: ( data && data['capacity']) || 1,
      status: ( data && data['status'] ) || TrainingBatchStatus.PENDING,
      note:  ( data && data['note'] ) || '',

      logs: ( data && data['logs'] ) || [],

      objectives: ( data && data['objectives'] && data['objectives'].map( (o: object_t) => this.createTrainingObjective(o) ) ) || [],

      course:   ( data && data['course'] && this.createCourse(data['course']) ) || null,

      trainer_ids: ( data && ( data['trainer_ids'] || ( data['trainers'] && data['trainers'].map( (t:object_t) => t['id'] ) ) ) ) || [],
      trainers: ( data && data['trainers'] ) || [],

      attendee_ids: ( data && ( data['attendee_ids'] || ( data['attendees'] && data['attendees'].map( (t:object_t) => t['id'] ) ) ) ) || [],
      attendees:   ( data && data['attendees'] ) || [],

      attachments: ( data && data['attachments'] ) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      my_record: ( data && data['my_record'] && this.createTrainingRecord(data['my_record'])) || null,

      attr: ( data && data['attr'] )   || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null
    }
    return b;
  }

  public createCourseRequest(data: object_t|null = null): TrainingCourseRequest {
    let r: TrainingCourseRequest = {
      id: ( data && data['id'] ) || null,

      status: ( data && data['status'] ) || TrainingCourseRequestStatus.REQUESTED,

      course_id: ( data && ( data['course_id'] || ( data['course'] && data['course'].id ) ) ) || null,
      course:    ( data && data['course'] && this.createCourse(data['course']) ) || null,

      user_id:   ( data && ( data['user_id'] || ( data['user'] && data['user'].id ) ) ) || this.session.currentUser!.id,
      user:      ( data && data['user'] ) || null,

      batch_id:  ( data && ( data['batch_id'] || (data['batch'] && data['batch'].id ) ) ) || null, // if not null = closed - user already assigned to a batch
      batch:     ( data && data['batch'] && this.createTrainingBatch(data['batch']) ) || null,

      attr: ( data && data['attr'] )   || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null
    };
    return r;
  }

  protected castToArray<T>(d: T): T[] { return Array.isArray(d) ? d : [d]; }
  public createCourseObjective(data: object_t|null = null): CourseObjective {
    data = data || {};

    const o: CourseObjective = {
      id: data['id'] || null,

      //description:  this.parseCourseObjectiveDescription( ( data && data['description'] ) || null ),
      //description:  this.parseCourseObjectiveDescription( data || null ),
      description:  data['description']  || null,
      min_score:    data['min_score']    || 0,
      quiz_count:   data['quiz_count']   || 0,
      related_docs: data['related_docs'] || [],

      attr: data['attr'] || {},
      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null
    };

    o.attr!['evaluations'] = data['attr'] && this.castToArray(data['attr']['evaluations']) || [],
    o.attr!['evaluators']  = data['attr'] && this.castToArray(data['attr']['evaluators'])  || [],
    o.attr!['evidences']   = data['attr'] && this.castToArray(data['attr']['evidences'])   || []

    return o;
  }

  public createCourse(data: object_t|null = null): TrainingCourse {
    data = data || {};

    let my_batch : TrainingBatch|null = data['my_batch'] && this.createTrainingBatch(data['my_batch']) || null;

    let next_exam: number = 0;
    if  ( my_batch && my_batch.my_record && my_batch.my_record.examinations!.length > 0 ) {
      let sorted = my_batch.my_record.examinations!
                    .map( (d) => Date.parse(d.created_at||'') )
                    .sort( (a, b) => b - a);
      next_exam = Math.max( 0, config('client.exam.exam_silent_period', 24 * 60 * 60) * 1000 - ( Date.now() - sorted[0] ) );
    }

    let course: TrainingCourse = {
      id:     data['id'] || null,

      code:   data['code'] || null,
      name:   data['name'] || null,

      levels:    data['levels'] && data['levels'].map( (l: object_t) => this.createCompetencyLevel(l) ) || [],
      level_ids: data['levels'] && data['levels'].map( (l: object_t) => l['id'] ) || [],

      prerequisite_ids: data['prerequisites'] && data['prerequisites'].map( (c: object_t) => c['id'] ) || [],
      prerequisites:    data['prerequisites'] && data['prerequisites'].map( (c: object_t) => this.createCourse(c) ) || [],

      postrequisite_ids: data['postrequisites'] && data['postrequisites'].map( (c: object_t) => c['id'] ) || [],
      postrequisites:    data['postrequisites'] && data['postrequisites'].map( (c: object_t) => this.createCourse(c) ) || [],

      description: data['description'],
      objectives:  data['objectives'] && data['objectives'].map( (o: object_t) => this.createCourseObjective(o) ) || [],
      auto_batch:  data['auto_batch'] || false,
      min_score:   data['objectives'] && data['objectives'].reduce( (acc: number, current: object_t) => acc + current['min_score'], 0 ) || 0,

      questionable_defer_key: data['questionable_defer_key'] || this.stringUtils.random(16),

      //related_opls:   data['related_opls'] && data['related_opls'].map( (o: object_t) => this.oplapi.create(o)) || [],
      related_opl_ids: data['related_opl_ids'] || data['related_opls'] && data['related_opls'].map( (o: object_t) => o['id']) || [],

      requests:   data['requests'] && data['requests'].map( (r: object_t) => this.createCourseRequest(r) ) || [],
      my_request: data['my_request'] && this.createCourseRequest(data['my_request']) || null,

      batches: data['batches'] && data['batches'].map( (b: object_t) => this.createTrainingBatch(b) ) || [],
      active_batches: data['active_batches'] && data['active_batches'].map( (b: object_t) => this.createTrainingBatch(b) ) || [],
      my_batch: my_batch || undefined,

      trainer_ids: data['trainer_ids'] || ( data && data['trainers'] && data['trainers'].map( (u:object_t) => u['id']) ) || [],
      trainers:    data['trainers'] || [],

      covers:      data['attachments'] && data['attachments'].filter( (f: Attachment) => f.meta && f.meta['is_cover']) || [],
      attachments: data['attachments'] && data['attachments'].filter( (f: Attachment) => ! f.meta || ! f.meta['is_cover']) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      next_exam: next_exam,

      attr: data['attr'] || {},
      created_at: data['created_at'] || null,
      updated_at: data['updated_at'] || null,
      deleted_at: data['deleted_at'] || null
    };

    // parsing course description
    //if ( ! data['description'] || (<string>data['description']).length == 0 ) {
      /*
    if ( data['description'] ) {
      let lines: string[] = [];
      if ( data['attr'] ) {
        if ( data['attr']['objectives'] ) {
          let o: string[] = Array.isArray(data['attr']['objectives']) ? data['attr']['objectives'] : data['attr']['objectives'].split("\n");
          lines = lines.concat(this.formatBullet('วัตถุประสงค์', o));
          lines.push('');
        }

        if ( data['attr']['content'] ) {
          let c: string[] = Array.isArray(data['attr']['content']) ? data['attr']['content'] : data['attr']['content'].split("\n");
          lines = lines.concat(this.formatBullet('เนื้อหา', c));
        }
      }

      course.description = lines.join("\n");
    }
    */

    // scoring and exam config
    course.attr = Object.assign({
      scoring: {
        correct_answer: 1,
        incorrect_answer: 0,
        no_answer: 0,
        timer_mode: ExamTimerMode.NO_TIMER,
        time_limit: 0,
        time_penalty: 0
      },
      exam_config: {
        no_of_questions: 10,
        no_of_choices: 4
      }
    }, course.attr);

    return course;
  }

  public createExamChoice(data: object_t|null = null) : ExamChoice {
    let c: ExamChoice = {
      id: ( data && data['id'] ) || null,

      content:     ( data && data['content'] ) || '',
      is_correct:  ( data && data['is_correct'] ) || false,
      attachments: ( data && data['attachments'] ) || [],
      attachment_defer_key:   ( data && data['attachment_defer_key'] ) || this.stringUtils.random(16),

      attr: ( data && data['attr'] ) || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null
    };
    return c;
  }

  public createTrainingExamQuestion(data: object_t|null = null) : TrainingExamQuestion {
    let q: TrainingExamQuestion = this.appCommonService.createExamQuestion(data) as TrainingExamQuestion;
    q.course_id = ( data && data['course_id'] ) || null;
    q.course    = ( data && data['course'] && this.createCourse(data['course']) ) || null;
    return q;
  }

  public createExamAnswer(data: object_t|null = null): ExamAnswer {
    let ans: ExamAnswer = {
      question_id: ( data && data['question_id'] ) || null,
      answer:  ( data && data['answer'] && this.createExamChoice(data['answer']) ) || this.createExamChoice(),
      choices: ( data && data['choices'] && data['choices'].map( (c: object_t) => this.createExamChoice(c) ) ) || []
    };
    return ans;
  }

  public createScoreExplanations(data: object_t|null = null): ExamScoreExplanations {
    let score: ExamScoreExplanations = {
      correct:   parseInt( (data && data['correct'])   as string || '0'),
      incorrect: parseInt( (data && data['incorrect']) as string || '0'),
      no_answer: parseInt( (data && data['no_answer']) as string || '0'),
      time_limit_exceed:  parseInt( (data && data['time_limit_exceed'])  as string || '0' ),
      time_limit_penalty: parseInt( (data && data['time_limit_penalty']) as string || '0' )
    };
    return score;
  }

  public createExamAnswerSheet(data: object_t|null = null): ExamAnswerSheet {
    let ans: ExamAnswerSheet = {
      id: ( data && data['id'] ) || null,

      time_spent:   ( data && data['time_spent'] )   || 0,
      candidate_id: ( data && data['candidate_id'] ) || null,
      candidate:    ( data && data['candidate'] )    || null,

      plan_id: ( data && data['plan_id'] ) || null,
      plan:    ( data && data['plan'] && this.createTrainingPlan(data['plan']) ) || null,

      objective_id: ( data && data['objective_id'] ) || null,
      objective:    ( data && data['objective'] && this.createTrainingObjective(data['objective']) ) || null,
      score_explanations: this.createScoreExplanations( data && data['score_explanations'] || null),
      answers:      ( data && data['answers'] && data['answers'].map( (a: object_t) => this.createExamAnswer(a)) ) || [],

      score:     ( data && data['score'] ) || 0,
      max_score: ( data && data['max_score'] ) || 0,

      attr: ( data && data['attr'] )   || {},
      created_at: ( data && data['created_at'] ) || null,
      updated_at: ( data && data['updated_at'] ) || null,
      deleted_at: ( data && data['deleted_at'] ) || null,

      result: 'n/a' // for front-end used only
    };
    return ans;
  }
}