<li *ngFor="let item of items"
  [ngClass]="{
    'list-divider': item.type == 'divider',
    'list-header': item.type == 'header',
    'active-link': isActive(item) && ! hasChildren(item),
    'active active-sub': isActive(item) && hasChildren(item)
  }"
  title="{{ item.description || '' | translate }}"
>

  <!--
  <div class="hidden">
    {{ item | json }}
  </div>
  -->

  <!-- type = header -->
  <div *ngIf="item.type == 'header'" id="{{item.id}}" style="display: inline-block;">
    {{ item.label | translate }}
    <span *ngIf="!! item.badge"
      title="{{ getBadge(item.badge.name).tooltip }}"
      class="pull-right label label-{{ getBadge(item.badge.name).class }}"
    >
      {{ getBadge(item.badge.name).text }}
    </span>
  </div>

  <!-- type = entry -->
  <a *ngIf="(! item.type || item.type == 'entry') && !! item.link" (click)="goto(item)"
    id="{{item.id}}"
    [ngClass]="{ 'collapse': !!item.children && item.children.length }"
  >
    <i *ngIf="! isSubMenu && item.icon" class="icon-fw {{item.icon}}"></i>
    <i *ngIf="!! item.children && item.children.length > 0" class="arrow"></i>
    <span class="menu-title">
      {{ item.label | translate }}
      <span *ngIf="!! item.badge"
        title="{{ getBadge(item.badge.name).tooltip }}"
        class="pull-right badge badge-{{ getBadge(item.badge.name).class }}"
        (click)="getBadge(item.badge.name).onClick!()"
      >{{ getBadge(item.badge.name).text }}</span>
    </span>
  </a>

  <!-- child menu, if any -->
  <ul *ngIf="(! item.type || item.type == 'entry' ) && item.children && item.children.length > 0" class="collapse submenu"
    id="{{item.id}}"
    [ngClass]="{ 'in show': isActive(item) }"
    [menu-items]="item.children"
    [parent]="item"
  >
  </ul>
</li>
