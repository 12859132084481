/**
 * Common Pages Module - collection of common pages
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PinaconoCommonModule } from '@pinacono/common';
import { PinaconoUIModule } from '@pinacono/ui';

import { ThemesModule } from 'src/app/themes/module';

import { CommonPagesService } from './common-pages.service';

import { ChPassComponent } from './ch-pass/ch-pass.component';
import { Error403Component } from './error403/error403.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { ForgetPassComponent } from './forget-pass/forget-pass.component';
import { LoginComponent } from './login/login.component';
import { OfflineComponent } from './offline/offline.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { SearchPageComponent } from './search/search.component';
import { VersionsComponent } from './versions/versions.component';
import { MaintenancePageComponent } from './maintainance/maintenance';

 /**
 * module declaration
 */
 @NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    TranslateModule.forChild(),
    PinaconoCommonModule,
    PinaconoUIModule,

    ThemesModule
  ],
  declarations: [
    ChPassComponent,
    Error403Component,
    Error404Component,
    Error500Component,
    ForgetPassComponent,
    LoginComponent,
    MaintenancePageComponent,
    OfflineComponent,
    ProfileComponent,
    ResetPassComponent,
    SearchPageComponent,
    VersionsComponent
  ],
  providers: [
    CommonPagesService
  ],
  exports: [
    //TranslateModule,

    ChPassComponent,
    Error403Component,
    Error404Component,
    Error500Component,
    ForgetPassComponent,
    LoginComponent,
    MaintenancePageComponent,
    OfflineComponent,
    ProfileComponent,
    ResetPassComponent,
    SearchPageComponent,
    VersionsComponent
  ]
 })

 export class CommonPageModule {
 }
