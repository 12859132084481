import PACKAGE_INFO  from '../../package.json';
import BUILD_INFO from '../../build_info.json';

import * as _ from 'lodash';

// CLIENT_CONFIG is included via config.js
// - see Pinacono\Http\Controllers\ClientConfig@get
declare let CLIENT_CONFIG: any;

let client_config = null;
try {
  client_config = CLIENT_CONFIG;
}
catch (e) {
  console.error(e);
}

export const APP_CONFIG = _.merge({
  production: false, // production (true) or development (false)

  // application information
  app_info: {
    name: PACKAGE_INFO.name,             // machine-readable application name - use as unique key to differentiate the app from other on the same device
    title: "Engineering Portal",         // human-readable application name - to be displayed in about box
    version: PACKAGE_INFO.version,       // version number - to be displayed in about box
    author: PACKAGE_INFO.author,         // author name or organization name
    homepage: PACKAGE_INFO.homepage,     // URL to the product's page
    signature: BUILD_INFO.signature,     // build signature
    build_no: BUILD_INFO.build_no,       // build number
    release_date: BUILD_INFO.build_date, // build date
    copyright: [                         // list of copyrights information
      "2022 Pinacono Co., Ltd."
    ]
  },

  // server configurations
  // ** this section is the default value and will be replaced
  // ** by the client.js from server
  server: {
    simulation: false, // enable/disable simulation mode
    verbose: false,    // verbose logging for server request?
    base: 'http://opl.dev.pinacono.com/server', // dev sever
    path: '/services/tpmtool/v1.0',
    /*
    timeout: 30, // in milli-sec
    retries: 0   // times
    */
    max_upload_size: 1000, // maximum upload size in MB
    max_upload_files: 10,  // maximum files can be attached to each document
  },

  // additional endpoints
  endpoints: {
    users: {
      lookup: { method: 'POST', url: '/users/lookup' }
    }
  },

  // navigation configuration
  navigation: {
    // list of landing pages - NavigatableLink
    landing_queue: [
      'privacy', 'campaign'
    ],
    logout_on_403: true // weather or not force logout instead of 403
  },

  // notification server
  notifications: {
    site_id: 'dev',
    url: 'http://opl.dev.pinacono.com:81', // dev sever
    // socket.io options
    socket_options: {}
  },

  // translations
  translations: {
    locale: 'th-TH',
    base_url: 'assets/i18n',
    // for PinaconoMissingTranslationHandler
    report_missing: false, // set to 'true' to log translation missing to debug console
    ignore_missing: false  // set to 'true' do not mark missing text with .translation-missing class
  },

  ui: {
    password: {
      check_previous: 1,
      strength_check: false,
      min_length: 8,
      min_capital: 1,
      min_number: 2,
      special_chars: "\\\/\\'|\":;.,!@#$%^&*()_-+=?[]{}()",
      min_special: 1,
      allow_non_ascii: false
    }
  },

  client: {
    service_worker: false
  },

  theme: {
    show_avatar: {
      navbar: false,
      sidebar: true
    },

    // image place holder url prefix - image name = <w>x<h>[-<fg-color>-<bg-color>.<png|jpg>]
    // e.g. /server/placeholder/100x100-000-fff.png
    placeholder_prefix: '/server/placeholder/',

    // list if menu id to be disabled (not shown)
    disabled_menus: [],
    inactivity_timeout: 0
  },

  // date-time
  timezone: 'Asia/Bangkok',
  formats: {
    browser_date_format: 'yyyy-MM-dd',
    date_format: 'dd MMM yyyy',
    time_format: 'HH:mm:ss',
    datetime_format: 'dd MMM yyyy HH:mm:ss',
    timestamp_format: 'dd MMM yyyy HH:mm:ss',
    database_timestamp_format: 'YYYY-MM-DD HH:mm:ssZ'
  },

  // other configurations
  logging: false, // enable/disable local storage logging
  secret: 'The Really Most Secret Message in the Universe', // encryption key
}, client_config);