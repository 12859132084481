/**
 * themes constansts and interfaces
 */

import {
  Permission,
  PermissionChecker,
  NavigatableLink
} from '@pinacono/common';

// -- configuration

export interface ThemeConfig {
  show_avatar: {
    navbar: boolean, // show avatar in dropdown nav menu
    sidebar: boolean // show avatar as profile menu on sidebar
  },
  disabled_menus: string[];
  inactivity_timeout: number;
}

export const DEFAULT_THEME_CONFIG = {
  show_avatar: {
    navbar: true,
    sidebar: true
  },
  disabled_menus: []
}

// -- utilities
export type Colors = 'default'|'primary'|'secondary'|'info'|'success'|'warning'|'danger'|'mint'|'purple'|'pink'|'dark';

// -- menu
//export interface MenuBadgeInfo {
//  /** text to display on menu badge */
//  text: string;
//  /** css class to apply to menu badge */
//  class: string|null;
//  /** text to display on badge when hover */
//  tooltip?: string;
//  /** clicak callback */
//  onClick: () => void;
//}

export interface Breadcrumb {
  /** display label */
  label: string;
  /** target link information */
  link?: NavigatableLink;
}

export interface MenuBadgeSetting {
  /** text to display on menu badge */
  text: string;
  /** optional color class for badge. See Nifty's background helper class */
  class?: string;
  /** text to display on badge when hover */
  tooltip?: string;
  /** clicak callback */
  onClick?: () => void;
}

export interface Menu {
  /** menu id */
  id: string;
  /** menu entry type - valid types are 'header', 'entry', 'divider' */
  type: 'header' | 'entry' | 'divider';
  /** target link information */
  link?: NavigatableLink;
  /** menu icon class - see Nifty's icons for the list */
  icon?: string;
  /** label text - valid for type 'header' only */
  label: string;
  /** menu descript - use as title attribute */
  description?: string;
  /** weather or not menu is disabled */
  disabled?: boolean;
  /** condition to show/hide this entry */
  visible_for?: Permission[] | PermissionChecker;
  /** text to display as a badge at the end of menu entry. Valid for type 'entry' and 'header' */
  badge?: {
    name: string,
    settings: MenuBadgeSetting
  };
  /** optional list of child menus. Valid for type 'entry' only */
  children?: Menu[];
}

/** Notification configuration */
export interface NotificationConfig {
  /** type: 'success',  */
  class?: Colors,
  /** icon class name - default is no icon */
  icon?: string,
  /** noty container: 'floating', 'page', css-selector - default = 'floating'*/
  container?: string,
  /** title text */
  title?: string,
  /** message text */
  message: string,
  /** weather or not to display 'close' button */
  closeBtn?: boolean,
  /** time in ms to keep it visible */
  timer?: number
};

/** search page */
export interface SearchResultEntry {
  title: string;
  url: string;
  teaser: string;

  image_url?: string;
  image_caption?: string;

  document?: any;
  highlight?: Array<{
    key: string,
    value: any,
  }>;
}
