import {
  Pipe,
  PipeTransform
} from '@angular/core';

import {
  Attachment
} from '@pinacono/common';

import {
  ProjectDocument,
  ProjectDrawing
} from './../types';

import {
  ProjectLibService
} from '../projects.service';

/**
 * extract attachments from project document
 */
@Pipe({
  name: 'extract_attachments_from_project_document',
  pure: false,
})
export class ExtractAttachmentsFromProjectDocumentPipe implements PipeTransform {
  constructor(
    protected api: ProjectLibService
  ) {
  }

  transform<T>(document: ProjectDocument|ProjectDrawing): Attachment[] {
    if ( (<ProjectDocument>document).revisions ) {
      const last_rev = (document as ProjectDocument).revisions[0] || null;
      return last_rev?.attachments || [];
    }
    else {
      const dwg = document as ProjectDrawing;
      if ( dwg.revisions.length > 0 ) {
        const sorted = dwg.revisions.sort( (a, b) => Date.parse(a.updated_at||'') - Date.parse(b.updated_at||'') );
        return sorted[0].attachments;
      }
    }
    return [];
  }
}