/**
 * Campaign Page Module
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PinaconoUIModule } from '@pinacono/ui';
import {
  PinaconoCommonModule,
  ServerService
} from '@pinacono/common';

import { ThemesModule } from 'src/app/themes/module';

import { BindingsComponent } from './bindings/bindings.component';
import { CampaignComponent } from './campaign/campaign.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { MaximoComponent } from './maximo/maximo.component';
import { PrivacyComponent } from './privacy/privacy.component';

import { SiamPiwatService } from './siampiwat.service';
import { ENDPOINTS } from './endpoints';

/**
* module declaration
*/
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    TranslateModule.forChild(),
    PinaconoCommonModule,
    PinaconoUIModule,
    ThemesModule
  ],
  declarations: [
    // components
    BindingsComponent,
    CampaignComponent,
    ContactComponent,
    FaqComponent,
    MaximoComponent,
    PrivacyComponent
  ],
  providers: [
    SiamPiwatService
  ],
  exports: [
    //TranslateModule,

    BindingsComponent,
    CampaignComponent,
    ContactComponent,
    FaqComponent,
    MaximoComponent,
    PrivacyComponent
  ]
})

export class SiamPiwatModule {
  constructor(server: ServerService) {
    server.registerEndpoints(ENDPOINTS);
  }
}
