import {
  Component,
  Input,
  Output, EventEmitter
} from "@angular/core";

@Component({
  selector: 'search',
  templateUrl: 'search.component.html',
  styleUrls: [ 'search.component.scss' ]
})
export class SearchCompoment {
  @Input() keyword: string|null = null;
  @Output() keywordChange = new EventEmitter<string>();

  constructor() {}

  public search(keyword: string) {
    this.keywordChange.emit(keyword);
  }
}