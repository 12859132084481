import {
  Component,
  Input, Output, EventEmitter,
  OnChanges, SimpleChanges
} from "@angular/core";

import {
  ServerService
} from '@pinacono/common';

import {
  LayoutOptions
} from 'elkjs';

import {
  GraphNode, ILink
} from '@pinacono/diagram';

import {
  JobDescription,
  Competency,
  TrainingCourse
} from '../../types';

import {
  TrainingService
} from '../../training.service';

// -- skill component

@Component({
  selector: 'competency',
  templateUrl: 'competency.html',
  styleUrls: [ 'competency.scss' ]
})
export class CompetencyComponent implements OnChanges{
  @Input() id!: string;
  @Input() show_attachments?: boolean = false; // @depreciated - no attachment for competency
  @Input() show_levels?: boolean = false;
  @Input() show_dependencies?: boolean = false;

  @Output() onCourseSelected = new EventEmitter<number>();
  @Output() onJobSelected = new EventEmitter<number>();

  public data: Competency|null = null;

  public nodes: GraphNode<TrainingCourse>[] = [];
  public links: ILink[] = [];

  public rootLayoutOptions: LayoutOptions = {
    'elk.spacing.edgeNode': '25',
    'elk.layered.spacing.edgeNodeBetweenLayers': '25',
    'elk.layered.spacing.edgeEdgeBetweenLayers': '25',
    'elk.layered.spacing.nodeNodeBetweenLayers': '150',
    'elk.spacing.nodeNode': '25'
  };

  // -- life cycle
  constructor(
    protected server: ServerService,
    protected api: TrainingService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['id'] && this.id ) {
      let associated: string[] = [
        'levels',
        'levels.jobs',
        'levels.courses',
        'levels.courses.prerequisites',
        'levels.courses.postrequisites'
      ];

      this.server.show('training/competencies', parseInt(this.id), { with: associated.join(',') })
      .then( (c: object) => {
        this.data = this.api.createCompetency(c);

        this.nodes = [];
        this.links = [];

        let next_link_id = 1;
        for ( let level of ( this.data.levels || [] ) ) {
          for ( let course of (level.courses || []) ) {
            this.nodes.push({
              id: course.id!.toString(),
              data: course
            });

            course.prerequisites && course.prerequisites.forEach( (c: TrainingCourse) => {
              this.links.push({
                id: `link-${next_link_id++}`,
                source: c.id!.toString(),
                target: course.id!.toString()
              })
            });

            course.postrequisites && course.postrequisites.forEach( (c: TrainingCourse) => {
              this.links.push({
                id: `link-${next_link_id++}`,
                source: course.id!.toString(),
                target: c.id!.toString()
              })
            });
          }
        }
      });
    }
  }

  // -- template API
  public selectJob(job: JobDescription) {
    this.onJobSelected.emit(job.id);
  }

  public selectCourse(course: TrainingCourse) {
    this.onCourseSelected.emit(course.id);
  }
}