<full-page [title]="'Requests List'">
  <div page-content>
    <panel>
      <div panel-control>
        <button *ngIf="selectedRequests.length > 0" class="btn btn-success btn-icon" (click)="addSelectedToBatch()">
          <i class="pli-file"></i> {{ 'Add to Batch' | translate }}
        </button>
      </div>
      <!--
      <div panel-heading>
        <input class="form-control" type="text" [(ngModel)]="course" #lookup>
        <lookup [input]="lookup" [minChar]="3" [autoClose]="false"
          [items]="lookupCourses"
          (onLookup)="onCourseLookup($event)"
          (onSelect)="onCourseSelect($event)"
        >
        </lookup>
      </div>
      -->
      <div panel-body>
        <extended-slickgrid *ngIf="gridOptions" #grid
          class="row-selectable"
          [gridId]="'training-requests-table'"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>

<modal [hideCloseButton]="true" [size]="'large'" #myBatchesList>
  <div modal-title>
    {{ 'List of current batches' | translate }}
  </div>
  <div modal-body>

    <div *ngFor="let batch of modalTargetBatches"
      class="list-group-item list-item-sm text-sm text-left bg-light clickable"
      (click)="addRequestsToBatch(batch)"
    >
      {{ batch.name }} - ( {{ batch.schedule_start_at | datetime }} - {{ batch.schedule_finish_at | datetime }})
    </div>

    <p *ngIf=" modalTargetBatches.length == 0"
      class="list-group-item list-item-sm text-center text-sm text-muted bg-light"
    >
      {{ 'No batch available!' | translate }}
    </p>

  </div>
  <div modal-footer>
    <button class="btn btn-success btn-icon" (click)="newBatch()">
      <i class="pli-file"></i> {{ 'Create New Batch' | translate }}
    </button>
  </div>
</modal>