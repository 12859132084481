import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable } from "rxjs";
import { TranslateLoader } from "@ngx-translate/core";

export function translationLoaderFactory(http: HttpClient, modules: string[] = []): TranslateLoader {
  return new PinaconoTranslationLoader(http, modules);
}

export class PinaconoTranslationLoader implements TranslateLoader{

  constructor(
    private http: HttpClient,
    private modules: string[]
  ) {}

  // -- translate loader interface

  public getTranslation(lang: string): Observable<any> {
    // load all translation and merge into single object
    return forkJoin([

      // app's translation
      this.http.get(`assets/i18n/${lang}.json`),

      // modules' translations
      ... this.modules.map( (module: string) => {
        return this.http.get(`assets/${module}/i18n/${lang}.json`);
      })

    ])
    .pipe(
      map( (response: any) => response.reduce((a:any , b:any) => Object.assign(a, b)) )
    );
  }
}