<full-page [title]="'Librarian'">
  <div page-content>
    <panel>
      <div panel-heading>
        {{ 'Library' | translate }}
      </div>
      <div panel-body>

        <div class="row">

          <div class="col-4">
            <label class="label">Library Status</label>
            <div *ngFor="let branch of api.libraries_branches; let i = index" class="form-check">
              <input class="form-check-input" type="checkbox" value="{{ branch }}" id="branch-{{ i }}" [attr.checked]="api.isBranchOpened(branch) ? 1 : null" (change)="setBranchStatus(branch, $event)">
              <label class="form-check-label" for="branch-{{ i }}">{{ branch}}</label>
            </div>
          </div>

          <div class="col-8">

            <div class="row mb-2">
              <div class="col-2">
                <label class="label">Current Branch</label>
              </div>
              <div class="col-auto">
                <select class="form-control" [(ngModel)]="current_branch">
                  <option value="" selected disabled hidden>{{ 'Please select branch' | translate }}</option>
                  <option *ngFor="let branch of api.libraries_branches" [value]="branch">{{ branch }}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <label for="staff-id" class="label col-form-label">{{ 'Check-In/Out' | translate }}</label>
              </div>

              <div class="col-auto">
                <input type="text" class="form-control" id="staff-in" placeholder="Enter Staff ID" #staffID>
              </div>

              <div class="col-auto">
                <button class="btn-check-in-out btn btn-sm btn-primary" (click)="checkin(staffID.value)">Check-In</button>
              </div>

              <div class="col-auto">
                <button class="btn-check-in-out btn btn-sm btn-danger" (click)="checkout(staffID.value)">Check-Out</button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </panel>

    <panel>
      <div panel-control>
        <button class="btn btn-primary btn-labeled" title="Create new request" (click)="createNewReservation()">
          <i class="btn-label pli-add"></i> {{ 'Create Request' | translate }}
        </button>
      </div>
      <div panel-heading>
        {{ 'Reservation Requests' | translate }}
      </div>
      <div panel-body>
        <extended-slickgrid *ngIf="!! gridOptions" gridId="main-table"
          class="row-selectable"
          [columnDefinitions]="columnDefinitions"
          [gridOptions]="gridOptions"
          (onAngularGridCreated)="onGridReady($event)"
          (onClick)="onSelectRow($event)"
        >
        </extended-slickgrid>
      </div>
    </panel>
  </div>
</full-page>

<modal [template-id]="'reservation'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #modalReservation
>
  <div modal-title>
    {{ 'Book Reservation' | translate }}
    <span *ngIf="!! reservation" class="mb-2 badge" [ngClass]="{
      'badge-purple' : ( reservation.status == 'requesting'),
      'badge-success': ( reservation.status == 'approved' || reservation.status == 'delivered' ),
      'badge-default': ( reservation.status == 'returned' || reservation.status == 'cancelled' ),
      'badge-danger' : ( reservation.status == 'returned' && reservation.instance_status == 'lost' ),
      'badge-warning': ( reservation.status == 'returned' && reservation.instance_status == 'damage' ),
    }">
      {{ reservation.status | titlecase }}
      {{ reservation.status == 'returned' ? ' - ' + (
          reservation.instance_status == 'OK' ? 'OK' :
          reservation.instance_status == 'lost' ? 'Lost' :
          reservation.instance_status == 'damage' ? 'Damage' :
          'n/a'
        ) : null
      }}
    </span>
  </div>

  <div modal-body>
    <form #reservationForm="ngForm">
      <ng-container *ngIf="!! reservation">
      <!-- request -->
      <div class="row">
        <div class="col form-group">
          <label for="requester" class="form-label">Requester:</label>
          <input *ngIf="reservation.user !== null" type="text" class="form-control" id="requester" name="requester" disabled [(ngModel)]="reservation.user.fullname">
          <user-lookup *ngIf="walkin_mode"
            [delay]="2000"
            (onSelect)="selectRequester($event)"
          ></user-lookup>
        </div>

        <div class="col form-group">
          <label for="request-date" class="form-label">Request Date:</label>
          <input type="date" class="form-control" id="request-date" name="request-date" disabled [(ngModel)]="reservation.request_date">
        </div>

        <div class="col form-group">
          <label for="require-date" class="form-label">Required Before:</label>
          <input type="date" class="form-control" id="require-date" name="require-date" disabled [(ngModel)]="reservation.require_before">
        </div>
      </div>

      <!-- approval -->
      <div *ngIf="!! reservation.approved_by" class="row">
        <div class="col form-group">
          <label for="approve-date" class="form-label">Approve Date:</label>
          <input type="date" class="form-control" id="approve-date" name="approve-date" disabled [(ngModel)]="reservation.approve_date">
        </div>

        <div class="col form-group">
          <label for="approver" class="form-label">Approver:</label>
          <input type="text" class="form-control" id="approver" name="approver" disabled [(ngModel)]="reservation.approved_by.fullname">
        </div>
      </div>

      <!-- walk in require book search -->
      <div *ngIf="walkin_mode" class="row">
        <div class="col form-group">
          <label for="book" class="form-label">Book Title:</label>
          <input *ngIf="reservation.book !== null" type="text" class="form-control" id="book" name="book" disabled [(ngModel)]="reservation.book.title">
          <input *ngIf="reservation.book === null" type="text" class="form-control" id="book" name="book" disabled value="Please select book instance.">
          <!--
          <input *ngIf="reservation.book !== null" type="text" class="form-control" id="book" name="book" disabled [(ngModel)]="reservation.book.title">
          <lookup-input class="lookup"
            [placeholder]="'Enter book information'"
            [minChar]="3"
            [autoClose]="false"
            [delay]="2000"
            [items]="bookLookupItems"
            [autoClose]="false"
            (onLookup)="lookupBook($event)"
            (onSelect)="selectBook($event)"
          >
          </lookup-input>
          -->
        </div>
      </div>

      <!-- delivery -->
      <div *ngIf="reservation.status == 'approved' || !! reservation.delivered_by" class="row">
        <div class="col form-group">
          <label for="instance" class="form-label">Instance:</label>
          <div *ngIf="! reservation.instance" class="text-thin">{{ 'Please select book instance' | translate }}</div>
          <div *ngIf="!! reservation.instance" class="text-thin">{{ selectedBookInstance }}</div>
          <lookup-input *ngIf="! reservation.delivered_by" class="lookup"
            [placeholder]="'Enter bard code'"
            [minChar]="3"
            [delay]="500"
            [autoClose]="false"
            [items]="bookInstanceLookupItems"
            [autoClose]="false"
            [disabled]="! reservation.book_id"
            (onLookup)="lookupBookInstance($event)"
            (onSelect)="selectBookInstance($event)"
          >
          </lookup-input>
          <validation-errors *ngIf="! reservation.delivered_by" id="instance-error"
            [errors]="deliver_instance_errors"
          ></validation-errors>
          <input *ngIf="reservation.delivered_by" type="text" class="form-control" id="deliver-by" name="deliver-by" disabled [(ngModel)]="reservation.instance!.code">
        </div>
        <div class="col form-group">
          <label for="return-before" class="form-label">Return Before:</label>
          <input type="date" class="form-control" id="return-before" name="return-before" required [disabled]="!! reservation.delivered_by" [(ngModel)]="reservation.return_before" #return_before="ngModel">
          <validation-errors *ngIf="! reservation.delivered_by" id="return-before-error"
            [models]="return_before"
            [messages]="{ required: 'This field is required' }"
          ></validation-errors>
        </div>
      </div>

      <!-- return -->
      <div *ngIf="reservation.status == 'delivered' || !! reservation.returned_to" class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'Book Status' | translate }}:</label>

          <div *ngIf="reservation.status != 'returned'" class="ms-3">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="instance-status" id="instance-ok"     (change)="calcFine()" [value]="'OK'"     [(ngModel)]="reservation.instance_status"><label class="form-check-label" for="instance-OK">{{ 'OK' | translate }}</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="instance-status" id="instance-lost"   (change)="calcFine()" [value]="'lost'"   [(ngModel)]="reservation.instance_status"><label class="form-check-label" for="instance-lost">{{ 'Lost' | translate }}</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="instance-status" id="instance-damage" (change)="calcFine()" [value]="'damage'" [(ngModel)]="reservation.instance_status"><label class="form-check-label" for="instance-damage">{{ 'Damage' | translate }}</label>
            </div>
          </div>

          <div *ngIf="reservation.status == 'returned'" class="ms-3">
            <span class="text-thin">{{ reservation.instance_status || 'n/a' }}</span>
          </div>

        </div>

        <div class="col form-group">
          <label for="fine" class="form-label">{{ 'Fine Amount' | translate }}:
            <div class="reservation-status">
              <span *ngIf="is_late" class="badge badge-warning">{{ 'Late' | translate }}</span>
              <span *ngIf="reservation.instance_status == 'lost'"   class="badge badge-danger">{{ 'Lost' | translate }}</span>
              <span *ngIf="reservation.instance_status == 'damage'" class="badge badge-warning">{{ 'Damage' | translate }}</span>
            </div>
          </label>
          <input type="number" class="form-control" id="fine" name="fine" required [disabled]="reservation.status != 'delivered'" [(ngModel)]="reservation.fine" #fine="ngModel">
          <div *ngIf="fine_details.length > 0" class="mt-2">
            <label>{{ 'Details' | translate }}:</label>
            <ul id="fine-details">
              <li *ngFor="let detail of fine_details">
                <span class="fine-desc">{{ detail.desc }}</span><span class="fine-amount">{{ detail.fine | number:'1.0-0' }} THB.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- returned -->
      <div *ngIf="reservation.status == 'returned' || !! reservation.returned_to" class="row">
        <div class="col form-group">
          <label for="return-date" class="form-label">Return Date:</label>
          <input type="date" class="form-control" id="return-date" name="return-date" disabled [(ngModel)]="reservation.return_date">
        </div>
        <div class="col form-group">
          <label for="returned-to" class="form-label">Return To:</label>
          <input type="text" class="form-control" id="returned-to" name="returned-to" disabled [(ngModel)]="reservation.returned_to!.fullname">
        </div>
      </div>

      <!-- note -->
      <div class="row">
        <div class="col">
          <label for="note" class="form-label">Note:</label>
          <textarea class="form-control" id="note" name="note" autosize [(ngModel)]="reservation.note">
          </textarea>
        </div>
      </div>
      </ng-container>
    </form>
  </div>

  <div modal-footer>

    <button *ngIf="reservation && reservation.status == 'requesting'" class="btn btn-labeled btn-success" type="button" (click)="approveReservation();">
      <i class="btn-label pli-yes"></i> {{ 'Approve' | translate }}
    </button>

    <button *ngIf="reservation && reservation.status == 'approved'" class="btn btn-labeled btn-success" type="button" (click)="deliverReservation();">
      <i class="btn-label pli-yes"></i> {{ 'Deliver' | translate }}
    </button>

    <button *ngIf="reservation && reservation.status == 'delivered'" [attr.disabled]="reservation.extended ? 1 : null" [ngClass]="{ 'btn-default': reservation.extended, 'btn-purple' : ! reservation.extended }" class="btn btn-labeled" type="button" (click)="extendReservation();">
      <i class="btn-label pli-left-4"></i> {{ 'Extend' | translate }}
    </button>

    <button *ngIf="reservation && reservation.status == 'delivered'" class="btn btn-labeled btn-success" type="button" (click)="returnReservation();">
      <i class="btn-label pli-left-4"></i> {{ 'Return' | translate }}
    </button>

    <button *ngIf="reservationForm?.dirty && reservation && reservation.status != 'approved' && reservation.status != 'delivered'" class="btn btn-labeled btn-success" type="button" (click)="saveReservation();">
      <i class="btn-label pli-save"></i> {{ 'Save' | translate }}
    </button>

    <button class="btn btn-labeled btn-default" type="button" (click)="closeReservation();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>
