<footer id="footer">
  <p class="pad-lft float-start clickable" title="Version {{ app_info.signature }}" [routerLink]="['/versions']">
    <span id="version" class="text-xs" >Version {{ app_info.signature }}</span>
    <span class="hidden-sm hidden-xs"> - </span>
    <span id="copyright" title="Pinacono Co., Ltd." class="hidden-sm hidden-xs">&copy; {{ app_info.copyright }}</span>
  </p>
  <div id="pinacono-logo">
    <a href="https://www.pinacono.com" target="_blank">
      <img src="assets/images/pinacono/badge.png" title="Developed by Pinacono Co., Ltd." alt="Developed by Pinacono Co., Ltd.">
    </a>
  </div>
</footer>

