<!-- Panel element-->
<div class="panel" class="{{ panelClasses }}">

  <div class="panel-heading"
    [ngClass]="{ 'hidden' : ! hasHeading }"
  >
    <div class="panel-control" #panelControl>
      <ng-content select="[panel-control]"></ng-content>
      <button *ngIf="feature == 'maximizable'" class="btn btn-default" data-panel="fullscreen">
        <i class="icon-max psi-maximize-3"></i>
        <i class="icon-min psi-minimize-3"></i>
      </button>
      <button *ngIf="feature == 'dismissable'" class="btn btn-default" data-panel="dismiss">
        <i class="psi-cross"></i>
      </button>
      <button *ngIf="((feature == 'collapsible') || (feature == 'collapsed'))" class="btn btn-default" data-panel="minmax">
        <i class="psi-chevron-up"></i>
      </button>
    </div>
    <h3 class="panel-title" #panelHeading>
      <ng-content select="[panel-heading]"></ng-content>
    </h3>
  </div>

  <div class="panel-body" [ngClass]="{ 'collapse': (feature == 'collapsible' || feature == 'collapsed'), 'in' : (feature == 'collapsible') }" [ngStyle]="{ 'height': height }">
    <div class="panel-content-container" [ngClass]="{ 'nano has-scrollbar' : !! height }">
      <div class="panel-content-inner-container" [ngClass]="{'nano-content' : !! height }">
        <ng-content select="[panel-body]"></ng-content>
      </div>
    </div>
  </div>

  <div class="panel-footer"
    [ngClass]="{ 'hidden': ! hasFooter }"
    #panelFooter
  >
    <ng-content select="[panel-footer]"></ng-content>
  </div>

</div>
<!-- end of Panel element-->