<!-- Minimal Page Container-->
<div id="container" class="cls-container footer-fixed selectable">

  <!-- Background Image -->
  <div id="bg-overlay"></div>

  <!-- language switcher -->
  <ul id="nav-language-switcher" class="nav navbar-top-links">
    <li class="dropdown">
      <a href="#" data-bs-toggle="dropdown" class="dropdown">
        <span>{{ theme.currentLang.toUpperCase() }}</span>
      </a>
      <ul class="head-list dropdown-menu dropdown-menu-sm-end">
        <li *ngFor="let l of theme.languages">
          <a href="#" stopPropagation (click)="theme.currentLang = l">{{ l.toUpperCase() }}</a>
        </li>
      </ul>
    </li>
    <!-- end of language switcher -->
  </ul>

  <!-- Page Content -->
  <div class="cls-content">
    <ng-content></ng-content>
  </div>
  <!-- end of Page Content -->

  <!-- Footer -->
  <!--
  <page-footer></page-footer>
  -->
  <!-- end of Footer -->

</div>
<!-- end of Nifty Minimal Page -->
