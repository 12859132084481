<full-page [title]="'Training Course'">
  <div page-content>
    <course *ngIf="!!id"
      [id]="id"
      [show_dependencies]="true"
      (onAfterAction)="onAfterCourseAction($event)"
      #courseComponent
    >
    </course>
  </div>
</full-page>