<div class="form-group" title="{{ config.label | translate }}">
  <label class="form-label"><i *ngIf="!!config.icon" class="icon fa fa-fw fa-{{ config.icon }}"></i> {{ config.label | translate }}: </label>

  <!-- type = select -->
  <checkboxes *ngIf="config.type == 'select'"
    [options]="options"
    [value]="'id'"
    [label]="'text'"
    [title]="'text'"
    (change)="inputChange()"
    [(model)]="data"
  ></checkboxes>

  <!-- type = dropdown -->
  <select *ngIf="config.type == 'dropdown' && config.options"
    id="prop-{{ config.name }}" name="{{ config.name }}"
    class="form-control select"
    (change)="inputChange()"
    [(ngModel)]="data"
  >
    <option *ngFor="let o of config.options" value="{{ o.id }}">{{ o.text }}</option>
  </select>

  <!-- type = text -->
  <input *ngIf="config.type == 'text'"
    type="text"
    id="prop-{{ config.name }}" name="{{ config.name }}"
    class="form-control"
    (change)="inputChange()"
    [(ngModel)]="data"
  >

  <!-- type = list -->
  <input *ngIf="config.type == 'editable-list'"
    id="prop-{{ config.name }}" name="{{ config.name }}"
    class="form-control"
    [attr.list]="'prop-' + config.name + '-list'"
    (change)="inputChange()"
    [(ngModel)]="data"
  >
  <datalist id="prop-{{ config.name }}-list">
    <option *ngFor="let o of config.options" value="{{ o.id }}">{{ o.text }}</option>
  </datalist>

  <!-- type = tree -->
  <!-- div *ngIf="config.type == 'tree'">Coming soon...</div> -->

  <validation-errors *ngIf="errors" [errors]="errors" [dirty]="true"></validation-errors>
</div>
