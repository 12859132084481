import {
  Component,
  ViewChild
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NgForm
} from '@angular/forms';

import {
  NavigationService,
  object_t,
  PaginatedResults,
  ServerService,
  SessionService,
  StringUtilsService,
  User
} from '@pinacono/common';

import {
  AttachmentsComponent,
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  LayoutOptions
} from 'elkjs';

import {
  DiagramComponent,
  GraphLink,
  GraphNode,
  ILink
} from '@pinacono/diagram';

import { ExamChoice, ExamQuestion } from 'src/app/common/types';
import { OPL } from 'src/app/modules/opl/types';
import { BasePageComponent } from 'src/app/classes/base-page.component';

import {
  TrainingService
} from '../training.service';

import {
  CompetencyLevel,
  //TrainingExamQuestion,
  TrainingCourse,
  ExamScoring,
  ExamTimerMode,
  ExamQuestionsConfig
} from '../types';

import * as _ from 'lodash';

@Component({
  selector: 'training-course-edit-page',
  templateUrl: 'edit.html',
  styleUrls: [ 'edit.scss' ]
})
export class TrainingCourseEditPage extends BasePageComponent {

  @ViewChild('form') form!: NgForm;
  @ViewChild('attachments') attachments!: AttachmentsComponent;
  @ViewChild('graph') graphComponent!: DiagramComponent;
  @ViewChild('questionEditor') questionEditor!: ModalComponent;

  public examTimerMode = ExamTimerMode;

  public nodes: GraphNode<TrainingCourse>[] = [];
  public links: ILink[] = [];

  public course: TrainingCourse|null = null;
  /*
  public description = {
    objectives: '',
    content: ''
  };
  */

  public examConfig: ExamQuestionsConfig = {
    no_of_questions: 10,
    no_of_choices: 4
  };

  public scoring: ExamScoring = {
    correct_answer: 1,
    incorrect_answer: 0,
    no_answer: 0,
    timer_mode: ExamTimerMode.NO_TIMER,
    time_limit: 0, // in minutes
    time_penalty: 0
  };

  //public quiz: TrainingExamQuestion[] = [];
  public quiz: ExamQuestion[] = [];

  public rootLayoutOptions: LayoutOptions = {
    'elk.spacing.edgeNode': '25',
    'elk.layered.spacing.edgeNodeBetweenLayers': '25',
    'elk.layered.spacing.edgeEdgeBetweenLayers': '25',
    'elk.layered.spacing.nodeNodeBetweenLayers': '150',
    'elk.spacing.nodeNode': '25'
  };

  // -- lifecycle
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: ServerService,
    protected stringUtils: StringUtilsService
  ) {
    super(router, activatedRoute);
  }

  public override loadData(): Promise<void> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    if ( ! id || id == 'new' ) {
      this.course = this.api.createCourse();
      this.course.objectives = [this.api.createCourseObjective(null)]; // at least one objtive is required
      return Promise.resolve();
    }

    const associated: string[] = [
      'levels',
      'levels.jobs',
      'levels.competency',
      'objectives',
      'prerequisites.postrequisites',
      'postrequisites.prerequisites',
      'requests',
      'batches',
      'trainers'
    ];

    const appends: string[] = [
      'related_opls'
    ]

    let promises: Promise<void>[] = [];
    promises.push(this.server.rejectOnError().show('training/courses', parseInt(id!), {
        with: associated.join(','),
        appends: appends.join(',')
    })
    .then( (c: object_t) => {
      this.course = this.api.createCourse(c);
      this.scoring    = Object.assign(this.scoring,    (this.course.attr && this.course.attr['scoring'])     || null);
      this.examConfig = Object.assign(this.examConfig, (this.course.attr && this.course.attr['exam_config']) || null);

      this.nodes = [];
      this.links = [];

      this.nodes.push({
        id: this.course.id!.toString(),
        data: this.course
      });

      let link_next_id = 1;
      for ( let course of this.course.prerequisites || [] ) {
        this.nodes.push({
          id: course.id!.toString(),
          data: course
        });

        this.links.push({
          id: `link-${link_next_id++}`,
          source: course.id!.toString(),
          target: this.course.id!.toString()
        });
      }

      for ( let course of this.course.postrequisites || [] ) {
        this.nodes.push({
          id: course.id!.toString(),
          data: course
        });

        this.links.push({
          id: `link-${link_next_id++}`,
          source: this.course.id!.toString(),
          target: course.id!.toString()
        });
      }
    })
    .catch( async (error) => {
      await this.ui.alert('Cannot load training course - ' + error);
      this.back();
    }));

    promises.push(this.server.rejectOnError().index('exam/questions', {
      'course': id
    }, {
      perpage: -1
    })
    .then( (res: PaginatedResults<object>) => {
      this.quiz = res.data.map( (q) => this.api.createTrainingExamQuestion(q));
    })
    .catch( (error) => {
      this.ui.alert('Error while loading examination - ' + error);
    }));

    return new Promise( (resolve) => Promise.all(promises).then( () => resolve() ) );
  }

  // -- template API

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  // evaluations
  public addEvaluation() {
    if ( ! this.course ) return;
    this.course.objectives.push(this.api.createCourseObjective());
  }

  public removeEvaluation(index: number) {
    if ( ! this.course ) return;
    this.ui.confirm("Remove this course evaluation?", undefined, () => {
      this.course!.objectives.splice(index, 1);
    });
  }

  // OPLs lookup
  public lookup_opls: LookupItem<OPL>[] = [];
  public lookupOPL(keyword: string) {
    this.server.lookup('contents', { keyword: keyword})
    .then( (res: object_t[]) => {
      this.lookup_opls = res.map( o => {
        const opl = o as OPL
        return {
          label: `${o['doc_code']}: ${o['title']}`,
          value: opl
        }
      } );
    });
  }

  public onRelatedOPLsChanged(list: OPL[]) {
    if ( ! this.course ) return;
    this.course.related_opls = list;
    this.course.related_opl_ids = list.map( item => item.id! );
  }

   // trainers lookup
   public lookup_trainers: LookupItem<User>[] = [];
   public lookupTrainers(keyword: string) {
     this.server.lookup('users', { name: keyword})
     .then( (res: object_t[]) => {
       this.lookup_trainers = res.map( u => {
        const user = u as User
         return {
           label: `${u['fullname']} (${u['email']})`,
           value: user
         }
       } );
     });
   }

   public onTrainersChanged(list: User[]) {
     if ( ! this.course ) return;
     this.course.trainers = list;
     this.course.trainer_ids = list.map( item => item.id );
   }

   // Competencies lookup
  public lookup_levels: LookupItem<CompetencyLevel>[] = [];
  public lookupLevels(keyword: string) {
    this.server.lookup('training/competencies', {keyword: keyword}, 2, { appends: 'levels' })
    .then( (res: object[]) => {
      this.lookup_levels = [];
      for ( let competency of res.map( c => this.api.createCompetency(c) ) ) {
        for ( let level of competency.levels || [] ) {
          level.competency = competency;
          this.lookup_levels.push({
            label: `${competency.code} (Level ${level.level}): ${competency.name}`,
            value: level
          });
        }
      }
    });
  }

  public onRelatedLevelsChanged(list: CompetencyLevel[]) {
    if ( ! this.course ) return;
    this.course.levels = list;
    this.course.level_ids = list.map( l => l.id! );
  }

  // Course dependencies
  public lookup_courses: LookupItem<TrainingCourse>[] = [];
  public selected_course: string = '';
  public prerequisite_ids: number[] = [];
  public postrequisite_ids: number[] = [];
  public lookupCourses(keyword: string) {
    this.server.lookup('training/courses', {keyword: keyword}, 5)
    .then( (res: object[]) => {
      this.lookup_courses = res.map( c => {
        let course = this.api.createCourse(c);
        return {
          label: `${course.code}: ${course.name}`,
          value: course
        }
      });
    });
  }

  public addRelatedCourse(event: LookupEvent<TrainingCourse>) {
    let course: TrainingCourse = event.value.value as TrainingCourse;
    this.nodes.push({
      id: course.id!.toString(),
      data: course
    });
    this.nodes = this.nodes.slice();
  }

  protected updateRequisiteIds() {
    if ( ! this.course ) return;
    this.course.prerequisites && ( this.course.prerequisite_ids  = this.course.prerequisites.map( p => p.id! ) );
    this.course.postrequisites && ( this.course.postrequisite_ids = this.course.postrequisites.map( p => p.id! ) );
  }

  public onCoursesConnected(connection: GraphLink|null) {
    if ( ! connection || ! connection.source || ! connection.target || ! this.course ) return;
    let pre: TrainingCourse  = connection.source.data;
    let post: TrainingCourse = connection.target.data;

    if ( pre.id == this.course.id ) {
      if ( ! this.course.postrequisites ) this.course.postrequisites = [];
      // add post as this.course's postrequisite
      this.course.postrequisites.push(post);
      this.updateRequisiteIds();
      return;
    }

    if ( post.id == this.course.id ) {
      if ( ! this.course.prerequisites ) this.course.prerequisites = [];
      // add pre as this.course's prerequisite
      this.course.prerequisites.push(pre);
      this.updateRequisiteIds();
      return;
    }

    this.ui.alert('Current course must be either prerequisite or postrequisite of the other course', undefined, 'Invalid Dependencies', () => {
      this.graphComponent.deleteLink(connection.id);
    });
  }

  public onCourseRemoved(node: GraphNode<TrainingCourse>) {
    if ( ! this.course ) return;

    let i: number

    i = ( this.course.prerequisites && this.course.prerequisites.findIndex( p => p.id == node.data.id ) ) || -1;
    if ( i >= 0 ) {
      this.course.prerequisites && this.course.prerequisites.splice(i, 1);
    }
    i = ( this.course.postrequisites && this.course.postrequisites.findIndex( c => c.id == node.data.id ) ) || -1;
    if ( i >= 0 ) {
      this.course.postrequisites && this.course.postrequisites.splice(i, 1);
    }

    this.updateRequisiteIds();
  }

  public onDependencyRemoved(link: GraphLink) {
    if ( ! link.source || ! link.target || ! this.course ) return;

    let pre: TrainingCourse  = link.source.data;
    let post: TrainingCourse = link.target.data;

    if ( pre.id == this.course.id ) {
      // add post as this.course's postrequisite
      let i = ( this.course.postrequisites && this.course.postrequisites.findIndex( c => c.id == post.id ) ) || -1;
      if ( i >= 0 ) {
        this.course.postrequisites && this.course.postrequisites.splice(i, 1);
      }
      this.updateRequisiteIds();
      return;
    }

    if ( post.id == this.course.id ) {
      // add pre as this.course's prerequisite
      let i = ( this.course.prerequisites && this.course.prerequisites.findIndex( c => c.id == pre.id ) ) || -1;
      if ( i >= 0 ) {
        this.course.prerequisites && this.course.prerequisites.splice(i, 1);
      }
      this.updateRequisiteIds();
      return;
    }
  }

  // others
  public delete() {
    if ( this.course?.id ) {
      this.ui.confirm('Delete course "{{name}}"? This cannot be restored!', { name: this.course.name }, async () => {
        await this.server.destroy('training/courses', this.course!.id!)
        this.back();
      });
    }
  }

  public save() {
    if ( ! this.course ) return;

    let data: object_t = _.cloneDeep(this.course);

    // remove unnecessary fields to reduce the data to be sent
    delete data['attachments']; // attachments and covers are handle seperately
    delete data['covers'];
    delete data['batches'];
    delete data['active_batches'];
    delete data['levels']
    delete data['prequisites'];
    delete data['postrequisites'];
    delete data['requests'];
    delete data['trainers'];

    // evaluations
    for ( let i in data['objectives'] ) {
      let obj = data['objectives'][i];
      if ( ! obj['description'] || (<string>obj['description']).trim() == '' ) {
        data['objectives'][i]['description'] =
          this.api.formatBullet('** วัตถุประสงค์ **', this.api.bulletize(obj.attr['objectives'] || null)) +
          "\n" +
          this.api.formatBullet('** เนื้อหา **', this.api.bulletize(obj.attr['content'] || null));
      }
    }

    // attributes
    data['attr'] = Object.assign({}, this.course.attr, {
      //content: this.description.content.split("\n"),
      //objectives: this.description.objectives.split("\n"),
      scoring: this.scoring,
      exam_config: this.examConfig
    });

    this.course.related_opls  && ( data['related_opl_ids'] = this.course.related_opls.map( o => o.id ) );
    this.course.trainers      && ( data['trainer_ids']     = this.course.trainers.map( t => t.id ) );
    this.course.prerequisites && ( data['prerequisites']   = this.course.prerequisites.map( p => p.code ) );

    let promise: Promise<any>;

    if ( this.course.id ) {
      promise = this.server.update('training/courses', this.course.id, data);
    }
    else {
      promise = this.server.create('training/courses', data);
    }
    promise.then( (course: TrainingCourse) => {
      this.nav.goto([ '/training/courses/view', course.id ]);
    });
  }

  // -- questions
  public question: ExamQuestion|null = null;
  protected loadQuestion(id: number|null): Promise<void> {
    if ( ! this.course ) return Promise.resolve();

    if ( ! id ) {
      this.question = this.api.createTrainingExamQuestion({
        defer_key: this.course.questionable_defer_key || this.stringUtils.random(16)
      });
      return Promise.resolve();
    }
    return this.server.show('exam/questions', id)
      .then( (q: object) => {
        this.question = this.api.createTrainingExamQuestion(q);
      });
  }

  public editQuestion(q?: ExamQuestion) {
    this.loadQuestion( (q && q.id) || null)
    .then( () => {
      this.questionEditor.show();
    });
  }

  public deleteChoice(choice: ExamChoice) {
    if ( choice && choice.id ) {
      this.server.destroy('exam/choices', choice.id);
    }
  }

  public async saveQuestion() {
    if ( ! this.question || ! this.course ) return;

    this.question.author_id = this.session.currentUser!.id;
    this.question.level = 0; // level is not use at the moment. just assign '0'

    let q: ExamQuestion;
    if ( this.question.id ) {
      q = await this.server.update('exam/questions', this.question.id, this.question);
    }
    else {
      let data: object_t = Object.assign({}, this.question);
      data['master']    = 'training_course';
      data['master_id'] = this.course.id;
      q = await this.server.create('exam/questions', data);
    }

    this.quiz.push(q);
    this.questionEditor.hide();
  }

  public cancelQuestion() {
    this.questionEditor.hide();
  }
}