<minimal-page>
  <div class="cls-content-sm">
    <div page-head>
      <h1 class="error-code text-info">Offline</h1>
    </div>
    <div page-content>
      <div class="text-center">
        <p class="h4 text-uppercase text-bold">{{ 'Server Offline' | translate}}!</p>
        <div class="pad-btm">
          Sorry, but the server is currenly offline. Please contact the administration for more information.
        </div>
      </div>
    </div>
  </div>
</minimal-page>