import * as moment from 'moment';

import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import { NgForm } from '@angular/forms';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  config,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  User,
  UtilsService
} from '@pinacono/common';

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  ComponentFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { LibraryService } from '../library.service';
import {
  Book,
  BookInstance,
  BookReservation,
  BROWSER_DT_FORMAT
} from '../types';
import { HardcopyStatus } from '../../documents/types';

@Component({
  selector: 'library-activities',
  templateUrl: 'activities.html',
  styleUrls: [ 'activities.scss' ],
  providers: [ AngularUtilService ]
})
export class LibraryActivitiesPage extends BasePageComponent implements OnInit {
  public data: BookReservation[] = [];

  /**
   * graphQL configuration
   */

  protected readonly dataSetName: string = 'book_reservations';
  protected filters: GraphqlFilteringOption[] = [
    //{ field: 'menu', operator: 'EQ', value: 'published' }
  ];
  protected trashed: 'WITH'|'WITHOUT'|'ONLY' = 'WITHOUT';

  // grid interface
  protected gridComponent: AngularGridInstance|null = null;
  protected selectedRow: number = 0;
  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected ngUtilService: AngularUtilService,
    protected nav: NavigationService,
    protected ui: UIService,
    protected api: LibraryService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService,
    protected utils: UtilsService
  ) {
    super(router, activatedRoute);
  }

  protected user_id: number|null = null;
  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  protected silent: boolean = true;
  protected override loadData(): Promise<void> {
    if ( !! this.gridComponent ) {
      this.silent = false;
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces

  protected createDataModel(data: object_t): BookReservation {
    return this.api.createBookReservation(data);
  }

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      this.graphqlServer
      .sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.data = re.data[this.dataSetName].nodes.map( d => this.createDataModel(d) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initGrid() {
    /** @TODO - update colum definitions */
    // -- Grid columns definitions
    this.columnDefinitions = [
      {
        id: 'code', name: 'Book Code',
        field: 'book.code',
        fields: [ 'book.doc_code' ],
        type: FieldType.object,
        cssClass: 'doc-code text-left', minWidth: 180, maxWidth: 180,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return dataContext.book.doc_code || 'n/a';
        }
      },
      {
        id: 'book_id',  name: 'Title',
        field: 'book.title',
        fields: [ 'book.id', 'book.title' ],
        type: FieldType.object,
        sortable: true,
        filterable: true,
        minWidth: 100,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return dataContext.book.title || 'n/a';
        }
      },

      /*
      {
        id: 'staff', name: 'Requester',
        field: 'user',
        fields: [ 'user.id', 'user.avatar', 'user.avatar.thumb_url', 'user.email', 'user.fullname', 'user.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left',
        width: 100,
        filterable: true,
        formatter: ComponentFormatter,
        params: {
          //component: GridUserComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'user'
          }
        }
      },
      */

      {
        id: 'bar_code',  name: 'Instance Code',
        field: 'instance.code',
        fields: [ 'instance.location', 'approved_by.id', 'approved_by.email', 'approved_by.fullname', 'approved_by.profiles.staff_id', 'approve_date' ],
        type: FieldType.object,
        cssClass: 'text-center',
        sortable: true,
        filterable: true,
        width: 50,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return dataContext.instance && dataContext.instance.code || 'n/a';
        }
      },

      {
        id: 'instance-status', name: 'Instance Status',
        field: 'instance_status',
        type: FieldType.string,
        cssClass: 'text-center',
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          const labels: object_t = {
            damage: { label: 'Damage', css: 'warning' },
            ok:     { label: 'OK',     css: 'success' },
            lost:   { label: 'Lost',   css: 'danger'  },
            na:     { label: 'N/A',    css: 'default' },
          }
          const status = dataContext.instance_status?.toLowerCase() || 'na';
          return `<span class="badge badge-${labels[status].css || 'default'}">${labels[status].label || 'n/a'}</span>`
        },
        sortable: true,
        filterable: true,
        width: 40,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: 'OK',     label: 'OK'     },
            { value: 'lost',   label: 'Lost'   },
            { value: 'damage', label: 'Damage' },
          ]
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        fields: [ 'status', 'extended' ],
        type: FieldType.string,
        cssClass: 'text-center',
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          const labels: object_t = {
            requesting: { label: 'Requesting', css: 'warning' },
            approved:   { label: 'Approved',   css: 'success' },
            rejected:   { label: 'Rejected',   css: 'danger' },
            delivered:  { label: 'Delivered',  css: 'info' },
            returned:   { label: 'Returned',   css: 'dark' },
            //extended:   { label: 'Extended',   css: 'purple' },
            cancelled:  { label: 'Cancelled',  css: 'default' }
          }
          const status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>` + (dataContext.extended ? ' <span class="badge badge-purple">Extended</span>' : '');
        },
        sortable: true,
        filterable: true,
        width: 40,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: 'requesting', label: 'Requesting' },
            { value: 'approved',   label: 'Approved'   },
            { value: 'rejected',   label: 'Rejected'   },
            { value: 'delivered',  label: 'Delivered'  },
            { value: 'returned',   label: 'Returned'   },
            //{ value: 'extended',   label: 'Extended'   },
            { value: 'cancelled',  label: 'Cancelled'  }
          ]
        }
      },

      {
        id: 'request_date', name: 'Request Date',
        field: 'request_date',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'require_before', name: 'Require Before',
        field: 'require_before',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'deliver_date', name: 'Collecting Date',
        field: 'deliver_date',
        fields: [ 'delivered_by.id', 'delivered_by.email', 'delivered_by.fullname', 'delivered_by.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'return_befire', name: 'Return Before',
        field: 'return_before',
        fields: [ 'return_before' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'return_date', name: 'Return Date',
        field: 'return_date',
        fields: [ 'return_date', 'returned_to.id', 'returned_to.email', 'returned_to.fullname', 'returned_to.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];

    // -- Grid options
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: this.dataSetName,
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'user_id', value: this.session.currentUser?.id },
            { field: 'trashed', value: this.trashed }
          ]
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'reservations'
      },

      enableSorting: true,

      rowHeight: 60,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
      }
    };
  }

  @ViewChild('modalReservation') modalReservation!: ModalComponent;
  public reservation: BookReservation|null = null;
  public async onSelectRow(event: Event) {
    this.selectedRow = (event as CustomEvent).detail.args['row'];
    const res: object_t = await this.server.show('library/reservation/book', this.data[this.selectedRow].id!, { with: 'book,instance,approved_by,delivered_by,returned_to' });
    this.reservation = this.api.createBookReservation(res);
    this.is_late = !! this.reservation.return_before && this.utils.dateTime_utils.to_moment(this.reservation.return_before, 'YYYY-MM-DD').isBefore();
    this.calcFine();
    setTimeout(() => this.modalReservation.show());
  }

  // -- template API

  @ViewChild('reservationForm') reservationForm!: NgForm;

  // -- fine calculator
  public is_late: boolean = false;
  public fine_details: { desc: string, fine: number }[] = [];
  public calcFine() {
    if ( ! this.reservation ) return;

    if ( this.reservation.fine && this.reservation.fine > 0 ) return;

    const rules: object_t = config('client.library');

    this.reservation.fine = 0;
    this.fine_details = [];

    if ( this.is_late ) {
      const days = 0 - this.utils.dateTime_utils.to_moment(this.reservation.return_before, 'YYYY-MM-DD').diff(this.utils.dateTime_utils.to_moment(), 'days');
      const fine = rules['fine'] * days;

      this.reservation.fine += fine;
      this.fine_details.push({
        desc: 'Late for ' + this.utils.number_utils.format(days) +' day(s)',
        fine: fine
      });
    }

    if ( this.reservation.instance_status == 'lost' ) {
      const fine = ( this.reservation.book.attr['BOOK'] && this.reservation.book.attr['BOOK'].price ) || 0;

      this.reservation.fine += fine;
      this.fine_details.push({
        desc: 'Book lost, price is ' + this.utils.number_utils.format(fine),
        fine: fine
      });
    }

    if ( this.reservation.instance_status == 'damage' ) {
      const fine = ( this.reservation.book.attr['BOOK'] && this.reservation.book.attr['BOOK'].price ) || 0;

      this.reservation.fine += fine;
      this.fine_details.push({
        desc: 'Book damage, price is ' + this.utils.number_utils.format(fine),
        fine: fine
      });
    }
  }

  public async extendReservation() {
    /** @todo - revise to use workflow */
    if ( ! this.reservation ) return;
    if ( await this.ui.confirm('Extend this request?') ) {
      await this.server.post('library/reservation/book/extend/{id}', { id: this.reservation.id! }, {
        return_before: moment(this.reservation.return_before).add(1, 'week').format('YYYY-MM-DD')
      });
      this.modalReservation.hide();
      this.refresh();
    }
  }
}