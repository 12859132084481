<full-page [title]="'Edit Course'">
  <div page-content class="pad-all">
    <panel>
      <div panel-body>
        <form #form="ngForm">
          <div *ngIf="!!course" class="media">
            <!--
            <pre>{{ course | json }}</pre>
            -->
            <div id="attachments-panel" class="media-left">
              <!--
              <gallery *ngIf="course.covers.length > 0" class="img-responsive" [items]="course.covers"></gallery>
              <img *ngIf="course.covers.length == 0" class="img-responsive" src="/assets/imgs/300x300.png">
              -->
              <label class="control-label text-bold">{{ 'Cover Images' | translate }}:</label>
              <attachments
                id="covers-uploader"
                class="pad-top pad-btm"
                [(files)]="course.covers"
                [common-meta-data]="{ is_cover: true }"
                [accepted-file-types]="'image/*'"
                [master-content-type]="'training_courses'"
                [master-id]="course.id"
                [defer-key]="course.attachment_defer_key"
              >
                <!--
                <ng-template
                  let-file="file"
                  let-meta="meta"
                >
                  <input class="form-control" type="text" [(ngModel)]="meta['title']">
                </ng-template>
                -->
              </attachments>

              <label class="control-label text-bold mar-top">{{ 'Files' | translate }}:</label>
              <attachments *ngIf="course.attachments"
                id="attachments-uploader"
                class="pad-top pad-btm"
                [(files)]="course.attachments"
                [master-content-type]="'training_courses'"
                [master-id]="course.id"
                [defer-key]="course.attachment_defer_key"
              ></attachments>
            </div>
            <div class="media-body">

              <!-- course name and code -->
              <div class="section row title">
                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label class="control-label text-bold">{{ 'Course code' | translate }}:</label>
                    <input class="form-control full-width" type="text" name="code" required [(ngModel)]="course.code" #code="ngModel">
                    <div class="alert alert-danger" *ngIf="code.invalid && (code.dirty || code.touched)">
                      <div class="text-sm text-thin" *ngIf="code.errors && code.errors['required']">{{ 'Course Code is required' | translate }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label class="control-label text-bold">{{ 'Course Name' | translate }}:</label>
                    <input class="form-control full-width" type="text" name="name" required [(ngModel)]="course.name" #name="ngModel">
                    <div class="alert alert-danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                      <div class="text-sm text-thin" *ngIf="name.errors && name.errors['required']">{{ 'Course Name is required' | translate }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-3">
                  <div id="auto-batch" class="form-group" title="Create new batch automatically or not.">
                    <label for="auto-batch">
                      <input class="checkbox" type="checkbox" name="auto-batch" id="auto-batch" [(ngModel)]="course.auto_batch">
                      {{ 'Auto create new batch' | translate }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- course description -->
              <div class="section mar-top description" title="{{ 'Course Description' | translate }}">

                <label class="control-label text-bold">{{ 'Course Content' | translate }}:</label>
                <textarea class="form-control" rows="6" name="description_content" [(ngModel)]="course.description"></textarea>

                <label class="mar-top control-label text-bold">{{ 'Course Objectives' | translate }}:</label>
                <textarea class="form-control" rows="6" name="description_objectives" [(ngModel)]="course.attr!['objectives']"></textarea>

              </div>

              <!-- course evaluation -->
              <div class="section mar-top evaluations" title="{{ 'Course Evaluations' | translate }}">
                <label class="control-label text-bold">{{ 'Course Evaluations' | translate }}:</label>

                <div *ngFor="let objective of course.objectives; let i = index" class="evaluation-entry zebra">
                  <div class="row">
                    <div class="col-xs-12 col-sm-3">
                      <label class="control-label text-bold">{{ 'Evaluations' | translate }}:</label>
                      <textarea class="form-control" rows="3" name="evaluations" required [(ngModel)]="objective.attr!['evaluations']" #evaluations="ngModel"></textarea>
                      <div class="alert alert-danger" *ngIf="evaluations.invalid && (evaluations.dirty || evaluations.touched)">
                        <div class="text-sm text-thin" *ngIf="evaluations.errors && evaluations.errors['required']">{{ 'Objective evaluation is required' | translate }}</div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-3">
                      <label class="control-label text-bold">{{ 'Evaluators' | translate }}:</label>
                      <textarea class="form-control" rows="3" name="evaluators" [(ngModel)]="objective.attr!['evaluators']" #evaluators="ngModel"></textarea>
                      <div class="alert alert-danger" *ngIf="evaluators.invalid && (evaluators.dirty || evaluators.touched)">
                        <div class="text-sm text-thin" *ngIf="evaluators.errors && evaluators.errors['required']">{{ 'Objective evaluator is required' | translate }}</div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-3">
                      <label class="control-label text-bold">{{ 'Evidences' | translate }}:</label>
                      <textarea class="form-control" rows="3" name="evidences" [(ngModel)]="objective.attr!['evidences']" #evidences="ngModel"></textarea>
                      <div class="alert alert-danger" *ngIf="evidences.invalid && (evidences.dirty || evidences.touched)">
                        <div class="text-sm text-thin" *ngIf="evidences.errors && evidences.errors['required']">{{ 'Objective evidence is required' | translate }}</div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-3">
                      <label class="control-label text-bold">{{ 'Minimum Score' | translate }}:</label>
                      <dl class="text-xs text-thin dl-horizontal min-score-help">
                        <dt>= 0.0</dt>
                          <dd>No Exam</dd>
                        <dt>&#8804; 1.0</dt>
                          <dd>Min %<dd>
                        <dt>&gt; 1.0</dt>
                          <dd>Min score</dd>
                      </dl>
                      <div class="row">
                        <div class="col-xs-7">
                          <input class="form-control" type="number" min="0.0" max="100.0" name="min_score" [(ngModel)]="objective.min_score">
                        </div>
                        <div class="col-xs-5 text-left text-xs min-score-value">
                          = {{ objective.min_score | percent }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="action btn btn-icon" (click)="removeEvaluation(i)" title="Remove this evaluation">
                    <i class="icon-lg fa fas fa-minus"></i>
                  </button>
                </div>

                <div class="evaluation-entry">
                  <div class="row">
                    <div class="col-xs-12">&nbsp;</div>
                  </div>
                  <button class="action btn btn-icon" (click)="addEvaluation()" title="Add new evaluation">
                    <i class="icon-lg fa fas fa-plus"></i>
                  </button>
                </div>
              </div>

              <!-- scoring -->
              <div class="section mar-top scoring" title="{{ 'Examination Configuration' | translate }}">

                <div class="row">
                  <div class="col-xs-12 col-sm-4">
                    <div class="form-group">
                      <label class="control-label text-bold">{{ 'No. of Questions per Exam' | translate }}:</label>
                      <input class="form-control full-width" type="number" name="exam_config_questions" required min="0" [(ngModel)]="examConfig.no_of_questions">
                    </div>

                    <div class="form-group">
                      <label class="control-label text-bold">{{ 'No. of Choices per Question' | translate }}:</label>
                      <input class="form-control full-width" type="number" name="exam_config_choices" required min="0" [(ngModel)]="examConfig.no_of_choices">
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-4">
                    <div class="form-group">
                      <label class="control-label text-bold">{{ 'Correct Answer Score' | translate }}: (points)</label>
                      <input class="form-control full-width" type="number" name="score_correct" required min="0" [(ngModel)]="scoring.correct_answer">
                    </div>

                    <div class="form-group">
                      <label class="control-label text-bold">{{ 'Incorrect Answer Score' | translate }}: (points)</label>
                      <input class="form-control full-width" type="number" name="score_incorrect" required [(ngModel)]="scoring.incorrect_answer">
                    </div>

                    <div class="form-group">
                      <label class="control-label text-bold">{{ 'No Answer Score' | translate }}: (points)</label>
                      <input class="form-control full-width" type="number" name="score_noanswer" required [(ngModel)]="scoring.no_answer">
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-4">
                    <div class="form-group">
                      <label class="control-label text-bold">{{ 'Time Limit Mode' | translate }}:</label>
                      <select class="form-control" name="score_timermode" [(ngModel)]="scoring.timer_mode">
                        <option value="{{ examTimerMode.NO_TIMER }}">{{ 'No Timer' | translate }}</option>
                        <option value="{{ examTimerMode.TIME_LIMIT }}">{{ 'Time Limit' | translate }}</option>
                        <option value="{{ examTimerMode.TIME_PENALTY }}">{{ 'Exceeding Time Penalty' | translate }}</option>
                      </select>
                    </div>

                    <div *ngIf="scoring.timer_mode == examTimerMode.TIME_LIMIT || scoring.timer_mode == examTimerMode.TIME_PENALTY" class="form-group">
                      <label class="control-label text-bold">{{ 'Time Limit' | translate }}: (minutes)</label>
                      <input class="form-control full-width" type="number" name="score_time_limit" required [(ngModel)]="scoring.time_limit">
                    </div>

                    <div *ngIf="scoring.timer_mode == examTimerMode.TIME_PENALTY" class="form-group">
                      <label class="control-label text-bold">{{ 'Time Penalty' | translate }}: (pts/min)</label>
                      <input class="form-control full-width" type="number" name="score_time_penalty" required [(ngModel)]="scoring.time_penalty">
                    </div>
                  </div>
                </div>

              </div>

              <!-- related OPLs -->
              <div class="section mar-top related-opls">
                <label class="control-label text-bold">{{ 'Related OPLs' | translate }}:</label>
                <list-editor
                  [(list)]="course.related_opls!"
                  [name]="'related-opls'"
                  [placeholder]="'Enter OPL code or title'"
                  [lookupList]="lookup_opls"
                  (onLookup)="lookupOPL($event)"
                  (listChange)="onRelatedOPLsChanged($event)"
                >
                  <ng-template #itemTemplate
                    let-item="item"
                    let-index="index"
                  >
                    <span class="code">{{ item.document.doc_code }}</span>: <span class="title">{{ item.title }}</span>
                  </ng-template>
                </list-editor>
              </div>

              <!-- related Competencies -->
              <div class="section mar-top competencies">
                <label class="control-label text-bold">{{ 'Competencies' | translate }}:</label>
                <list-editor
                  [(list)]="course.levels!"
                  [name]="'related-levels'"
                  [placeholder]="'Enter competency code or title'"
                  [lookupList]="lookup_levels"
                  (onLookup)="lookupLevels($event)"
                  (listChange)="onRelatedLevelsChanged($event)"
                >
                  <ng-template #itemTemplate
                    let-item="item"
                    let-index="index"
                  >
                    <strong>{{ item.competency.code }} (Level {{ item.level || 0 }})</strong>: {{ item.competency.name || 'Unknown Competency' }}
                  </ng-template>
                </list-editor>
              </div>

              <!-- course dependencies-->
              <div *ngIf="nodes.length > 0" class="section mar-top course-dependencies">

                <div  *ngIf="course.prerequisites && course.prerequisites.length > 0" class="prerequisites" title="{{ 'Prerequisites' | translate }}">
                  <label class="control-label text-bold">{{ 'Prerequisites' | translate }}:</label>
                  <ul>
                    <li *ngFor="let c of course.prerequisites" class="text-sm text-thin">
                      <strong>{{ c.code }}</strong> - {{ c.name }}
                    </li>
                  </ul>
                </div>

                <div *ngIf="course.postrequisites && course.postrequisites.length > 0" class="postrequisites" title="{{ 'Required By' | translate }}">
                  <label class="control-label text-bold">{{ 'Required by' | translate }}:</label>
                  <ul>
                    <li *ngFor="let c of course.postrequisites" class="text-sm text-thin">
                      <strong>{{ c.code }}</strong> - {{ c.name }}
                    </li>
                  </ul>
                </div>

                <div class="dependencies-editor">
                  <label class="control-label text-bold">{{ 'Course Dependencies' | translate }}:</label>
                  <lookup-input  class="lookup"
                    ngDefaultControl
                    [name]="'course-dependencies'"
                    [minChar]="3" [autoClose]="false"
                    [items]="lookup_courses"
                    [autoClose]="false"
                    [(ngModel)]="selected_course"
                    (onLookup)="lookupCourses($event)"
                    (onSelect)="addRelatedCourse($event)"
                  >
                  </lookup-input>

                  <!--
                  <div class="btn-group graph-toolbar">
                    <button class="btn btn-icon" (click)="deleteSelected()" title="Delete selected">
                      <i class="icon-lg fas fa-trash"></i>
                    </button>
                  </div>
                  -->

                  <diagram #graph
                    [readonly]="false"
                    [scale]="0.5"
                    [rootLayoutOptions]="rootLayoutOptions"
                    [(nodes)]="nodes"
                    [(links)]="links"
                    (connected)="onCoursesConnected($event)"
                    (nodeDeleted)="onCourseRemoved($event)"
                    (linkDeleted)="onDependencyRemoved($event)"
                  >
                    <ng-template
                      let-node="node"
                      let-selected="selected"
                    >
                      <div class="node-container"
                        [ngClass]="{
                          'selected': selected,
                          'current-course': ( node.data.id == course.id )
                        }"
                      >
                        <div class="text-bold course-title">
                          {{ node.data.code }}
                        </div>
                        <div class="course-details">
                          <h4>{{ node.data.name }}</h4>
                          <p [markdown]="node.data.description">{{ node.data.description }}</p>
                        </div>
                      </div>
                    </ng-template>
                  </diagram>
                </div>

              </div>

              <!-- trainers -->
              <div class="section mar-top course-trainers">
                <label class="control-label text-bold">{{ 'Mentors' | translate }}:</label>
                <list-editor
                  [(list)]="course.trainers!"
                  [name]="'trainers'"
                  [placeholder]="'Enter name, email or staff ID'"
                  [lookupList]="lookup_trainers"
                  [position]="'top'"
                  [addOnSelect]="true"
                  (onLookup)="lookupTrainers($event)"
                  (listChange)="onTrainersChanged($event)"
                >
                  <ng-template #itemTemplate
                    let-item="item"
                    let-index="index"
                  >
                    <user-card [user]="item"></user-card>
                  </ng-template>
                </list-editor>
              </div>

              <!-- quiz -->
              <div *ngIf="!!course.id" class="section mar-top course-exam">
                <label class="control-label text-bold">{{ 'Quiz' | translate }}:</label>
                <ul *ngIf="quiz.length > 0">
                  <li *ngFor="let q of quiz">
                    <a class="btn-link" (click)="editQuestion(q)">{{ q.content }}</a>
                  </li>
                </ul>
                <div *ngIf="quiz.length == 0" class="text-center text-sm text-italic text-thin">
                  - {{ 'No quiz avaialble for this course.' | translate }} -
                </div>
                <div class="text-right">
                  <button type="button" class="btn btn-labeled btn-primary" (click)="editQuestion()">
                    <i class="icon-lg fa fas fa-plus"></i> {{ 'Add new question.' | translate }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
      <div panel-footer >
        <div class="text-right">
          <button type="button" class="btn btn-labeled btn-default" (click)="back()">
            <i class="btn-label fa fas fa-chevron-circle-left"></i>{{ 'Back' | translate }}
          </button>
          <button type="button" class="btn btn-labeled btn-success" [attr.disabled]="form.invalid ? 1 : null" (click)="save()"
            ><i class="btn-label fa far fa-save"></i>{{ 'Save' | translate }}
          </button>
          <button *ngIf="course && course.id && session.isPermitted(['core_admin'])" type="button" class="btn btn-labeled btn-danger" (click)="delete()"
            ><i class="btn-label fa fas fa-trash"></i>{{ 'Delete' | translate }}
          </button>
        </div>
      </div>
    </panel>
  </div>
</full-page>

<modal template-id="quiz-editor"
  size="large"
  [hideCloseButton]="true"
  #questionEditor
>
  <div modal-body>
    <quiz-editor *ngIf="question"
      [(question)]="question"
      (onChoiceDeleted)="deleteChoice($event)"
      (onSave)="saveQuestion()"
      (onCancel)="cancelQuestion()"
    >
    </quiz-editor>
  </div>
</modal>