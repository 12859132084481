import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  NgForm
} from '@angular/forms';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  object_t,
  NavigationService,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  Column,
  FieldType,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  DataGridButton,
  GraphQLServerService,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import {
  ExternalContact
} from '../types';

import {
  ProjectLibService
} from '../projects.service';

@Component({
  selector: 'app-contacts',
  templateUrl: 'contacts.html',
  styleUrls: ['contacts.scss']
})
export class ProjectLibContactsPage extends BasePageComponent implements OnInit {

  @ViewChild('modalEditContact') modalEditContact!: ModalComponent;
  @ViewChild('editorForm') editorForm!: NgForm;

  // data
  public contact: ExternalContact;
  public contacts: ExternalContact[] = [];

  // options
  public spw_departments: string[] = [];
  public company_roles: string[] = [];
  public companies: string[] = [];

  // grid interface
  protected gridComponent: AngularGridInstance|null = null;
  protected selectedRow: number = 0;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  protected filters: GraphqlFilteringOption[] = [
    //{ field: 'menu', operator: 'EQ', value: 'published' }
  ];

  public constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected ui: UIService,
    protected api: ProjectLibService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService
  ) {
    super(router, activatedRoute);
    this.contact = this.api.createExternalContact();
  }

  public override async ngOnInit() {
    this.initGrid();
    this.spw_departments = await this.loadDataList('spw_departments');
    this.companies       = await this.loadDataList('companies');
    this.company_roles   = await this.loadDataList('company_roles');
  }

  // -- overloading

  protected silent: boolean = true;
  protected override loadData(): Promise<any> {
    if ( !! this.gridComponent ) {
      this.silent = false;
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- internal

  protected loadDataList(name: string): Promise<string[]> {
    return this.server
    .request('projlib.external_contact.data_list', {name: name})
    .then( (res: string[]) => {
      return res;
    });
  }

  // -- template API

  public is_spw: boolean = false;
  private _origin_company = '';
  public toggleSPW(event: Event) {
    this.is_spw = (event.target as any).checked;
    if ( this.is_spw ) {
      this._origin_company = this.contact.company || '';
      this.contact.company =  'SPW';
    }
    else {
      this.contact.company = this._origin_company;
    }
  }

  public async saveContact() {
    const errors = await this.ui.validateForm(this.editorForm)
    if ( Object.keys(errors).length > 0  ) {
      this.ui.alert('Contact data is incompleted. Please recheck.');
      return;
    }

    // create/update contact
    if ( this.contact.id ) {
      await this.server.update('projects/contacts', this.contact.id, this.contact);
    }
    else {
      await this.server.create('projects/contacts', this.contact);
    }
    this.contact = this.api.createExternalContact();
    this.modalEditContact.hide();
    this.refresh();
  }

  // -- grid interfaces

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.graphqlServer.silent() : this.graphqlServer;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.contacts = re.data['external_contacts'].nodes.map( (p: object_t) => {
            return this.api.createExternalContact(p);
          });
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initGrid() {
    let component = this;
    this.columnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', width: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'organization', name: 'Company',
        field: 'company',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150,
        sortable: true,
        filterable: true,
      },

      {
        id: 'department', name: 'Department',
        field: 'department',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150 ,
        sortable: true,
        filterable: true
      },

      {
        id: 'fullname', name: 'Full Name',
        field: 'fullname',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150,
        sortable: true,
        filterable: true,
      },

      {
        id: 'job_title', name: 'Job Title',
        field: 'job_title',
        type: FieldType.string,
        cssClass: 'text-center', minWidth: 150,
        sortable: true,
        filterable: true
      },

      {
        id: 'email', name: 'E-Mail',
        field: 'email',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150, maxWidth: 200,
        sortable: true,
        filterable: true,
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 100,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'edit',
              title: 'Edit this contact',
              //css: 'btn-primary',
              css: 'btn-default',
              //icon: 'pli-pen',
              icon: 'fa-solid fa-pen',
              visible: function(row: number, cell: number, dataContext: ExternalContact, columnDef: Column, grid: SlickGrid): boolean {
                return  component.session.hasPermission(['core_admin'])  && ! dataContext.deleted_at;
              },
              click: async (row: number, col: number, dataContext: ExternalContact, config: DataGridButton, grid: SlickGrid) => {
                this.contact = this.api.createExternalContact(await this.server.show('projects/contacts', this.contacts[row].id!));
                this.modalEditContact.show();
              }
            },
            {
              name: 'delete',
              title: '(Soft) delete this contact',
              //css: 'btn-warning',
              css: 'btn-default',
              //icon: 'pli-trash',
              icon: 'fa-solid fa-trash-can',
              visible: function(row: number, cell: number, dataContext: ExternalContact, columnDef: Column, grid: SlickGrid): boolean {
                return  component.session.hasPermission(['core_admin'])  && !! dataContext.deleted_at;
              },
              click: async (row: number, col: number, dataContext: ExternalContact, config: DataGridButton, grid: SlickGrid) => {
                if ( await this.ui.confirm('Mark this contact as deleted (soft delete)?') ) {
                  await this.server.destroy('projects/contacts', dataContext.id!)
                  this.refresh();
                }
              }
            },
            {
              name: 'restore',
              title: 'Restore this contact',
              css: 'btn-default',
              icon: 'pli-recycling-2',
              //icon: 'fa-solid fa-recycle',
              visible:  function(row: number, cell: number, dataContext: ExternalContact, columnDef: Column, grid: SlickGrid): boolean {
                return !! dataContext.deleted_at && component.session.hasPermission(['core_admin']);
              },
              click: async (row: number, col: number, dataContext: ExternalContact, config: DataGridButton, grid: SlickGrid) => {
                if ( await this.ui.confirm('Retstore this project?') ) {
                  await this.server.restore('projects/contacts', dataContext.id!);
                  this.refresh();
                }
              }
            },

            {
              name: 'trash',
              title: '(Hard) Delete this contact',
              css: 'btn-default',
              icon: 'fa-solid fa-bomb',
              visible:  function(row: number, cell: number, dataContext: ExternalContact, columnDef: Column, grid: SlickGrid): boolean {
                return !! dataContext.deleted_at && component.session.hasPermission(['core_admin']);
              },
              click: async (row: number, col: number, dataContext: ExternalContact, config: DataGridButton, grid: SlickGrid) => {
                if ( await this.ui.confirm('Permanently delete (hard delete) this project?') ) {
                  await this.server.purge('projects/contacts', dataContext.id!);
                  this.refresh();
                }
              }
            },
          ]
        }
      }
    ];

    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'external_contacts',
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'trashed', value: 'WITH' }
          ]
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };
  }

  public async onSelectRow(event: Event) {
    this.selectedRow = (event as CustomEvent).detail.args['row'];
    if ( ! this.contacts[this.selectedRow].id  ) {
      console.warn('Contact ID is not available!');
      return;
    }
    this.contact = this.api.createExternalContact(await this.server.show('projects/contacts', this.contacts[this.selectedRow].id!));
    this.is_spw = this.contact.company == 'SPW';
    this.modalEditContact.show();
  }
}
