import { Injectable } from '@angular/core';

import {
  object_t,
  ServerService,
  SessionService,
  Taxonomy,
  StringUtilsService
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

import { ThemeService } from 'src/app/themes/theme.service';

import { OPLConfig, OPL, OPLQuestion } from './types';

import { AppCommonService } from 'src/app/common/app-common.service';
import { CustomPropertyConfig } from 'src/app/common/types';
//import { TrainingService } from '../training/training.service'; // circular DI!!

@Injectable({
  providedIn: 'root'
})
export class OplService {

  protected counts = {}

  public config: OPLConfig = {
    min_length: 0,
    min_read_comment_length: 0,
    min_read_time: 30,
    min_quiz: 0,
    quiz_count: 0,
    read_prove_mode: 'timer' // 'quiz' or 'timer' or 'none'
  }
  public domains: Taxonomy[]     = [];
  public categories: Taxonomy[]  = [];
  public properties: CustomPropertyConfig[] = [];

  constructor(
    protected ui: UIService,
    protected server: ServerService,
    protected session: SessionService,
    protected theme: ThemeService,
    protected stringUtils: StringUtilsService,
    //protected training: TrainingService,
    protected common: AppCommonService
  ) {
  }

  /**
   * ensure that the value is always array for displaying
   */
  public getProperties(name: string, value: any) {
    let v: any[] = ! Array.isArray(value) ? [value] : value;
    let p = this.properties.find( p => p.name == name );

    if ( ! p ) {
      return v;
    }

    for ( let j in v ) {
      if ( ! p.options[j]  || ! p.options[j].id ) {
        console.warn(`Error! cannot find option for ${v[j]}`);
        continue;
      }
      if ( p.options[j].id == v[j] ) {
        v[j] = p.options[j].text;
        break;
      }
    }
    return v;
  }

  /**
   * count available OPL on server
   */
  public async count() {
    if ( ! this.session.currentUser ) {
      return;
    }
    const res: object_t = await this.server.silent().request('opl.count', {
      uid: this.session.currentUser.id
    })
    for ( let n in res ) {
      this.theme.setMenuBadge(`opl-${n}`, res[n] as string);
    }
  }

  /**
   * Create OPL object from raw data
   *
   * @param data object
   * @return OPL
   */
  public create(data: object_t|null = null): OPL {
    let opl: OPL = {
      id:         data && data['id']         || null,
      title:      data && data['title']      || 'New OPL',
      status:     data && data['status']     || 'draft',
      author:     data && data['author']     || null,
      approver:   data && data['approver']   || null,
      domain:     data && data['domain']     || this.session.currentUser?.primary_domain || null,
      domain_id:  data && data['domain_id']  || this.session.currentUser?.primary_domain?.id || null,
      categories: data && data['categories'] || [ this.session.currentUser?.primary_domain || undefined ],
      teaser:     data && ( data['teaser']   || data['content'] ) || '',
      content:    data && data['content']    || '',
      attachments: {
        unsorted: data && data['attachments'] && data['attachments']['unsorted'] || [],
        image:    data && data['attachments'] && data['attachments']['image']    || [],
        video:    data && data['attachments'] && data['attachments']['video']    || [],
        youtube:  data && data['attachments'] && data['attachments']['youtube']  || [],
        document: data && data['attachments'] && data['attachments']['document'] || []
      },
      attachment_defer_key: data && data['attachment_defer_key'] || this.stringUtils.random(16),
      properties: data && data['properties'] || {},
      questions: [],
      questionable_defer_key: data && data['questionable_defer_key'] || this.stringUtils.random(16),
      related_course_ids: data && data['related_course_ids'] || [],
      //related_courses: ( data && data['related_courses'] && data['related_courses'].map( (c:object_t) => this.training.createCourse(c) ) )|| [],
      audiences: data && data['audiences'] || { read: [], unread: [] },
      document:  data && data['document'],
      comments:  data && data['comments'] || [],
      comment:   '', // new comment always blank
      revision_logs: ( data && data['revision_logs'] || [] ).map( (l:object_t) => this.common.createComment(l) ),
      references: data && data['references'] || '',

      created_at:  data && data['created_at']  || null,
      updated_at:  data && data['updated_at']  || null,
      approved_at: data && data['approved_at'] || null,
      deleted_at:  data && data['deleted_at']  || null
    };

    if ( data && data['questions'] ) {
      opl.questions = [];
      for ( let q of data['questions'] ) {
        opl.questions.push(this.createOPLExamQuestion(q as Object, opl));
      }
    }

    /*
    if ( data && data['properties'] ) {
      opl.properties = {};
      for ( let n in data['properties'] ) {
        let name = data['properties'][n].name;
        opl.properties[name] = ( data && data['properties'][name] ) || [];
      }
    }
    */

    return opl;
  }

  /**
   * Validate OPL
   *
   * @param opl
   * @param for_submit
   */
  public validate(opl: OPL, for_submit: boolean): boolean {
    // -- minimum requirement for saving
    if ( opl.title.length <= 0 ) {
      this.ui.alert('OPL Title is required.');
      return false;
    }

    if ( ! for_submit ) {
      return true;
    }

    if ( ! opl.properties || ! opl.properties['opl_type'] || opl.properties['opl_type'].length == 0 ) {
      this.ui.alert('OPL Type is required.', undefined, 'Error!');
      return false;
    }

    if ( ! opl.properties['location'] || opl.properties['location'].length == 0 ) {
      this.ui.alert('OPL Location is required.', undefined, 'Error');
      return false;
    }

    // -- comprehensive validation for submit

    if ( opl.questions && opl.questions.length < this.config.min_quiz ) {
      this.ui.alert('At least {{ count }} questions are required.', {count: this.config.min_quiz}, 'Error');
      return false;
    }

    for ( let q of ( opl.questions || [] ) ) {
      if ( q.choices.filter( c => c.is_correct ).length < 1 ) {
        this.ui.alert('At least {{ n }} correct answer is required.', { n : 1 }, 'Error');
        return false;
      }

      if ( q.choices.filter( c => ! c.is_correct).length < 3 ) {
        this.ui.alert('At least {{ n }} incorrect answers is required.', {n: 3}, 'Error');
        return false;
      }
    }

    // strip HTML code
    let div = document.createElement('div');
    div.innerHTML = opl.content || '';

    //if ( opl.content.length < this.config.min_length ) {
    if ( div.innerText.length < this.config.min_length ) {
      this.ui.alert('At least {{ n }} characters of content is required.', {n: this.config.min_length}, 'Error');
      return false;
    }

    if ( ! opl.domain ) {
      this.ui.alert("Please select domain.", undefined, 'Error');
      return false;
    }

    if ( opl.categories.length <= 0 ) {
      this.ui.alert("Please select at least one category.");
      return false;
    }

    return true;
  }

  public createOPLExamQuestion(data: object_t|null, opl: OPL) : OPLQuestion {
    let q: OPLQuestion = this.common.createExamQuestion(data) as OPLQuestion;
    q.master_type = 'opl';
    q.master_id   = opl.id || 0;
    q.defer_key   = opl.questionable_defer_key;
    return q;
  }
}
