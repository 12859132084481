import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  NavigationService,
  UserService
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

@Component({
  selector: 'app-redirector-page',
  templateUrl: 'redirector.html',
  styleUrls: ['redirector.scss']
})
export class RedirectorComponent implements AfterViewInit {
  public url: string|null = null;

  constructor(
    public translate: TranslateService,
    public user: UserService,
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected ui: UIService
  ) {
  }

  public ngAfterViewInit(): void {
    this.url = this.activatedRoute.snapshot.queryParamMap.get('url');
    if ( ! this.url ) {
      this.nav.setRoot('home');
      return;
    }
    document.location.href = this.url;
  }

  // -- template API
  public onClick(url: string) {
    this.nav.setRoot(url);
  }
}
