/**
 * redirector endpoints
 */

 import { ServiceEndpointsMapping } from "@pinacono/common";

 export const ENDPOINTS: ServiceEndpointsMapping = {
  group: {
    team: '/groups/team/{uid}', // get team members
  },

  maximo: {
    redirect: '/maximo/url/redirect',
    calendar:  '/maximo/calendar'
    //subgroups: '/maximo/url/subgroups'
  },

  bindings: {
    line: {
      get:  '/nodered/get/{code}', // retrive registration request by code
      bind: '/nodered/bind/{code}' // bind line chatbot account
    }
  }
 };
