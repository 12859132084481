/**
 * types for Training module
 */

import {
  Attachment,
  object_t,
  Taxonomy,
  LogMessage,
  User
} from '@pinacono/common';

import {
  ExamChoice,
  ExamQuestion
} from 'src/app/common/types';

import { OPL } from '../opl/types';
import { Comment } from 'src/app/common/types';

// -- job description and competency

export interface JobCompetency {
  competency: Competency;
  expectation: number;
}

export interface JobDescription {
  id?: number;

  title: string;
  code: string;
  description: string;
  job_level: string;

  competencies: JobCompetency[];
  document?: Document;
  users?: [User];

  attachments: Attachment[];
  attachment_defer_key?: string;

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface Competency {
  id?: number;

  code: string;
  name: string;
  description: string;

  levels?: CompetencyLevel[];

  //categories: Taxonomy[];
  categories: string;
  attachments: Attachment[];
  attachment_defer_key?: string;

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface CompetencyLevel {
  id?: number;

  level: number;
  description: string;
  attr?: object_t;

  competency_id: number;
  competency?: Competency;

  job_ids: number[];
  jobs?: JobDescription[];

  course_ids: number[];
  courses?: TrainingCourse[];

  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

// -- training plan and profile

/**
 * note: client require profiles for every competencies available
 * on server. Server must also generates at-least the 'level 0' for
 * competencies those the user are not applicable.
 */
export interface CompetencyProfile {
  id?: number;

  user_id: number;
  user?: User;

  competency_id: number;
  competency: Competency;

  actual: number;
  expectation: number;  // model-calculated
  egap?: number;        // client-calculated
  target: number;       // client-calculated
  tgap?: number;        // client-calculated

  active_plans?: TrainingPlan[];  // client-calculated

  plans?: TrainingPlan[];
  records: TrainingRecord[];

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface GapsAnalyzerData {
  id: number;
  competency: Competency;
  category: string;
  profiles: CompetencyProfile[];
}

export interface Candidate {
  staff: User;
  profiles: CompetencyProfile[];
}

export interface TrainingPlan {
  id: number;

  user_id: number;
  user?: User;

  competency_id: number;
  competency: Competency;
  target: number;
  dead_line: string; // date
  status: 'unplanned' | 'planned' | 'completed' | 'missed' | 'cancelled';
  records: TrainingRecord[];

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface CompetencyProfileSnapshot extends CompetencyProfile {
  snap_from: string;
  snap_to: string;
}

// -- training record

export enum TrainingRecordStatus {
  PENDING    = 'pending',
  PROGRESS   = 'in_progress',
  CANCELLED  = 'cancelled',  // terminal
  FAILED     = 'failed',     // terminal
  PASSED     = 'passed'      // terminal
}

export interface TrainingRecord {
  id?: number;
  status: TrainingRecordStatus;

  user_id: number;
  user?: User;

  batch_id: number;
  batch?: TrainingBatch;

  plan_id: number;
  plan?: TrainingPlan

  applied_at?: string;
  closed_at?: string;

  objectives: TrainingObjective[];

  examinations?: ExamAnswerSheet[];

  note: string;
  logs: LogMessage[];

  comments: Comment[];
  attachments: Attachment[];
  attachment_defer_key?: string;

  attr: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

// -- training course and objective

export enum TrainingObjectiveResult {
  PASSED    = 'passed',
  FAILED    = 'failed',
  PENDING   = 'pending',
  PROGRESS  = 'progress',
  CANCELLED = 'cancelled'
}

export interface TrainingObjective {
  id?: number;
  description: CourseObjectiveDescription;

  record_id: number;
  record?: TrainingRecord;

  trainer_id: number;
  trainer: User;

  min_score?: number;

  answer_sheet?: ExamAnswerSheet[];
  result: TrainingObjectiveResult;

  comments: Comment[];
  attachments: Attachment[];
  attachment_defer_key: string;

  attr: object_t;

  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface CourseObjectiveDescription {
  evaluations: string[];
  evaluators: string[];
  evidences: string[];
}

export interface CourseObjective {
  id?: number;

  description: CourseObjectiveDescription;
  min_score: number;  // > 0 = need exam and auto approve
  quiz_count?: number; // > 0 = need exam and auto approve
  related_docs?: string[];

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface TrainingCourse {
  id?: number;

  code: string;
  name: string;

  //level: CompetencyLevel;
  levels?: CompetencyLevel[]; // Skill levels of the target group. Attendees require this skill level to join the course.
  level_ids?: number[];       // for saving

  related_opls?: OPL[];
  related_opl_ids?: number[]; // for saving

  prerequisite_ids?: number[];      // for saving
  prerequisites?: TrainingCourse[];
  postrequisite_ids?: number[];     // for saving
  postrequisites?: TrainingCourse[];

  description: string;
  objectives: CourseObjective[];
  auto_batch: boolean;     // automatically create batch when user enroll course, team manager become mentor automatically
  min_score: number;       // calculated by client, 0 = no exam required

  questionable_defer_key?: string;

  requests?: TrainingCourseRequest[]; //
  my_request?: TrainingCourseRequest; //

  batches?: TrainingBatch[];
  active_batches?: TrainingBatch[]; // all not-closed batch
  my_batch?: TrainingBatch;         // the batch that user join

  trainer_ids?: number[]; // for saving
  trainers?: User[];

  covers: Attachment[];
  attachments?: Attachment[];
  attachment_defer_key?: string;

  readonly next_exam: number;

  attr?: object_t,
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

// -- training request

export enum TrainingCourseRequestStatus {
  REQUESTED = 'requested',
  ACCEPTED  = 'accepted',
  POSTPONED = 'postponed',
  CANCELLED = 'cancelled',
  CLOSED    = 'closed'
}

export interface TrainingCourseRequest {
  id?: number;

  status: TrainingCourseRequestStatus;

  course_id: number;
  course?: TrainingCourse;

  user_id: number;
  user?: User;

  batch_id?: number;      // if not null = closed - user already assigned to a batch
  batch?: TrainingBatch;

  attr?: object_t,
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

// -- training batch

export enum TrainingBatchStatus {
  PENDING    = 'pending',
  OPEN       = 'open',
  FULL       = 'full',
  PROGRESS   = 'in_progress',
  EVALUATION = 'evaluation',
  CANCELLED  = 'cancelled',
  CLOSED     = 'closed'
}

export interface TrainingBatch {
  id?: number;
  name: string;
  venue?: string;
  schedule_start_at?: string;
  schedule_finish_at?: string;

  capacity: number;
  status: TrainingBatchStatus;
  note?: string;
  logs: LogMessage[];

  objectives: TrainingObjective[];

  course: TrainingCourse;

  trainer_ids?: number[];     // for saving
  trainers: User[];

  attendee_ids?: number[];    // for saving
  attendees: User[];

  attachments?: Attachment[];
  attachment_defer_key?: string;

  my_record?: TrainingRecord;

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

// -- quiz and exam

export enum ExamTimerMode {
  NO_TIMER     = 0, // no timer at all
  TIME_LIMIT   = 1, // limit time, force submitting
  TIME_PENALTY = 2  // take penalty according to exceeding time (in sec)
}

export interface ExamQuestionsConfig {
  no_of_questions: number; // number of questions per exam
  no_of_choices: number;   // number of choices per question
}

export interface ExamScoring {
  correct_answer: number;
  incorrect_answer: number;
  no_answer: number;
  timer_mode: ExamTimerMode;
  time_limit: number;   // in minutes
  time_penalty: number; // points per min
}

export interface TrainingExamQuestion extends ExamQuestion {
  course_id?: number;  // for saving
  course?: TrainingCourse;
}

export interface ExamAnswer {
  question_id: number;
  answer:  ExamChoice;
  choices: ExamChoice[]; // choices are random from pool, so we need to keep the active list in table.
}

export interface ExamScoreExplanations {
  correct: number;
  incorrect: number;
  no_answer: number;
  time_limit_exceed: number; // in minutes
  time_limit_penalty: number;
}

export interface ExamAnswerSheet {
  id: number;

  time_spent: number; // in seconds
  candidate_id: number;
  candidate: User;

  plan_id: number;
  plan?: TrainingPlan;

  objective_id: number;
  objective?: TrainingObjective;
  score_explanations: ExamScoreExplanations;

  answers: ExamAnswer[];

  score: number;     // computed by server
  max_score: number; // computed by server

  attr?: object_t;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;

  result?: 'passed'|'failed'|'n/a'; // for front-end used only
}

// -- training course

export interface CourseActionEvent {
  action: 'request' | 'unrequest' | 'apply' | 'unapply' | 'exam',
  params: CourseRequestAction | CourseApplyAction | CourseExamAction
}

export interface CourseRequestAction {
  course: TrainingCourse;
};

export interface CourseUnrequestAction {
  course: TrainingCourse;
};

export interface CourseApplyAction {
  course: TrainingCourse;
  batch: TrainingCourseRequest;
};

export interface CourseExamAction {
  course: TrainingCourse;
  batch: TrainingBatch;
  score: number;
}
