import {
  Component,
  ViewChild,
  OnInit
} from '@angular/core';

import {
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  ServerService,
  SessionService,
  Taxonomy,
  TreeUtilsService,
  UserService
} from '@pinacono/common';

import {
  ModalComponent,
  Tree,
  TreeComponent,
  UIService,
  UserProfileComponent
} from '@pinacono/ui';

import { AppUser } from 'src/app/types';

/**
 * Groups Administration
 */

@Component({
  selector: 'app-admin-groups-page',
  templateUrl: 'groups.html',
  styleUrls: ['./groups.scss']
})
export class AdminGroupsComponent implements OnInit {

  @ViewChild('modalAddManager') modalAddManager!: ModalComponent;
  @ViewChild('modalAddMember')  modalAddMember!: ModalComponent;
  @ViewChild('modalAddUser')    modalAddUser!: ModalComponent;

  @ViewChild('groupTree') groupTree!: TreeComponent;

  @ViewChild('profileEditor') profileEditor!: UserProfileComponent;

  // template variables
  public vocabs: Taxonomy[] = [];
  public root: Taxonomy[] = [];

  public selected_vid: number|null = null;
  public selected_groups: Taxonomy[] = [];

  public users: { [name: string]: AppUser[] } = {
    managers: [],
    members: [],
    others: []
  };

  public contextMenu: Tree.ContextMenu = {
    create: {
      label: 'Create New Group',
    },
    edit: {
      label: 'Rename Group',
    },
    delete: {
      label: 'Delete Group',
    }
  };

  // -- constructor
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected session: SessionService,
    protected treeUtils: TreeUtilsService,
    protected ui: UIService,
    protected server: ServerService,
    protected userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.server.index('vocabularies').then( (v) => {
      this.vocabs = v;
      this.selected_vid = v[0].id;
    });
  }

  // -- template API
  public selectVocab(vid: number) {
    if ( ! vid ) {
      return;
    }

    this.server.show('terms', vid)
    .then( (terms: Taxonomy[]) => {
      this.root = terms;
      this.selected_vid = vid;
      this.selected_groups = [];
      this.users['managers'] = [];
      this.users['members']  = [];
      this.users['others']   = [];
      //this.groupTree.reload();
    });
  }

  public onSelectedGroupChange(nodes: Taxonomy[]) {
    this.selectGroup(nodes);
  }

  public selectGroup(groups: Taxonomy[]) {
    if ( groups.length == 0 ) {
      return;
    }
    this.users['others'] = [];
    this.lookupName = '';
    this.selected_groups = groups;

    groups[0].id && this.server.show('groups', groups[0].id, { permissions: 'core_manage_group' })
    .then( (users: AppUser[]) => {
      this.users['managers'] = users;
    });

    groups[0].id && this.server.show('groups', groups[0].id, { permissions: 'core_access_group' })
    .then( (users: AppUser[]) => {
      this.users['members'] = users;
    });
  }

  public selected_nid: number|undefined = undefined;
  public treeEvent(data: Tree.TreeEvent) {
    if ( data.name == 'ready' && this.selected_nid ) {
      this.groupTree.select([this.selected_nid]);
      this.selected_nid = undefined;
    };
  }

  public createGroup(data: Tree.CreateEvent) {
    this.selected_nid = data.node.id;
    this.server.request('groups.add', null, {
      name: data.newName,
      description: data.newName,
      new_parent_id: data.node.id
    })
    .then( () => {
      this.selected_vid && this.selectVocab(this.selected_vid);
    });
  }

  public editGroup(data: Tree.EditEvent) {
    this.selected_nid = data.node.id;
    this.server.request('groups.edit', { id: data.node.id }, {
      name: data.newName,
    })
    .then( (res: any) => {
      this.selected_vid && this.selectVocab(this.selected_vid);
    });
  }

  public deleteGroup(data: Tree.DeleteEvent) {
    let parent: Taxonomy = this.treeUtils.find(this.root, (node: Taxonomy): boolean => { return node.id == data.node.parent_id; } )
    this.selected_nid = ( parent && parent.id ) || undefined;
    this.server.request('groups.delete', { id: data.node.id })
    .then( (res: any) => {
      this.selected_vid && this.selectVocab(this.selected_vid);
    });
  }

  public lookupName: string = '';
  protected lookupDelay: number = 0;
  public lookupOthers(name: string, permissions: string) {
    /*
    if ( name.length == 0 ) {
      return;
    }
    */

    if ( this.lookupDelay != 0 ) {
      clearTimeout(this.lookupDelay);
    }

    this.lookupDelay = window.setTimeout( () => {
      this.server.request('groups.others', {
        gid: this.selected_groups.map( g => g.id ).join(','),
        name: name,
        permissions: permissions
      }, null, { perpage: 5 })
      .then( (users: AppUser[]) => {
        this.users['others'] = users;
        this.lookupDelay = 0;
      });
    }, 1000);
  }

  public addManager(user: AppUser) {
    this.ui.confirm('Add {{ name }} as a manager of this group?', { name: user.fullname }, () => {
      this.modalAddManager.hide();
      this.server.request('groups.grant', {gid: this.selected_groups.map( g => g.id).join(',')}, {user_id: user.id, permissions: ['core_manage_group']})
      .then( () => {
        this.selectGroup(this.selected_groups);
      });
    });
  }

  public removeManager(user: AppUser) {
    this.ui.confirm('Remove {{ name }} from manager(s) list of this group?', { name: user.fullname }, () => {
      this.server.request('groups.revoke', {gid: this.selected_groups.map(g => g.id).join(',')}, {user_id: user.id, permissions: ['core_manage_group']})
      .then( () => {
        this.selectGroup(this.selected_groups);
      });
    });
  }

  public addMember(user: AppUser) {
    this.ui.confirm('Add {{ name }} as a member of this group?', { name: user.fullname }, () => {
      this.modalAddMember.hide();
      this.server.request('groups.grant', {gid: this.selected_groups.map(g => g.id).join(',')}, {user_id: user.id, permissions: ['core_access_group']})
      .then( () => {
        this.selectGroup(this.selected_groups);
      });
    });
  }

  public removeMember(user: AppUser) {
    this.ui.confirm('Remove {{ name }} from member(s) list of this group?', { name: user.fullname }, () => {
      this.server.request('groups.revoke', {gid: this.selected_groups.map(g => g.id).join(',')}, {user_id: user.id, permissions: ['core_access_group']})
      .then( () => {
        this.selectGroup(this.selected_groups);
      });
    });
  }

  public currentUser: AppUser|null = null;
  public editUser(user: AppUser|null, permission: string) {
    if ( user !== null ) {
      this.currentUser = user;
    }
    else {
      this.currentUser = this.userService.create({
        primary_domain: Object.assign({
          permissions: [permission]
        }, this.selected_groups[0])
      });
      /* {
        id: null,
        email: null,
        fullname: null,
        password: null,
        password_confirmation: null,
        active: true,
        avatar: null,
        avatar_id: null,
        avatar_pathUrl: null,
        avatar_thumbUrl: null,
        permissions: [],
        groups: [],
        primary_domain: Object.assign({
          permissions: [permission]
        }, this.selected_groups[0]),
        preferences: {},
        profiles: {},
        attr: {}
      };
      */
    }
    this.modalAddUser.show();
  }

  public userIsValid: boolean = false;
  public validateUser(isValid: boolean) {
    this.userIsValid = isValid;
  }

  public saveUser() {
    //console.log('save user info', this.currentUser);
    this.profileEditor.save()
    .then( (user: AppUser) => {
      this.server.request('groups.grant', {
        gid: this.selected_groups.map( g => g.id).join(',')
      }, {
        user_id: user.id,
        permissions: this.currentUser && this.currentUser.primary_domain && [this.currentUser.primary_domain.permissions[0]]
      });
      this.selectGroup(this.selected_groups);
    });
    this.modalAddUser.hide();
  }
}
