<full-page [title]="'Competency Gap'">
  <div page-content>

    <!-- Gaps/Gaps Radar -->
    <panel>
      <!--
      <div panel-control>
        <button (click)="simulate()" class="btn btn-primary btn-labeled" [title]="'Job Simulator'">
          <i class="btn-label pli-chemical"></i> {{ 'Job Simulator' | translate }}
        </button>
      </div>
      -->
      <div panel-heading>
        <h4>{{ 'Staff Competencies' | translate }}</h4>
      </div>
      <div panel-body>
        <div *ngIf="!!staff" class="row">
          <div class="col-xs-12">
            <gaps-analyzer *ngIf="candidate && job_description"
              [job]="job_description"
              [candidates]="[candidate]"
              [target]="'edit'"
              (onPlanChange)="refresh()"
              (onJobChange)="refresh()"
              (onCompetencyClick)="onCompetencySelected($event)"
            >
            </gaps-analyzer>
          </div>
        </div>
        <div id="records-container" class="row">
          <div class="col-xs-12">
            <h4>{{ 'Training Records' | translate }}</h4>
            <extended-slickgrid *ngIf="gridOptions" id="gaps-table"
              class="row-selectable"
              [gridId]="'main-table'"
              [columnDefinitions]="columnDefinitions"
              [gridOptions]="gridOptions"
              (onAngularGridCreated)="onGridReady($event)"
              (onClick)="onSelectRow($event)"
            >
            </extended-slickgrid>
          </div>
        </div>
      </div>
      <div panel-footer>
        <div class="text-right">
          <button type="button" class="btn btn-labeled btn-dark" data-dismiss="modal" (click)="back()"><i class="btn-label fa far fa-chevron-circle-left"></i>{{ 'Back' | translate }}</button>
        </div>
      </div>
    </panel>
  </div>
</full-page>