<div id="opl-{{ content.id }}" class="opl opl-{{ format }} panel">
  <!-- full or feed -->
  <div *ngIf="format == 'full' || format == 'feed'" class="row">

    <!-- medias (left) -->
    <div *ngIf="has_media" class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <div class="blog-header">
        <gallery *ngIf="format == 'full'" class="img-responsive" [items]="content.attachments?.unsorted || []"></gallery>
        <img *ngIf="format == 'feed' && !!feed_thumb" [attr.src]="feed_thumb" (click)="open()" stop-propagation class="img-responsive">
      </div>
    </div>

    <!-- content (right) -->
    <div class="col-xs-12 col-sm-12" [ngClass]="has_media ? 'col-md-7 col-lg-7' : 'col-md-12 col-lg-12'">
      <div class="blog-content">

        <!-- header -->
        <div class="blog-title pad-btm">
          <div class="media-block">
            <div class="media-left">
              <avatar [user]="content.author || null"></avatar>
            </div>
            <div class="media-body">
              <div class='opl-title row'>
                <h2 class="col-xs-12 col-md-9">
                  <a class="btn-link col-xs-10" (click)="open()">
                    <i class="fa fa-hashtag"></i><span class="selectable">{{ content.id }} - {{ content.title }}</span>
                  </a>
                </h2>
                <div class="col-xs-12 col-md-3">
                  <div class="text-sm opl-status text-right">
                    <span *ngIf="content.status == 'draft'"     class="label label-default text-thin">{{ 'Draft' | translate }}</span>
                    <span *ngIf="content.status == 'submitted'" class="label label-warning text-thin">{{ 'Submitted' | translate }}</span>
                    <span *ngIf="content.status == 'approved'"  class="label label-success text-thin" title="Approved at {{ content.approved_at | datetime }} by {{ content.approver?.fullname || 'n/a' }} ({{ content.approver?.email || 'n/a' }})">{{ 'Approved' | translate }}</span>
                    <span *ngIf="content.status == 'rejected'"  class="label label-danger  text-thin">{{ 'Rejected' | translate }}</span>
                    <span *ngIf="is_read" class="label label-success text-thin" ><i class="fa fa-rular fa-eye"></i> {{ 'Read' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-9 text-sm pad-top">
              <div>
                {{ 'by' | translate }} <a [routerLink]="['/profile', content.author?.id]" class="btn-link" title="{{ content.author?.fullname || 'n/a'}} - {{ content.author?.email || 'n/a' }}" stop-propagation (click)="browse({author: content.author?.id})">{{ content.author?.fullname || 'n/a' }}</a>
              </div>
              <div *ngIf="content.status == 'approved'">
                {{ 'approved by' | translate }} <a [routerLink]="['/profile', content.approver?.id]" class="btn-link" title="{{ content.approver?.fullname || 'n/a' }} - {{ content.approver?.email ||'n/a' }}" stop-propagation (click)="browse({approver: content.approver?.id})">{{ content.approver?.fullname || 'n/a' }}</a>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 text-sm pad-top items">
              <span *ngIf="!! content.document" class="pull-right text-xs text-thin text-light label label-dark doc-code">
                {{ docService.getDocCode(content.document) }}
              </span>
              <span *ngIf="content.revision_logs && content.revision_logs.length > 1" class="pull-right text-thin label label-default revision">
                Rev: {{ (content.revision_logs.length - 1) | number }}
              </span>
            </div>
          </div>
        </div>

        <!-- body -->
        <div class="blog-body bord-top">

          <!-- meta data -->
          <div class="meta pad-top pad-btm">
            <div class="text-sm" title="{{ 'Last Updated' | translate }}">
              <i class="icon fa fa-fw fa-clock"></i>{{ content.updated_at | datetime }}
            </div>
            <div class="text-sm" title="{{ 'Approval Domain' | translate }}">
              <i class="icon fa fa-fw fa-flag"></i>
              <a href="#" class="btn-link" stop-propagation (click)="browse({domain: content.domain.id})">{{content.domain && content.domain.name}}</a>
            </div>
            <div class="text-sm" title="{{ 'Categories' | translate }}">
              <i class="icon fa fa-fw fa-solid fa-users"></i>
              <a *ngFor="let g of content.categories" href="#" class="btn-link categories multiple-values" [ngClass]="{ 'effective': session.isEffectiveGroup(g.id||0) }" stop-propagation (click)="browse({category: g.id})">{{g.name}}</a>
            </div>
            <div *ngFor="let p of oplService.properties " class="text-sm" title="{{ p.label }}">
              <span class="label icon"><i class="fa fa-fw fa-solid fa-{{ p.icon || 'gears' }}"></i> {{ p.label }}</span>
              <div class="inline property-value">
                <a *ngFor="let v of oplService.getProperties(p.name, content.properties && content.properties[p.name])" href="#" class="btn-link properties multiple-values" stop-propagation (click)="browseProperty(p, v)"> {{ v || 'n/a' }}</a>
                <span *ngIf="oplService.getProperties(p.name, content.properties && content.properties[p.name]).length == 0" class="null">( {{ 'not selected' | translate }} )</span>
              </div>
            </div>
          </div>

          <!-- content -->
          <p *ngIf="format == 'full'" class="content selectable" [innerHTML]="parsed.content | safe"></p>
          <p *ngIf="format == 'feed'" class="content selectable teaser">
            <span [innerHTML]="parsed.teaser | safe"></span>
            <a class="mar-lft label label-default btn-link" href="#" stop-propagation (click)="open()">{{ "Read More" | translate }}</a>
          </p>

          <!-- downloadble attachments -->
          <div class="downloads mar-top" *ngIf="!!content.attachments && !! content.attachments.document && content.attachments.document.length > 0">
            <download *ngFor="let file of content.attachments.document" [file]="file"></download>
          </div>

          <!-- related course-->
          <div *ngIf="format == 'full' && related_courses.length > 0" class="related-courses mar-top">
            <h6>{{ 'Related Courses' | translate }}:</h6>
            <ul>
              <li *ngFor="let course of related_courses">
                <a [routerLink]="['/training/course', course.id ]" class="btn-link">{{ course.code + ' - ' + course.name }}</a>
              </li>
            </ul>
          </div>

          <!-- reader comment -->
          <div *ngIf="format == 'full' && content.status == 'approved' && must_read && oplService.config.read_prove_mode == 'timer'">
            <div *ngIf="!done_reading" class="board-top new-comment">
              <div id="reading-timer" class="full-width text-muted text-center"><i class="pli-clock icon-fw icon-lg"></i>{{ remaining_read_time | interval:'mm:ss' }}</div>
            </div>
            <div *ngIf="done_reading" class="board-top new-comment">
              <div class="panel panel-trans">
                <div class="pad-all">
                  <div class="media mar-btm">
                    <div class="user-info">
                      <avatar [user]="session.currentUser" class="media-left"></avatar>
                      <div class="media-body">
                        <p class="text-lg text-main text-semibold mar-no"> {{ session.currentUser?.fullname || 'n/a' }} </p>
                        <p>{{ session.currentUser && session.currentUser.profiles && session.currentUser.profiles['job_title'] || 'n/a' }}</p>
                      </div>
                    </div>
                  </div>
                  <textarea class="bq form-control"
                    placeholder='{{ "Write some comment.." | translate }}'
                    autosize
                    name="comment"
                    style="resize: vertical;"
                    minlength="50"
                    [rows]="2"
                    [minHeight]="67"
                    [(ngModel)]="readerComment"
                  >
                  </textarea>
                  <div class="text-right pad-top">
                    <button class="btn btn-default btn-labeled" (click)="addComment()"><i class="btn-label fa fa-comments"></i> {{ 'Add Comment' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- quiz -->
          <div *ngIf="format =='full' && content.status == 'approved' && must_read && oplService.config.read_prove_mode == 'quiz'">
            <div *ngIf="! hide_quiz" id="quiz" class="pad-all bg-gray">
              <label class="coltron-label text-bold">{{ 'Answer following questions.' | translate }}:</label>
              <panel *ngFor="let q of questions; let i = index">
                <div panel-body>
                  <pre style="display: none;">{{ q | json }}</pre>
                  <label class="control-label question"><span class="text-bold">Q</span>: {{ q.content }}</label>
                  <div class="choices">
                    <div>
                      <label *ngFor="let choice of q.choices" class="radio">
                        <input type="radio" name="answer-{{i}}" value="{{ choice }}" (click)="answer(q, choice)">
                        {{ choice }}
                      </label>
                    </div>
                  </div>
                </div>
              </panel>
            </div>
            <div *ngIf="hide_quiz" id="sorry" class="text-semi-bold media-block">
              <div class="media-left bg-danger pad-all">
                <i class="icon-3x pli-thumbs-down-smiley"></i>
              </div>
              <div class="media-body pad-all">
                {{ 'You must answer all questions correctly, please read the OPL carefully. The questions will appear again after 30 seconds.' | translate }}
              </div>
            </div>
          </div>

          <!-- quiz (for approve) -->
          <div *ngIf="content.status == 'submitted' && is_owner && content.questions && content.questions.length > 0">
            <label class="coltron-label text-bold">{{ 'Quiz' | translate }}:</label>
            <div id="quiz" class="pad-all bg-gray">
              <panel *ngFor="let q of content.questions">
                <div panel-body>
                  <pre style="display: none;">{{ q | json }}</pre>
                  <label class="control-label question"><span class="text-bold">Q</span>: {{ q.content }}</label>
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 pad-rgt choices correct-answers">
                      <div>
                        <label class="control-label text-bold">{{ 'Correct Choice(s)' | translate }}</label>
                        <label *ngFor="let choice of correct_answers(q)" class="radio">
                          <input type="radio" name="answer" value="{{ choice }}">
                          {{ choice }}
                        </label>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 pad-left choices incorrect-answers">
                      <div>
                        <label class="control-label text-bold">{{ 'Incorrect Choice(s)' | translate }}</label>
                        <label *ngFor="let choice of incorrect_answers(q)" class="radio">
                          <input type="radio" name="answer" value="{{ choice }}">
                          {{ choice }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </panel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="col-xs-12 blog-footer">
      <div class="row">
        <div class="col-xs-12 col-sm-6 footer-items pad-btm">
          <a class="btn-link" data-bs-toggle="collapse" title="{{ 'Revision Log' | translate }}" [attr.data-bs-target]="'#logs-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseLog">
            <i class="pli-pencil icon-fw"></i>{{ ( content.revision_logs && content.revision_logs.length ) || 'N/A' }}
          </a>
          <a class="btn-link" data-bs-toggle="collapse" title="{{ 'Readers Comments' | translate }}" [attr.data-bs-target]="'#comments-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseComment">
            <i class="pli-speech-bubble-5 icon-fw"></i>{{ content.comments && content.comments.length }}
          </a>
          <a *ngIf="content.status == 'approved'" title="{{ 'Target readers, who has already read this OPL' | translate }}" class="btn-link" data-bs-toggle="collapse" [attr.data-bs-target]="'#audiences-read-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseAudiences">
            <i class="fa fa-eye icon-fw"></i>{{ content.audiences && content.audiences.read.length }}
          </a>
          <a *ngIf="content.status == 'approved'" title="{{ 'Target reader, who has never read this OPL' | translate }}" class="btn-link" data-bs-toggle="collapse" [attr.data-bs-target]="'#audiences-unread-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseAudiences">
            <i class="fa fa-eye-slash icon-fw"></i>{{ content.audiences && content.audiences.unread.length }}
          </a>
          <a *ngIf="content.status == 'approved'" title="{{ 'Print this OPL' | translate }}" class="btn-link" href="#" role="button" stop-propagation (click)="print.print()">
            <i class="fa fa-solid fa-print icon-fw"></i>
          </a>
        </div>

        <div class="col-xs-12 col-sm-6 text-right footer-items">
          <button *ngIf="can_flag" stop-propagation (click)="flag()" title="{{ 'Flag this OPL for my team' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-thumbs-up"></i> {{ 'Flag' | translate }}
          </button>
          <button *ngIf="can_unflag" stop-propagation (click)="unflag()" title="{{ 'Un-flag this OPL' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-thumbs-down"></i>{{ 'Un-Flag' | translate }}
          </button>
          <button *ngIf="can_approve" stop-propagation (click)="approve()" title="{{ 'Approve' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-thumbs-up"></i> {{ 'Approve' | translate }}
          </button>
          <button *ngIf="can_approve" stop-propagation (click)="reject()" title="{{ 'Reject' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-thumbs-down"></i> {{ 'Reject' | translate }}
          </button>
          <button *ngIf="can_submit" stop-propagation (click)="submit()" title="{{ 'Submit' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-check-square"></i> {{ 'Submit' | translate }}
          </button>
          <button *ngIf="can_quick_edit" stop-propagation (click)="edit(true)" title="{{ 'Quick Edit' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-solid fa-bolt"></i> {{ 'Quick Edit' | translate }}
          </button>
          <button *ngIf="can_edit" stop-propagation (click)="edit(false)" title="{{ 'Edit' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-solid fa-pencil"></i> {{ 'Edit' | translate }}
          </button>
          <button *ngIf="can_delete" stop-propagation (click)="trash()" title="{{ 'Delete' | translate }}" class="btn btn-default btn-labeled">
            <i class="btn-label icon-lg icon-fw fa fa-trash-alt"></i> {{ 'Delete' | translate }}
          </button>
          <!-- @TODO - restore -->
        </div>
      </div>

      <div id="footer-{{ content.id }}" class="accordian">

        <!-- revision logs -->
        <div class="logs collapse mar-top" id="logs-{{ format }}-{{ content.id }}" [attr.data-parent]="'#footer-' + content.id">
          <div class="row">
            <div *ngIf="content.revision_logs && content.revision_logs.length == 0" class="text-center text-muted full-width">
              {{ 'No OPL revision log entry at the moment.' | translate }}
            </div>
            <comments *ngIf="content.id && !!content.revision_logs"
              class="col-xs-12"
              [content]="content.revision_logs || []"
              [content_id]="content.id"
              [content_type]="'opl'"
              [buttons]="comment_buttons"
              [readonly]="true"
            >
            </comments>
            <!--
            <div *ngFor="let log of content.revision_logs" class="col-xs-12">
              <div class="pad-all">
                <div class="media mar-btm">
                  <div class="user-info">
                    <avatar [user]="log.author" class="media-left"></avatar>
                    <div class="media-body">
                      <p class="text-lg text-main text-semibold mar-no"> {{ log.author.fullname }} </p>
                      <span><i class="pli-mail icon-fw"></i>{{ log.author.email || 'n/a' }}</span>
                      <span><i class="pli-business-man icon-fw"></i> {{ log.author.profiles['job_title'] || 'n/a' }}</span>
                      <span><i class="fa fa-clock icon-fw"></i> {{ log.updated_at | datetime }}</span>
                    </div>
                  </div>
                </div>
                <blockquote class="bq-sm bq-open bq-close">{{ log.log }}</blockquote>
              </div>
            </div>
            -->
          </div>
        </div>

        <!-- list of target audiences who has already read this OPL -->
        <div class="audiences collapse mar-top" id="audiences-read-{{ format }}-{{ content.id }}" [attr.data-parent]="'#footer-' + content.id">
          <div class="row" data-masonry>
            <ng-container *ngIf="content.audiences">
            <div *ngIf="content.audiences && content.audiences.read.length == 0" class="text-center text-muted col">
              {{ 'No any target audience read this OPL, at the moment.' | translate }}
            </div>
            <div *ngFor="let audience of content.audiences.read" class="col">
              <user-card [user]="audience" [buttons]="audience['buttons']">
                <div user-profile>
                  <p class="text-sm" title="{{ 'Job Title' | translate }}"><i class="icon-fw icon-lg pli-business-man"></i> {{ audience.profiles && audience.profiles['job_title'] || 'n/a' }}</p>
                  <p class="text-sm" title="{{ 'Last Login' | translate }}"><i class="pli-wifi-2 icon-lg icon-fw"></i> {{ audience.last_login ? ( audience.last_login | datetime ) : ( 'Never' | translate ) }}</p>
                  <p class="text-sm" title="{{ 'Read on' | translate }}"><i class="fa fa-rular fa-eye icon-fw"></i> {{ audience['read_on'] | datetime }}</p>
                  <p *ngIf="oplService.config.read_prove_mode != 'quiz'" class="text-sm" title="{{ 'Quiz Failed' | translate }}"><i class="fa fa-thumbs-down icon-fw"></i> {{ ( audience['fails'] || 0 )  | number }}</p>
                </div>
                <div *ngIf="audience['comment']" user-details>
                  <blockquote class="bq-sm bq-open bq-close selectable" [innerHTML]="audience['comment'] | safe"></blockquote>
                </div>
              </user-card>
            </div>
            </ng-container>
          </div>
        </div>

        <!-- List of target audiences who has never read this OPL -->
        <div class="audiences collapse mar-top" id="audiences-unread-{{ format }}-{{ content.id }}" [attr.data-parent]="'#footer-' + content.id">
          <div class="row">
            <ng-container *ngIf="content.audiences">
            <div *ngIf="content.audiences.unread.length == 0" class="text-center text-muted full-width">
              {{ 'All target audiences have already read this OPL.' | translate }}
            </div>
            <div *ngFor="let audience of content.audiences.unread" class="col">
              <user-card [user]="audience" [buttons]="audience['buttons']">
                <div user-profile>
                  <p class="text-sm" title="{{ 'Job Title' | translate }}"><i class="icon-fw icon-lg pli-business-man"></i> {{ ( audience.profiles && audience.profiles['job_title'] ) || 'n/a' }}</p>
                  <p class="text-sm" title="{{ 'Last Login' | translate }}"><i class="pli-wifi-2 icon-lg icon-fw"></i> {{ audience.last_login ? ( audience.last_login | datetime ) : ( 'Never' | translate ) }}</p>
                </div>
              </user-card>
            </div>
            </ng-container>
          </div>
        </div>

        <!-- comments -->
        <div class="comments collapse mar-top" id="comments-{{ format }}-{{ content.id }}" [attr.data-parent]="'#footer-' + content.id">
          <comments [content]="content.comments || []" [content_id]="content.id || null" [content_type]="'opl'" [buttons]="comment_buttons" [readonly]="false"></comments>

          <!--
          <div class="comments">
            <comment *ngFor="let comment of content.comments" [content]="comment" [format]="format"></comment>
          </div>
          <div *ngIf="!!content.id" class="board-top new-comment">
            <div class="panel panel-trans">
              <div class="pad-all">
                <div class="media mar-btm">
                  <div class="user-info">
                    <avatar [user]="user.account" class="media-left"></avatar>
                    <div class="media-body">
                      <p class="text-lg text-main text-semibold mar-no"> {{ user.account.fullname }} </p>
                      <p>{{ user.account.profiles['job_title'] }}</p>
                    </div>
                  </div>
                </div>
                <textarea rows="2" class="bq form-control" placeholder='{{ "Write some comment.." | translate }}' autosize minHeight="67" name="comment" style="resize: vertical;" [(ngModel)]="newComment"></textarea>
                <div class="text-right pad-top">
                  <button class="btn btn-default btn-labeled" (click)="comment()"><i class="btn-label fa fa-comments"></i> {{ 'Add Comment' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          -->

        </div>
      </div>

    </div>
  </div>

</div>

<!-- print area -->
<print-area #print [preview-only]="false">
  <div class="blog opl">
    <!-- header -->
    <div class="blog-header pad-btm no-break">
      <div class="text-sm pull-right">
        <span *ngIf="content.status == 'draft'"     class="label label-default">{{ 'Draft' | translate }}</span>
        <span *ngIf="content.status == 'submitted'" class="label label-warning">{{ 'Submitted' | translate }}</span>
        <span *ngIf="content.status == 'approved'"  class="label label-success" title="Approved at {{ content.approved_at | datetime }} by {{ content.approver?.fullname || 'n/a' }} ({{ content.approver?.email || 'n/a' }})">{{ 'Approved' | translate }}</span>
        <span *ngIf="content.status == 'rejected'"  class="label label-danger">{{ 'Rejected' | translate }}</span>
      </div>
      <div class="media">
        <avatar [user]="content.author||null" class="media-left"></avatar>
        <div class="media-body">
          <h2 class="pad-left">#{{ content.id }} - {{ content.title }}</h2>
          <p class="text-sm">by {{ content.author?.fullname || 'n/a' }} ({{ content.author?.email || 'n/a' }})</p>
        </div>
      </div>
      <!--
      <div class="d-flex">
        <avatar [user]="content.author||null" class="flex-shrink-0"></avatar>
        <div class="flex-grow-1 ms-3">
          <h2 class="pad-left">#{{ content.id }} - {{ content.title }}</h2>
          <p class="text-sm">by {{ content.author?.fullname || 'n/a' }} ({{ content.author?.email || 'n/a' }})</p>
        </div>
      </div>
      -->
    </div>

    <!-- body -->
    <div class="blog-body bord-top no-break">
      <div class="meta pad-top pad-btm">
        <div class="text-sm" title="{{ 'Last Updated' | translate }}">
          <i class="icon fa fa-fw fa-clock"></i>{{ content.updated_at | datetime }}
        </div>
        <div class="text-sm" title="{{ 'Approval Domain' | translate }}">
          <i class="icon fa fa-fw fa-flag"></i>
          {{content.domain && content.domain.name}}
        </div>
        <div class="text-sm" title="{{ 'Categories' | translate }}">
          <i class="icon fa fa-fw fa-solid fa-users"></i>
          <span *ngFor="let g of content.categories" class="categories multiple-values" [ngClass]="{ 'effective': session.isEffectiveGroup(g.id||0) }">{{g.name}}</span>
        </div>
        <div *ngFor="let p of oplService.properties " class="text-sm" title="{{ p.label }}">
          <i class="icon fa fa-fw fa-solid fa-{{ p.icon ? p.icon : 'gears' }}"></i>
          <span *ngFor="let v of oplService.getProperties(p.name, content.properties && content.properties[p.name])" class="properties multiple-values"> {{ v || 'n/a' }}</span>
        </div>
      </div>
      <p class="content" [innerHTML]="parsed.content | safe"></p>
      <div class="downloads mar-top" *ngIf="content.attachments && content.attachments.document && content.attachments.document.length > 0">
        <download *ngFor="let file of content.attachments.document" [file]="file"></download>
      </div>
    </div>

    <!-- footer -->
    <div class="blog-footer no-break">
      <div class="media-left footer-items">
        <span *ngIf="is_read" class="mar-rgt label label-success" >
          <i class="icon fa fa-fw fa-eye"></i>{{ "Read" | translate }}
        </span>
      </div>
      <div class="media-body text-right footer-items">
        <span>
          <i class="pli-speech-bubble-5 icon-fw"></i>{{ content.comments && content.comments.length || 0 }}
        </span>
        <span *ngIf="content.status == 'approved'">
          <i class="icon fa fa-fw fa-eye icon-fw"></i>{{ content.audiences && content.audiences.read.length || 0}}
        </span>
        <span *ngIf="content.status == 'approved'">
          <i class="icon fa fa-fw fa-eye-slash icon-fw"></i>{{ content.audiences && content.audiences.unread.length || 0 }}
        </span>
        <span *ngIf="content.status == 'approved'">
          <i class="icon fa fa-fw fa-solid fa-print icon-fw"></i>
        </span>
      </div>
    </div>

    <!-- images -->
    <div *ngIf="has_media" class="blog-images">
      <gallery class="img-responsive" [items]="content.attachments?.unsorted || []" [layout]="'plain'"></gallery>
    </div>

    <!-- comments -->
    <!--
    <div *ngIf="is_owner"  class="blog-comments break-before">
      <comment *ngFor="let comment of content.comments" [content]="comment" [format]="format"></comment>
    </div>
    -->
  </div>
</print-area>

<modal template-id="rev-log-input" [size]="'small'" [hideCloseButton]="true" [fade]="true" #revlog>
  <div modal-title>
    {{ 'Revision Log Message' | translate }}
  </div>

  <div modal-body>
    <textarea
      class="form-control"
      placeholder='{{ "Write revision log here.." | translate }}'
      autosize
      name="log-msg"
      style="resize: none;"
      [rows]="2"
      [minHeight]="40"
      [(ngModel)]="log_msg"
    >
    </textarea>
  </div>

  <div modal-footer>
    <button stop-propagation (click)="doSubmit()" title="{{ 'Submit' | translate }}" class="btn btn-default btn-labeled">
      <i class="btn-label icon-lg icon-fw fa fa-check-square"></i> {{ 'Submit' | translate }}
    </button>
  </div>
</modal>