/**
 * admin endpoints
 */

import { ServiceEndpointsMapping } from "@pinacono/common";

export const ENDPOINTS: ServiceEndpointsMapping = {
  doclib: {
    categories: '/docs/categories',                  // list cactegories structure
    code: {
      lock:    { method: 'POST', url: '/spw/docs/lock/code' },         // lock document code and get next document id
      release: { method: 'POST', url: '/spw/docs/release/code/{id}' }, // release document code
    },
    template: {
      lock: { method: 'POST', url: '/spw/docs/lock/template' }, // lock template code of a memo
    },
    validate: { method: 'POST', url: '/spw/docs/validate' },    // validate the doc data

    unique: {
      title: { method: 'POST', url: 'docs/unique/title' },      // validate doc title uniqueness
    },

    get: {
      by_code: '/docs/code/{code}', // get document id by code - used to be doclib.code
      by_access_code: '/docs/access/{access_code}', // used to be doclib.access
    },

    file: {
      attach:   { method: 'POST', url: '/docs/file/attach/{id}' },
      detach:   '/docs/file/detach/{id}',
      download: { method: 'DOWNLOAD', url: '{url}', raw: true }
    },

    revision: {
      activate:   '/revision/activate/{id}',
      deactivate: '/revision/deactivate/{id}',
      //upload:     {method: 'UPLOAD', url: '/revisions/{rev_id}/upload' },
      upload:     {method: 'POST', url: '/revisions/attachments/create' },
      detach:     '/revisions/{rev_id}/detach/{file_id}',
    },

    hardcopy: {
      unique:   '/docs/hardcopy/unique/{value}',
      generate: '/docs/hardcopy/generate/{rev_id}/{count}',
    },

    submit:  '/docs/{id}/action/submit',
    approve: '/docs/{id}/action/approve',
    reject:  '/docs/{id}/action/reject',
    review:  '/docs/{id}/action/review',
    publish: '/docs/{id}/action/publish',

    access: {
      request: { method: 'POST', url: '/docs/access/request/{doc_id}' },
      info:    '/docs/access/{request_id}/info',
      grant:   '/docs/access/{request_id}/grant',
      deny:    '/docs/access/{request_id}/deny',
    },

    reports: {
      all: { method: 'POST', url: '/reports/doc.all' }
    }
  }
};
