<full-page [page-title]="'Home'">
  <div page-content class="row">
    <div class="col-lg-12">

      <div class="row">
        <!---------------------------------->
        <!-- CMMS Redirection Chart -->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" (click)="fileUtils.saveXLSX('maximo_redirection', charts['redirect_hourly'].config.data)" [tooltip]="'Export to Excel'">
                <i class="pli-file-excel"></i>
              </button>
            </div>
            <div panel-heading>
              CMMS Redirections in 24 Hours by Hour
            </div>
            <div panel-body>
              <div id="cmms-redirect-by-hour" [chart]="charts['redirect_hourly'].type" [chart-config]="charts['redirect_hourly'].config" [chart-data]="charts['redirect_hourly'].config.data" style="height: 210px"></div>
            </div>
          </panel>
        </div>
        <!-- end of CMMS Redirection Chart -->
        <!---------------------------------->

        <!---------------------------------->
        <!-- CMMS Redirection Chart -->
        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" (click)="fileUtils.saveXLSX('team_redirect', charts['team_redirect'].config.data)" [tooltip]="'Export to Excel'">
                <i class="pli-file-excel"></i>
              </button></div>
            <div panel-heading>CMMS Redirections in 24 Hours by Team</div>
            <div panel-body>
              <div id="cmms-redirect-by-team" [chart]="charts['team_redirect'].type" [chart-config]="charts['team_redirect'].config" [chart-data]="charts['team_redirect'].config.data" style="height: 210px"></div>
            </div>
          </panel>
        </div>
        <!-- end of CMMS Redirection Chart -->
        <!---------------------------------->
      </div>

      <!-- OPL report -->
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" [tooltip]="'Export to Excel'" (click)="fileUtils.saveXLSX('opl-by-time', charts['opl_by_time'].config.data)">
                <i class="pli-file-excel"></i>
              </button>
            </div>
            <div panel-heading>OPL by Time</div>
            <div panel-body>
              <div id="opl-by-time" [chart]="charts['opl_by_time'].type" [chart-config]="charts['opl_by_time'].config" [chart-data]="charts['opl_by_time'].config.data" style="height: 210px"></div>
              <!--
              <div style="position: relative;">
                <maximizable class="full-height">
                  <d3chart #opl_by_time [type]="charts.opl_by_time.type" [data]="charts.opl_by_time.data" [options]="charts.opl_by_time.config" class="fill-height"></d3chart>
                  <table *ngIf="charts.opl_by_time.data.length > 0" class="table table-condensed table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th *ngFor="let m of charts.opl_by_time.data[0].values">{{ m.x }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let d of charts.opl_by_time.data">
                        <td>{{ d['key'] }}</td>
                        <td *ngFor="let v of d['values']">{{ v['y'] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </maximizable>
              </div>
              -->
            </div>
          </panel>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-control>
              <button class="btn btn-icon" [tooltip]="'Export to Excel'" (click)="fileUtils.saveXLSX('opl-by-team', charts['opl_by_team'].config.data)">
                <i class="pli-file-excel"></i>
              </button>
            </div>
            <div panel-heading>OPL by Team</div>
            <div panel-body>
              <div id="opl-by-team" [chart]="charts['opl_by_team'].type" [chart-config]="charts['opl_by_team'].config" [chart-data]="charts['opl_by_team'].config.data" (chart-click)="chartDrillDown('opl_by_team', $event)" style="height: 210px"></div>
              <!--
              <div style="position: relative;">
                <maximizable class="full-height">
                  <d3chart #opl_by_time [type]="charts.opl_by_team.type" [data]="charts.opl_by_team.data" [options]="charts.opl_by_team.config" class="fill-height"></d3chart>
                  <table *ngIf="charts.opl_by_team.data.length > 0" class="table table-condensed table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th *ngFor="let m of charts.opl_by_team.data[0].values">{{ m.x }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let d of charts.opl_by_team.data">
                        <td>{{ d['key'] }}</td>
                        <td *ngFor="let v of d['values']">{{ v['y'] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </maximizable>
              </div>
              -->
            </div>
          </panel>
        </div>
      </div>
      <!-- OPL report -->

      <!---------------------------------->
      <!-- KPI Charts -->
      <!--
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-heading>Area Chart</div>
            <div panel-body>
              <div id="demo-morris-area-legend" class="text-center"></div>
              <div id="demo-morris-area" [chart]="charts.area.type" [chart-config]="charts.area.config" [chart-data]="charts.area.data" (chart-render)="onAreaChartRender($event)" style="height:210px"></div>
            </div>
          </panel>
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-heading>Line Chart</div>
            <div panel-body>
              <div id="demo-morris-line-legend" class="text-center"></div>
              <div id="demo-morris-line" [chart]="charts.line.type" [chart-config]="charts.line.config" [chart-data]="charts.line.data" style="height:230px"></div>
            </div>
          </panel>
        </div>
      </div>
      -->

      <!--
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-heading>Bar Chart</div>
            <div panel-body>
              <div id="demo-morris-bar" [chart]="charts.bar.type" [chart-config]="charts.bar.config" [chart-data]="charts.bar.data" style="height: 210px"></div>
            </div>
          </panel>
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12">
          <panel>
            <div panel-heading>Donut Chart</div>
            <div panel-body>
              <div id="demo-morris-donut" class="morris-donut" [chart]="charts.donut.type" [chart-config]="charts.donut.config" [chart-data]="charts.donut.data" style="height: 210px"></div>
            </div>
          </panel>
        </div>
      </div>
      -->

      <!---------------------------------->
      <!-- Events -->
      <!--
      <div class="row">
        <div class="col-md-12">
          <panel>
            <div panel-heading>Upcoming Events</div>
            <div panel-body>
              <div class="fluid">
                <full-calendar id='events-calendar' [options]="calendarOptions" #calendar></full-calendar>
              </div>
            </div>
          </panel>
        </div>

      </div>
      -->
      <!-- end of Events -->
      <!---------------------------------->

      <!-- end of KPI Charts -->
      <!---------------------------------->

      <!---------------------------------->
      <!-- forum topics -->
      <!--

      <hr class="new-section-sm bord-no">

      <div class="row">
        <panel class="col-md-12 col-sm-12 col-xs-12">
          <div panel-heading>News from Forum!</div>
          <div panel-body>
            <div *ngIf="forums.length == 0" class="text-center">{{ 'No forum topic available right now!' | translate }}</div>
            <ul *ngIf="forums.length > 0" class="sortable-list tasklist list-unstyled">
              <li *ngFor="let topic of forums" [ngClass]="{'task-warning': false, 'task-info': false, 'task-danger': false}">
                <p class="text-bold text-main text-sm">#{{topic.id}}</p>
                <p class="pad-btm bord-btm">{{ topic.title }}</p>
                <a href="#" class="task-footer">
                  <span class="box-inline">
                    <img class="img-xs img-circle" src="topic.user.avatar_thumbUrl || 'assets/nifty/profile-photos/1.png'" alt="task-user">
                    topic.user.fullname
                  </span>
                  <span class="text-sm"><i class="demo-pli-clock icon-fw text-main"></i>{{ topic.updated_at | date:'' }}</span>
                </a>
              </li>
            </ul>
          </div>
        </panel>
      </div>
      -->
      <!-- end of forums topics -->
      <!---------------------------------->

      <!---------------------------------->
      <!-- Personal Section -->
      <!--
      <hr class="new-section-sm bord-no">
      <div class="row">
        <div class="col-xs-12">
          <panel>
            <div panel-heading>OPL</div>
            <div panel-body>
              <div class="row">
                <table id="demo-foo-accordion" class="table toggle-arrow-tiny">
                  <thead>
                    <tr>
                      <th data-toggle="true">Title</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let opl of opls">
                      <td><a href="#" stop-propagation class="btn btn-link" (click)="nav.setRoot('opls', { id: opl.id })">{{ opl.title }}</a></td>
                      <td>{{ opl.updated_at | datetime }}</td>
                      <td><span class="label label-table label-success">Passed</span></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">
                        <div *ngIf="opls.length > 0" class="text-center">
                          <pagination [pageno]="opls_pagination.pageno" [perpage]="opls_pagination.perpage" [total]="opls_pagination.total" (pagenoChange)="loadOPLPage($event)"></pagination>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-sm-12 col-xs-12">
                  <div class="panel panel-warning panel-colorful media middle pad-all">
                    <div class="media-left">
                      <div class="pad-hor">
                        <i class="pli-teacher icon-3x"></i>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="text-2x mar-no text-semibold">{{ opls_read }}/{{ opls_total }}</p>
                      <p class="mar-no">{{ 'OPL Read' | translate }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <div class="panel panel-info panel-colorful media middle pad-all">
                    <div class="media-left">
                      <div class="pad-hor">
                        <i class="pli-pencil icon-3x"></i>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="text-2x mar-no text-semibold">{{ opls_written }}</p>
                      <p class="mar-no">{{ 'OPL Written' | translate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </panel>
        </div>
        -->

        <!-- managers list -->
        <!--
        <div class="col-md-4 col-md-12 col-xs-12">
          <panel>
            <div panel-heading>{{ 'Your Managers' | translate }}</div>
            <div panel-body class="pad-all">
              <div class="media mar-btm">
                <div class="media-left">
                  <img src="assets/nifty/profile-photos/9.png" class="img-md img-circle" alt="Avatar">
                </div>
                <div class="media-body">
                  <p class="text-lg text-main text-semibold mar-no">Daenerys Targaryen</p>
                  <p>Mother of the Dragons</p>
                </div>
              </div>
              <div class="bord-top">
                <ul class="list-group bg-trans bord-no">
                  <li class="list-group-item list-item-sm">
                    <div class="media-left">
                      <i class="pli-email icon-lg"></i>
                    </div>
                    <div class="media-body">
                      <a href="#" class="btn-link text-sm text-thin">someone@somewhere.something</a>
                    </div>
                  </li>
                  <li class="list-group-item list-item-sm">
                    <div class="media-left">
                      <i class="pli-phone icon-lg"></i>
                    </div>
                    <div class="media-body">
                      <a href="#" class="btn-link text-sm text-thin">02-xxx-xxxx ext. 12345</a>
                    </div>
                  </li>
                  <li class="list-group-item list-item-sm">
                    <div class="media-left">
                      <i class="pli-smartphone-4 icon-lg"></i>
                    </div>
                    <div class="media-body">
                      <a href="#" class="btn-link text-sm text-thin">081-123-4567</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div panel-body class="pad-all">
              <div class="media mar-btm">
                <div class="media-left">
                  <img src="assets/nifty/profile-photos/3.png" class="img-md img-circle" alt="Avatar">
                </div>
                <div class="media-body">
                  <p class="text-lg text-main text-semibold mar-no">Jon Snow</p>
                  <p>King of the North</p>
                </div>
              </div>
              <div class="bord-top">
                <ul class="list-group bg-trans bord-no">
                  <li class="list-group-item list-item-sm">
                    <div class="media-left">
                      <i class="pli-email icon-lg"></i>
                    </div>
                    <div class="media-body">
                      <a href="#" class="btn-link text-sm text-thin">someone@somewhere.something</a>
                    </div>
                  </li>
                  <li class="list-group-item list-item-sm">
                    <div class="media-left">
                      <i class="pli-phone icon-lg"></i>
                    </div>
                    <div class="media-body">
                      <a href="#" class="btn-link text-sm text-thin">02-xxx-xxxx ext. 12345</a>
                    </div>
                  </li>
                  <li class="list-group-item list-item-sm">
                    <div class="media-left">
                      <i class="pli-smartphone-4 icon-lg"></i>
                    </div>
                    <div class="media-body">
                      <a href="#" class="btn-link text-sm text-thin">081-123-4567</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </panel>
        </div>
        -->
        <!-- end of managers list -->
      <!--
      </div>
      -->

      <!-- training plan -->
      <!--
      <div class="row">
        <div class="col-md-12">
          <panel>
            <div panel-heading>Training Plans</div>
            <div panel-body>
              <table id="demo-foo-accordion" class="table toggle-arrow-tiny">
                <thead>
                  <tr>
                    <th data-toggle="true">Competency</th>
                    <th>Dead-line</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>พ่อค้าโชว์รูมโนติส</td>
                    <td>22 Jun 2018</td>
                    <td><span class="label label-table label-success">Passed</span></td>
                  </tr>
                  <tr>
                    <td>ผ้าห่มไลน์ปัจฉิมนิเทศ</td>
                    <td>3 Oct 2018</td>
                    <td><span class="label label-table label-success">Passed</span></td>
                  </tr>
                  <tr>
                    <td>Business Services Sales</td>
                    <td>19 Apr 2018</td>
                    <td><span class="label label-table label-danger">Failed</span></td>
                  </tr>
                  <tr>
                    <td>เซอร์วิสแก๊สโซฮอล์อุปการคุณ</td>
                    <td>13 Jan 2018</td>
                    <td><span class="label label-table label-success">Passed</span></td>
                  </tr>
                  <tr>
                    <td>ตัวตนตะหงิดภควัมบดี</td>
                    <td>N/A</td>
                    <td><span class="label label-table label-dark">None</span></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">
                      <div class="text-right">
                        <pagination [pageno]="pageno" [perpage]="perpage" [total]="total" (pagenoChange)="loadPage($event)"></pagination>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </panel>
        </div>
      </div>
      -->
      <!-- end of training plan -->

      <!-- end of Personal Section -->

    </div>
  </div>
</full-page>