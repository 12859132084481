import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularSlickgridModule } from 'angular-slickgrid';

import {
  PinaconoCommonModule,
  ServerService,
  SessionService
} from '@pinacono/common';

import { PinaconoD3Module } from '@pinacono/d3';
import { PinaconoUIModule } from '@pinacono/ui';
import { SlickgridExtensionModule } from '@pinacono/slickgrid-extension';
import { DiagramModule } from '@pinacono/diagram';

import { ThemesModule } from 'src/app/themes/module';
import { AppCommonModule } from 'src/app/common/module';

// constants and interfaces
import { AppUser } from 'src/app/types';

// services
import { ENDPOINTS } from './endpoints';
import { TrainingService } from './training.service';

// components
import { CompetencyComponent } from './components/competency/competency.component';
import { CourseComponent } from './components/course/course.component';
import { ExamComponent } from './components/exam/exam.component';
import { GapsAnalyzerComponent } from './components/gap-analyzer/gaps-analyzer.component';
import { JobDescriptionComponent } from './components/job-description/job-description.component';

// pipes and directives
import { GapsByCategoryPipe } from './gap/gaps-by-category.pipe';

// pages
import { TrainingDashboardPage } from './dashboard/dashboard';

import { TrainingBatchesListPage } from './batch/list';
import { TrainingBatchViewPage } from './batch/view';

import { TrainingCompetenciesListPage } from './competency/list';
import { TrainingCompetencyViewPage } from './competency/view';

import { TrainingCoursesListPage } from './course/list';
import { TrainingCourseViewPage } from './course/view';
import { TrainingCourseEditPage } from './course/edit';

import { TrainingGapsViewPage } from './gap/view';
import { TrainingGapsSimulatorPage } from './gap/simulator';
import { TrainingGapsComparatorPage } from './gap/comparator';

import { TrainingJobDescriptionViewPage } from './job/view';

import { TrainingRecordsListPage } from './record/list';
import { TrainingRecordViewPage } from './record/view';

import { TrainingReportsPage } from './report/reports';
import { TrainingRequestsListPage } from './request/list';
import { TrainingTeamPage } from './team/team';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    FormsModule,

    TranslateModule.forChild(),
    AngularSlickgridModule,
    NgxSelectModule,
    NgbModule,

    PinaconoCommonModule,
    PinaconoD3Module,
    PinaconoUIModule,
    SlickgridExtensionModule,
    DiagramModule,

    ThemesModule,
    AppCommonModule
  ],
  declarations: [
    // components
    CompetencyComponent,
    CourseComponent,
    ExamComponent,
    GapsAnalyzerComponent,
    JobDescriptionComponent,

    // pipes and directives
    GapsByCategoryPipe,

    // pages
    TrainingDashboardPage,

    TrainingBatchesListPage,
    TrainingBatchViewPage,

    TrainingCompetenciesListPage,
    TrainingCompetencyViewPage,

    TrainingCoursesListPage,
    TrainingCourseViewPage,
    TrainingCourseEditPage,

    TrainingGapsViewPage,
    TrainingGapsSimulatorPage,
    TrainingGapsComparatorPage,

    TrainingJobDescriptionViewPage,

    TrainingRecordsListPage,
    TrainingRecordViewPage,

    TrainingReportsPage,
    TrainingRequestsListPage,
    TrainingTeamPage
  ],
  providers: [
    TrainingService
  ],
  exports: [
    //TranslateModule,

    // components
    CompetencyComponent,
    CourseComponent,
    ExamComponent,
    GapsAnalyzerComponent,
    JobDescriptionComponent,

    // pipes and directives
    GapsByCategoryPipe,

    // pages
    TrainingDashboardPage,

    TrainingBatchesListPage,
    TrainingBatchViewPage,

    TrainingCompetenciesListPage,
    TrainingCompetencyViewPage,

    TrainingCoursesListPage,
    TrainingCourseViewPage,
    TrainingCourseEditPage,

    TrainingGapsViewPage,
    TrainingGapsSimulatorPage,
    TrainingGapsComparatorPage,

    TrainingJobDescriptionViewPage,

    TrainingRecordsListPage,
    TrainingRecordViewPage,

    TrainingReportsPage,
    TrainingRequestsListPage,
    TrainingTeamPage
  ]
})
export class TrainingModule {
  constructor(
    server: ServerService,
    session: SessionService,
    //notification: NotificationService,
    //trainingService: TrainingService
  ) {
    server.registerEndpoints(ENDPOINTS);

    session.LOGGEDIN.subscribe( (u: AppUser|null) => {
      // -- Module login
      if ( u === null ) return;
      // loggedin - acquire protected master data after logged-in
      /*
      Promise.all([
        server.request('opl.domains')
        .then( (res: Taxonomy[]) => {
          oplService.domains = res;
        }),

        server.request('opl.categories')
        .then( (res: Taxonomy[]) => {
          oplService.categories = res;
        }),

        server.request('opl.properties')
        .then( (res: CustomPropertyConfig[]) => {
          oplService.properties = res;
        })
      ]);
      */
    });

    session.LOGGEDOUT.subscribe( () => {
      // -- Module logout
    });

    // notification message parser
    /*
    notification.registerParser('Training', (n:IrisNotification): NotificationMessage => {
      return {
        group: 'training',
        link: `/opl/view/${n.closure['opl_id']}`
      };
    });
    */

    /*
    // -- Module boot
    Promise.all([
      // load configurations on BOOT
      server.getconfig('opl')
      .then( (cfg: OPLConfig) => {
        oplService.config = cfg;
      }),

      // add other bootstrap here
    ]);
    */
  }
}
