/**
 * Experimental Pages Module
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AngularSlickgridModule } from 'angular-slickgrid';

import { PinaconoUIModule } from '@pinacono/ui';
import { SlickgridExtensionModule } from '@pinacono/slickgrid-extension';

//import { PinaconoIIPViewerModule } from 'src/app/iip/module';
import { PinaconoIIPViewerModule } from '@pinacono/iip-viewer';

import { ThemesModule } from 'src/app/themes/module';
import { AppCommonModule } from 'src/app/common/module';

import { ExperimentalComponent } from './exp.component';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

/**
* module declaration
*/
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PinaconoIIPViewerModule,
    PinaconoUIModule,
    NgbCarousel,

    TranslateModule,
    ThemesModule,
    AngularSlickgridModule,
    SlickgridExtensionModule,
    AppCommonModule
  ],
  declarations: [
    ExperimentalComponent
  ],
  providers: [
  ],
  exports: [
    //TranslateModule,
    ExperimentalComponent
  ]
})

export class ExperimentsModule {
}
