import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserService } from '@pinacono/common';

@Component({
  selector: 'app-forget-pass-page',
  templateUrl: 'forget-pass.component.html',
  styleUrls: ['./forget-pass.component.scss']
})
export class ForgetPassComponent {

  // template variables
  public request_sent: boolean = false;

  // -- lifecycle
  constructor(
    protected user: UserService
  ) { }

  // template API
  /** extract email from the form and perform reset logic. */
  public reset(resetForm: NgForm)  {
    this.user.reset(resetForm.controls['email'].value)
    .then( () => {
      this.request_sent = true;
    });
  }


}
