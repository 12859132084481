/**
 * injectable tokens
 */

import { InjectionToken } from '@angular/core';

import { ThemeConfig, Menu } from './types';

export const THEME_CONFIG = new InjectionToken<ThemeConfig>('THEME_CONFIG');
export const MAIN_MENU = new InjectionToken<Menu[]>('MAIN_MENU');
export const USER_MENU = new InjectionToken<Menu[]>('USER_MENU');
export const LOGIN_MENU = new InjectionToken<Menu[]>('LOGIN_MENU');
