import {
  Component,
  OnInit,
  ViewChild
} from "@angular/core";

import {
  ActivatedRoute,
  Router
} from "@angular/router";

import * as moment from 'moment';

import {
  NavigationService,
  object_t,
  ServerError,
  ServerService,
  SessionService,
  UtilsService
} from '@pinacono/common';

import {
  BootboxInputOption,
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ComponentFormatter,
  DatetimeMomentFormatter,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from "src/app/classes/base-page.component";

import { HardcopyStatus } from 'src/app/modules/documents/types';

import {
  Book,
  BookInstance,
  InstanceStatusMapping,
  BookReservation,
  ReservationStatus
} from '../types';

import { LibraryService } from '../library.service';


@Component({
  selector: 'library-book-view',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ],
  providers: [ AngularUtilService ]
})
export class LibraryBookViewPage extends BasePageComponent implements OnInit {

  @ViewChild('modalBookInstances') modalBookInstances!: ModalComponent;
  @ViewChild('modalBookReservationHistory') modalBookReservationHistory!: ModalComponent;

  public book: Book|null = null;

  public remainingQuota: number = 5;

  public requested: BookReservation[] = [];
  public approved: BookReservation[] = [];
  public with_you: BookReservation[] = [];

  // reservation inforamtion
  public is_reservable: boolean = false;
  public is_reserved: boolean = false;
  public available: number = 0;

  public can_view_history: boolean = false;
  public can_cancel_reservation = false;

  // export constants
  public InstanceStatusMapping = InstanceStatusMapping;

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected ngUtilService: AngularUtilService,
    protected nav: NavigationService,
    protected ui: UIService,
    protected utils: UtilsService,
    protected api: LibraryService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService
  ) {
    super(router, activatedRoute);
  }

  protected override async loadData(): Promise<void> {
    this.book = this.api.createBook();

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.back();
      return Promise.resolve();
    }

    const nid: number = parseInt(id);
    if ( isNaN(nid) ) {
      this.ui.alert(`Document id "${id}" could not be found!`);
      this.nav.back();
      return Promise.resolve();
    }

    const res: object_t = await this.server.show('library/book', nid, { appends: 'instances,covers' });
    this.book = this.api.createBook(res);
    this.instances = (this.book.instances || []).filter( (instance: BookInstance) => {
      if ( this.session.hasPermission(['library_manage']) ) {
        return true;
      }
      return [
        HardcopyStatus.available,
        HardcopyStatus.checked_out,
        HardcopyStatus.reserved,
        HardcopyStatus.library_use_only,
        HardcopyStatus.other_location
      ].indexOf(instance.status) >= 0;
    })
    .sort( ( a: BookInstance, b: BookInstance) => {
      if ( a.location.branch < b.location.branch ) return -1;
      if ( a.location.branch > b.location.branch ) return 1;

      if ( a.location.cabinet < b.location.cabinet ) return -1;
      if ( a.location.cabinet > b.location.cabinet ) return 1;

      if ( a.location.shelf < b.location.shelf ) return -1;
      if ( a.location.shelf > b.location.shelf ) return 1;

      return 0;
    });

    let reservations: BookReservation[] = [];

    if ( this.session.currentUser ) {
      // calculate remaining quota for current user
      const res = await this.server.request('library.reservation.user.active', { uid: this.session.currentUser.id });
      reservations = Array.from(res)
        .filter( (re: unknown) =>  {
          const r = re as object_t;
          return r['reservable_type'] === 'doc' && r['user_id'] === this.session.currentUser!.id;
        })
        .map( (r: unknown) => {
          return this.api.createBookReservation(r as object_t)
        });

      this.remainingQuota = this.api.rules.quota - reservations.length;
      //console.log('remainingQuota =', this.remainingQuota);
    }

    this.requested = reservations.filter( r => r.book_id == this.book!.id && r.status == ReservationStatus.requesting );
    this.approved  = reservations.filter( r => r.book_id == this.book!.id && r.status == ReservationStatus.approved );
    this.with_you  = reservations.filter( r => r.book_id == this.book!.id && r.status == ReservationStatus.delivered );

    const AvaialbleStatuses = [
      HardcopyStatus.available,
      HardcopyStatus.checked_out,
      HardcopyStatus.reserved
    ];

    this.is_reservable = !! this.book.instances && this.book.instances.filter( instance => AvaialbleStatuses.includes(instance.status) ).length > 0;
    this.is_reserved   = (this.requested.length + this.approved.length + this.with_you.length) > 0;
    this.available     = this.book.instances ? this.book.instances.filter( instance => instance.status == HardcopyStatus.available ).length : 0;

    this.can_view_history       = this.session.hasPermission(['library_manage']);
    this.can_cancel_reservation = this.requested.length != 0;
  }

  // -- Template API

  public async reserve() {
    if ( ! this.book ) return;

    const require_before: string = await this.ui.prompt(
      'When will you need this book?',
      null, undefined, null, // binding, callback, config
      'date', {
        min: moment().add(this.api.rules.require_before, 'days').format('YYYY-MM-DD'),
        max: moment().add(this.api.rules.require_before + 7, 'days').format('YYYY-MM-DD')
      },
      moment().add(this.api.rules.require_before, 'days').format('YYYY-MM-DD')
    );
    if ( require_before === null ) {
      return;
    }

    //const reservations = await this.server.request('library/reservation/user')

    try {
      await this.server.rejectOnError(true).create('library/reservation/book', {
        book_id: this.book.id,
        user_id: this.session.currentUser!.id,
        /*
        request_date: moment().toISOString(),
        require_before: moment(require_before).toISOString()
        */
        request_date: moment().format('YYYY-MM-DD'),
        require_before: moment(require_before).format('YYYY-MM-DD')
      });
      //console.log('book reserved!');
      this.refresh();
    }
    catch (e: unknown) {
      if ( (e as any).code == 406 ) {
        this.ui.alert((e as ServerError).error.message, undefined, 'Error!')
      }
      console.error(e);
    }
  }

  public async cancel() {
    if ( ! this.book ) return;
    //const r = this.book.reservations && this.book.reservations.filter( r => r.id && r.user.id == this.session.currentUser!.id && ( r.status == ReservationStatus.requesting || r.status == ReservationStatus.approved ) ) || [];
    const r = this.requested;
    if ( r.length == 0 ) {
      return;
    }

    const options: BootboxInputOption[] = [];
    r.forEach((r: BookReservation, i: number) => {
      const req  = this.utils.dateTime_utils.format('DD MMM YYYY', r.request_date);
      const need = this.utils.dateTime_utils.format('DD MMM YYYY', r.require_before);
      options.push({
        text:  `Req: ${req}, Need: ${need}`,
        value: i.toFixed(0)
      });
    });

    const indexes = await this.ui.prompt('Please select the reservation to cancel.', null, undefined, null, 'checkbox', { inputOptions: options }, '0' )
    if ( !! indexes ) {
      indexes.forEach( async (index: string) => {
        const i = parseInt(index);
        if ( i !== undefined ) {
          await this.server.update('library/reservation/book', r[i].id!, { status: 'cancelled' });
        }
      });
      //console.log('book reservation cancelled!');
      this.refresh();
    }
  }

  public instances: BookInstance[] = [];
  public async locations() {
    if ( ! this.book ) return;
    const res: object_t = await this.server.show('library/book', this.book.id, { appends: 'instances' });
    this.instances = (this.api.createBook(res).instances || []).filter( (instance: BookInstance) => {
      if ( this.session.hasPermission(['library_manage']) ) {
        return true;
      }
      return [
        HardcopyStatus.available,
        HardcopyStatus.checked_out,
        HardcopyStatus.reserved,
        HardcopyStatus.library_use_only,
        HardcopyStatus.other_location
      ].indexOf(instance.status) >= 0;
    })
    .sort( ( a: BookInstance, b: BookInstance) => {
      if ( a.location.branch < b.location.branch ) return -1;
      if ( a.location.branch > b.location.branch ) return 1;

      if ( a.location.cabinet < b.location.cabinet ) return -1;
      if ( a.location.cabinet > b.location.cabinet ) return 1;

      if ( a.location.shelf < b.location.shelf ) return -1;
      if ( a.location.shelf > b.location.shelf ) return 1;

      return 0;
    });

    this.modalBookInstances.show();
  }

  // -- Book Reservation History
  public history() {
    this.reservations = [];
    this.modalBookReservationHistory.show();
    setTimeout( () => this.book && this.initReservationGrid(this.book) );
  }

  public closeHistory() {
    this.modalBookReservationHistory.hide();
    this.reservationGridOptions = null;
  }

  public get can_reserve(): boolean {
    return this.is_reservable && this.remainingQuota > 0;
  }

  // reservation grid interface
  public reservations: BookReservation[] = [];
  protected reservationsFilters: GraphqlFilteringOption[] = [
    //{ field: 'menu', operator: 'EQ', value: 'published' }
  ];
  protected reservationGridComponent: AngularGridInstance|null = null;
  public reservationGridOptions: GridOption|null = null;
  public reservationGridColumnDefinitions: Column[] = [];

  protected processReservationGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      this.graphqlServer
      .silent()
      .sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.reservations = re.data['book_reservations'].nodes.map( r => this.api.createBookReservation(r) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onReservationGridReady(event: Event) {
    //grid: AngularGridInstance
    this.reservationGridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initReservationGrid(book: Book) {
    this.reservationGridColumnDefinitions = [
      {
        id: 'staff', name: 'Requester',
        field: 'user',
        fields: [ 'user.id', 'user.avatar', 'user.avatar.thumb_url', 'user.email', 'user.fullname', 'user.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left',
        width: 120,
        filterable: true,
        formatter: ComponentFormatter,
        params: {
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'user'
          }
        }
      },


      {
        id: 'bar_code',  name: 'Code',
        field: 'instance.code',
        fields: [ 'instance.location', 'approved_by.id', 'approved_by.email', 'approved_by.fullname', 'approved_by.profiles.staff_id', 'approve_date' ],
        type: FieldType.object,
        cssClass: 'text-center',
        sortable: true,
        filterable: true,
        width: 30,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return value || 'n/a';
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-center',
        width: 40,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          const labels: object_t = {
            requesting: { label: 'Requesting', css: 'warning' },
            approved:   { label: 'Approved',   css: 'success' },
            rejected:   { label: 'Rejected',   css: 'danger' },
            delivered:  { label: 'Collected',  css: 'info' },
            returned:   { label: 'Returned',   css: 'info' },
            lost:       { label: 'Lost',       css: 'danger' },
            cancelled:  { label: 'Cancelled',  css: 'warning' }
          }
          const status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },
        //sortable: true,
        filterable: true,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: 'requesting', label: 'Requesting' },
            { value: 'approved',   label: 'Approved'   },
            { value: 'rejected',   label: 'Rejected'   },
            { value: 'delivered',  label: 'Collected'  },
            { value: 'returned',   label: 'Returned'   },
            { value: 'lost',       label: 'Lost'       },
            { value: 'cancelled',  label: 'Cancelled'  }
          ]
        }
      },

      {
        id: 'request_date', name: 'Request Date',
        field: 'request_date',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'require_before', name: 'Require Before',
        field: 'require_before',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'deliver_date', name: 'Collecting Date',
        field: 'deliver_date',
        fields: [ 'delivered_by.id', 'delivered_by.email', 'delivered_by.fullname', 'delivered_by.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'return_date', name: 'Return Date',
        field: 'return_date',
        fields: [ 'return_date', 'returned_to.id', 'returned_to.email', 'returned_to.fullname', 'returned_to.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];

    this.reservationGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.reservationGridColumnDefinitions,
          datasetName: 'book_reservations',
          persistenceFilteringOptions: this.reservationsFilters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'book_id', value: book.id },
            { field: 'trashed', value: 'WITHOUT' }
          ]
        },

        //preProcess: ():void => {},
        process: this.processReservationGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Reservation'
      },

      enableSorting: true,

      rowHeight: 60,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#history-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'container',
        bottomPadding: 10,
        minHeight: 500,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        /** @TODO - initial grid preset option here */
      }
    };
  }
}