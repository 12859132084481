import { Component } from '@angular/core';
import { NavigationService } from '@pinacono/common';

@Component({
  selector: 'app-e404-page',
  templateUrl: 'error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component {

  constructor(
    public nav: NavigationService
  ) { }

  public search(keyword: string) {
    this.nav.naviateByUrl(`/search/${keyword}`);
  }
}
