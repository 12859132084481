<full-page [title]="'Browse OPLs'" #page>
  <div page-content>
    <!-- Search bar -->
    <search [(keyword)]="keyword" (keywordChange)="search()"></search>

    <!-- OPL List -->
    <div class="fluid row">
      <div class="blog blog-list col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <!-- New OPL - remove *ngIf below to enable the creation panel -->
        <div *ngIf="false" id="new-opl" class="mar-top">
          <div class="opl">
            <div class="panel row">
              <form action="#" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <textarea
                    id="new-opl-input"
                    autosize
                    class="form-control full-width"
                    name="new-opl-content"
                    placeholder="Write my new OPL..."
                    [rows]="3" style="resize:none;"
                    [(ngModel)]="new_opl"
                  >
                  </textarea>
                  <div class="pull-right pad-top pad-bottom">
                    <button (click)="create()" class="btn btn-info btn-labeled">
                      <i class="icon-fw psi-pen-4"></i>
                      {{ 'Create' | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- 'Create New OPL' button -->
        <div *ngIf="false" class="full-width text-right mar-top mar-btm">
          <button class="btn btn-success btn-icon" stop-propagation (click)="nav.push('/#/opl/edit/new')">
            <i class="icon-lg icon-fw pli-file-add"></i> {{ 'Create New OPL' | translate }}
          </button>
        </div>

        <opl *ngFor="let opl of opls"
          [content]="opl"
          [format]="'feed'"
          (onBrowse)="browse($event)"
          (onOpen)="open($event)"
          (onEdit)="edit($event)"
          (onQuickEdit)="qedit($event)"
          (onTrash)="trash($event)"
          (onFlag)="flag($event)"
          (onUnFlag)="unflag($event)"
        ></opl>

        <div *ngIf="opls.length == 0" class="text-center text-muted">
          {{ 'There is not any result matched the filter(s).' | translate }}
        </div>
      </div>

      <div *ngIf="opls.length > 0" class="full-width text-center">
        <pagination
          [pageno]="pagination.pageno"
          [perpage]="pagination.perpage"
          [total]="pagination.total"
          (pagenoChange)="loadPage($event)"
        ></pagination>
      </div>
    </div>
  </div>
</full-page>
