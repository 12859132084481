import { Component, OnInit } from '@angular/core';

import {
  ServerService,
  FileUtilsService
} from '@pinacono/common';

@Component({
  selector: 'admin-reports-page',
  templateUrl: 'reports.html'
})
export class AdminReportsComponent implements OnInit {

  // -- form parameters
  public maximo = {
    from: null,
    to: null
  };

  public access = {
    from: null,
    to: null
  };

  public passwd = {
    from: null,
    to: null
  };


  // -- constructor
  constructor(
    protected server: ServerService,
    protected fileUtils: FileUtilsService
  ) {
  }

  public ngOnInit(): void {
  }

  public run(name: string, params: object|null = null) {
    this.server.request(`admin.reports.${name}`, params)
    .then( (res: any) => {
      this.fileUtils.saveXLSX(name, res);
    });
  }
}