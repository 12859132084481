import { Component, OnInit } from '@angular/core';

/**
 * currently not working - see app-routing.module.ts
 */
@Component({
  selector: 'app-offline-page',
  templateUrl: 'offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {
  public ngOnInit() {
    //console.warn('Entering offline page!');
  }
}
