<full-page [title]="'Search Drawings'">
  <div page-content>
    <panel>
      <div panel-heading>
        <h4>Search Drawings</h4>
      </div>
      <div panel-body>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3 pb-2">
            <h6>Select Master Drawing</h6>
            <div class="form-group">
              <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
              <input type="text" class="form-control" id="title" name="title" [(ngModel)]="search_input.title">
            </div>
            <div>
              <custom-properties [config]="buildings_config" [(data)]="search_input.attr['building']" (dataChange)="onBuildingChange($event)"></custom-properties>
            </div>
            <div>
              <custom-properties [config]="floors_config" [(data)]="search_input.attr['floor']"></custom-properties>
            </div>
            <div class="m-3 text-right">
              <button title="Search" class="btn btn-info btn-labeled" (click)="searchMaster()" >
                <i class="btn-label icon-lg icon-fw pli-magnifi-glass"></i> {{ 'Search' | translate }}
              </button>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-9 pad-no" id="search-result">

            <!-- no-result message -->
            <div *ngIf="masterDrawingsList.length == 0 && supplementsList.length == 0" class="col mt-5 text-center" id="no-result">
              <span class="text-muted text-thin text-lg">
                {{ 'Sorry, no any search result found, please try other criteria.' | translate }}
              </span>
            </div>

            <!-- master drawings -->
            <div *ngIf="supplementsList.length == 0 && masterDrawingsList.length > 0" class="row">
              <div *ngFor="let dwg of masterDrawingsList" class="col m-2 entry">
                <div class="d-flex">
                  <div class="flex-shrink-0 download-button">
                    <download *ngFor="let f of dwg.attachments" [file]="f" #downloader>
                      <ng-template
                        let-file="file"
                        let-icon="icon"
                      >
                        <button class="btn btn-default btn-icon mar-all" title="click to download {{ file.file_name}}" (click)="downloader.download()">
                          <i class="btn-label icon-fw icon-3x fa fa-fw {{ icon }}"></i>
                        </button>
                      </ng-template>
                    </download>
                  </div>
                  <div class="flex-grow-1 ms-1 details clickable" title="Click to search drawing inside {{ dwg.title }}" (click)="selectMasterDrawing(dwg)">
                    <div class="text-bold">
                      {{ dwg.title || 'Untitled' }}
                    </div>
                    <div>
                      <span class="badge" [ngClass]="{ 'badge-pink': !dwg.iip_name, 'badge-success': dwg.iip_name }" title="{{ dwg.iip_name ? 'Preview available' : 'Preview in progress' | translate }}">
                        <i [ngClass]="{ 'fa-regular fa-hourglass' : !dwg.iip_name, 'fa-solid fa-check': dwg.iip_name }"></i>
                      </span>
                      <span *ngIf="dwg.is_master" class="badge badge-mint" title="{{ 'Master Drawing' | translate }}">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="drawing-attributes">
                      อาคาร {{ dwg.attr['building'] || 'n/a' }} ชั้น {{dwg.attr['floor'] || 'n/a' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- supplements -->
            <div *ngIf="supplementsList.length > 0" class="row">

              <div *ngFor="let sup of supplementsList" class="col m-2 entry">

                <!-- drawing supplements -->
                <div *ngIf="sup.type == 'drawing'" class="d-flex supplement supplement-drawing">
                  <ng-container *ngFor="let doc of [castToDrawing(sup)]">
                  <div class="flex-shrink-0 download-button">
                    <download *ngFor="let f of doc.attachments" [file]="f" #downloader>
                      <ng-template
                        let-file="file"
                        let-icon="icon"
                      >
                        <button class="btn btn-default btn-icon mar-all" title="click to download {{ file.file_name}}" (click)="downloader.download()">
                          <i class="btn-label icon-fw icon-3x fa fa-fw {{ icon }}"></i>
                        </button>
                      </ng-template>
                    </download>
                  </div>
                  <div class="flex-grow-1 ms-1 details clickable" title="Click to edit {{ doc.title }}" (click)="selectSupplement(sup)">
                    <div class="text-sm text-thin">{{ 'Drawing' | translate }}</div>
                    <div class="text-bold">
                      {{ doc.title || 'Untitled' }}
                    </div>
                    <div>
                      <span class="badge" [ngClass]="{ 'badge-pink': ! doc.iip_name, 'badge-success': doc.iip_name }" title="{{ doc.iip_name ? 'Preview available' : 'Preview in progress' | translate }}">
                        <i [ngClass]="{ 'fa-regular fa-hourglass' : ! doc.iip_name, 'fa-solid fa-check': doc.iip_name }"></i>
                      </span>
                      <span *ngIf="doc.is_master" class="badge badge-mint" title="{{ 'Master Drawing' | translate }}">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="drawing-attributes">
                      อาคาร {{ doc.attr['building'] || 'n/a' }} ชั้น {{ doc.attr['floor'] || 'n/a' }}
                    </div>
                  </div>
                  </ng-container>
                </div>

                <!-- project supplements -->
                <div *ngIf="sup.type == 'project'" class="d-flex supplement supplement-project">
                  <ng-container *ngFor="let doc of [castToProject(sup)]">
                  <div class="flex-grow-1 ms-1 details clickable" title="Click to edit {{ doc.title }}" (click)="selectSupplement(sup)">
                    <div class="text-sm text-thin">{{ 'Project' | translate }}</div>
                    <div class="text-bold">
                      {{ doc.title_prefix && doc.title_prefix + ' ' }}{{ doc.title || 'Untitled' }}{{ doc.title_suffix && ' ' + doc.title_suffix }}
                    </div>
                    <div class="text-sm text-thin project-scope">
                      {{ doc.project_scope }}
                    </div>
                  </div>
                  </ng-container>
                </div>

                <!-- project drawing supplements -->
                <div *ngIf="sup.type == 'project_drawing'" class="d-flex supplement supplement-project-drawing">
                  <ng-container *ngFor="let doc of [castToProjectDrawing(sup)]">
                  <ng-container *ngFor="let dwg of [doc.revisions[0]]"><!-- consider the downloadable revision here -->
                    <div class="flex-shrink-0 download-button">
                    <download *ngFor="let f of dwg.attachments" [file]="f" #downloader>
                      <ng-template
                        let-file="file"
                        let-icon="icon"
                      >
                        <button class="btn btn-default btn-icon mar-all" title="click to download {{ file.file_name}}" (click)="downloader.download()">
                          <i class="btn-label icon-fw icon-3x fa fa-fw {{ icon }}"></i>
                        </button>
                      </ng-template>
                    </download>
                  </div>
                  <div class="flex-grow-1 ms-1 details clickable" title="Click to edit {{ doc.title }}" (click)="selectSupplement(sup)">
                    <div class="text-sm text-thin">{{ 'Project Drawing' | translate }}</div>
                    <div class="text-bold">
                      {{ doc.title || 'Untitled' }}
                    </div>
                    <div>
                      <span class="badge" [ngClass]="{ 'badge-pink': ! dwg.iip_name, 'badge-success': dwg.iip_name }" title="{{ dwg.iip_name ? 'Preview available' : 'Preview in progress' | translate }}">
                        <i [ngClass]="{ 'fa-regular fa-hourglass' : ! dwg.iip_name, 'fa-solid fa-check': dwg.iip_name }"></i>
                      </span>
                      <span *ngIf="dwg.is_master" class="badge badge-mint" title="{{ 'Master Drawing' | translate }}">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="drawing-attributes">
                      อาคาร {{ dwg.attr['building'] || 'n/a' }} ชั้น {{ dwg.attr['floor'] || 'n/a' }}
                    </div>
                  </div>
                  </ng-container>
                  </ng-container>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</full-page>

<modal template-id="polygon-editor"
  size="fullscreen"
  [hideCloseButton]="true"
  #polygonEditor
>
  <div modal-body id="iip">

    <div class="row">

      <div class="col-3">
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="drawing.title" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Building:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="drawing.attr['building']" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Floor:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="drawing.attr['floor']" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Polygons:</label>
          <ol *ngFor="let obj of polygons" class="coordinates">
            <li *ngFor="let node of obj.vertices"><span>{{ node.x | number:'1.2-2' }}</span>, <span>{{ node.y | number:'1.2-2' }}</span></li>
          </ol>
        </div>
      </div>

      <div class="col-9" id="right-pane">
        <span *ngIf="drawing.id && ! drawing.iip_name" class="text-muted text-thin text-lg">
          {{ 'Sorry, master drawing preview is not avaialble at the moment.' | translate }}
        </span>

        <iip-viewer *ngIf="drawing.iip_name !== null"
          [src]="drawing.iip_name"
          [path]="iip_base_path"
          [navButtons]="['reset', 'zoomIn', 'zoomOut']"
          [showCoords]="false"
          [showScale]="false"
          [drawingTools]="enableDrawingTools"
          [polygons]="polygons"
          [prototype]="polygonPrototype"
          (onZoom)="onDrawingZoom($event)"
        ></iip-viewer>

      </div>
    </div>
  </div>

  <div modal-footer>

    <button *ngIf="polygons.length > 0" title="Search" class="btn btn-success btn-labeled" (click)="search()">
      <i class="btn-label icon-lg icon-fw pli-magnifi-glass"></i> {{ 'Search' | translate }}
    </button>

    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="polygonEditor.hide()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
    </button>

  </div>

</modal>
