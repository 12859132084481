<minimal-page>
  <div class="cls-content-fw animated fadeIn slow">
    <div id="page-content" class="pad-btm">
      <content-frame id="campaign-content"
        [url]="'server/contents/campaign.html'"
        (onError)="onContentLoadError($event)"
      ></content-frame>
      <div class="mar-top mar-btm">
        <button (click)="next()" class="btn btn-labeled btn-success" type="button">
          <i class="btn-label pli-arrow-forward-2"></i>{{ 'OK' | translate }}
        </button>
      </div>
    </div>
  </div>
</minimal-page>