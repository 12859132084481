<full-page [title]="'Manage Taxonomies'">
  <div page-content>
    <panel class="taxonomies-panel">
      <div panel-heading>
        {{ 'Taxonomies' | translate }}
      </div>
      <div panel-body>
        <form #theform="ngForm">
          <select name="vocab" [select-picker]="{}" (select-picker-on-select)="selectVocab($event)" [(ngModel)]="selected_vid" #vocab="ngModel">
            <option *ngFor="let v of vocabs" value="{{ v.id }}" [title]="v.description"> {{ v.name }}</option>
          </select>
        </form>
        <tree class="bg-trans select-group"
          [data]="root"
          [options]="treeOptions"
          [contextMenu]="contextMenu"
          (onTreeEvent)="treeEvent($event)"
          (onCreateNode)="createNode($event)"
          (onEditNode)="editNode($event)"
          (onDeleteNode)="deleteNode($event)"
          (onMoveNode)="moveNode($event)"
          (selectedChange)="onSelectedNodeChange($event)"
          #taxonomyTree
        >
        </tree>
      </div>
    </panel>
  </div>
</full-page>
