/**
 * Home Page Module
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

/*
import { FullCalendarModule } from '@fullcalendar/angular';

import * as calendarDayGridPlugin from '@fullcalendar/daygrid';
import * as calendarTimeGridPlugin from '@fullcalendar/timegrid';
import * as calendarListlugin from '@fullcalendar/list';
import * as calendarScrollGridPlugin from '@fullcalendar/scrollgrid';
import * as calendarIntertionPlugin from '@fullcalendar/interaction';
*/

import {
  PinaconoCommonModule,
  ServerService
} from '@pinacono/common';

import { PinaconoUIModule } from '@pinacono/ui';
import { ThemesModule } from 'src/app/themes/module';

import { HomeComponent } from './home.component';

// see https://fullcalendar.io/docs#toc
/*
FullCalendarModule.registerPlugins([
  calendarDayGridPlugin.default,
  calendarIntertionPlugin.default,
  calendarListlugin.default,
  calendarScrollGridPlugin.default,
  calendarTimeGridPlugin.default
]);
*/

/**
 * module declaration
 */
@NgModule({
  declarations: [
    // components
    HomeComponent,
  ],
  imports: [
    CommonModule,

    TranslateModule,

//    FullCalendarModule,
    PinaconoCommonModule,
    PinaconoUIModule,
    ThemesModule
  ],
  providers: [
  ],
  exports: [
    //TranslateModule,
    HomeComponent
  ]
})
export class HomeModule {
  constructor(
    server: ServerService
  ) {
    server.registerEndpoints({
      home: {
        calendar: '/calendar/{name}',
        forums:   '/forums/announcement'
      }
    });
  }
}
