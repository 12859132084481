<full-page [title]="'Groups Administration'">
  <div page-content class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
      <panel class="groups-panel">
        <div panel-heading>
          {{ 'Group' | translate }}
        </div>
        <div panel-body>
          <form #theform="ngForm">
            <select name="vocab" [select-picker] (select-picker-on-select)="selectVocab($event)" [(ngModel)]="selected_vid" #vocab="ngModel">
              <option *ngFor="let v of vocabs" value="{{ v.id }}" [title]="v.description"> {{ v.name }}</option>
            </select>
          </form>
          <tree class="bg-trans select-group"
            [data]="root"
            [options]="{ core: { multiple: false } }"
            [expand]="true"
            [contextMenu]="contextMenu"
            (onTreeEvent)="treeEvent($event)"
            (onCreateNode)="createGroup($event)"
            (onEditNode)="editGroup($event)"
            (onDeleteNode)="deleteGroup($event)"
            (selectedChange)="onSelectedGroupChange($event)"
            #groupTree
          >
          </tree>
        </div>
      </panel>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
      <!-- Direct Managers -->
      <div class="row">
        <panel class="users-panel col-xs-12">
          <div panel-control>
            <button class="btn btn-primary btn-labeled"
              [modal]="'add-manager'"
              [attr.disabled]="selected_groups.length > 0 ? null : ''"
              [title]="'Add Manager(s)'"
            >
              <i class="btn-label pli-add-user"></i> {{ 'Add Manager(s)' | translate }}
            </button>
          </div>
          <div panel-heading>
            {{ 'Managers' | translate }}
          </div>
          <div panel-body class="row">
            <div *ngFor="let user of users['managers']" class="col user-card-container manager">
              <button (click)="removeManager(user)" class="remove btn btn-default btn-icon" [title]="'Remove from manager'">
                <i class="icon-lg pli-trash"></i>
              </button>
              <user-card [user]="user" (click)="editUser(user, 'core_manage_group')">
                <div user-profile>
                  <p class="text-sm text-bold">{{ user.profiles['job_title'] }}</p>
                </div>
              </user-card>
            </div>
            <div *ngIf="selected_groups.length == 0" class="full-width text-center">
              <p class="text-muted text-sm">Please select a group.</p>
            </div>
            <div *ngIf="selected_groups.length > 0 && users['managers'].length == 0" class="full-width text-center">
              <p class="text-muted text-sm">This group do not have manager(s).</p>
            </div>
          </div>
        </panel>
      </div>

      <!-- Direct Members -->
      <div class="row">
        <panel class="users-panel col-xs-12">
        <div panel-control>
          <button  class="btn btn-primary btn-labeled"
            [modal]="'add-member'"
            [attr.disabled]="selected_groups.length > 0 ? null : ''"
            [title]="'Add Member(s)'"
          >
            <i class="btn-label pli-add-user"></i> {{ 'Add Member(s)' | translate}}
          </button>
        </div>
          <div panel-heading>
            {{ 'Members' | translate }}
          </div>
          <div panel-body class="row">
            <div *ngFor="let user of users['members']" class="col user-card-container member">
              <button class="remove btn btn-default btn-icon"
                (click)="removeMember(user)"
                [title]="'Remove from member'"
              >
                <i class="icon-lg pli-trash"></i>
              </button>
              <user-card [user]="user" (click)="editUser(user, 'core_access_group')">
                <div user-profile>
                  <p class="text-sm text-bold">{{ user.profiles['job_title'] }}</p>
                </div>
              </user-card>
            </div>
            <div *ngIf="selected_groups.length == 0" class="full-width text-center">
              <p class="text-muted text-sm">Please select a group.</p>
            </div>
            <div *ngIf="selected_groups.length > 0 && users['members'].length == 0" class="full-width text-center">
              <p class="text-muted text-sm">This group do not have member(s).</p>
            </div>
          </div>
        </panel>
      </div>
    </div>
  </div>
</full-page>

<modal [template-id]="'add-manager'" [size]="'large'" [fade]="true" #modalAddManager>
  <div modal-title>
    {{ 'Add new manager' | translate }}
  </div>
  <div modal-body>
    <div class="pad-btm input-group">
      <input type="text" class="form-control full-width" name="name-lookup" placeholder="Type part of name to search" [(ngModel)]="lookupName" (ngModelChange)="lookupOthers($event, 'core_manage_group')">
      <span class="input-group-btn">
        <button class="btn btn-labeled btn-mint" type="button" (click)="lookupOthers(lookupName, 'core_manage_group')">
          <i class="btn-label pli-find-user"></i> {{ 'Search' | translate }}
        </button>
        <button class="btn btn-labeled btn-success" type="button" (click)="editUser(null, 'core_manage_group')">
          <i class="btn-label pli-add-user"></i> {{ 'New' | translate }}
        </button>
      </span>
    </div>
    <div *ngFor="let user of users['others']">
      <user-card [user]="user" (click)="addManager(user)">
        <div user-profile>
          <p class="text-sm text-bold">{{ user.profiles['job_title'] }}</p>
        </div>
      </user-card>
    </div>
  </div>
</modal>

<modal [template-id]="'add-member'" [size]="'large'" [fade]="true" #modalAddMember>
  <div modal-title>
    {{ 'Add new member' | translate }}
  </div>
  <div modal-body>
    <div class="pad-btm input-group">
      <input type="text" class="form-control" name="name-lookup" placeholder="Type part of name to search" [(ngModel)]="lookupName" (ngModelChange)="lookupOthers($event, 'core_access_group')">
      <span class="input-group-btn">
        <button class="btn btn-labeled btn-mint" type="button" (click)="lookupOthers(lookupName, 'core_access_group')">
          <i class="btn-label pli-find-user"></i> {{ 'Search' | translate }}
        </button>
        <button class="btn btn-labeled btn-success" type="button" (click)="editUser(null, 'core_access_group')">
          <i class="btn-label pli-add-user"></i> {{ 'New' | translate }}
        </button>
      </span>
    </div>
    <div *ngFor="let user of users['others']">
      <user-card [user]="user" (click)="addMember(user)">
        <div user-profile>
          <p class="text-sm text-bold">{{ user.profiles['job_title'] }}</p>
        </div>
      </user-card>
    </div>
  </div>
</modal>

<modal [template-id]="'add-user'" [size]="'large'" [fade]="true" [hideCloseButton]="true" #modalAddUser>
  <div modal-title>
    {{ 'Add/Edit User' | translate }}
  </div>
  <div modal-body>
    <user-profile *ngIf="currentUser"
      [user]="currentUser"
      [editable]="['*']"
      [disable]="['join-date', 'last-login', 'agreements']"
      [hide-save-button]="true"
      (validated)="validateUser($event)"
      #profileEditor
    >
      <div class="col-xs-12">
        <div class="form-group">
          <label class="control-label" for="maximo_user">
            {{ 'CMMS User' | translate }}
          </label>
          <input type="text"
            class="form-control"
            name="maximo_user"
            placeholder="CMMS User ID"
            [(ngModel)]="currentUser.maximo_user"
            #mobile="ngModel"
          >
        </div>
      </div>
      <!--
      @TODO add permission editor here
      <div class="panel">
      </div>
      -->
    </user-profile>
  </div>
  <div modal-footer>
    <button class="btn btn-labeled btn-success" type="button" [attr.disabled]="userIsValid ? null : ''" (click)="saveUser()">
      <i class="btn-label pli-save"></i> {{ 'Save' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="modalAddUser.hide();">
      <i class="btn-label pli-close"></i> {{ 'Cancel' | translate }}
    </button>
  </div>
</modal>