import {
  Component,
  ViewChild
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  ServerService,
  SessionService,
  Taxonomy,
  User
} from "@pinacono/common";

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  Tree,
  UIService
} from "@pinacono/ui";

import { BasePageComponent } from "src/app/classes/base-page.component";

import { TrainingService } from '../training.service';
import {
  JobDescription
} from '../types';

const MAX_CANDIDATES = 2;

@Component({
  selector: 'training-team-page',
  templateUrl: 'team.html',
  styleUrls: [ 'team.scss' ]
})
export class TrainingTeamPage extends BasePageComponent {

  @ViewChild('jobSelectorModal') jobSelectorModal!: ModalComponent;

  public teams: Taxonomy[] = [];
  public users: User[] = [];
  public candidates: User[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public server: ServerService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle
  public override loadData(): Promise<void> {
    this.candidates = [];
    this.users = [];

    return new Promise<void>( (resolve, reject) => {
      let promise: Promise<Taxonomy[]>;

      if ( this.session.hasPermission(['core_admin', 'manage_all_teams']) ) {
        promise = this.server.show('terms', 1);
        this.loadTeam(1);
      }
      else {
        let domain_id = this.session.currentUser?.primary_domain?.id;
        if ( ! domain_id ) {
          resolve();
          return;
        }
        promise = this.server.show('terms', domain_id);
        this.loadTeam(domain_id);
      }

      promise.then( ( terms: Taxonomy[]) => {
        this.teams = terms;
        this.selectedTeams = terms.map( (t:Taxonomy) => {
          return {
            id: t.id,
            text: t.name,
            original: t
          }
        })
        resolve();
      });
    });
  }

  protected loadTeam(id: number | number[]) {
    let ids: string;
    if ( Array.isArray(id) ) {
      ids = id.join(',');
    }
    else {
      ids = id.toString();
    }
    this.server.get('/groups/{gid}', { gid: ids, direct: 0 })
    .then( ( res: User[]) => {
      this.users = res;
    });
  }

  // -- template API
  public view(user: User) {
    //this.nav.push(TrainingGapsViewPage, {id: user.id});
    this.nav.push([ '/training/gaps/view', user.id ]);
    this.dont_refresh = true;
  }

  public add(user: User, event: Event) {
    let i = this.candidates.indexOf(user);
    let checked = !! (event.target as any)['checked'];
    if ( checked && i < 0 ) {
      if ( this.candidates.length >= MAX_CANDIDATES ) {
        this.ui.alert(`Maximum ${MAX_CANDIDATES} users at a time.`);
        (event.target as any)['checked'] = false;
      }
      else {
        this.candidates.push(user);
      }
    }
    else if ( ! checked && i >= 0 ) {
      this.candidates.splice(i, 1);
    }
  }

  public popupCompareDialog() {
    if ( this.candidates.length < 2 ) {
      this.ui.alert("Please select 2-3 users to compare.");
      return;
    }

    this.jobSelectorModal.show();
  }

  public compare() {
    if ( ! this.job ) {
      this.ui.alert("Please select a valid job title.");
      return;
    }

    /*
    this.nav.push(TrainingGapsComparatorPage, {
      job: this.job.id,
      candidates: this.candidates.map( (u: User) => u.id ).join(',')
    });
    */
    // @TODO - confirm commands is correct
    this.nav.push({ commands: [
      'training/gaps/compare',
      {
        job: this.job.id,
        candidates: this.candidates.map( (u: User) => u.id ).join(',')
      }
    ] });
    this.dont_refresh = true;
    this.jobSelectorModal.hide();
  }

  public isChecked(user: User): true|null {
    return (this.candidates.indexOf(user) >= 0) || null
  }

  /**
   * lookup for a form
   */
  public job: JobDescription|null = null;
  public jobs_list: LookupItem<JobDescription>[] = [];
  public job_title: string = '';

  public lookupJobTitle(keyword: string) {
    this.server.lookup('training/jobs', { name: keyword })
    .then ( (res: object[]) => {
      this.jobs_list = [];
      res.map( (j: object) => this.api.createJobDescription(j) ).forEach( (j: JobDescription) => {
        this.jobs_list.push({
          label: j.title,
          value: j
        });
      });
      this.jobs_list = this.jobs_list.slice(); // trig the ngOnChange ...
    });
  }

  /**
   * select a form as the master of the document
   */
  public selectJobTitle(item: LookupEvent<JobDescription>) {
    if ( item.value.value === null || ! item.value.value.id ) {
      return;
    }
    this.job_title = item.value.value.title;
    this.server.show('training/jobs', item.value.value.id)
    .then( (res: object) => {
      this.job = this.api.createJobDescription(res);
    });
  }

  /**
   * select team from jsTree
   */
  public selectedTeams: Tree.Node[] = [];
  public selectTeam(nodes: Tree.Node[]) {
    if ( nodes.length > 0 ) {
      this.loadTeam(nodes.map( n => n.id || 0));
    }
    else {
      this.users = [];
    }
  }
}