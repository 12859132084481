/**
 * document browser configuration profiles
 */

import { GraphqlFilteringOption } from '@slickgrid-universal/graphql';

export interface BrowseOptions {
  title: string;
  enableSearch: boolean;
  filters: GraphqlFilteringOption[];
  hiddenColumns: string[];
  avaiableButtons: string[];
}

export const BrowserProfiles: { [name: string]: BrowseOptions } = {
  /** default profile */
  default: {
    title: 'Documents',
    enableSearch: true,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'status',          operator: 'EQ', value: 'published' },
      { field: 'user_domain',     operator: 'IN', value: '{{user_domain}}'}
    ],
    hiddenColumns: ['id', 'confidential'],
    avaiableButtons: [
      //'edit',
      //'review',
      //'publish',
      //'expire',
      //'delete',
      //'restore',
      //'trash'
    ]
  },

  /** for administrator */
  admin: {
    title: 'All Documents',
    enableSearch: true,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'menu',            operator: 'EQ', value: 'admin' }
    ],
    hiddenColumns: [ 'confidential' ],
    avaiableButtons: [
      'edit',
      //'review',
      //'publish',
      //'expire',
      'delete',
      'restore',
      'trash'
    ]
  },

  /** list all docs with author is me */
  mine: {
    title: 'My Documents',
    enableSearch: false,
    filters: [
      { field: 'menu', operator: 'EQ', value: 'mine' }
    ],
    hiddenColumns: [ 'id', 'confidential', 'uploader' ],
    avaiableButtons: [
      'edit',
      //'review',
      //'publish',
      //'expire',
      //'delete',
      //'restore',
      'trash'
    ]
  },

  /** list all deleted docs - for dcc manager */
  deleted: {
    title: 'Deleted',
    enableSearch: false,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'status',          operator: 'EQ', value: 'deleted' }
    ],
    hiddenColumns: ['id', 'confidential', 'status'],
    avaiableButtons: [
      'edit',
      //'review',
      //'publish',
      //'expire',
      //'delete',
      'restore',
      'trash'
    ]
  },

  /** list all expired doc - for dcc manager */
  expired: {
    title: 'Expired',
    enableSearch: false,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'status',          operator: 'EQ', value: 'expired' }
    ],
    hiddenColumns: ['id', 'confidential', 'status'],
    avaiableButtons: [
      'edit',
      //'review',
      //'publish',
      //'expire',
      'delete',
      'restore',
      'trash'
    ]
  },

  /** list all docs with 'reserved' status */
  reserved: {
    title: 'Reserved',
    enableSearch: false,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'status',          operator: 'EQ', value: 'reserved' }
    ],
    hiddenColumns: [ 'id', 'confidential', 'status' ],
    avaiableButtons: [
      'edit',
      //'review',
      //'publish',
      //'expire',
      //'delete',
      //'restore',
      'trash'
    ]
  },

  /** list all docs with 'reviewed' status */
  reviewed: {
    title: 'Approved',
    enableSearch: false,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'status',          operator: 'EQ', value: 'reviewed' }
    ],
    hiddenColumns: ['id', 'confidential', 'status'],
    avaiableButtons: [
      'edit',
      //'review',
      'publish',
      //'expire',
      //'delete',
      //'restore',
      'trash'
    ]
  },

  /** list all docs with 'submitted' status */
  submitted: {
    title: 'Submitted',
    enableSearch: false,
    filters: [
      { field: 'confidentiality', operator: 'EQ', value: 'true' },
      { field: 'status',          operator: 'EQ', value: 'submitted' }
    ],
    hiddenColumns: ['id', 'confidential', 'status'],
    avaiableButtons: [
      'edit',
      'review',
      //'publish',
      //'expire',
      //'delete',
      //'restore',
      'trash'
    ]
  }

}
