/**
 * admin endpoints
 */

import { ServiceEndpointsMapping } from "@pinacono/common";

export const ENDPOINTS: ServiceEndpointsMapping = {
  opl: {
    domains:      '/domains/opl',          // get all approval domains
    user_domains: '/domains/opl/{uid}',    // get approval domains of a user
    categories:   '/categories/opl',       // get OPL categories
    properties:   '/properties/opl',       // get custom properties configs

    by_code:    '/opls/code/{doccode}',    // get OPL by document code

    count:      '/opls/count/{uid}',               // count avaialble OPLs by types
    action:     '/opls/{opl_id}/action/{action}', // apply action on an OPL
    get_read:   '/opls/{opl_id}/read',            // get users who';'ve already read an OPL
    set_read:   { method: 'POST',   url: '/opls/{opl_id}/read' }, // mark an OPL as read
    reset_read: { method: 'DELETE', url: '/opls/{opl_id}/read' }, // reset an OPL as unread

    upload:  { method: 'POST', url: '/opls/{opl_id}/upload' }, // upload attachment for an OPL
    attach:  '/opls/{opl_id}/attach/{file_id}',  // attach a file to an OPL
    detach:  '/opls/{opl_id}/detach/{file_id}',  // detach a file from an OPL
    revlog:  { method: 'POST', url: '/opls/{id}/revlog' }, // add revision log
    submit:  '/opls/{id}/action/submit',  // submit OPL for approval
    reject:  '/opls/{id}/action/reject',  // reject a submitted OPL
    approve: '/opls/{id}/action/approve', // approve a submitted OPL
    remind:  '/opls/{id}/remind/{uid}',     // remind user to read OPL
    request: '/opls/{id}/request/{action}', // request action on OPL
    flag:    '/opls/{id}/flag/{gid}',   // flag OPL for my group
    unflag:  '/opls/{id}/unflag/{gid}', // unflag OPL for my group

    reports: {
      all: { method: 'POST', url: '/reports/opl.all' }
    }
  }
};
