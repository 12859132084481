import {
  Component,
  ViewChild
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import { BasePageComponent } from "src/app/classes/base-page.component";
import { CourseComponent } from "../components/course/course.component";

import { TrainingService } from '../training.service';
import { CourseActionEvent } from '../types';

@Component({
  selector: 'training-course-view-page',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ],
})
export class TrainingCourseViewPage extends BasePageComponent {

  @ViewChild('courseComponent') courseComponent!: CourseComponent;

  public id: string|null = null;

  // -- lifecycle
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: ServerService
  ) {
    super(router, activatedRoute);
  }

  public override loadData(): Promise<void> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    if ( ! id ) {
      this.back();
      return Promise.resolve();
    }

    this.id = id;
    return Promise.resolve();
  }

  // -- template API

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public onAfterCourseAction(event: CourseActionEvent) {
    this.courseComponent.load();
  }
}