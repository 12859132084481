<minimal-page class="landing-page">
  <div id="versions" class="pad-all cls-content-lg">
    <h1 class="pad-all h3">{{ 'Components' | translate }}</h1>
    <label>Client:</label>
    <ul>
      <li class="text-thin">Client v. <strong>{{ app_info.signature }}</strong></li>
      <li *ngFor="let v of client_packages" class="text-thin">
        {{ v.name }} v. <strong>{{ v.version }}</strong>
      </li>
    </ul>
    <label>Server:</label>
    <ul>
      <li *ngFor="let v of CLIENT_CONFIG.server.versions" class="text-thin">
        {{ v.name }} v. <strong>{{ v.version }}</strong>
      </li>
    </ul>
    <div class="pad-all">
      <a [routerLink]="['/login']" class="btn-link text-thin text-main">{{ 'Back' | translate }}</a>
    </div>
  </div>
</minimal-page>