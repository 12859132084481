import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PinaconoCommonModule } from '@pinacono/common';
import { PinaconoUIModule } from '@pinacono/ui';

// services
import { ThemeConfig, DEFAULT_THEME_CONFIG, Menu } from './types';
import { THEME_CONFIG, MAIN_MENU, USER_MENU, LOGIN_MENU } from './tokens'
import { ThemeService } from './theme.service';

// components
import { FooterComponent } from './components/footer/footer.component';
import { FullPageComponent } from './components/full-page/full-page.component';
import { MenuComponent } from './components/menu/menu.component';
import { MinimalPageComponent } from './components/minimal-page/minimal-page.component';
import { PanelComponent } from './components/panel/panel.component';
import { SearchCompoment } from './components/search-page/search.component';
import { SearchPageComponent } from './components/search-page/search-page.component';

// pipes
import { LeftPadPipe } from '../themes/pipes/leftpad.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    //TranslateModule,
    TranslateModule,
    PinaconoCommonModule,
    PinaconoUIModule
  ],
  declarations: [
    // components
    FooterComponent,
    FullPageComponent,
    MenuComponent,
    MinimalPageComponent,
    PanelComponent,
    SearchCompoment,
    SearchPageComponent,

    // pipes
    LeftPadPipe
  ],
  providers: [
    ThemeService,
    { provide: THEME_CONFIG, useValue: DEFAULT_THEME_CONFIG },
    { provide: MAIN_MENU,  useValue: [] },
    { provide: USER_MENU,  useValue: [] },
    { provide: LOGIN_MENU, useValue: [] }
  ],
  exports: [
    // components
    FooterComponent,
    FullPageComponent,
    MenuComponent,
    MinimalPageComponent,
    PanelComponent,
    SearchCompoment,
    SearchPageComponent,

    // pipes
    LeftPadPipe
  ]
})
export class ThemesModule {

  public static init(config: ThemeConfig, main_menu: Menu[], user_menu: Menu[], login_menu: Menu[]): ModuleWithProviders<ThemesModule> {
    return {
      ngModule: ThemesModule,
      providers: [
        ThemeService,
        { provide: THEME_CONFIG, useValue: config   },
        { provide: MAIN_MENU,  useValue: main_menu  },
        { provide: USER_MENU,  useValue: user_menu  },
        { provide: LOGIN_MENU, useValue: login_menu }
      ]
    };
  }

  constructor(
    service: ThemeService, // force constructor code in service be executed
  ) {
  }
}
