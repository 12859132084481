import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  object_t,
  ServerService,
  FileUtilsService
} from '@pinacono/common';

@Component({
  selector: 'page-library-reports',
  templateUrl: 'reports.html',
  styleUrls: [ 'reports.scss' ]
})
export class LibraryReportsPage implements OnInit {

  // -- form parameters
  public params = {
  };

  // -- constructor
  constructor(
    protected server: ServerService,
    protected fileUtils: FileUtilsService
  ) {
  }

  public ngOnInit(): void {
  }

  public from: string|null = null;
  public to: string|null = null;

  public async run(name: string, form: NgForm) {
    const params = {
      from: this.from,
      to: this.to
    };

    const res: object_t = await this.server.post(`/reports/${name}`, null, params);
    this.fileUtils.saveXLSX(name, res);
  }
}