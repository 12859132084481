<minimal-page>
  <div class="cls-content-sm panel">
    <div class="panel-body">
      <div class="mar-ver pad-btm">
        <div id="main-logo" class="animated fadeIn delay-1s slower"><img src="assets/images/logo-login.png"></div>
        <h1 class="h3">{{ 'SPWG Engineering Portal Login' | translate }}</h1>
        <p class="text-thin text-xs"><a [routerLink]="['/versions']">Version {{ app_info.version }} Build {{ app_info.build_no }} ({{ app_info.release_date }})</a></p>
        <p>{{ 'Sign in to your account' | translate }}</p>
      </div>
      <form #f="ngForm" (ngSubmit)="login(f)">
        <div class="form-group">
          <input type="text" name="email" ngModel required class="form-control" placeholder="{{ 'E-mail' | translate }}" autofocus>
        </div>
        <div class="form-group">
          <input type="password" name="password" ngModel class="form-control" autocomplete="current-password" placeholder="{{ 'Password' | translate }}" passwordVisible>
        </div>
        <div *ngIf="allow_remember" class="form-checkd-btm text-left">
          <input id="form-remember" name="remember" ngModel class="form-check-input" type="checkbox">
          <label for="form-remember" class="form-check-label">{{ 'Remember me' | translate }}</label>
        </div>
        <button class="btn btn-primary btn-lg btn-block" type="submit">{{ 'Sign In' | translate }}</button>
      </form>
    </div>

    <div class="pad-all">
      <a [routerLink]="['/forget']"  class="btn-link text-xs text-thin mar-rgt">{{'Forgot password' | translate}} ?</a>
      <a [routerLink]="['/faq']"     class="btn-link text-xs text-thin mar-left mar-rgt">{{'FAQ' | translate}} !</a>
      <a [routerLink]="['/contact']" class="btn-link text-xs text-thin text-main mar-lft">{{ 'Contact Administrator' | translate }}</a>
    </div>
    <div id="note" class="pad-all bord-top text-thin text-sm">
      {{ 'Running on the latest version of following browsers' | translate  }}:
      <span class="browser">Google Chrome</span>
      <span class="browser">Mozilla FireFox</span>
      <span class="browser">Microsoft&trade; Edge</span>
    </div>
  </div>
</minimal-page>
