import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PinaconoUIModule } from '@pinacono/ui';
import { ThemesModule } from 'src/app/themes/module';

// services
import { AppCommonService } from './app-common.service';

// components
import { CheckBoxesComponent } from './components/checkboxes/checkboxes.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CustomPropertiesComponent } from './components/custom-properties/custom-properties.component';
import { KibanaComponent } from './components/kibana/kibana.component';
import { QuizEditorComponent } from './components/quiz-editor/quiz-editor.component';
import { RelatedDocumentsComponent } from './components/related-documents/related-documents.component';
import { SelectGroupsComponent } from './components/select-groups/select-groups';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,

    TranslateModule,
    PinaconoUIModule,
    ThemesModule,
  ],
  declarations: [
    // components
    CheckBoxesComponent,
    CommentsComponent,
    CustomPropertiesComponent,
    KibanaComponent,
    QuizEditorComponent,
    RelatedDocumentsComponent,
    SelectGroupsComponent,
  ],
  providers: [
    AppCommonService
  ],
  exports: [
    //TranslateModule,

    // components
    CheckBoxesComponent,
    CommentsComponent,
    CustomPropertiesComponent,
    KibanaComponent,
    QuizEditorComponent,
    RelatedDocumentsComponent,
    SelectGroupsComponent,
  ]
})
export class AppCommonModule {
  constructor() {}
}
