<div>
  <list-editor
    [(list)]="docs"
    [name]="'related-docs'"
    placeholder="{{ 'Enter document code or name to search' | translate }}"
    [lookupList]="items"
    (onLookup)="onLookup($event)"
    (listChange)="onChange($event)"
  >
    <ng-template #itemTemplate
      let-item="item"
      let-index="index"
    >
      <span class="code">{{ item }}</span>
    </ng-template>
  </list-editor>

  <!--
  <div class="input-group keyword">
    <input #input type="text" placeholder="{{ 'Search' | translate }}..." name="keyword" class="form-control" [(ngModel)]="keyword">
    <span class="input-group-btn">
      <button class="btn btn-mint" type="button" (click)="onLookup(keyword)">{{ 'Search' | translate }}</button>
    </span>
  </div>

  <lookup [input]="input" [minChar]="minChar" [autoClose]="true"
    [items]="items"
    (onLookup)="onLookup($event)"
    (onSelect)="onSelect($event)"
    class="lookup"
  >
  </lookup>
  <textarea #editor autosize [(ngModel)]="docs"
    id="related-doc" class="form-control editor"
    name="related-doc"
    style="resize:none;" rows="5"
    placeholder="{{ 'List of related document codes, one document per line' | translate }}"
    (change)="onChange($event)"
  >
  </textarea>
  -->
</div>
