<full-page [title]="'Training Reports'">
  <div page-content>

    <form id="report-form" ngForm="reportForm">
      <div class="pad-all">
        <div class="row">
          <panel id="select-users" class="col">
            <div panel-body>
              <div class="row pad-top">
                <div class="col">
                  <button class="btn btn-default btn-labelled" (click)="selectGroupModal.show()">{{'Select User(s)' | translate}}</button>
                </div>
              </div>

              <div class="row pad-top pad-btm">
                <label class="text-bold">{{ 'Selected Users' | translate }}:</label>
                <div *ngFor="let user of selectedUsers" class="col form-group user-card-container">
                  <user-card [user]="user"></user-card>
                  <button class="btn btn-icon" (click)="removeSelectedUser(user)">
                    <i class="pli-close"></i>
                  </button>
                </div>
                <div *ngIf="selectedUsers.length == 0" class="empty">
                  - {{ 'No user(s) selected, assume all users are selected.' | translate }} -
                </div>
              </div>
            </div>
          </panel>

        </div>
        <div class="row">

          <!-- Report #1 -->
          <panel id="competency-report-1" class="col">
            <div panel-heading>Report Type 1 - Individual Training Record</div>
            <div panel-body>
              <p class="text-sm text-muted">Individual Training Record by member in team(s) and record's date</p>

              <div class="row pad-top">
                <div class="col">
                  <input type="date" name="from" class="form-control"
                    [daterange]="'from'" [date-ref]="toDate"
                    [(ngModel)]="from"
                    #fromDate="ngModel"
                  >
                </div>
                  -
                <div class="col">
                  <input type="date" name="to" class="form-control"
                    [daterange]="'to'" [date-ref]="fromDate"
                    [(ngModel)]="to"
                    #toDate="ngModel"
                  >
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type1', parseReport1Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #2 -->
          <panel id="competency-report-2" class="col">
            <div panel-heading>Report Type 2 - Actuals vs Plan</div>
            <div panel-body>
              <p class="text-sm text-muted">Expected Competency Level vs Plan by member in team(s) and competency</p>

              <div class="row pad-top">
                <div class="col">
                  <input type="date" name="from" class="form-control"
                    [daterange]="'from'" [date-ref]="toDate"
                    [(ngModel)]="from"
                    #fromDate="ngModel"
                  >
                </div>
                  -
                <div class="col">
                  <input type="date" name="to" class="form-control" required
                    [daterange]="'to'" [date-ref]="fromDate"
                    [(ngModel)]="to"
                    #toDate="ngModel"
                  >
                </div>
              </div>

              <div class="row pad-top">
                <div class="col form-group">
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems.report2"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report2')"
                    (onSelect)="selectCompetency($event, skill)"
                  >
                  </lookup-input>
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type2', parseReport2Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #3 -->

          <panel id="competency-report-3" class="col">
            <div panel-heading>Report Type 3 - Actuals vs Expectations</div>
            <div panel-body>
              <p class="text-sm text-muted">Expected Competency Level vs Actual by member in team(s) and by Competency</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems.report3"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report3')"
                    (onSelect)="selectCompetency($event, skill)"
                  >
                  </lookup-input>
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type3', parseReport3Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #4 -->
          <panel id="competency-report-4" class="col">
            <div panel-heading>Report Type 4 - Competency Comparation between Users</div>
            <div panel-body>
              <p class="text-sm text-muted">Competencies comparation between users by job description.</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <lookup-input
                    [placeholder]="'Enter job code or job title'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupJobItems"
                    [autoClose]="false"
                    (onLookup)="lookupJobs($event)"
                    (onSelect)="selectJob($event, job)"
                  >
                  </lookup-input>
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type4', parseReport4Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

        </div>
      </div>
    </form>

  </div>
</full-page>

<modal #selectGroupModal
  template-id="select-groups"
  size="xlarge"
  [fade]="true"
>
  <div modal-title>
    <h5>{{ 'Select Group(s)' | translate }}</h5>
  </div>
  <div modal-body>
    <select-groups [(selected)]="selectedUsers"></select-groups>
  </div>
</modal>