import {
  Component,
  Input,
  Output, EventEmitter,
  OnChanges, SimpleChanges
} from '@angular/core';

import {
  config, object_t,
  ServerService,
  SessionService
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';

import { ExamChoice } from 'src/app/common/types';

import {
  CourseObjective, TrainingCourse, TrainingObjective,
  ExamAnswer, ExamAnswerSheet, ExamTimerMode, ExamScoring, TrainingExamQuestion
} from '../../types';

import {
  TrainingService
} from '../../training.service';

// note:  based on original code from following commit
// https://git.pinacono.com/siampiwat/clients/tree/27bf71e8889f4d2475ab51a01b6e5c7a27339a32/src/pages/training

@Component({
  selector: 'exam',
  templateUrl: 'exam.html',
  styleUrls: [ 'exam.scss' ]
})
export class ExamComponent implements OnChanges {
  @Input('course') course!: TrainingCourse;
  @Input('objective') objective: CourseObjective|null = null;
  @Output('saved') onSaved = new EventEmitter<number>();

  public answer_sheet: ExamAnswerSheet|null = null;
  protected timer: number|null = null;
  protected timer_paused: boolean = false;

  public examTimerMode = ExamTimerMode;

  public exam_elapsed: number = 0;
  public exam_remaining: number = 0;
  public scoring: ExamScoring;

  public current_question: number = 0;
  public new_exam: TrainingExamQuestion[] = [];
  public new_answer_sheet: ExamAnswer[] = [];

  // -- lifecycle
  public constructor(
    protected ui: UIService,
    protected server: ServerService,
    protected session: SessionService,
    protected api: TrainingService
  ) {
    this.scoring = {
      correct_answer: 1,
      incorrect_answer: 0,
      no_answer: 0,
      timer_mode: ExamTimerMode.NO_TIMER,
      time_limit: 0,
      time_penalty: 0
    };
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['course'] && !! this.course) {
      this.server.request('training.exams.create', null, {
        doc_id: this.course.id,
        doc_type: 'training_course'
      })
      .then( (res: object_t[])  => {

        this.new_answer_sheet = [];
        this.new_exam = res.map( (q: object_t) => {
          this.new_answer_sheet.push( this.api.createExamAnswer({
            question_id: q['id'],
            choices: q['choices']
          }));
          return this.api.createTrainingExamQuestion(q);
        });

        this.scoring = Object.assign({
          correct_answer: 1,
          incorrect_answer: 0,
          no_answer: 0,
          timer_mode: ExamTimerMode.NO_TIMER,
          time_limit: 0,
          time_penalty: 0
        }, (this.course!.attr && this.course.attr['scoring']) || null);

        this.current_question = 0;
        this.exam_elapsed = 0;
        this.exam_remaining = this.scoring.timer_mode == ExamTimerMode.TIME_LIMIT ? ( this.scoring.time_limit * 60 ): 0;
        this.timer = window.setInterval( () => {

          if ( this.timer_paused ) {
            return;
          }

          this.exam_elapsed++;
          if ( this.scoring.timer_mode == ExamTimerMode.TIME_LIMIT ) {
            this.exam_remaining--;

            if ( this.exam_remaining == 0 ) {
              this.timeLimitExceed();
            }
          }
        }, 1000);
      });
    }
  }

  // -- internal
  protected submit() {
    if ( this.timer ) {
      clearInterval(this.timer);
      this.timer = null;
    }

    this.server.request('training.exams.save', null, {
      doc_type: 'training_course',
      doc_id: this.course.id,
      candidate_id: this.session.currentUser!.id,
      time_spent: this.exam_elapsed,
      answers: this.new_answer_sheet
    })
    .then( (res: ExamAnswerSheet) => {
      if ( config('client.exam.report_exam_result', false) ) {
        let result = this.examResult(res.score, res.max_score, res.objective as CourseObjective);
        this.ui.alert('Result: {{ result }}', { result: result }, 'Result')
        .then( () => {
          this.onSaved.emit(( res.score/res.max_score ) * 100);
        });
      }
      else {
        this.onSaved.emit(( res.score/res.max_score ) * 100);
      }
    });
  }

  protected timeLimitExceed() {
    if ( this.timer ) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.ui.alert('Time limit exceed. Your examination has been automatically submitted.');
    this.submit();
  }

  // -- template API

  public examResult(score: number, max_score: number, objective: CourseObjective ): 'passed'|'failed'|'n/a' {
    objective = objective || this.objective || this.course.objectives.find( o => o.min_score > 0 );
    return objective && ( objective.min_score <= (score/max_score) ? 'passed' : 'failed' ) || 'n/a';
  }

  public is_correct(choices: ExamChoice[], selected: number) {
    let corrects: number[] = choices.filter( (c: ExamChoice) => { return c.is_correct; } ).map( (c:ExamChoice) => { return c.id; });
    return corrects.indexOf(selected) >= 0;
  }

  public prevQuestion() {
    this.current_question = Math.max(this.current_question - 1, 0);
  }

  public nextQuestion(max: number) {
    this.current_question = Math.min(this.current_question + 1, max);
  }

  public saveExam() {
    this.ui.confirm('You have answered {{ answers }} of {{ questions }} questions. Submit the answer sheet now?', {
        answers: this.new_answer_sheet.filter( (a: ExamAnswer) => !!a.answer.id ).length,
        questions: this.new_answer_sheet.length
      },
      () => {
        this.submit()
      }
    )
  }

  // -- external API

  public pause() {
    this.timer_paused = true;
  }

  public is_paused() {
    return this.timer_paused;
  }

  public resume() {
    this.timer_paused = false;
  }
}