<panel>
  <div panel-body>

    <label class="control-label">{{ 'Question' | translate }}:</label>
    <div class="question">
      <div class="media">
        <div class="media-left">
          <attachments #questionMedia
            [(files)]="question.attachments!"
            [master-content-type]="'exam/questions'"
            [master-id]="question.id"
            [defer-key]="question.attachment_defer_key"
            (change)="onQuestionChange()"
          ></attachments>
        </div>
        <div class="media-body">
          <!-- question -->
          <textarea
            autosize
            class="textarea form-control"
            [rows]="6"
            name="question"
            placeholder="{{ 'Enter question here' | translate }}..."
            (change)="onQuestionChange()"
            [(ngModel)]="question.content"
          ></textarea>
          <i class="sign fa-5x text-warning fa-solid fa-question"></i>
        </div>
      </div>
    </div>

    <!-- answers -->
    <label class="control-label">{{ 'Answers' | translate }}:</label>
    <div class="answers entry">
      <!-- existing answers -->
      <div *ngFor="let choice of question.choices; let i = index" class="media">
        <div class="media-left">
          <attachments #choiceMedia
            [(files)]="choice.attachments!"
            [max-files]="1"
            [master-content-type]="'exam/choices'"
            [master-id]="choice.id"
            [defer-key]="choice.attachment_defer_key"
            (change)="onQuestionChange()"
          ></attachments>
        </div>
        <div class="media-body">
          <textarea
            autosize
            class="textarea form-control"
            [rows]="5"
            name="answer-{{ i }}-content"
            placeholder="{{ 'Enter choice content here' | translate }}..."
            [(ngModel)]="choice.content"
            (change)="onQuestionChange()"
          ></textarea>
          <i *ngIf="choice.is_correct" class="sign fa-5x text-success fa-solid fa-check"></i>
          <i *ngIf="! choice.is_correct" class="sign fa-5x text-danger fa-solid fa-times"></i>
          <div class="options form-check">
            <input id="answer-{{ i }}-correct" class="form-check-input" type="checkbox" name="answer-{{ i }}-correct" [attr.checked]="choice.is_correct" [(ngModel)]="choice.is_correct">
            <label for="answer-{{ i }}-correct" class="for-check-label">{{ 'This is correct answer' | translate }}</label>
            <button type="button" class="btn btn-labeled btn-warning" (click)="remove(i)"><i class="btn-label fa fa-regular fa-plus"></i>{{ 'Remove' | translate }}</button>
          </div>
        </div>
      </div>
      <div *ngIf="question.choices.length == 0" class="text-sm text-thin text-italic">
        - {{ 'No answer available. Please add one.' | translate }} -
      </div>
    </div>

    <!-- new answer -->
    <label class="control-label">{{ 'New Answer' | translate }}:</label>
    <div class="new-answer entry">
      <div class="media">
        <div class="media-left">
          <!--
          <div id="upload-not-available">
            <img class="img-responsive" src="assets/imgs/1x1.png">
            <div>{{ 'Please add this choice before upload image.' | translate }}</div>
          </div>
          -->
          <attachments #newChoiceMedia
            [(files)]="newChoice.attachments!"
            [max-files]="1"
            [master-content-type]="'exam/choices'"
            [defer-key]="newChoice.attachment_defer_key"
          ></attachments>
        </div>
        <div class="media-body">
          <textarea
            autosize
            class="textarea form-control"
            [rows]="5"
            name="new-answer"
            placeholder="{{ 'Enter choice content here' | translate }}..."
            [(ngModel)]="newChoice.content"
          ></textarea>
          <i *ngIf="newChoice.is_correct" class="sign fa-5x text-success fa-solid fa-check"></i>
          <i *ngIf="! newChoice.is_correct" class="sign fa-5x text-danger fa-solid fa-times"></i>
          <div class="options form-check">
            <input id="new-answer-correct" class="form-check-input" type="checkbox" name="new-answer-correct" [attr.checked]="newChoice.is_correct" [(ngModel)]="newChoice.is_correct">
            <label for="new-answer-correct" class="form-check-label">{{ 'This is correct answer' | translate }}</label>
            <button type="button" class="btn btn-labeled btn-primary" (click)="add()"><i class="btn-label fa fa-regular fa-plus"></i>{{ 'Add' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div panel-footer>
    <div class="text-right">
      <button type="button" class="btn btn-labeled btn-danger" (click)="cancel()"><i class="btn-label fa fas fa-times"></i>{{ 'Cancel' | translate }}</button>
      <button type="button" class="btn btn-labeled btn-success" (click)="save()"><i class="btn-label fa far fa-save"></i>{{ 'Save' | translate }}</button>
    </div>
  </div>
</panel>
