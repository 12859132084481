/**
 * routes for 'spw' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// other pages
import { BindingsComponent } from './bindings/bindings.component';
import { CampaignComponent } from 'src/app/pages/spw/campaign/campaign.component';
import { ContactComponent } from 'src/app/pages/spw/contact/contact.component';
import { FaqComponent } from 'src/app/pages/spw/faq/faq.component';
import { MaximoComponent } from 'src/app/pages/spw/maximo/maximo.component';
import { PrivacyComponent } from 'src/app/pages/spw/privacy/privacy.component';

// -- configurations

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  // -- app's page

  contact: {
    path: 'contact',
    component: ContactComponent
  },

  faq: {
    path: 'faq',
    component: FaqComponent
  },

  privacy: {
    path: 'privacy',
    component: PrivacyComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  campaign: {
    path: 'campaign',
    component: CampaignComponent,
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

  maximo_redirect: {
    path: 'maximo',
    component: MaximoComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          and: [
            { isLoggedIn: true },
            { hasMaximoUser: true }
          ]
        }
      }
    }
  },

  maximo_proxy: {
    path: 'maximo/:target',
    component: MaximoComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          and: [
            { isLoggedIn: true },
            { hasMaximoUser: true }
          ]
        }
      }
    }
  },

  bindings: {
    path: 'bindings/:code',
    component: BindingsComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          and: [
            { isLoggedIn: true }
          ]
        }
      }
    }
  }
}