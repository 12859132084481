<minimal-page>
  <div class="cls-content-sm panel">
    <div class="panel-body">
      <h1 class="h3">{{ 'Change password' | translate }}</h1>
      <p class="pad-btm">{{'Your current password has been expired. Please change your password' | translate}}.</p>
      <form #f="ngForm" autocomplete="off">
        <div class="form-group">
          <input type="password" name="password"
            class="form-control" placeholder="{{ 'New password' | translate }}" autocomplete="new-password"
            required
            confirmPassword="confirm"
            (keyup)="validate($event)"
            [(ngModel)]="new_passwd"
            #input_new_password="ngModel"
          >
          <div *ngIf="pwd_suggestions.length" id="password-suggestions" class="alert alert-danger">
            <div class="text-sm text-thin" *ngIf="input_new_password.errors && input_new_password.errors['required']">{{ 'New password is required' | translate }}</div>
            <div *ngFor="let s of pwd_suggestions" class="text-sm text-thin suggestion"><i class="fa-solid fa-times fa-fw"></i> {{ s  }}</div>
          </div>
        </div>
        <div class="form-group">
          <input type="password" name="confirm"
            required
            class="form-control" placeholder="{{ 'New password again' | translate }}"
            [(ngModel)]="cnf_passwd"
            #input_cnf_password="ngModel"
          >
          <div class="alert alert-danger" *ngIf="input_new_password.invalid && (input_cnf_password.dirty || input_cnf_password.touched)">
            <div class="text-sm text-thin" *ngIf="input_cnf_password.errors && input_cnf_password.errors['required']">{{ 'Password confirmation is required' | translate }}</div>
            <div class="text-sm text-thin" *ngIf="input_new_password.errors && input_new_password.errors['confirmPassword']">{{ 'Password confirmation is mismatched' | translate }}</div>
          </div>
        </div>
        <div class="form-group text-right">
          <button class="btn btn-info btn-lg btn-block" type="submit" [disabled]="! f.form.valid || ! new_passwd || !! pwd_suggestions.length" (click)="update()">{{ 'Change Password' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</minimal-page>