import { object_t } from '@pinacono/common';
import {
  Component
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  LookupEvent,
  LookupItem,
  UIService
} from '@pinacono/ui';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import {
  Candidate,
  JobDescription,
  CompetencyProfile,
  JobCompetency
} from '../types';

import { TrainingService } from '../training.service';

@Component({
  selector: 'training-gaps-simulator-page',
  templateUrl: 'simulator.html',
  styleUrls: [ 'simulator.scss' ]
})
export class TrainingGapsSimulatorPage extends BasePageComponent {

  public job_description: JobDescription|null = null;
  public candidate: Candidate|null = null;

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public server: ServerService,
    public api: TrainingService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle
  public override loadData(): Promise<void> {
    return new Promise<void>( () => {
      this.server.get('training.job.self')
      .then( (res: object_t) => {
        this.job_description = this.api.createJobDescription(res);
        this.loadProfile();
      });
    });
  }

  protected async loadProfile() {
    if ( ! this.job_description ) return Promise.resolve();

    const profiles: CompetencyProfile[] = [];
    const res: object_t[] = await this.server.get('training.profile.self');
    this.job_description!.competencies.forEach( (c: JobCompetency) => {
      let p: object_t|undefined = res.find( (p) => p['competency_id'] == c.competency.id );
      if ( ! p ) {
        p = {
          competency_id: c.competency.id,
          competency: c.competency,
          user_id: this.session.currentUser!.id,
          user: this.session.currentUser
        };
      }
      p['expectation'] = c.expectation;
      profiles.push(this.api.createCompetencyProfile(p));
    });
    this.candidate = {
      staff: this.session.currentUser!,
      profiles: profiles
    };
  }

  // -- template API

  /**
   * lookup for a form
   */
  public jobs_list:LookupItem<JobDescription>[] = [];
  public job_title: string = '';

  public async lookupJobTitle(keyword: string) {
    this.jobs_list = [];
    const res: object_t[] = await this.server.lookup('training/jobs', { name: keyword })
    res.map( (j: object_t) => this.api.createJobDescription(j) ).forEach( (j: JobDescription) => {
      this.jobs_list.push({
        label: j.title,
        value: j
      });
    });
    this.jobs_list = this.jobs_list.slice(); // trig the ngOnChange ...
  }

  /**
   * select a form as the master of the document
   */
  public async selectJobTitle(item: LookupEvent<JobDescription>) {
    if ( item.value.value === null || ! item.value.value.id ) {
      return;
    }
    this.job_title = item.value.value.title;
    const res: object_t = await this.server.show('/training/jobs', item.value.value.id);
    this.job_description = this.api.createJobDescription(res);
    this.loadProfile();
  }

  public onCompetencyClick(competency_id: number) {
    //this.nav.push(TrainingCompetencyPage, { id: competency_id } );
    this.nav.push(['/training/competencies/view', competency_id]);
  }

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }
}