import {
  Component,
  OnInit,
  ViewChild,
  Renderer2
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  NavigationService,
  object_t,
  PaginatedResults,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  Column,
  Filters,
  FieldType,
  SlickGrid
} from '@slickgrid-universal/common'

import {
  ModalComponent,
  UIService
} from "@pinacono/ui";

import {
  GraphqlPaginatedResult
} from "@slickgrid-universal/graphql";

import {
  AngularGridInstance,
  AngularUtilService,
  GridOption,
  //GridService
} from "angular-slickgrid";

import {
  ComponentFormatter,
  ExtendedFormatters,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from "@pinacono/slickgrid-extension";

import { BasePageComponent } from "src/app/classes/base-page.component";

import {
  TrainingBatch,
  TrainingBatchStatus,
  TrainingCourse,
  TrainingCourseRequest,
  TrainingCourseRequestStatus
} from '../types';

import { TrainingService } from '../training.service';

@Component({
  selector: 'training-requests-list-page',
  templateUrl: 'list.html',
  styleUrls: [ 'list.scss' ],
  providers: [ AngularUtilService ]
})
export class TrainingRequestsListPage extends BasePageComponent implements OnInit {

  //@ViewChild('grid') grid: AngularSlickgridComponent

  // grid
  protected gridInstance: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // models
  public requests: TrainingCourseRequest[] = [];
  public selectedRequests: TrainingCourseRequest[] = [];
  public course: TrainingCourse|null = null;

  // modal
  @ViewChild('myBatchesList') batchesListDialog!: ModalComponent;
  public modalTargetBatches: TrainingBatch[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: ServerService,
    protected graphServer: GraphQLServerService,
    protected ngUtilService: AngularUtilService,
    //protected gridService: GridService,
    protected renderer: Renderer2
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle
  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  protected silent: boolean = true;
  public override loadData(): Promise<void> {
    if ( !! this.gridInstance ) {
      this.selectedRequests = [];
      this.silent = false;
      this.gridInstance.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.graphServer.silent() : this.graphServer;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.requests = re.data['trainingRequests'].nodes.map( (r: object_t) => this.api.createCourseRequest(r) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.log('GraphQL Error:', error);
        }
      );
    });
  }

  protected initGrid() {
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'trainingRequests',
          //filteringOptions: [
          persistenceFilteringOptions: [
            { field: 'trainer_id', operator: 'EQ', value: this.session.currentUser!.id.toString() }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },
      enableSorting: true,
      rowHeight: 80,
      //autoHeight: true,
      forceFitColumns: true,
      enableAutoResize: true,
      autoResize: {
        calculateAvailableSizeBy: 'container',
        minHeight: 600
      },
      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
      /*
      rowSelectionOptions: {
        selectActiveRow: false // allow multiple rows
      }
      */
    };

    this.columnDefinitions = [
      {
        id: 'id', name: 'Request ID',
        field: 'id',
        type: FieldType.number,
        cssClass: 'text-right', width: 15,
        sortable: true,
        filterable: true
      },

      {
        id: 'requester', name: 'Requester',
        field: 'user',
        fields: ['user.fullname', 'user.email', 'user.avatar.thumb_url', 'user.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 120 ,
        sortable: true,
        filterable: true,
        formatter: ComponentFormatter,
        params: {
          //component: GridUserComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'user'
          }
        }
      },

      {
        id: 'course', name: 'Course',
        field: 'course',
        fields: ['course.id', 'course.name', 'course.code'],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 200 ,
        sortable: true,
        filterable: true,
        formatter: function(row: number, col: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          return `<span title="${dataContext.course.code}: ${dataContext.course.name}">${dataContext.course.name}</span>`;
        }.bind(this)
      },

      {
        id: 'batch_name', name: 'Batch Name',
        field: 'batch',
        fields: [ 'batch.id', 'batch.name', 'batch.status' ],
        type: FieldType.string,
        cssClass: 'text-left',
        sortable: true,
        filterable: true,
        formatter: function(row: number, coll: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          if ( ! dataContext.batch ) {
            return `<span class="text-italic text-thin" title="n/a">- no active batch -</span>`;
          }
          return `<span title="${dataContext.batch.name}">${dataContext.batch.name} (<i class="batch-status">${dataContext.batch.status}</i>)</span>`;
        }.bind(this)
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-left', width: 50 ,
        sortable: true,
        filterable: true,
        formatter: function(row: number, col: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
            const labels: { [name: string] : {label: string, css: string} } = {
              requested:  { label: 'Requested', css: 'info'    },
              accepted:   { label: 'Accepted',  css: 'success' },
              postponed:  { label: 'Postponed', css: 'mint'    },
              cancelled:  { label: 'Cancelled', css: 'warning' },
              closed:     { label: 'Closed',    css: 'dark'    }
            }
            let status = dataContext.status.toLowerCase();
            return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
          }.bind(this),
        filter: {
          model: Filters.multipleSelect,
          collection: [
            { value: TrainingCourseRequestStatus.REQUESTED, label: 'Requested',   css: 'info'    }, // user is requesting to attend the course and waiting for approval
            { value: TrainingCourseRequestStatus.ACCEPTED,  label: 'Accepted',    css: 'success' }, // user's requested has been approved
            { value: TrainingCourseRequestStatus.POSTPONED, label: 'Postponed',   css: 'mint'    }, // trainer accepted the request but then postponed the request, return to waiting state
            { value: TrainingCourseRequestStatus.CANCELLED, label: 'Cancelled',   css: 'warning' }, // user cancel the request
            { value: TrainingCourseRequestStatus.CLOSED,    label: 'Closed',      css: 'dark'    }  // the request was successfully fullfilled
          ]
        }
      },

      {
        id: 'updated_at', name: 'Last Update',
        field: 'updated_at',
        fields: [ 'updated_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 80,
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundDate
        },
        asyncPostRender: (cellNode: JQuery, row: number, dataContext: object, colDef: Column) => {
          let request = this.requests[row];
          let rowNode = this.gridInstance?.slickGrid.getCellNode(row, 0);
          //let rowNode = this.gridService.getRowNodeFromEventArgument({ row: row, cell: 0, grid: this.grid.grid});
          if ( ! rowNode ) return;
          this.updateRowClass(request.id!, rowNode);
        }
      }
    ];
  }

  // -- grid interfaces
  public onGridReady(event: Event) {
    this.gridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    let request = this.requests[(event as CustomEvent).detail.args['row']];

    if ( request.status != TrainingCourseRequestStatus.POSTPONED && request.status != TrainingCourseRequestStatus.REQUESTED ) {
      //this.nav.push(TrainingBatchPage, { id: request.batch_id });
      this.nav.push([ '/training/batches/view', request.batch_id ])
      return;
    }

    let i = this.selectedRequests.findIndex( r => r.id === request.id );
    if ( i >= 0 ) {
      // remove from list
      this.selectedRequests.splice(i, 1);
      /*
      if ( this.selectedRequests.length == 0 ) {
        this.course = null;
        this.grid.activateFilterOnColumn('course');
        this.grid.activateFilterOnColumn('status');
      }
      */
    }
    else {
      if ( ! this.course ) {
        this.course = request.course!;
        /*
        this.grid.activateSortOnColumn('id');
        this.grid.activateFilterOnColumn('course', [this.course.code]);
        this.grid.activateFilterOnColumn('status', [request.status])
        */
      }
      this.selectedRequests.push(request);
    }

    //this.updateRowClass(request.id, this.gridService.getRowNodeFromEventArgument(event.detail.args));
    let row = (event as CustomEvent).detail.args.row;
    let rowNode = this.gridInstance?.slickGrid.getCellNode(row, 0);
    if ( ! rowNode ) return;
    this.updateRowClass(request.id!, rowNode);
  }

  protected updateRowClass(id: number, row: Element) {
    if ( this.selectedRequests.findIndex( r => r.id === id ) >= 0 ) {
      this.renderer.addClass(row, 'selected');
    }
    else {
      this.renderer.removeClass(row, 'selected');
    }
  }

  public addRequestsToBatch(batch: TrainingBatch) {
    this.ui.confirm('Add {{ fullname }} to batch {{ batch_name }}?', {
      fullname: this.selectedRequests.map( r => r.user && r.user.fullname || null ).join(', '),
      batch_name: batch.name
    }, () => {
      let promises: Promise<object>[] = [];

      for ( let r of this.selectedRequests ) {
        promises.push(
          this.server.request('training.batch.attendee.add', null, {
            request_id: r.id,
            batch_id: batch.id
          })
        );
      }

      Promise.all(promises)
      .then( (req: object) => {
        this.refresh();
        this.batchesListDialog.hide();
      });
    });
  }

  public removeRequestFromBatch(request: TrainingCourseRequest) {
    if ( ! request.batch ) {
      return; // should not happen
    }
    this.ui.confirm('Remove {{ fullname }} from batch {{ batch_name }}?', {
      fullname: request.user!.fullname,
      batch_name: request.batch.name
    }, () => {
      this.server.request('training.batch.attendee.remove', null, {
        request_id: request.id,
        batch_id: request.batch!.id,
      })
      .then( (req: object) => {
        this.refresh();
      });
    });
  }

  // -- template API
  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public newBatch() {
    this.batchesListDialog.hide();

    if ( this.selectedRequests.length == 0 ) {
      this.ui.confirm("No request selected. Do you wish to create a new empty batch?", undefined, () => {
        //this.nav.push(TrainingBatchPage, { requests: this.selectedRequests });
        // @TODO - confirm, if route commands format is correct?
        this.nav.push({ commands: [ '/training/batches', { requests: this.selectedRequests } ] });
      });
    }
    else {
      //this.nav.push(TrainingBatchPage, { requests: this.selectedRequests });
      // @TODO - confirm, if route commands format is correct?
      this.nav.push({ commands: [ '/training/batches', { requests: this.selectedRequests } ] });
    }
  }

  public addSelectedToBatch() {
    if ( this.selectedRequests.length == 0 ) {
      this.ui.alert("Please select some requests");
      return;
    }

    if ( this.selectedRequests.filter( r => r.status != TrainingCourseRequestStatus.REQUESTED && r.status != TrainingCourseRequestStatus.POSTPONED ).length > 0 ) {
      this.ui.alert("Some selected requests were already progress and cannot add to batch. Please check!");
      return;
    }

    // query for available batches
    this.server.index('training/batches', {
      trainer_id: this.session.currentUser!.id,
      course_id: this.selectedRequests[0].course!.id,
      status: TrainingBatchStatus.OPEN
    },
    {
      perpage: -1
    })
    .then( (res: PaginatedResults<object>) => {
      this.modalTargetBatches = res.data.map( b => this.api.createTrainingBatch(b) );
      this.batchesListDialog.show();
    });
  }
}