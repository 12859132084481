<full-page [title]="project.title">
  <div page-content>
    <panel class="proj-lib">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ project.title_prefix }}
            {{ project.title || '&lt;New Project&gt;' }}
            {{ project.title_suffix }}
          </span>
          <span class="text-thin small"> - {{ project.documentable.doc_code }}</span>
        </h4>

        <!-- section 1: Project Registration  -->
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Project Registration' | translate }}
              <collapse-button [target]="'proj-registration'" [collapsed]="false"></collapse-button>
            </h4>
          </div>

          <div class="panel-body" id="proj-registration">

            <div class="row">
              <div class="col-9">
                <!-- project code and status -->
                <div class="pad-top row">
                  <!-- project code display -->
                  <div class="col">
                    <label class="form-label text-bold">{{ 'Project Code' | translate }}:</label>
                    <span class="text-thin" #doccode>{{ project.documentable.doc_code }}</span>
                    <copy-button
                      [src]="doccode"
                      (copied)="onCodeCopied($event)"
                    ></copy-button>
                  </div>

                  <div class="col text-right">
                    <label class="form-label text-bold">{{ 'Project Status' | translate }}</label>
                    <span class="badge badge-info text-thin">{{ project.project_status }}</span>
                    <!--
                    <span *ngIf="!project.is_handing_over" class="badge badge-info text-thin">{{ project.project_status }}</span>
                    <span *ngIf="project.is_handing_over" class="badge badge-mint text-thin">{{ 'Handing Over' | translate }}</span>
                    -->
                  </div>

                </div>
                <!-- end of project code and status -->

                <!-- project title -->
                <div class="pad-top row" id="doc-title">
                  <!-- project title -->
                  <div class="col">
                    <label class="form-label text-bold">{{ 'Project Title' | translate }}:</label>
                    {{ project.title_prefix }} {{ project.title }} {{ project.title_suffix}}
                  </div>
                </div>
                <!-- end of project title -->

                <!-- project domain -->
                <!--
                <div class="pad-top row">
                  <div class="col">
                    <label class="form-label text-bold">{{ 'Domain' | translate }}:</label>
                    {{ doc_domain }}
                  </div>
                </div>
              -->
                <!-- end of project domain -->

                <!-- project owner -->
                <div class="pad-top row">
                  <div class="col">
                    <label class="form-label text-bold">{{ 'Registered by' | translate }}:</label>
                    {{ project.uploader?.fullname || 'unknown' }} ({{ project.uploader?.email || 'n/a' }})
                  </div>
                </div>
                <!-- end of project owner -->

              </div>

              <div class="col-3 pad-no" id="doc-master-drawing">
                <div *ngIf="project.masters.length == 0" id="no-master">
                  - No master drawing avaialble -
                </div>

                <div *ngIf="project.masters.length > 0" id="project-masters" class="carousel slide">
                  <div class="carousel-inner">
                    <div *ngFor="let master of project.masters; let i = index"
                      id="master-{{i}}"
                      class="carousel-item"
                      [ngClass]="{ 'active': i == 0 }"
                      (click)="selectMaster(master)"
                    >
                      <iip-viewer title="{{ 'Click to expand' | translate}}"
                        [src]="master.iip_name"
                        [path]="iip_base_path"
                        [showNavigation]="false"
                        [showCoords]="false"
                        [showScale]="false"
                        [drawingTools]="false"
                        [polygons]="master.pivot_polygons"
                      ></iip-viewer>
                    </div>

                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#project-masters" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#project-masters" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- end of section 1: Project Registration -->

        <!-- section 2: Project Information -->
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'project Information' | translate }}
              <collapse-button [target]="'project-info'" [collapsed]="false"></collapse-button>
            </h4>
          </div>

          <div class="panel-body" id="project-info">

            <!-- project type, related buildings and schedule -->
            <div class="pad-top row">
              <div class="col">
                <label class="form-label text-bold">{{ 'Project Type' | translate }}</label>
                <div>{{ project.project_type }}</div>
              </div>
              <div class="col">
                <label class="form-label text-bold">{{ 'Related Building' | translate }}:</label>
                <div>
                  <span *ngFor="let loc of project.locations" class="delimited">{{ loc }}</span>
                </div>
              </div>
              <div class="col">
                <label class="form-label text-bold">{{ 'Project Schedule' | translate }}</label>
                <div id="proj-schedule">
                  {{ project.schedule_start | date }} - {{ project.schedule_finish | date }}
                </div>
              </div>
            </div>
            <!-- end of project type, related buildings, and schedule -->

            <!-- project schedule -->
            <div class="pad-top row">
              <div class="col">
                {{ project.project_scope }}
              </div>
            </div>
            <!-- end of project scope -->

          </div>
        </div>
        <!-- end of section 2: Project Inforamtion -->

        <!-- section 3: Project Organization -->
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Project Organization' | translate }}
              <collapse-button [target]="'proj-organization'" [collapsed]="false"></collapse-button>
            </h4>
          </div>

          <div class="panel-body" id="proj-organization">
            <ng-container *ngFor="let team of teams">
            <h5 class="pt-3 team-name"> <collapse-button [target]="team.name | string_convert:utils.string_utils.snakeCase" [collapsed]="true"></collapse-button> {{ team.name }}</h5>
            <div class="project-team" id="{{ team.name | string_convert:utils.string_utils.snakeCase }}">
              <div class="row">
                <div class="form-group col-6">
                  <label class="form-label text-bold">{{ 'Leader' | translate }}</label>
                  <user-card *ngFor="let member of api.getTeamMembers(project.internal_project_roles, team.name, 'leader')" [user]="member.user!"></user-card>
                  <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'leader').length == 0" class="role-not-assigned">
                    - No any user has been assigned to this role -
                  </div>
                </div>
                <div class="form-group col-6">
                  <label class="form-label text-bold">{{ 'Coordinator' | translate }}</label>
                  <user-card *ngFor="let member of api.getTeamMembers(project.internal_project_roles, team.name, 'coordinator')" [user]="member.user!"></user-card>
                  <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'coordinator').length == 0" class="role-not-assigned">
                    - No any user has been assigned to this role -
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="form-label text-bold">{{ 'Members' | translate }}</label>
                <div *ngFor="let member of api.getTeamMembers(project.internal_project_roles, team.name, 'member')" class="col-6">
                  <user-card [user]="member.user!"></user-card>
                </div>
                <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'member').length == 0" class="role-not-assigned">
                  - No any user has been assigned to this role -
                </div>
              </div>
            </div>
            </ng-container>

            <!-- External -->
            <h5 class="pt-3"> <collapse-button [target]="'external'" [collapsed]="true"></collapse-button>{{ 'Stakeholder Relevant' | translate }}</h5>
            <div class="project-team" id="external">

              <div class="row">
                <label class="form-label text-bold">{{ 'ภายนอกสายงาน' | translate }}</label>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ 'หน่วยงาน' | translate }}</th>
                      <th>{{ 'ชื่อ-สกุล' | translate }}</th>
                      <th>{{ 'โทร.' | translate }}</th>
                      <th>{{ 'E-Mail' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let role of project.external_project_roles">
                      <td>{{ role.contact.department }}</td>
                      <td>{{ role.contact.fullname }}</td>
                      <td>{{ role.contact.tel }}</td>
                      <td>{{ role.contact.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <label class="form-label text-bold">{{ 'ภายนอกบริษัทฯ' | translate }}</label>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ 'Company Role' | translate }}</th>
                      <th>{{ 'Company' | translate }}</th>
                      <th>{{ 'ชื่อ-สกุล' | translate }}</th>
                      <th>{{ 'โทร.' | translate }}</th>
                      <th>{{ 'E-Mail' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let role of project.vendor_project_roles; let i = index">
                      <td>{{ role.project_role }}</td>
                      <td>{{ role.contact.company }}</td>
                      <td>{{ role.contact.fullname }}</td>
                      <td>{{ role.contact.tel }}</td>
                      <td>{{ role.contact.email }}</td>
                    </tr>
                  </tbody>

                </table>

              </div>
            </div>
          </div>
        </div>
        <!-- end of section 3: Project Organization -->

        <!-- section 4: Distribution List -->
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Distribution List' | translate }}
              <collapse-button [target]="'distribution-list'" [collapsed]="false"></collapse-button>
            </h4>
            <!--
            <div class="panel-control">
              <button type="button" *ngIf="api.can('save', 'distribution_list', project)" class="btn btn-icon btn-info" title="{{ 'Add new list' | translate }}" (click)="addNewDistributionList()">
                <i class="pli-add"></i>
              </button>
            </div>
            -->
          </div>
          <div class="pabel-body" id="distribution-list">
            <div class="mar-all">
              <extended-slickgrid *ngIf="!! distributionListGridOptions" gridId="distribution-list-table"
                class="row-selectable"
                [columnDefinitions]="distributionListColumnDefinitions"
                [gridOptions]="distributionListGridOptions"
                (onAngularGridCreated)="onDistributionListGridReady($event)"
                (onClick)="onDistributionListGridSelectRow($event)"
              >
              </extended-slickgrid>
            </div>
          </div>
        </div>
        <!-- end of section 4: Distribution List -->

        <!-- section 5: Project Documents -->
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Project Documents' | translate }}
              <collapse-button [target]="'proj-documents'" [collapsed]="false"></collapse-button>
            </h4>
            <div class="panel-control">
              <button *ngIf="api.can('save', 'document', project)" type="button" class="btn btn-icon btn-info" title="{{ 'Add new document' | translate }}" (click)="addNewProjectDocument()">
                <i class="pli-add"></i>
              </button>
            </div>
          </div>
          <div class="pabel-body" id="proj-documents">
            <div class="mar-all">
              <extended-slickgrid *ngIf="!! projectDocumentGridOptions" gridId="project-doc-table"
                class="row-selectable"
                [columnDefinitions]="projectDocumentColumnDefinitions"
                [gridOptions]="projectDocumentGridOptions"
                (onAngularGridCreated)="onProjectDocumentGridReady($event)"
                (onClick)="onProjectDocumentGridSelectRow($event)"
              >
              </extended-slickgrid>
            </div>
          </div>
        </div>
        <!-- end of section 5: Project Documents -->

        <!-- section 6: Project Drawings -->
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Project Drawings' | translate }}
              <collapse-button [target]="'proj-drawings'" [collapsed]="false"></collapse-button>
            </h4>
            <div class="panel-control">
              <button type="button" class="btn btn-icon btn-mint" title="{{ 'Generate drawings list' | translate }}" (click)="generateDrawingsList()">
                <i class="pli-file"></i>
              </button>
              <button *ngIf="api.can('save', 'drawing', project)" type="button" class="btn btn-icon btn-info" title="{{ 'Add new drawing' | translate }}" (click)="addNewProjectDrawing()">
                <i class="pli-add"></i>
              </button>
            </div>
          </div>
          <div class="pabel-body" id="proj-drawings">
            <div class="mar-all">
              <extended-slickgrid *ngIf="!! projectDrawingGridOptions" gridId="project-drawing-table"
                class="row-selectable"
                [columnDefinitions]="projectDrawingColumnDefinitions"
                [gridOptions]="projectDrawingGridOptions"
                (onAngularGridCreated)="onProjectDrawingGridReady($event)"
                (onClick)="onProjectDrawingGridSelectRow($event)"
              >
              </extended-slickgrid>
            </div>
          </div>
        </div>
        <!-- end of section 6: Project Drawings -->

        <!-- section 7: Release Bundle Template -->
        <div [ngStyle]="{ display: 'none' }" class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Release Bundle Template' | translate }}
              <collapse-button [target]="'bundle-templates'" [collapsed]="false"></collapse-button>
            </h4>
            <div class="panel-control">
              <button type="button" *ngIf="api.can('save', 'bundle_template', project)" class="btn btn-icon btn-info" title="{{ 'Add new template' | translate }}" (click)="addNewBundleTemplate()">
                <i class="pli-add"></i>
              </button>
            </div>
          </div>
          <div class="pabel-body" id="bundle-templates">
            <div class="mar-all">
              <extended-slickgrid *ngIf="!! bundleTemplatesGridOptions" gridId="bundle-template-list-table"
                class="row-selectable"
                [columnDefinitions]="bundleTemplatesColumnDefinitions"
                [gridOptions]="bundleTemplatesGridOptions"
                (onAngularGridCreated)="onBundleTemplatesGridReady($event)"
                (onClick)="onBundleTemplatesGridSelectRow($event)"
              >
              </extended-slickgrid>
            </div>
          </div>
        </div>
        <!-- end of section 7: Release Bundle Template -->

        <!-- section 8: Comments -->
        <!--
        <div class="panel panel-bordered panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              {{ 'Comments' | translate }}
              <collapse-button [target]="'proj-comments'" [collapsed]="false"></collapse-button>
            </h4>
          </div>
          <div class="panel-body" id="proj-comments">
            <comments
              [content]="project.comments"
              [content_id]="project.id || null"
              [content_type]="'project'"
              [attributes]="{ status: project.project_status }"
              [readonly]="false"
            >
            </comments>
          </div>
        </div>
        -->
        <!-- end of section 8: Comments -->

        <!-- section 9: project action buttons -->
        <div class="pad-top row">
          <div class="col text-right">

            <button *ngIf="bundle_contents.length > 0 && api.can('save', 'bundle', project)" type="button"
              class="btn btn-labeled btn-success"
              data-dismiss="modal"
              title="{{ 'Review Bundle' | translate }}"
              (click)="reviewBundle()"
            >
              <i class="btn-label pli-mail-forward"></i>{{ 'Review Bundle' | translate }} ({{ bundle_contents.length }})
            </button>

            <!--
            <button type="button"
              class="btn btn-labeled btn-pink"
              data-dismiss="modal"
              title="{{ 'Drawings List' | translate }}"
              (click)="generateDrawingsList()"
            >
              <i class="btn-label pli-eifel-tower"></i>{{ 'Drawings List' | translate }}
            </button>
            -->

            <button type="button"
              class="btn btn-labeled btn-default"
              data-dismiss="modal"
              title="{{ 'Back to previouse page' | translate }}"
              (click)="back()"
            >
              <i class="btn-label fa fa-solid fa-circle-chevron-left"></i>{{ 'Back' | translate }}
            </button>
          </div>
        </div>
        <!-- end of section 9: project action buttons -->


      </div>
    </panel>
  </div>
</full-page>

<!-- distribution list creator -->
<modal [template-id]="'distribution-list'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #distributionListDialog>
  <div modal-title>
    {{ 'Distribution List' | translate }}
    <div id="distribution-list-status" class="status-{{distributionList && distributionList.status || 'draft' }}">{{ distributionList && distributionList.status }}</div>
  </div>
  <div modal-body>
    <form *ngIf="!!distributionList" #distributionListForm="ngForm">

      <div class="row">
        <div class="col">
          <label class="form-label">{{ 'Group Name' | translate }}:</label>
          <input #title="ngModel"
            type="text"
            class="form-control"
            disabled
            name="title"
            [(ngModel)]="distributionList.title"
          >
        </div>

        <div class="col">
          <label class="form-label">{{ 'Description' | translate }}:</label>
          <input #description="ngModel"
            type="text" class="form-control" name="description"
            disabled
            [(ngModel)]="distributionList.description"
          >
        </div>
      </div>

      <div class="form-check">
        <h6 class="mt-3">{{ 'ภายในสายงาน' | translate }}</h6>
        <div class="row">
          <div *ngFor="let member of project.internal_project_roles | array_unique:indexOfInternalRole" class="form-check col-6">
            <input class="form-check-input" type="checkbox" value="" disabled [attr.checked]="indexOfInternalRole(member, distributionList.internals) < 0 ? null : 1" id="u-{{ member.user!.id }}" (change)="updateInternalDistributionList($event, member)">
            <label class="form-check-label" for="u-{{ member.user!.id }}">{{ member.user!.fullname }} ({{ member.user!.email }})</label>
          </div>
        </div>

        <h6 class="mt-3">{{ 'ภายนอกสายงาน' | translate }}</h6>
        <div class="row">
          <div *ngFor="let member of project.external_project_roles | array_unique:indexOfExternalRole" class="form-check col-6">
            <input class="form-check-input" type="checkbox" value="" disabled [attr.checked]="indexOfContact(member.contact, distributionList.contacts) < 0 ? null : 1" id="u-{{ member.contact.id }}" (change)="updateExternalDistributionList($event, member)">
            <label class="form-check-label" for="u-{{ member.contact.id }}">{{ member.contact.fullname }} ({{ member.contact.email }})</label>
          </div>
        </div>

        <h6 class="mt-3">{{ 'ภายนอกบริษัท' | translate }}</h6>
        <div class="row">
          <div *ngFor="let member of project.vendor_project_roles | array_unique:indexOfExternalRole" class="form-check col-6">
            <input class="form-check-input" type="checkbox" value="" disabled [attr.checked]="indexOfContact(member.contact, distributionList.contacts) < 0 ? null : 1" id="u-{{ member.contact.id }}" (change)="updateExternalDistributionList($event, member)">
            <label class="form-check-label" for="u-{{ member.contact.id }}">{{ member.contact.fullname }} ({{ member.contact.email }})</label>
          </div>
        </div>

      </div>

    </form>
  </div>
  <div modal-footer>
    <!--
    <button *ngIf="api.can('review', 'distribution_list', project, distributionList)" class="btn btn-labeled btn-warning" type="button" (click)="reviewDistributionList();">
      <i class="btn-label pli-eye"></i> {{ 'Review' | translate }}
    </button>
    <button *ngIf="api.can('approve', 'distribution_list', project, distributionList)" class="btn btn-labeled btn-success" type="button" (click)="approveDistributionList();">
      <i class="btn-label pli-check"></i> {{ 'Approve' | translate }}
    </button>
    <button *ngIf="api.can('save', 'distribution_list', project, distributionList)" class="btn btn-labeled btn-primary" type="button" (click)="saveDistributionList();">
      <i class="btn-label pli-save"></i> {{ 'Save' | translate }}
    </button>
    -->
    <button class="btn btn-labeled btn-default" type="button" (click)="distributionListDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- master drawing viewer -->
<modal template-id="master-drawing-viewer"
  size="fullscreen"
  [hideCloseButton]="true"
  #masterDrawingViewer
>
  <div modal-body id="iip">
    <div *ngIf="selectedMasterForShowing" class="row">

      <div class="col-3">
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="selectedMasterForShowing.title" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Building:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="selectedMasterForShowing.attr['building']" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Floor:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="selectedMasterForShowing.attr['floor']" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Polygons:</label>
          <div *ngIf="selectedMasterForShowing.pivot_polygons.length == 0" id="no-polygons">
            - No location polygons specified -
          </div>
          <ol *ngFor="let obj of selectedMasterForShowing.pivot_polygons" class="coordinates">
            <li *ngFor="let node of obj.vertices"><span>{{ node.x | number:'1.2-2' }}</span>, <span>{{ node.y | number:'1.2-2' }}</span></li>
          </ol>
        </div>
      </div>

      <div class="col-9" id="right-pane">

        <ng-container *ngIf="!! selectedMasterForShowing.id">
          <div *ngIf="! selectedMasterForShowing.iip_name" class="no-data text-muted">
            {{ 'No master drawing is selected, preview is not avaialble at the moment.' | translate }}
          </div>
          <iip-viewer *ngIf="selectedMasterForShowing.iip_name !== null"
            [src]="selectedMasterForShowing.iip_name"
            [path]="iip_base_path"
            [navButtons]="['reset', 'zoomIn', 'zoomOut']"
            [showCoords]="false"
            [showScale]="false"
            [drawingTools]="false"
            [polygons]="selectedMasterForShowing.pivot_polygons"
          ></iip-viewer>
        </ng-container>
      </div>
    </div>
  </div>

  <div modal-footer>
    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="masterDrawingViewer.hide()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
    </button>

  </div>

</modal>

<!-- file viewer -->
<modal [template-id]="'view-document'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #documentViewDialog
>
  <div modal-title *ngIf="selectedDocument !== null">
    {{ selectedDocument.prefix }} {{ selectedDocument.title }}
  </div>

  <div modal-body *ngIf="selectedDocument !== null">

    <!-- section 1: Document information-->
    <div class="panel panel-bordered panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'Document Information' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="doc-info">
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">{{ 'Document Title' | translate }}:</label>
            <div class="text-lg text-semibold">
              {{ selectedDocument.prefix }} {{ selectedDocument.title }}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <div>
              <label class="form-label text-bold">{{ 'Status' | translate }}:</label>
              <span class="text-thin">{{ selectedDocument.status }}</span>
            </div>
          </div>
          <div class="col">
            <label class="form-label text-bold">{{ 'Hard Copies' | translate }}: </label>
            <span class="text-thin"> {{ selectedDocument.attr && selectedDocument.attr['hardcopies'] || 0 }}</span>  <!-- to be replaced by document's hardcopies soon -->
          </div>
        </div>

        <!--
        <div class="row" id="approvers">
          <div class="col">
            <label class="form-label">{{ 'Reviewer' | translate }}:</label>
            <user-card *ngIf="selectedDocument.reviewer"
              [user]="selectedDocument.reviewer"
            ></user-card>
            <div *ngIf="! selectedDocument.reviewer" class="text-thin text-danger">
              - {{ 'Reviewer not assigned' | translate }} -
            </div>
          </div>
          <div class="col">
            <label class="form-label">{{ 'Approver' | translate }}:</label>
            <user-card *ngIf="selectedDocument.approver"
              [user]="selectedDocument.approver"
            ></user-card>
            <div *ngIf="! selectedDocument.approver" class="text-thin text-danger">
              - {{ 'Approver not assigned' | translate }} -
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <!-- end of section 1: Document Information -->

    <!-- section 2: Files List -->
    <div class="panel panel-bordered panel-default">

      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'File(s)' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="files-list">

        <div *ngIf="selectedDocument.revisions.length > 0" class="row">
          <div class="col-12">
            <div *ngFor="let file of selectedDocument.revisions[0].attachments" class="row">
              <download class="col-2" [file]="file" #downloader>
                <ng-template let-f="file" let-icon="icon">
                  <button class="template-btn" title="click to download {{ f.file_name}}" (click)="downloader.download()">
                    <img *ngIf="utils.mime_utils.mime_isImage(f.content_type)"
                      class=" file-thumbnail"
                      title="{{ f.caption || f.file_name }}"
                      src="{{ f.thumb_url || f.path_url || f.data_url || null }}"
                    >
                    <i *ngIf="! utils.mime_utils.mime_isImage(f.content_type)"
                      class="file-thumbnail fa-5x fa-fw {{ icon }}"
                    ></i>
                  </button>
                </ng-template>
              </download>

              <div class="col">
                <div class="text-bold file-name" title="{{ file.file_name }}">
                  {{ file.file_name }}
                </div>
                <div class="text-thin file-attr">
                  <span>{{ file.created_at | datetime }}</span>
                  <span>{{ file.file_size | memsize:'m' | number:'1.2-2' }} MB</span>
                </div>
                <div class="text-thin">
                  {{ file.caption }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedDocument.revisions.length == 0" class="text-thin text-center">
          {{ '- No downloables avaialble at the moment -' }}
        </div>
      </div>

    </div>
    <!-- end of section 2: Files List -->

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="documentViewDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- drawing viewer -->
<modal [template-id]="'view-drawing'"
  [size]="'xlarge'"
  [fade]="true"
  [hideCloseButton]="true"
  #drawingViewDialog
>
  <div modal-title *ngIf="selectedDrawing !== null">
    {{ selectedDrawing.drawing_no }}
  </div>

  <div modal-body *ngIf="selectedDrawing !== null">
    <!-- section 1: Drawing information-->
    <div class="panel panel-bordered panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'Drawing Information' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="drawing-info">

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Drawing No' | translate }}:</label>
            {{ selectedDrawing.drawing_no }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Title' | translate }}:</label>
            {{ selectedDrawing.title }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Stage' | translate }}:</label>
            {{ selectedDrawing.stage }}
          </div>
          <div class="col">
            <label class="form-label">{{ 'Status' | translate }}:</label>
            {{ selectedDrawing.status }}
          </div>
          <div class="col">
            <label class="form-label">{{ 'Hard Copies' | translate }}:</label>
            {{ selectedDrawing.attr && selectedDrawing.attr['hardcopies'] || 0 }} <!-- to be replaced by document's hardcopies soon -->
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Drawing Type' | translate }}:</label>
            {{ selectedDrawing.type }}
          </div>
        </div>

        <div class="row mb-1">
          <div class="col">
            <label class="form-label">{{ 'Description' | translate }}:</label>
            <div id="drawing-description">{{ selectedDrawing.description }}</div>
          </div>
        </div>

      </div>
    </div>
    <!-- end of section 1: Document Information -->

    <!-- section 2: Files List -->
    <div class="panel panel-bordered panel-default">

      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'File(s)' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="drawings-list">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center">File</th>
              <th class="text-center">Revision</th>
              <th class="text-center">Reviewer</th>
              <th class="text-center">Approver</th>
              <th class="text-center">Masters</th>
              <th class="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dwg of [ selectedDrawing.revisions[0] ]"> <!-- show only last rev -->
              <td>
                <div *ngFor="let file of dwg.attachments">
                  <download [file]="file" #downloader>
                    <ng-template let-f="file" let-icon="icon">
                      <button class="template-btn" title="click to download {{ f.file_name}}" (click)="downloader.download()">
                        <i class="file-thumbnail fa-3x fa-fw {{ icon }}"></i>
                      </button>
                    </ng-template>
                  </download>
                  <div class="inline text-xs me-3 ms-3">
                    <div class="text-bold file-name" title="{{ file.file_name }}">
                      {{ file.file_name }}
                    </div>
                    <div class="text-thin file-attr">
                      <span>{{ file.created_at | datetime }}</span>
                      <span>{{ file.file_size | memsize:'m' | number:'1.2-2' }} MB</span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ dwg.revision }}
              </td>
              <td class="text-center">
                <user-card *ngIf="dwg.reviewer"
                  [user]="dwg.reviewer"
                ></user-card>
                <div *ngIf="! dwg.reviewer" class="text-thin text-danger">
                  - {{ 'Reviewer not assigned' | translate }} -
                </div>
              </td>
              <td class="text-center">
                <user-card *ngIf="dwg.approver"
                  [user]="dwg.approver"
                ></user-card>
                <div *ngIf="! dwg.approver" class="text-thin text-danger">
                  - {{ 'Approver not assigned' | translate }} -
                </div>
                </td>
              <td class="text-center">
                <ng-container *ngFor="let master of selectedDrawing.masters">
                <button *ngIf="master" class="button btn-default text-very-thin text-xs" (click)="selectMaster(master)" title="Click to view master drawing.">
                  {{ master.title }}
                </button>
                </ng-container>
                <!--
                <button *ngIf="selectedDrawing.master" class="button btn-default" (click)="selectMaster(selectedDrawing.master)" title="Click to view master drawing.">
                  {{ selectedDrawing.master.title }}
                </button>
                -->
                <div *ngIf="selectedDrawing.masters.length == 0" class="text-thin">
                  - no master -
                </div>
              </td>
              <td class="text-center">
                {{ dwg.created_at | datetime }}
              </td>

              <!--
              <div class="row" id="approvers">
                <div class="col">
                  <label class="form-label">{{ 'Reviewer' | translate }}</label>
                  <user-card *ngIf="selectedDrawing.reviewer"
                    [user]="selectedDrawing.reviewer"
                  ></user-card>
                  <div *ngIf="! selectedDrawing.reviewer" class="text-thin text-danger">
                    - {{ 'Reviewer not assigned' | translate }} -
                  </div>
                </div>
                <div class="col">
                  <label class="form-label">{{ 'Approver' | translate }}</label>
                  <user-card *ngIf="selectedDrawing.approver"
                    [user]="selectedDrawing.approver"
                  ></user-card>
                  <div *ngIf="! selectedDrawing.approver" class="text-thin text-danger">
                    - {{ 'Approver not assigned' | translate }} -
                  </div>
                </div>
              </div>
              -->
            </tr>
            <tr *ngIf="selectedDrawing.revisions.length == 0">
              <td colspan="4" class="text-center text-thin">
                {{ '- No drawing revisions avaialble at the moment -' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <!-- end of section 2: Files List -->

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="drawingViewDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- drawing polygon on master -->
<modal [template-id]="'view-master'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #editPolygonDialog
>
  <div modal-title *ngIf="selectedMasterForPolygon !== null">
    {{ 'Drawing Location on Master' | translate }}
  </div>

  <div modal-body *ngIf="selectedMasterForPolygon !== null">
    <iip-viewer *ngIf="selectedMasterForPolygon.iip_name !== null"
      [src]="selectedMasterForPolygon.iip_name"
      [path]="iip_base_path"
      [navButtons]="['reset', 'zoomIn', 'zoomOut']"
      [showCoords]="false"
      [showScale]="false"
      [drawingTools]="false"
      [polygons]="selectedMasterForPolygon.pivot_polygons"
    ></iip-viewer>
  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="editPolygonDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- add distribution list to release bundle -->
<modal [template-id]="'select-distribution-list'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #selectDistributionListModal
>
  <div modal-title>
    {{ 'Review Recipients from' | translate }} {{ target_distribution_list && target_distribution_list.title }}
  </div>

  <div modal-body *ngIf="target_distribution_list">
    <div *ngIf="target_distribution_list.internals.length == 0 && target_distribution_list.contacts.length == 0" class="list-empty text-muted">
      -- No recipients in this list --
    </div>
    <ol>
      <li *ngFor="let role of target_distribution_list.internals; let i = index">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" checked="1" id="u-{{ role.user_id }}" #u (change)="updateSelectedRecipients(role.user!.email, u.checked)">
          <label class="form-check-label" for="u-{{ role.user_id }}">{{ role.user!.fullname }} ({{ role.user!.email }})</label>
        </div>
      </li>
      <li *ngFor="let contact of target_distribution_list.contacts; let i = index">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" checked="1" id="c-{{ contact.id }}" #u (change)="updateSelectedRecipients(contact.email, u.checked)">
          <label class="form-check-label" for="c-{{ contact.id }}">{{ contact.fullname }} ({{ contact.email }})</label>
        </div>
      </li>
    </ol>
  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-success" type="button" (click)="addDistributionListToRecipient()">
      <i class="btn-label pli-add"></i> {{ 'Add' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="selectDistributionListModal.hide()">
      <i class="btn-label pli-close"></i> {{ 'Cancel' | translate }}
    </button>
  </div>
</modal>

<!-- release bundle editor -->
<modal [template-id]="'view-bundle'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #bundleViewDialog
>
  <div modal-title>
    {{ 'Review Release Package' | translate }}
  </div>

  <div modal-body *ngIf="bundle_contents.length > 0">
    <!-- recipients -->
    <div class="release-info-section">
      <h6>{{ 'Recipients' | translate }}</h6>

      <!-- search contact list or add external email -->
      <div class="mt-3">
        <div class="text-semibold">{{ 'Search for Distribution List' | translate }}</div>
        <lookup-input class="lookup mt-2"
          [placeholder]="'Enter distibution list information to search'"
          [minChar]="3"
          [autoClose]="true"
          [delay]="0"
          [items]="lookup_bundle_distribution_lists"
          (onLookup)="lookupDistributionList($event)"
          (onSelect)="selectDistributionList($event)"
        >
        </lookup-input>
      </div>
      <div class="mt-3">
        <div class="text-semibold">{{ 'Add External E-Mail' | translate }}</div>
        <div class="input-group mt-2">
          <input type="email" class="form-control" placeholder="Other email address" [(ngModel)]="extra_email">
          <button class="btn btn-outline-secondary btn-default" type="button" (click)="addEmailToDistribution()"><i class="pli-mail-add"></i></button>
        </div>
      </div>

      <div class="mt-3 form-group">
        <div *ngIf="releaseBundle.recipients.length == 0" class="list-empty">
          -- Please add one or more recipient(s) --
        </div>
        <div class="mt-3">
          <ol>
            <li *ngFor="let recipient of releaseBundle.recipients; let i = index">
              <div class="row">
                <div class="col-7">
                  <button class="btn btn-icon remove-email" (click)="removeRecipient(i)"><i class="pli-trash"></i></button>
                  <label class="col-form-label">{{ recipient.email }}</label> <span *ngIf="recipient.extra" class="badge badge-mint">Extra</span>
                </div>
                <div class="col-2">
                  <label class="col-form-label">{{ 'Expire Date' | translate }}</label>
                </div>
                <div class="col-3">
                  <input class="form-control" type="date" [(ngModel)]="recipient.expired_at">
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <!-- bundle content list -->
    <div class="release-info-section">
      <h6 class="release-info-section">{{ 'Package Content' | translate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Approve Date</th>
            <th style="width: 12ch;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let d of bundle_contents; let i = index">
            <td>
              {{ i+1 }}
            </td>
            <td>
              <div>
                <span *ngIf="api.isProjectDocument(d)" class="clickable" (click)="viewDocument(asProjectDocument(d)!)">
                  {{ asProjectDocument(d)!.prefix || '- prefix missing -' }} {{ asProjectDocument(d)?.title || '- title missing -' }}
                </span>
                <span *ngIf="api.isProjectDrawing(d)" class="clickable" (click)="viewDrawing(asProjectDrawing(d)!)">
                  {{ asProjectDrawing(d)?.drawing_no || '- drawing no. missing '}} - {{ asProjectDrawing(d)?.description || '' }}
                </span>
              </div>
              <ul class="my-2" id="content-{{ i }}">
                <li *ngFor="let f of ( d | extract_attachments_from_project_document )">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" checked="1" id="f-{{ f.id }}" (change)="updateBundleFileExcludes($event, f)">
                    <label class="form-check-label" for="f-{{ f.id }}">{{ f.file_name }}</label>
                  </div>
                </li>
              </ul>
            </td>
            <td class="text-thin">
              <span class="text-thin">{{ d.approve_date || '(unapproved)'}}</span>
            </td>
            <td>
              <collapse-button [target]="'content-' + i" [collapsed]="true"></collapse-button>
              <button type="button"
                class="btn btn-icon btn-warning"
                data-dismiss="modal"
                title="{{ 'Remove this file' | translate }}"
                (click)="removeFromBundle(d)"
              >
                <i class="pli-remove-cart"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="bundle_contents.length == 0">
            <td colspan="4" class="text-thin">
              - No file in bundle -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- mail subject -->
    <div class="release-info-section">
      <h6>{{ 'Mail Content' | translate }}</h6>
      <div class="form-group">
        <label class="form-label text-bold">{{ 'Subject' | translate }}</label>
        <input type="text" class="form-control" placeholder="E-Mail subject" required [(ngModel)]="releaseBundle.subject" #subject="ngModel">
        <validation-errors [models]="subject"
          [messages]="{
            required: 'Mail subject is required'
          }"
        ></validation-errors>
      </div>

      <!-- mail body -->
      <div class="form-group">
        <label class="form-label text-bold">{{ 'Mail Message' | translate }}</label>
        <textarea class="form-control" #mailMessage="ngModel"
          placeholder="{{ 'E-Mail content ...' | translate }}"
          name="message"
          required
          [attr.rows]="5"
          [(ngModel)]="releaseBundle.message"
          autosize
        ></textarea>
        <validation-errors [models]="mailMessage"
          [messages]="{
            required: 'Mail message is required'
          }"
        ></validation-errors>
      </div>
    </div>
  </div>

  <div modal-footer>
    <button *ngIf="bundle_contents.length > 0 && releaseBundle.recipients.length > 0" class="btn btn-labeled btn-success" type="button" (click)="saveBundle();">
      <i class="btn-label pli-mail-send"></i> {{ 'Send' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="bundleViewDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- release bundle template editor -->
<modal [template-id]="'view-bundle-template'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #bundleTemplateViewDialog
>
  <div modal-title>
    {{ 'Review Release Bundle Template' | translate }}
  </div>

  <div modal-body *ngIf="bundleTemplate">
    <!-- recipients -->
    <div class="release-info-section">
      <h6>{{ 'Recipients' | translate }}</h6>

      <!-- search contact list or add external email -->
      <div class="mt-3">
        <div class="text-semibold">{{ 'Search for Distribution List' | translate }}</div>
        <lookup-input class="lookup mt-2"
          [placeholder]="'Enter distibution list information to search'"
          [minChar]="3"
          [autoClose]="true"
          [delay]="0"
          [items]="lookup_bundle_distribution_lists"
          (onLookup)="lookupDistributionList($event)"
          (onSelect)="selectDistributionList($event)"
        >
        </lookup-input>
      </div>
      <div class="mt-3">
        <div class="text-semibold">{{ 'Add External E-Mail' | translate }}</div>
        <div class="input-group mt-2">
          <input type="email" class="form-control" placeholder="Other email address" [(ngModel)]="extra_email">
          <button class="btn btn-outline-secondary btn-default" type="button" (click)="addEmailToDistribution()"><i class="pli-mail-add"></i></button>
        </div>
      </div>

      <div class="mt-3 form-group">
        <div *ngIf="releaseBundle.recipients.length == 0" class="list-empty">
          -- Please add one or more recipient(s) --
        </div>
        <div class="mt-3">
          <ol>
            <li *ngFor="let recipient of releaseBundle.recipients; let i = index">
              <div class="row">
                <div class="col-7">
                  <button class="btn btn-icon remove-email" (click)="removeRecipient(i)"><i class="pli-trash"></i></button>
                  <label class="col-form-label">{{ recipient.email }}</label> <span *ngIf="recipient.extra" class="badge badge-mint">Extra</span>
                </div>
                <div class="col-2">
                  <label class="col-form-label">{{ 'Expire Date' | translate }}</label>
                </div>
                <div class="col-3">
                  <input class="form-control" type="date" [(ngModel)]="recipient.expired_at">
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <!-- bundle documents list -->
    <div class="release-info-section">
      <h6 class="release-info-section">{{ 'Documents' | translate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Approve Date</th>
            <th style="width: 12ch;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let d of bundleTemplate.documents; let i = index">
            <td>
              {{ i+1 }}
            </td>
            <td>
              <div>
                <span class="clickable" (click)="viewDocument(d)">
                  {{ d.prefix || '- prefix missing -' }} {{ d.title || '- title missing -' }}
                </span>
              </div>
              <ul class="my-2" id="content-{{ i }}">
                <li *ngFor="let f of ( d | extract_attachments_from_project_document )">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" checked="1" id="f-{{ f.id }}" (change)="updateBundleFileExcludes($event, f)">
                    <label class="form-check-label" for="f-{{ f.id }}">{{ f.file_name }}</label>
                  </div>
                </li>
              </ul>
            </td>
            <td class="text-thin">
              <span class="text-thin">{{ d.approve_date || '(unapproved)'}}</span>
            </td>
            <td>
              <collapse-button [target]="'content-' + i" [collapsed]="true"></collapse-button>
              <button type="button"
                class="btn btn-icon btn-warning"
                data-dismiss="modal"
                title="{{ 'Remove this document' | translate }}"
                (click)="removeDocumentFromBundleTemplate(d)"
              >
                <i class="pli-remove-cart"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="bundleTemplate.documents.length == 0">
            <td colspan="4" class="text-thin">
              - No document in bundle -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- bundle drawings list -->
    <div class="release-info-section">
      <h6 class="release-info-section">{{ 'Drawings' | translate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Approve Date</th>
            <th style="width: 12ch;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let d of bundleTemplate.drawings; let i = index">
            <td>
              {{ i+1 }}
            </td>
            <td>
              <div>
                <span class="clickable" (click)="viewDrawing(d)">
                  {{ d.drawing_no || '- drawing no. missing '}} - {{ d.description || '' }}
                </span>
              </div>
              <ul class="my-2" id="content-{{ i }}">
                <li *ngFor="let f of ( d | extract_attachments_from_project_document )">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" checked="1" id="f-{{ f.id }}" (change)="updateBundleFileExcludes($event, f)">
                    <label class="form-check-label" for="f-{{ f.id }}">{{ f.file_name }}</label>
                  </div>
                </li>
              </ul>
            </td>
            <td class="text-thin">
              <span class="text-thin">{{ d.approve_date || '(unapproved)'}}</span>
            </td>
            <td>
              <collapse-button [target]="'content-' + i" [collapsed]="true"></collapse-button>
              <button type="button"
                class="btn btn-icon btn-warning"
                data-dismiss="modal"
                title="{{ 'Remove this drawing' | translate }}"
                (click)="removeDrawingFromBundleTemplate(d)"
              >
                <i class="pli-remove-cart"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="bundleTemplate.documents.length == 0">
            <td colspan="4" class="text-thin">
              - No document in bundle -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- mail subject -->
    <div class="release-info-section">
      <h6>{{ 'Mail Content' | translate }}</h6>
      <div class="form-group">
        <label class="form-label text-bold">{{ 'Subject' | translate }}</label>
        <input type="text" class="form-control" placeholder="E-Mail subject" required [(ngModel)]="bundleTemplate.subject" #subject="ngModel">
        <validation-errors [models]="subject"
          [messages]="{
            required: 'Mail subject is required'
          }"
        ></validation-errors>
      </div>

      <!-- mail body -->
      <div class="form-group">
        <label class="form-label text-bold">{{ 'Mail Message' | translate }}</label>
        <textarea class="form-control" #mailMessage="ngModel"
          placeholder="{{ 'E-Mail content ...' | translate }}"
          name="message"
          required
          [attr.rows]="5"
          [(ngModel)]="bundleTemplate.message"
          autosize
        ></textarea>
        <validation-errors [models]="mailMessage"
          [messages]="{
            required: 'Mail message is required'
          }"
        ></validation-errors>
      </div>
    </div>
  </div>

  <div modal-footer>
    <button *ngIf="bundleTemplate && bundleTemplate.recipients.length > 0" class="btn btn-labeled btn-success" type="button" (click)="saveBundleTemplate();">
      <i class="btn-label pli-mail-send"></i> {{ 'Save' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="bundleTemplateViewDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- drawing list -->
<modal [template-id]="'drawings-list'"
  [size]="'fullscreen'"
  [fade]="true"
  [hideCloseButton]="true"
  #drawingsListDialog
>
  <div modal-title>
    {{ 'Drawings List' | translate }}
  </div>

  <div modal-body>

    <div id="drawings-list-report">
      <table class="table table-striped table-bordered" #drawingsListReport>

        <thead>
          <tr>
            <th colspan="2" rowspan="4"></th>
            <th class="revision-head">ISSUE</th>
            <th *ngFor="let issue of drawingsListIndex; let i = index;" class="revision">{{ i }}</th>
          </tr>
          <tr>
            <!---->
            <th class="revision-head">DD</th>
            <th *ngFor="let issue of drawingsListIndex;" class="revision">{{ issue.DD | number:'2.0-0' }}</th>
          </tr>
          <tr>
            <!---->
            <th class="revision-head">MM</th>
            <th *ngFor="let issue of drawingsListIndex;" class="revision">{{ issue.MM | number:'2.0-0' }}</th>
          </tr>
          <tr>
            <!---->
            <th class="text-center revision-head">YY</th>
            <th *ngFor="let issue of drawingsListIndex;" class="revision">{{ issue.YY | number:'2.0-0' }}</th>
          </tr>
          <tr>
            <th class="text-all-caps text-center dwg-no">Dwg.No.</th>
            <th class="text-all-caps text-center description">Description</th>
            <th class="text-all-caps revision-head">Scale</th>
            <th *ngFor="let issue of drawingsListIndex;" class="revision"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let drawing of drawingsList">
            <td>{{ drawing.drawing_no }}</td>
            <td>{{ drawing.description }}</td>
            <td></td>
            <td *ngFor="let rev of drawing.revisions" class="revision">
              {{ rev.join(', ') }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3" class="text-center text-bold text-all-caps enlarge-first-letter">Total Issued</td>
            <th *ngFor="let issue of drawingsListIndex;" class="text-center">{{ issue.total | number:'1.0-0' }}</th>
          </tr>
        </tfoot>
      </table>
    </div>

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-success" type="button" (click)="saveDrawingsListAsExcel();">
      <i class="btn-label pli-save"></i> {{ 'Save' | translate }}
    </button>
    <button class="btn btn-labeled btn-default" type="button" (click)="drawingsListDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>