import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NavigationService,
  UserService
} from '@pinacono/common';

import {
  PasswordService,
  UIService
} from '@pinacono/ui';

@Component({
  selector: 'app-ch-pass-page',
  templateUrl: 'ch-pass.component.html',
  styleUrls: ['./ch-pass.component.scss']
})
export class ChPassComponent {
  public new_passwd: string = '';
  public cnf_passwd: string = '';

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected user: UserService,
    protected ui: UIService,
    protected password: PasswordService
  ) { }

  public pwd_suggestions: string[] = [];
  public validate(e: KeyboardEvent) {
    this.pwd_suggestions = this.password.validate( (e.currentTarget as HTMLInputElement).value);
  }

  /** extract email from the form and perform reset logic. */
  public update()  {
    this.user.chpass(this.new_passwd)
    .then( (res: string) => {
      if ( res == 'ok' ) {
        let redirect = this.activatedRoute.snapshot.paramMap.get('redirect');

        if ( !! redirect ) {
          this.nav.setRoot(redirect);
        }
        else if ( this.nav.canGoBack() ) {
          this.nav.pop();
        }
        else {
          this.nav.setRoot('home');
        }
        return;
      }
      this.ui.alert(res, {}, 'Error!');
    });
  }
}
