<full-page title="'LINE Notify'">
  <div page-content>
    <panel>
      <div panel-heading>{{ 'LINE Notify' | translate }}</div>
      <div panel-body>
        <ul class="subscriptions">
          <li *ngFor="let subject of subjects">
            <div class="row">
              <div class="col-5">
                <span class="subject">{{ subject.subject }}</span> <span class="description">{{ subject.description }}</span>
              </div>
              <div class="col-sm-2">
                <button class="btn btn-primary btn-rounded subscribe" title="subscribe to {{ subject.subject }}" (click)="subscribe(subject)">Subscribe</button>
              </div>
            </div>
            <ul>
              <li *ngFor="let subscription of subject.subscriptions">
                <div class="row">
                  <div class="col-sm-3">
                    <span class="subscriber">{{ subscription.target }}</span><span class="target-type">{{ subscription.target_type }}</span>
                  </div>
                  <div class="col-sm-1">
                    <button class="btn btn-warning btn-rounded unsubscribe" title="Unsubscribe" (click)="unsubscribe(subscription.id)">Unsubscribe</button>
                  </div>
                </div>
              </li>
            </ul>
        </ul>
      </div>
    </panel>
  </div>
</full-page>