import {
  Component,
  OnInit
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
/* remove @fullcalendar
import { CalendarOptions, DateSelectArg, FullCalendarComponent } from '@fullcalendar/angular';
*/

import { OPL } from 'src/app/modules/opl/types';

import {
  MorrisChart,
  UIService
} from '@pinacono/ui';

import { AppUser } from 'src/app/types';

import {
  FileUtilsService,
  NavigationService,
  PaginatedResults,
  ServerService,
  SessionService
} from '@pinacono/common';

import * as moment from 'moment-timezone';

/**
 * chart configuration
 */
interface ChartConfig {
  type: MorrisChart.ChartType;
  config: MorrisChart.ILineOptions | MorrisChart.IAreaOptions | MorrisChart.IBarOptions | MorrisChart.IDonutOptions;
};

/** Forum topic interface */
interface ForumTopic {
  /** Topic id */
  id?: string,
  /** Topic title */
  title?: string,
  /** Author information */
  author?: AppUser
  /** Last updated date */
  updated_at?: Date
};

enum CalendarEntryType {
  holiday, event, workorder
};

/** Calendar interface */
interface CalendarEntry {
  /** Repeated event id */
  id?: string;
  /** Entry title */
  title: string;
  /** Start date/time in ISO-8601 format */
  start: string|null;
  /** End date/time in ISO-8601 format */
  end: string|null;
  /** all-day or not */
  allDay?: boolean;
  /** Nifty's color class name */
  className?: string;
};

/** Maximo Calendar */
interface MaximoCalendar {
  [dept: string]: {
    [date: string]: {
      /** start date */
      start: string;
      /** work description */
      title: string;
      /** group name */
      group: string
    }[];
  };
};

/**
 * The Home Page Component
 */
@Component({
  selector: 'app-home-page',
  templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit {

  // -- chart configurations
  public charts : { [name: string]: ChartConfig } = {
    /*
    area: {
      type: 'area',
      config: {
        xkey: 'period',
        ykeys: ['dl', 'up'],
        labels: ['Visitor', 'Pageview'],
      },
      data: []
    },
    line: {
      type: 'line',
      config: {
        xkey: 'elapsed',
        ykeys: ['value'],
        labels: ['value'],
      },
      data: []
    },
    bar: {
      type: 'bar',
      config: {
        xkey: 'y',
        ykeys: ['a', 'b'],
        labels: ['Series A', 'Series B'],
      },
      data: []
    },
    donut: {
      type: 'donut',
      config: {
      },
      data: []
    },
    */

    // maximo redirection
    redirect_hourly: {
      type: 'Bar',
      config: {
        xkey: 'hour',
        ykeys: ['count'],
        labels: ['Users'],
        xLabelAngle: 80,
        data: []
      },
    },
    team_redirect: {
      type: 'Bar',
      config: {
        xkey: 'name',
        ykeys: ['count'],
        labels: ['Users'],
        xLabelAngle: 80,
        data: []
      },
    },

    // OPL
    opl_by_time: {
      // -- morris
      type: 'Bar',
      config: {
        xkey: 'month',
        ykeys: ['count'],
        labels: ['OPL'],
        xLabelAngle: 80,
        data: []
      },
      // -- D3.js
      /*
      type: 'multiBarChart',
      config: {
        margin: {
          left: 30,
          top: 50
        },
        reduceXTicks: false,  // If 'false', every single x-axis tick label will be rendered.
        //rotateLabels: 90,     // Angle to rotate x-axis labels.
        showLegend: true,     // Show the legend, allowing users to turn on/off line series.
        xAxis: {
          showAxis: true,     // Show the y-axis
          axisLabel: 'Month'
        },
        yAxis: {
          showAxis: true,     // Show the x-axis
          axisLabel: 'OPL'
        },
        showControls: false,   // Allow user to switch between 'Grouped' and 'Stacked' mode.
        groupSpacing: 0.1     // Distance between each group of bars.
      },
      */
    },
    opl_by_team: {
      // -- morris
      type: 'Bar',
      config: {
        xkey: 'name',
        ykeys: ['count'],
        labels: ['OPL'],
        xLabelAngle: 80,
        data: []
      },
      // -- D3.js
      /*
      type: 'multiBarChart',
      config: {
        margin: {
          left: 30,
          top: 50
        },
        reduceXTicks: false,  // If 'false', every single x-axis tick label will be rendered.
        rotateLabels: 90,     // Angle to rotate x-axis labels.
        showLegend: true,     // Show the legend, allowing users to turn on/off line series.
        xAxis: {
          showAxis: true,     // Show the y-axis
          axisLabel: 'Team'
        },
        yAxis: {
          showAxis: true,     // Show the x-axis
          axisLabel: 'OPL'
        },
        showControls: false,   // Allow user to switch between 'Grouped' and 'Stacked' mode.
        groupSpacing: 0.1     // Distance between each group of bars.
      },
      */
    }
  };

  /* remove @fullcalendar
  // calendar - see https://fullcalendar.io/docs#toc
  @ViewChild('calendar') calendar!: FullCalendarComponent;
  public calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    select: this.loadMaximoCalendar.bind(this)
  };
  public calendarEvents: CalendarEntry[] = [];
  */

  // opls
  public opls: OPL[] = [];
  public opls_pagination = {
    pageno: 0,
    perpage: 10,
    total: 0,
    sorting: null
  }
  public opls_total = 0;
  public opls_read = 0;
  public opls_written = 0;

  // forum topics
  public forums: ForumTopic[] = [];

  constructor(
    public nav: NavigationService,
    public fileUtils: FileUtilsService,
    protected activatedRoute: ActivatedRoute,
    protected session: SessionService,
    protected ui: UIService,
    protected server: ServerService,
    protected http: HttpClient
  ) {
  }

  /**
   * load page's data - see nifty-page.component.ts
   */
  public ngOnInit(): void {
    // -- load charts data

    // charts - official
    this.server.silent().request('/dashboard/hourly_redirects')
    .then( (res) => {
      this.charts['redirect_hourly'].config.data = res;
    });

    this.server.silent().request('/dashboard/team_redirects')
    .then( (res) => {
      this.charts['team_redirect'].config.data = res;
    });

    this.server.silent().request('/dashboard/opl_by_time')
    .then( (res) => {
      this.charts['opl_by_time'].config.data = res;
       /*
      // sum 'Year-to-Date' value
      Object.getOwnPropertyNames(res).forEach( (name: string) => {
        res[name].push({
          x: 'YTD',
          y: res[name].map( (a: object) => a['y'] ).reduce( (a: number, b: number) => a+b, 0)
        });
      });

      // format for D3
      this.charts.opl_by_time.data = [
        {
          key: 'Approved',
          values: res['approved']
        },
        {
          key: 'Read',
          values: res['read']
        },
        {
          key: 'Plan',
          values: res['plan']
        }
      ];
      */
    });

    this.server.silent().request('/dashboard/opl_by_team')
    .then( (res) => {
      this.charts['opl_by_team'].config.data = res;
       /*
      // sum 'Year-to-Date' value
      Object.getOwnPropertyNames(res).forEach( (name: string) => {
        res[name].push({
          x: 'YTD',
          y: res[name].map( (a: object) => a['y'] ).reduce( (a: number, b: number) => a+b, 0)
        });
      });

      // format for D3
      this.charts.opl_by_team.data = [
        {
          key: 'Approved',
          values: res['approved']
        },
        {
          key: 'Read',
          values: res['read']
        },
        {
          key: 'Plan',
          values: res['plan']
        }
      ];
      */
    });

    /* remove @fullcalendar
    // load holidays calendar
    this.server.silent().request('home.calendar', {name: 'holiday'})
    .then( (res: CalendarEntry[]) => {
      this.calendarEvents = res;
      for ( let i in this.calendarEvents ) {
        let e = this.calendarEvents[i];
        e.start = moment(e.start).utcOffset('+07:00').toISOString();
        e.end   = e.end ? moment(e.end).utcOffset('+07:00').toISOString() : null;
        e.className = this.getCalendarEntryClass(CalendarEntryType.holiday);
        e.allDay = (e as any)['all_day'];
      }
    });
    */

    // load personal OPL list
    //this.loadOPLPage();

    // load forums topics
    /*
    this.server.request('home.forums', null)
    .then( (res: object) => {
      console.log(res);
    });
    */

    /** @TODO - load personal training plans   */
  }

  /**
   * handle click on chart - make drilldown
   */
  public chartDrillDown(name: string, data: MorrisChart.ChartClickEvent) {
    //console.log(`Click on ${name}`, data);
  }


  /**
   * load Maximo's calendar according to view
   */
  /* remove @fullcalendar
  public loadMaximoCalendar(selection: DateSelectArg) {

    //console.log('loadMaximoCalendar', view);
    if ( !! (this.session.currentUser as AppUser).maximo_user ) {
      let params = {
        from: moment(selection.start).utcOffset('+07:00').format('YYYY-MM-DD'),
        to: moment(selection.end).utcOffset('+07:00').format('YYYY-MM-DD')
      };

      this.server.silent().request('maximo.calendar', params)
      .then( (rows: MaximoCalendar) => {
        for ( let group in rows ) {
          for ( let date in rows[group] ) {
            if ( !! this.calendarEvents.find( (e) => e.id == group + '-' + date ) ) {
              continue;
            }
            let count = rows[group][date].length;
            let e = {
              id: group + '-' + date,
              start: moment(date).utcOffset('+07:00').toISOString(),
              end: null,
              title: `${group} (${count})`,
              className: this.getCalendarEntryClass(CalendarEntryType.workorder, count),
              allDay: false
            };
            this.calendarEvents.push(e);
          }
        }
        this.calendar.getApi().render();
      });
    }
  }
  */

  /**
   * Load OPL with paging
   */
  public loadOPLPage( pageno: number|null = null ) {
    //return; // temp for production
    this.opls_pagination.pageno = pageno || this.opls_pagination.pageno;

    this.server.silent().index('opls', {
      name: 'unread'
    }, {
      pageno: this.opls_pagination.pageno,
      perpage: this.opls_pagination.perpage
    }, {
      format: 'compact'
    })
    .then( (res: PaginatedResults<OPL>) => {
      this.opls_pagination.pageno  = res.pageno;
      this.opls_pagination.perpage = res.perpage;
      this.opls_pagination.total   = res.total;
      this.opls = res.data;
    });

    this.server.silent().index('opls', { name: 'mine', count: true})
    .then( (res: number) => {
      this.opls_total = res;
    });

    this.server.silent().index('opls', { name: 'read', count: true})
    .then( (res: number) => {
      this.opls_read = res;
    });

    this.server.silent().index('opls', { name: 'approved', count: true })
    .then( (res: number) => {
      this.opls_written = res;
    });
  }

  /**
   * utility to get css class for a calendar entry
   */
  protected getCalendarEntryClass(type: CalendarEntryType, workload: number = 0): string  {
    if ( type == CalendarEntryType.holiday ) {
      return 'pink';
    }

    if ( type == CalendarEntryType.workorder ) {
      if ( workload >= 50 ) {
        return 'danger';
      }
      return 'mint'
    }

    if ( type == CalendarEntryType.event ) {
      return 'info'
    }

    return 'gray';
  }
}
