import {
  Component,
  ElementRef,
  Renderer2,
  Input,
  Output, EventEmitter,
  OnChanges, SimpleChanges
} from "@angular/core";

import { OptionItem } from "../../types";

@Component({
  selector: 'checkboxes',
  templateUrl: 'checkboxes.html',
  styleUrls: [ './checkboxes.scss' ]
})
export class CheckBoxesComponent implements OnChanges {
  @Input() options: OptionItem[] = [];
  @Input() value: string = 'value';
  @Input() label?: string = 'label';
  @Input() title?: string = 'title';

  @Input() model: string[] = [];
  @Output() modelChange = new EventEmitter<string[]>();

  @Output('change') onChange = new EventEmitter<any>();

  public optionsList : OptionItem[] = [];

  constructor(
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['options'] ) {
      let list: OptionItem[] = [];
      this.options.forEach( (item ) => {
        list.push({
          id:   item.id,
          text: item.text
        })
      });
      this.optionsList = list;
    }
  }

  public onInputChange(event: any) {
    if ( ! this.model ) {
      console.warn('checkboxes component cannot identify the model!');
      return;
    }

    if ( event.target.checked ) {
      if ( this.model.indexOf(event.target.value) < 0 ) {
        this.model.push(event.target.value);
      }
    }
    else {
      let i: number = this.model.indexOf(event.target.value);
      if ( i >= 0 ) {
        this.model.splice(i, 1);
      }
    }

    this.modelChange.emit(this.model);
    this.onChange.next(event);
  }
}