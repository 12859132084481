/**
 * project library endpoints
 */

import { ServiceEndpointsMapping } from "@pinacono/common";

export const ENDPOINTS: ServiceEndpointsMapping = {
  drawings: {
    /**
     * get drawing searching parameters options
     */
    search_params: '/drawings/search/params',

    masters: {
      /**
       * search masters drawing by attributes (e.g., building, floor, etc)
       */
      search: {
        method: 'POST',
        url: '/drawings/masters/search'
      }
    },

    supplements: {
      /**
       * search supplementaries by master id,
       * filter by (optional) polygons and (optional) types - comma delimited of supplement types
       */
      search: {
        method: 'POST',
        url: 'drawings/supplements/search/{master_id}'
      },

      /**
       * get masters of a supplement
       */
      masters: {
        method: 'POST',
        url: '/drawings/masters/of'
      }
    },

    /**
     * attach/detach are used in ProjectDrawing pages
     */
    attach: {
      method: 'POST',
      url: '/drawings/masters/{master_id}/attach'
    },
    detach: {
      method: 'POST',
      url: '/drawings/masters/{master_id}/detach'
    },

  },

  projlib: {
    register: { method: 'POST', url: '/spw/project/register' }, // lock project code and get next document id
    validation: {
      title: {
        unique: { method: 'POST', url: '/projects/validate/title/unique' },
      }
    },
    action: '/projects/{id}/action/{action}',
    attach_master: { method: 'POST', url: '/projects/{id}/master'},
    detach_master: { method: 'DELETE', url: '/projects/{id}/master/{rev_id}'},

    roles: {
      add:    { method: 'POST',   url: '/projects/{id}/role' },
      update: { method: 'PUT',    url: '/projects/{id}/role/{role_id}' },
      remove: { method: 'DELETE', url: '/projects/{id}/role/{role_id}' }
    },

    proj_doc: {
      validation: {
        title:{
          unique: { method: 'POST', url: '/projects/documents/validate/title/unique' }
        }
      },
      action: '/projects/documents/{id}/action/{action}',
      add_log: { method: 'POST', url: '/projects/documents/{id}/log' }
    },

    proj_drawing: {
      action: '/projects/drawings/{id}/action/{action}',
      attach: { method: 'POST', url: '/projects/drawings/attach/{id}/{drawing_id}' },
      detach: '/projects/drawings/detach/{id}/{drawing_id}',
      validation: {
        dwg_no: {
          unique: { method: 'POST', url: '/projects/drawings/validate/dwg_no/unique' },
        }
      },
      add_log: { method: 'POST', url: '/projects/drawings/{id}/log'
      }
    },

    external_contact: {
      data_list: '/projects/contacts/list/{name}',
      unique: 'projects/contacts/unique/{value}'
    },

    distribution_list: {
      add_internal:    '/projects/distribution_lists/internal/add/{list_id}/{role_id}',
      remove_internal: '/projects/distribution_lists/internal/del/{list_id}/{role_id}',

      add_external:    '/projects/distribution_lists/external/add/{list_id}/{contact_id}',
      remove_external: '/projects/distribution_lists/external/del/{list_id}/{contact_id}',
    }
  }
};
