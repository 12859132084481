import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  config,
  object_t,
  ServerService,
  SessionService,
} from '@pinacono/common';

import { Subject, Subscription } from './types';

@Component({
  selector: 'admin-notify-page',
  templateUrl: 'notify.html',
  styleUrls: ['notify.scss']
})
export class AdminNotifyComponent implements OnInit {

  public subjects: Subject[] = [];

  // -- constructor
  constructor(
    protected server: ServerService,
    protected route: ActivatedRoute,
    protected session: SessionService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    /*
    this.route.queryParams.subscribe( async (params: object_t) => {
      if ( params['code'] && params['state'] ) {
        // callback from LINE Notify - register the subscription to our server
        await this.server.request('admin.line_notify.subscribe', null, {
          code: params['code'],
          subject: params['state']
        });
      }
      this.subjects = await  this.server.request('admin.line_notify.list');
    });
    */
    this.subjects = await this.server.request('admin.line_notify.list');
  }

  // -- template API

  public async unsubscribe(id: string) {
    await this.server.request('admin.line_notify.unsubscribe', { id: id });
    this.subjects = await  this.server.request('admin.line_notify.list');
  }

  public async subscribe(subject: Subject) {
    const ep = this.server.compile('admin.line_notify.subscribe');
    /**
     * @todo - check how to get server base URL
     */

    // redirect to LINE Notify oAuth page
    const params: object_t = {
      response_type: 'code',
      client_id: config('client.line.client_id'),
      redirect_uri: ep.url,
      scope: 'notify',
      state: subject.subject
    };
    console.log('params = ', params);

    // redirect to LINE Notify oAuth page
    const queryParams = Object.keys(params)
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                        .join('&');
    const url = 'https://notify-bot.line.me/oauth/authorize?' + queryParams;
    console.log('url = ', url);
    window.location.href = url;
  }
}