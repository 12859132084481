import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';

import {
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  ServerService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

@Component({
  selector: 'app-bindings',
  templateUrl: 'bindings.component.html',
  styleUrls: ['./bindings.component.scss']
})
export class BindingsComponent implements OnInit {

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected server: ServerService,
    protected ui: UIService,
  ) { }

  public ngOnInit(): void {
    let code = this.activatedRoute.snapshot.paramMap.get('code');

    if ( ! code ) {
      this.nav.back();
    }
    this.server.rejectOnError().request('bindings.line.get', {code: code})
    .then(
      (res) => {
        if ( res === null ) {
          this.ui.alert(`Cannot find registration code ${code}`)
          .then( () => { this.nav.back(); } );
        }
        this.ui.confirm('Bind your portal account to LINE user "{{user_name}}"?',{ user_name: res['line_username'] },
          () => {
            this.server.rejectOnError().request('bindings.line.bind', {code: code})
            .then(
              (res) => {
                this.ui.alert('Success!');
                this.nav.back();
              },
              (err) => {
                console.error(err);
                this.nav.back();
              }
            );
          },
          () => {
            this.nav.back();
          }
        )
      },
      (err) => {
        if ( err.status == 404 ) {
          this.ui.alert('Invalid registration code.');
        }
        else {
          console.error(err);
        }
        this.nav.back();
      }
    );
  }
}
