import { Router, ActivatedRoute } from '@angular/router';
import { Component } from "@angular/core";

import {
  NavigationService,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import { BasePageComponent } from "src/app/classes/base-page.component";

import {
  Candidate,
  JobDescription,
  CompetencyProfile
} from '../types';

import { TrainingService } from '../training.service';

/*
import { HomePage } from '../home/home';
import { TrainingGapsSimulatorPage } from './gaps-simulator';
import { TrainingCompetencyPage } from './competency';
import { Candidate } from "./components/gaps-analyzer.component";
*/

@Component({
  selector: 'page-training-dashboard',
  templateUrl: 'dashboard.html',
  styleUrls: [ 'dashboard.scss' ]
})
export class TrainingDashboardPage extends BasePageComponent {

  // -- configurations
  protected maxSelectedGaps = 5;

  // -- models
  public job_description: JobDescription|null = null;
  //public gaps: CompetencyProfile[] = [];
  public candidate: Candidate|null = null;

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public server: ServerService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle
  public override loadData(): Promise<void> {

    return new Promise( (resolve, reject) => {
      let promises: Promise<void>[] = [];

      promises.push(
        this.server.get('training.profile.self', { with: 'plans' })
        .then( (res: object[]) => {
          this.candidate = {
            staff: this.session.currentUser!,
            profiles: res.map( (g: object) => this.api.createCompetencyProfile(g) )
          };
        })
      );

      promises.push(
        this.server.get('training.job.user', {id: this.session.currentUser?.id})
        .then( (res: object) => {
          this.job_description = this.api.createJobDescription(res);
        })
      );

      Promise.all(promises)
      .then( () => resolve() )
      .catch( console.warn );
    });
  }

  // -- template API

  public simulate() {
    //this.nav.push(TrainingGapsSimulatorPage);
    this.nav.push('/training/gaps/simulator')
    this.dont_refresh = true;
  }

  public onCompetencySelected(id: number) {
    //this.nav.push(TrainingCompetencyPage, { id: id } );
    this.nav.push([ 'training/competencies/view', id ]);
  }
}