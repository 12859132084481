import { Injectable } from "@angular/core";

import { ObjectUtilsService, config, object_t } from "@pinacono/common";
import { SearchResultEntry } from "./types";

@Injectable()
export class CommonPagesService {

  public constructor(
    protected objectUtils: ObjectUtilsService
  ) {}

  /**
   * @TODO - replace with the function in common library
   */
  protected flaten(obj: object_t): Array<{key: string, value: any}> {
    const res:  Array<{key: string, value: any}> = [];

    for ( const name of Object.getOwnPropertyNames(obj) ) {
      const v = obj[name];
      if ( v !== null && typeof v === 'object' && ! Array.isArray(v) ) {
        for ( let vv of this.flaten(v) ) {
          res.push({
            key: name + '.' + vv.key,
            value: vv.value
          })
        }
      }
      else {
        res.push({
          key: name,
          value: v
        });
      }
    }
    return res;
  }

  public getLink(r: any, prefix: string = ''): string {
    //let url = `/#/${r.doc_type}/view/${r.doc_id}`,
    //console.log('result =', r);

    // determine the best matched URL
    let url: string = `/${r.doc_type}/view/{doc_id}`;
    url = config(`client.spw.search_result_url.default`, url);

    let doc_type = r.doc_type;
    url = config(`client.spw.search_result_url.${doc_type}`, url);
    url = config(`client.spw.search_result_url.${doc_type}.default`, url);
    url = config(`client.spw.search_result_url.${doc_type}.${r.attributes.subtype}`, url);

    if ( r.attributes && r.attributes.subtype ) {
      doc_type = doc_type + '.' + r.attributes.subtype;
      url = config(`client.spw.search_result_url.${doc_type}`, url);
    }

    const bindings = this.flaten(r);
    for ( const binding of bindings ) {
      url = url.replace('{' + binding.key + '}', binding.value);
    }
    return prefix + url;
  }
}
