import {
  Component,
  Input,
  Output, EventEmitter,
  OnChanges, SimpleChanges
} from "@angular/core";

import { InterpolatbleErrorMessage } from "@pinacono/ui";

import { CustomPropertyConfig, OptionItem } from '../../types';

@Component({
  selector: 'custom-properties',
  templateUrl: 'custom-properties.html',
  styleUrls: [ './custom-properties.scss' ]
})
export class CustomPropertiesComponent implements OnChanges{
  @Input() config!: CustomPropertyConfig;
  @Input() errors: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[] | null = null;

  @Input() data: any;
  @Output() dataChange = new EventEmitter<any>();

  public options: OptionItem[] = [];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ( changes['config'] ) {
      this.options = this.config.options.map( o => {
        return {
          id: o.id?.toString() || '0' ,
          text: o.text
        }
      });
    }
  }

  public inputChange() {
    this.dataChange.emit(this.data);
  }
}