<minimal-page>
  <div class="cls-content-lg">
    <h1 class="error-code text-muted"><i class="pli-cat icon-2x"></i></h1>
    <p class="h4 text-uppercase text-bold">{{ 'Redirecting ...' | translate }}...</p>
    <div class="pad-btm">
      <button *ngIf="url" class="btn btn-info text-large" (click)="go(url)">{{ 'Click to Redirect!' | translate }}</button>
      <div *ngIf="!url" class="sk-spinner sk-spinner-pulse"></div>
    </div>
  </div>
</minimal-page>
