<full-page title="'Reports'">
  <div page-content>
    <form id="report-form" ngForm="reportForm">
      <div class="pad-all">
        <div class="row">

          <!-- Reservations -->
          <panel class="col-md-4">
            <div panel-heading>
              Reservations
            </div>
            <div panel-body>
              <p class="description">
                List of reservations and progress by time.
              </p>
              <form class="row" #params="ngForm">
                <div class="row pad-top">
                  <div class="col">
                    <input type="date" name="from" class="form-control"
                      [daterange]="'from'" [date-ref]="toDate"
                      [(ngModel)]="from"
                      #fromDate="ngModel"
                    >
                  </div>
                    -
                  <div class="col">
                    <input type="date" name="to" class="form-control"
                      [daterange]="'to'" [date-ref]="fromDate"
                      [(ngModel)]="to"
                      #toDate="ngModel"
                    >
                  </div>
                </div>
              </form>
              <div class="text-right pt-1">
                <button class="btn btn-default btn-labeled" (click)="run('library_reservations', params)">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

          <!-- Check In/Out -->
          <panel class="col-md-4">
            <div panel-heading>
              Check In/Out
            </div>
            <div panel-body>
              <p class="description">
                List of users check-in/check-out by time.
              </p>
              <form class="row" #params="ngForm">
                <div class="row pad-top">
                  <div class="col">
                    <input type="date" name="from" class="form-control"
                      [daterange]="'from'" [date-ref]="toDate"
                      [(ngModel)]="from"
                      #fromDate="ngModel"
                    >
                  </div>
                    -
                  <div class="col">
                    <input type="date" name="to" class="form-control"
                      [daterange]="'to'" [date-ref]="fromDate"
                      [(ngModel)]="to"
                      #toDate="ngModel"
                    >
                  </div>
                </div>
              </form>
              <div class="text-right pt-1">
                <button class="btn btn-default btn-labeled" (click)="run('library_checkin_out', params)">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

        </div>
      </div>
    </form>
  </div>
</full-page>