<full-page [title]="handover.project?.title || 'Unknown Project'">
  <div page-content>
    <panel class="proj-lib">
      <div panel-body class="fluid">
        <ng-container *ngIf="! handover.project" class="loading">
          <form #mainForm="ngForm"></form>
        </ng-container>

        <ng-container *ngIf="!! handover.project">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            Project Hand Over -
            {{ handover.project.title_prefix }}
            {{ handover.project.title || '&lt;New Project&gt;' }}
            {{ handover.project.title_suffix }}
          </span>
        </h4>

        <form #mainForm="ngForm">

          <!-- section 1: Project Information  -->
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Project Information' | translate }}
                <collapse-button [target]="'proj-registration'" [collapsed]="false"></collapse-button>
              </h4>
            </div>
            <div class="panel-body" id="proj-registration">
              <div class="row">
                <!-- Info -->
                <div class="col">
                  <!-- project code and status -->
                  <div class="pad-top row">
                    <!-- project code display -->
                    <div class="col">
                      <label class="form-label text-bold">{{ 'Project Code' | translate }}:</label>
                      <span class="text-thin" #doccode>{{ handover.project.documentable.doc_code }}</span>
                    </div>

                    <div class="col text-right">
                      <div class="text-left">
                        <div class="inline">
                          <label class="form-label text-thin">{{ 'Project Status' | translate }}</label>
                          <span class="badge badge-info text-thin">{{ handover.project.project_status }}</span>
                        </div>
                        <div class="inline ms-4">
                          <label class="form-label text-thin">{{ 'Hand Over Status' | translate }}</label>
                          <span class="badge badge-pink text-thin">{{ handover.status }}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- end of project code and status -->

                  <!-- project title -->
                  <div class="pad-top row" id="doc-title">
                    <label class="form-label text-bold">{{ 'Project Title' | translate }}:</label>

                    <!-- project prefix -->
                    <div class="col-xs-12 col-sm-4 col-md-2">
                      <input type="text" class="form-control" name="doc-title-prefix" disabled [(ngModel)]="handover.project.title_prefix">
                    </div>

                    <!-- project title -->
                    <div class="col-xs-12 col-sm-4 col-md-8">
                      <input type="text" class="form-control" name="title" disabled [(ngModel)]="handover.project.title">
                    </div>

                    <!-- project suffix -->
                    <div class="col-xs-12 col-sm-4 col-md-2">
                      <input type="text" class="form-control" name="doc-title-suffix" disabled [(ngModel)]="handover.project.title_suffix">
                    </div>

                  </div>
                  <!-- end of project title -->
                </div>
              </div>
            </div>
          </div>
          <!-- end of section 1: Project Information -->

          <!-- section 2: Project Documents -->
          <div class="panel panel-bordered panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Project Documents' | translate }}
                <collapse-button [target]="'proj-documents'" [collapsed]="false"></collapse-button>
              </h4>
              <div class="panel-control">
                <button type="button" class="btn btn-icon btn-info" data-dismiss="model" title="{{ 'Add documents to hand over.' | translate }}" (click)="addDocuments()">
                  <i class="pli-add"></i>
                </button>
              </div>
            </div>
            <div class="pabel-body" id="proj-documents">
              <div class="mar-all">
                <extended-slickgrid *ngIf="!! projectHandOverDocumentGridOptions" gridId="project-handover-doc-table"
                  class="row-selectable"
                  [columnDefinitions]="projectHandOverDocumentColumnDefinitions"
                  [gridOptions]="projectHandOverDocumentGridOptions"
                  (onAngularGridCreated)="onProjectHandOverDocumentGridReady($event)"
                  (onClick)="onProjectHandOverDocumentGridClick($event)"
                  (onValidationError)="onProjectHandOverDocumentGridValidationError($event)"
                >
                </extended-slickgrid>
              </div>
            </div>
          </div>
          <!-- end of section 2: Project Documents -->

          <!-- section 3: Project Drawings -->
          <div class="panel panel-bordered panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Project Drawings' | translate }}
                <collapse-button [target]="'proj-drawings'" [collapsed]="false"></collapse-button>
              </h4>
              <div class="panel-control">
                <button type="button" class="btn btn-icon btn-info" data-dismiss="model" title="{{ 'Add drawings to hand over.' | translate }}" (click)="addDrawings()">
                  <i class="pli-add"></i>
                </button>
              </div>
            </div>
            <div class="pabel-body" id="proj-drawings">
              <div class="mar-all">
                <extended-slickgrid *ngIf="!! projectHandOverDrawingGridOptions" gridId="project-handover-drawing-table"
                  class="row-selectable"
                  [columnDefinitions]="projectHandOverDrawingColumnDefinitions"
                  [gridOptions]="projectHandOverDrawingGridOptions"
                  (onAngularGridCreated)="onProjectHandoverDrawingGridReady($event)"
                  (onClick)="onProjectHandOverDrawingGridClick($event)"
                >
                </extended-slickgrid>
              </div>
            </div>
          </div>
          <!-- end of section 3: Project Drawings -->

          <!-- section 4: Comments -->
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Comments' | translate }}
                <collapse-button [target]="'proj-comments'" [collapsed]="false"></collapse-button>
              </h4>
            </div>
            <div class="panel-body" id="proj-comments">
              <comments
                [content]="handover.project.comments"
                [content_id]="handover.project.id || null"
                [content_type]="'project'"
                [attributes]="{ status: handover.project.project_status }"
                [readonly]="false"
              >
              </comments>
            </div>
          </div>
          <!-- end of section 4: Comments -->

          <!-- section 5: project action buttons -->
          <div class="pad-top row">
            <div class="col-xs-12 text-right">

              <button *ngIf="is_submittable()"
                type="button"
                class="btn btn-labeled btn-success"
                title="{{ 'Submit this hand over' | translate }}"
                (click)="action('submit')"
              >
                <i class="btn-label pli-check"></i> {{ 'Submit' | translate }}
              </button>

              <button *ngIf="is_approvable()"
                type="button"
                class="btn btn-labeled btn-success"
                title="{{ 'Approve this hand over' | translate }}"
                (click)="action('approve')"
              >
                <i class="btn-label pli-thumbs-up-smiley"></i> {{ 'Approve' | translate }}
              </button>

              <button *ngIf="is_rejectable()"
                type="button"
                class="btn btn-labeled btn-warning"
                title="{{ 'Reject this hand over' | translate }}"
                (click)="action('reject')"
              >
                <i class="btn-label pli-thumbs-down-smiley"></i> {{ 'Reject' | translate }}
              </button>

              <button *ngIf="is_saveable()"
                type="button"
                class="btn btn-labeled btn-dark"
                title="{{ 'Save this hand over' | translate }}"
                (click)="save()"
              >
                <i class="btn-label fa fa-save"></i> {{ 'Save' | translate }}
              </button>

              <button *ngIf="is_deletable()"
                type="button"
                class="btn btn-labeled btn-danger"
                title="{{ 'Delete this hand over' | translate }}"
                (click)="delete()"
              >
                <i class="btn-label fa fa-trash-alt"></i> {{ 'Delete' | translate }}
              </button>

              <!--
              <button *ngIf="is_restorable(project.project_status)"
                type="button"
                class="btn btn-labeled btn-info"
                (click)="restore()"
              >
                <i class="btn-label fa-solid fa-trash-can-arrow-up"></i>{{ 'Restore' | translate }}
              </button>

              <button *ngIf="is_purgable(project.project_status)"
                type="button"
                class="btn btn-labeled btn-danger"
                (click)="purge()"
              >
                <i class="btn-label fa fa-bomb"></i> {{ 'Purge' | translate }}
              </button>
              -->

              <button type="button"
                class="btn btn-labeled btn-default"
                data-dismiss="modal"
                title="{{ 'Back to previouse page' | translate }}"
                (click)="back()"
              >
                <i class="btn-label fa fa-solid fa-circle-chevron-left"></i>{{ 'Back' | translate }}
              </button>

            </div>
          </div>
          <!-- end of section 5: project action buttons -->

        </form>

        </ng-container>

      </div>
    </panel>
  </div>
</full-page>

<!-- master drawing location editor -->
<modal template-id="select-documents"
  size="xlarge"
  [hideCloseButton]="true"
  #selectDocumentsModal
>
  <div modal-body>

    <extended-slickgrid *ngIf="selectingDocumentsType == 'document' && projectDocumentGridOptions" gridId="project-document-table"
      class="row-selectable"
      [columnDefinitions]="projectDocumentColumnDefinitions"
      [gridOptions]="projectDocumentGridOptions"
      (onAngularGridCreated)="onProjectDocumentGridReady($event)"
      (onClick)="onProjectDocumentGridClick($event)"
      (onSelectedRowsChanged)="onProjectDocumentGridSelectedRowsChanged($event)"
    >
    </extended-slickgrid>

    <extended-slickgrid *ngIf="selectingDocumentsType == 'drawing' && projectDrawingGridOptions" gridId="project-drawing-table"
      class="row-selectable"
      [columnDefinitions]="projectDrawingColumnDefinitions"
      [gridOptions]="projectDrawingGridOptions"
      (onAngularGridCreated)="onProjectDrawingGridReady($event)"
      (onClick)="onProjectDrawingGridClick($event)"
      (onSelectedRowsChanged)="onProjectDrawingGridSelectedRowsChanged($event)"
    >
    </extended-slickgrid>

  </div>

  <div modal-footer>

    <!--
    <button *ngIf="selectedMaster.pivot_polygons.length > 0" title="Save" class="btn btn-success btn-labeled" (click)="saveMasterDrawing()">
      <i class="btn-label icon-lg icon-fw pli-disk"></i> {{ 'Save' | translate }}
    </button>
    -->

    <button type="button" class="btn btn-labeled btn-success" data-dismiss="modal" title="{{ 'Save' | translate }}" (click)="addHandOverDocuments()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Save' | translate }}
    </button>

    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="selectDocumentsModal.hide()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Cancel' | translate }}
    </button>

  </div>

</modal>

<!-- file viewer -->
<modal [template-id]="'view-document'"
  [size]="'large'"
  [fade]="true"
  [hideCloseButton]="true"
  #documentViewDialog
>
  <div modal-title *ngIf="selectedDocument !== null">
    {{ selectedDocument.prefix }} {{ selectedDocument.title }}
  </div>

  <div modal-body *ngIf="selectedDocument !== null">

    <!-- section 1: Document information-->
    <div class="panel panel-bordered panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'Document Information' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="doc-info">
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">{{ 'Document Title' | translate }}:</label>
            <div class="text-lg text-semibold">
              {{ selectedDocument.prefix }} {{ selectedDocument.title }}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <div>
              <label class="form-label text-bold">{{ 'Status' | translate }}:</label>
              <span class="text-thin">{{ selectedDocument.status }}</span>
            </div>
          </div>
          <div class="col">
            <label class="form-label text-bold">{{ 'Hard Copies' | translate }}: </label>
            <span class="text-thin"> {{ selectedDocument.attr && selectedDocument.attr['hardcopies'] || 0 }}</span>  <!-- to be replaced by document's hardcopies soon -->
          </div>
        </div>

        <!--
        <div class="row" id="approvers">
          <div class="col">
            <label class="form-label">{{ 'Reviewer' | translate }}:</label>
            <user-card *ngIf="selectedDocument.reviewer"
              [user]="selectedDocument.reviewer"
            ></user-card>
            <div *ngIf="! selectedDocument.reviewer" class="text-thin text-danger">
              - {{ 'Reviewer not assigned' | translate }} -
            </div>
          </div>
          <div class="col">
            <label class="form-label">{{ 'Approver' | translate }}:</label>
            <user-card *ngIf="selectedDocument.approver"
              [user]="selectedDocument.approver"
            ></user-card>
            <div *ngIf="! selectedDocument.approver" class="text-thin text-danger">
              - {{ 'Approver not assigned' | translate }} -
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <!-- end of section 1: Document Information -->

    <!-- section 2: Files List -->
    <div class="panel panel-bordered panel-default">

      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'File(s)' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="files-list">

        <div *ngIf="selectedDocument.revisions.length > 0" class="row">
          <div class="col-12">
            <div *ngFor="let file of selectedDocument.revisions[0].attachments" class="row">
              <download class="col-2" [file]="file" #downloader>
                <ng-template let-f="file" let-icon="icon">
                  <button class="template-btn" title="click to download {{ f.file_name}}" (click)="downloader.download()">
                    <img *ngIf="utils.mime_utils.mime_isImage(f.content_type)"
                      class=" file-thumbnail"
                      title="{{ f.caption || f.file_name }}"
                      src="{{ f.thumb_url || f.path_url || f.data_url || null }}"
                    >
                    <i *ngIf="! utils.mime_utils.mime_isImage(f.content_type)"
                      class="file-thumbnail fa-5x fa-fw {{ icon }}"
                    ></i>
                  </button>
                </ng-template>
              </download>

              <div class="col">
                <div class="text-bold file-name" title="{{ file.file_name }}">
                  {{ file.file_name }}
                </div>
                <div class="text-thin file-attr">
                  <span>{{ file.created_at | datetime }}</span>
                  <span>{{ file.file_size | memsize:'m' | number:'1.2-2' }} MB</span>
                </div>
                <div class="text-thin">
                  {{ file.caption }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedDocument.revisions.length == 0" class="text-thin text-center">
          {{ '- No downloables avaialble at the moment -' }}
        </div>
      </div>

    </div>
    <!-- end of section 2: Files List -->

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="documentViewDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>

<!-- drawing viewer -->
<modal [template-id]="'view-drawing'"
  [size]="'xlarge'"
  [fade]="true"
  [hideCloseButton]="true"
  #drawingViewDialog
>
  <div modal-title *ngIf="selectedDrawing !== null">
    {{ selectedDrawing.drawing_no }}
  </div>

  <div modal-body *ngIf="selectedDrawing !== null">
    <!-- section 1: Drawing information-->
    <div class="panel panel-bordered panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'Drawing Information' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="drawing-info">

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Drawing No' | translate }}:</label>
            {{ selectedDrawing.drawing_no }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Title' | translate }}:</label>
            {{ selectedDrawing.title }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Stage' | translate }}:</label>
            {{ selectedDrawing.stage }}
          </div>
          <div class="col">
            <label class="form-label">{{ 'Status' | translate }}:</label>
            {{ selectedDrawing.status }}
          </div>
          <div class="col">
            <label class="form-label">{{ 'Hard Copies' | translate }}:</label>
            {{ selectedDrawing.attr && selectedDrawing.attr['hardcopies'] || 0 }} <!-- to be replaced by document's hardcopies soon -->
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label">{{ 'Drawing Type' | translate }}:</label>
            {{ selectedDrawing.type }}
          </div>
        </div>

        <div class="row mb-1">
          <div class="col">
            <label class="form-label">{{ 'Description' | translate }}:</label>
            <div id="drawing-description">{{ selectedDrawing.description }}</div>
          </div>
        </div>

      </div>
    </div>
    <!-- end of section 1: Document Information -->

    <!-- section 2: Files List -->
    <div class="panel panel-bordered panel-default">

      <div class="panel-heading">
        <h4 class="panel-title">
          {{ 'File(s)' | translate }}
        </h4>
      </div>

      <div class="panel-body" id="drawings-list">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center">File</th>
              <th class="text-center">Revision</th>
              <th class="text-center">Reviewer</th>
              <th class="text-center">Approver</th>
              <th class="text-center">Masters</th>
              <th class="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dwg of [ selectedDrawing.revisions[0] ]"> <!-- show only last rev -->
              <td>
                <div *ngFor="let file of dwg.attachments">
                  <download [file]="file" #downloader>
                    <ng-template let-f="file" let-icon="icon">
                      <button class="template-btn" title="click to download {{ f.file_name}}" (click)="downloader.download()">
                        <i class="file-thumbnail fa-3x fa-fw {{ icon }}"></i>
                      </button>
                    </ng-template>
                  </download>
                  <div class="inline text-xs me-3 ms-3">
                    <div class="text-bold file-name" title="{{ file.file_name }}">
                      {{ file.file_name }}
                    </div>
                    <div class="text-thin file-attr">
                      <span>{{ file.created_at | datetime }}</span>
                      <span>{{ file.file_size | memsize:'m' | number:'1.2-2' }} MB</span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                {{ dwg.revision }}
              </td>
              <td class="text-center">
                <user-card *ngIf="dwg.reviewer"
                  [user]="dwg.reviewer"
                ></user-card>
                <div *ngIf="! dwg.reviewer" class="text-thin text-danger">
                  - {{ 'Reviewer not assigned' | translate }} -
                </div>
              </td>
              <td class="text-center">
                <user-card *ngIf="dwg.approver"
                  [user]="dwg.approver"
                ></user-card>
                <div *ngIf="! dwg.approver" class="text-thin text-danger">
                  - {{ 'Approver not assigned' | translate }} -
                </div>
                </td>
              <td class="text-center">
                <div *ngIf="selectedDrawing.masters.length == 0" class="text-thin">
                  - no master -
                </div>
              </td>
              <td class="text-center">
                {{ dwg.created_at | datetime }}
              </td>
            </tr>
            <tr *ngIf="selectedDrawing.revisions.length == 0">
              <td colspan="4" class="text-center text-thin">
                {{ '- No drawing revisions avaialble at the moment -' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <!-- end of section 2: Files List -->

  </div>

  <div modal-footer>
    <button class="btn btn-labeled btn-default" type="button" (click)="drawingViewDialog.hide();">
      <i class="btn-label pli-close"></i> {{ 'Close' | translate }}
    </button>
  </div>
</modal>
