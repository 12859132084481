import {
  Component,
  ViewChild,
  Input,
  ElementRef
} from "@angular/core";

import {
  ServerService,
  StringUtilsService
} from '@pinacono/common';

import {
  TerminalComponent,
  UIService
} from '@pinacono/ui';

import * as XLSX from 'xlsx';
import { Service } from "../types";

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'importer',
  templateUrl: 'importer.html',
  styleUrls: [ './importer.scss' ]
})
export class ImporterComponent {
  @Input('services') services: Service[] = [];

  @ViewChild('terminal') terminal!: TerminalComponent;
  @ViewChild('form') form!: ElementRef;

  public termid: string;
  public sheets: SelectOption[] = [];

  public selectedSheet: string|null = null;
  public selectedService: number = 0;
  public selectedFile: File|null = null;

  // -- initialization

  constructor(
    protected server: ServerService,
    protected ui: UIService,
    stringUtils: StringUtilsService
  ) {
    this.termid = stringUtils.random(8);
  }

  // -- events

  public onFileChange(event: Event) {
    if ( event.target && (event.target as any)['files'] ) {
      let reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if ( e.target ) {
          let data = new Uint8Array(e.target.result as ArrayBuffer);
          let workbook = XLSX.read(data, {type: 'array'});
          this.sheets.length = 0;
          this.sheets.push({ label: "All Sheets", value: '- all sheets -' });
          this.sheets = this.sheets.concat(workbook.SheetNames.map( (n: string) => { return { label: n, value: n }; }));
          this.selectedSheet = this.sheets[0].value;
        }
      };
      reader.readAsArrayBuffer((event.target as any)['files'][0]);
    }
  }

  // -- template API
  public upload() {
    if ( this.form.nativeElement.elements['sheetname'].value == '- all sheets -' ) {
      this.form.nativeElement.elements['sheetname'].value = null;
    }

    this.form.nativeElement.action = this.server.compile(this.services[this.selectedService].url, this.services[this.selectedService].params).url;
    this.form.nativeElement.method = 'post';
    this.form.nativeElement.target = this.termid;
    this.form.nativeElement.submit();
  }
}