<form class="upload-form info-input" method="post" enctype="multipart/form-data"
  #importer="ngForm"
  #form
>
  <div class="row">
    <div class="col">
      <input type="file" #file="ngModel"
        required
        name="file"
        class="form-control file-input"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        (change)="onFileChange($event)"
        [(ngModel)]="selectedFile"
      >
    </div>
    <div class="alert alert-danger" *ngIf="service.invalid && (service.dirty || service.touched)">
      <div *ngIf="file.errors && file.errors['required']">{{ 'Please select file' | translate }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <label class="control-label text-semi-bold" for="service">{{ 'Service' | translate }}</label>
      <select class="form-control" name="service" [(ngModel)]="selectedService" required #service="ngModel">
        <option *ngFor="let s of services; let i = index" value="{{ i }}">{{ s.label }}</option>
      </select>
      <div class="alert alert-danger" *ngIf="service.invalid && (service.dirty || service.touched)">
        <div *ngIf="service.errors && service.errors['required']">{{ 'Please select service to import' | translate }}</div>
      </div>
    </div>
    <div class="col form-group">
      <label class="control-label text-semi-bold" for="sheetname">{{ 'Sheet to Import' | translate }}</label>
      <select class="form-control" name="sheetname" [(ngModel)]="selectedSheet" #sheet="ngModel">
        <option *ngFor="let s of sheets" [selected]="s.value" [value]="s.value">{{ s.label }}</option>
      </select>
      <!--
      <div class="alert alert-danger" *ngIf="sheet.invalid && (sheet.dirty || sheet.touched)">
        <div *ngIf="sheet.errors['required']">{{ 'Please select work sheet to import' | translate }}</div>
      </div>
      -->
    </div>
    <div class="col form-group no-label">
      <button class="btn btn-default btn-labeled pull-right" (click)="upload()" [disabled]="!importer.form.valid"><i class="pli-file-upload btn-label"></i>Upload</button>
    </div>
  </div>
</form>
<terminal #terminal [frame-name]="termid"></terminal>