/**
 * routes for 'experiments' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// experimental page
import { ExperimentalComponent } from 'src/app/experiments/exp.component';

// -- configurations

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  experimental: {
    path: 'experimental',
    component: ExperimentalComponent
  },
};
