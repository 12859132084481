import {
  Component
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  config,
  NavigationService,
  PaginatedResults,
  Pagination,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { TrainingService } from '../training.service';
import {
  CourseActionEvent,
  TrainingCourse
} from '../types';

@Component({
  selector: 'training-courses-list-page',
  templateUrl: 'list.html',
  styleUrls: [ 'list.scss' ]
})
export class TrainingCoursesListPage extends BasePageComponent {

  public keyword: string = '';
  public pagination: Pagination = {
    pageno: 0,
    perpage: 12,
    total: 0,
    sorting: undefined
  }

  public courses: TrainingCourse[] = [];
  public empty: string = '';

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    protected server: ServerService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle
  public override loadData(): Promise<void> {
    let promise: Promise<any>;

    this.courses = [];

    if ( this.keyword.length > 0 ) {
      this.empty = `Cannot find any courses match keyword ${this.keyword}`;
      promise = this.server.search('training/courses', this.keyword, {}, this.pagination, { select: 'id'});
    }
    else {
      let uid: string = this.activatedRoute.snapshot.paramMap.get('uid') || 'mine';
      if ( uid == 'all' ) {
        this.empty = `There is no any training courses available on system, now.`;
        promise = this.server.index('training/courses', null, this.pagination);
      }
      else if ( uid == 'mine' ) {
        this.empty = `Cannot find any course according to your planned target. Please check, if you are already set any competency target.`;
        promise = this.server.request('training.course.planned', {uid: this.session.currentUser!.id}, { select: 'id'}, this.pagination);
      }
      else {
        this.empty = `Cannot find any course according to your planned target. Please check, if you are already set any competency target.`;
        promise = this.server.request('training.course.planned', {uid: uid}, { select: 'id'}, this.pagination)
      }
    }

    return promise.then( (res: PaginatedResults<object>) => {
      this.pagination.pageno  = res.pageno;
      this.pagination.perpage = res.perpage;
      this.pagination.total   = res.total;
      this.courses = res.data.map( (d: object) => this.api.createCourse(d) );
      if ( config('client.exam.hide_course_in_silent_period', false) ) {
        this.courses = this.courses.filter( (c: TrainingCourse) => c.my_batch?.my_record?.status == 'passed' || c.next_exam == 0 );
      }
    });

  }

  // -- template API

  public loadPage( pageno: number|null = null ) {
    this.pagination.pageno = pageno || this.pagination.pageno;
    this.loadData()
  }

  public search(keyword: string) {
    this.keyword = keyword;
    this.pagination.pageno = 0;
    this.loadData();
  }

  /*
  public onAfterCourseAction(event: CourseActionEvent) {
    this.refresh();
  }
  */

  public selectCourse(course_id?: number) {
    if ( ! course_id ) return;
    //this.nav.push(TrainingCoursePage, {id: course_id});
    this.nav.push([ '/training/courses/view', course_id ]);
  }

  public edit(course_id?: number) {
    if ( ! course_id ) return;
    //this.nav.push(TrainingCourseEditPage, {id: course_id});
    this.nav.push([ '/training/courses/edit', course_id]);
  }

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }
}
