// --
// @TODO - integrate into common module
// --
import { Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { config } from '@pinacono/common';

@Injectable()
export class PinaconoMissingTranslationHandler implements MissingTranslationHandler {

  handle( params: MissingTranslationHandlerParams): string {
    if ( config('translation.report_missing', false) ) {
      console.warn(`Translation missing for "${params.key}"`);
    }
    const s = params.translateService.parser.interpolate(params.key, params.interpolateParams) as string;
    return config('translation.ignore_missing', true) ? s : `<span class="translation-missing">${s}</span>`;
  }

}
