import {
  Component,
  ViewChild,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NavigationService,
  object_t,
  ServerService,
  UserService
} from '@pinacono/common';

import {
  UIService,
  UserProfileComponent
} from '@pinacono/ui';

import { AppUser } from 'src/app/types';

/**
 * @TODO - browse/search user
 */
@Component({
  selector: 'app-admin-users-page',
  templateUrl: 'users.html',
  styleUrls: [ 'users.scss' ]
})
export class AdminUsersComponent implements OnInit {

  @ViewChild('editor') profileEditor!: UserProfileComponent;

  public data!: AppUser;

  // -- constructor
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected userService: UserService,
    protected server: ServerService,
    protected ui: UIService
  ) {
  }

  public ngOnInit(): void {
  }

  public load(uid: number|null = null): Promise<void> {
    if ( ! uid ) {
      this.data = this.userService.create({
        id: null,
        email: null,
        fullname: null,
        password: null,
        password_confirmation: null,
        active: true,
        avatar: null,
        avatar_id: null,
        avatar_pathUrl: null,
        avatar_thumbUrl: null,
        permissions: [],
        groups: [],
        preferences: {},
        profiles: {},
        attr: {},
        primary_domain: null
      });
      return Promise.resolve();
    }

    return new Promise<void>( (resolve, reject) => {
      this.server.show('users', uid)
      .then( (u: object_t) => {
        this.data = this.userService.create(u);
        resolve();
      })
    });
  }

  // -- template API

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('home');
    }
  }

  public userIsValid: boolean = false;
  public validateUser(isValid: boolean) {
    /** @TODO - validate user information */
    this.userIsValid = isValid;
  }

  public saveUser() {
    //console.log('save user info', this.currentUser);
    this.ui.confirm('Save user?')
    .then( () => {
      this.profileEditor.save();
    });
  }
}
